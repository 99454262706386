import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { getURLParam } from "../../util/helper";
import { Image, View } from "react-native";
import { Button, HelperText, TextInput, Text } from "react-native-paper";
import basicStyles from "../../styles/basic";
import {
  createUser,
  emailLinkSignIn,
  emailLogin,
} from "../../util/firebaseConnection";
import { theme } from "../../../App";
import { signUpNavigationProps } from "../../util/navigationProps";
import { StackScreenProps } from "@react-navigation/stack";
import * as Linking from "expo-linking";
import { colors } from "../../styles/colors";

/**
 * Description: screen for entering user credentials, and login handled via firebase emailLogin function
 * @returns inputs for credentials and login button
 */
export const LoginScreen = ({
  route,
  navigation,
}: StackScreenProps<signUpNavigationProps, "parse">) => {
  const [initializing, setInitializing] = useState<boolean>(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMail, setErrorMail] = useState("");
  const [errorPW, setErrorPW] = useState("");

  const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);
  const logoSource = isBaxi
    ? require("../../../assets/baxi-logo.png")
    : require("../../../assets/carno-logo-black.png");

  function changeError(errorMail?: string, errorPW?: string) {
    if (errorMail == "reset") setErrorMail("");
    else errorMail && setErrorMail(errorMail);
    if (errorPW == "reset") setErrorPW("");
    else errorPW && setErrorPW(errorPW);
  }

  async function signInWithInviteLink() {
    if (route.params?.email) {
      await Linking.getInitialURL().then((inviteLink) => {
        route.params?.email &&
          inviteLink &&
          emailLinkSignIn(route.params.email, inviteLink);
        navigation.navigate("Entry");
      });
    }
    setInitializing(false);
  }

  useEffect(() => {
    getURLParam();
    signInWithInviteLink();
  }, []);

  if (!initializing) {
    return (
      <View style={{ justifyContent: "center", alignItems: "center", flex: 1, backgroundColor: colors.white }}>
        <View style={basicStyles.smallBox}>
          <View style={{ width: "100%", alignItems: "center" }}>
            <Image
              style={basicStyles.logo}
              source={logoSource}
            />
          </View>
          <TextInput
            testID="email-input"
            label="E-Mail"
            value={email}
            onChangeText={(email) => setEmail(email)}
            error={errorMail != ""}
          />
          <HelperText type="error" visible={errorMail != ""}>
            {errorMail}
          </HelperText>
          <TextInput
            testID="password-input"
            label="Password"
            secureTextEntry={true}
            onChangeText={(password) => setPassword(password)}
            error={errorPW != ""}
          />
          <HelperText
            type="error"
            visible={errorPW != ""}
            style={{ textAlign: "left", width: "70%" }}
          >
            {errorPW}
          </HelperText>
          {/*<TouchableOpacity onPress={()=>forgotPW(email,changeError)}>
            <Text style={styles.forgot_button}>Forgot Password?</Text>
    </TouchableOpacity>*/}
          <View>
            <Button
              testID="signin-button"
              mode="contained"
              style={{ marginBottom: 10 }}
              onPress={() => emailLogin(email, password, changeError)}
            >
              Sign-In
            </Button>
            <Button
              testID="signup-button"
              mode="outlined"
              style={{ marginBottom: 10 }}
              onPress={() => createUser(email, password, changeError)}
            >
              Create an Account
            </Button>
            <Text>
              By clicking on "Create an Account", you accept the Terms and
              Conditions.
            </Text>
          </View>
        </View>
      </View>
    );
  }
  return <Text>loading...</Text>;
};
