import { SetFabricTypeParams, SetAdjacentSpaceParams, SetElementObjectParams } from './types';

export function isFabricTypeParams(obj: any): obj is SetFabricTypeParams {
    return obj !== undefined
        && obj !== null
        && typeof obj === 'object'
        && typeof obj.subType !== 'undefined'
        && typeof obj.fabricType !== 'undefined'
        && typeof obj.uValue !== 'undefined';
}

export function isAdjacentSpaceTypeParams(obj: any): obj is SetAdjacentSpaceParams {
    return obj !== undefined
        && obj !== null
        && typeof obj === 'object'
        && typeof obj.subType !== 'undefined'
        && typeof obj.adjacentSpace !== 'undefined';
}

export function isFabricAndAdjacentSpaceTypeParams(obj: any): obj is SetFabricTypeParams & SetAdjacentSpaceParams {
    return obj !== undefined
        && obj !== null
        && typeof obj === 'object'
        && typeof obj.subType !== 'undefined'
        && typeof obj.fabricType !== 'undefined'
        && typeof obj.uValue !== 'undefined'
        && typeof obj.adjacentSpace !== 'undefined';
}
