import { FirebaseError } from "firebase/app";

export const authError = (
  error: FirebaseError,
  changeError: (errorMail?: string, errorPW?: string) => void
) => {
  changeError("reset", "reset");
  if (error.code === "auth/email-already-in-use")
    changeError("That email address is already in use!");
  if (error.code === "auth/invalid-email")
    changeError("That email address is invalid!");
  if (error.code === "auth/weak-password")
    changeError(undefined, "Password should be at least 6 characters!");
  if (error.code === "auth/user-not-found")
    changeError("There is no user associated with this E-Mail.");
  if (error.code === "auth/wrong-password")
    changeError(undefined, "The password is not correct.");
  if (error.code === "auth/too-many-requests")
    changeError(
      undefined,
      "Too many login attempts for this account. Reset your password or try again later."
    );
};
