import type { installer_detail, project_detail, room_detail, sectionMissingInfo } from './types';
import { isOrganisationFilledOut } from './checkFilledOutFields';
import { getGlobal } from './asyncStorageConnection';

export const getSectionsWithMissingInfo = async (
    organisation: any,
    user: installer_detail<'standard'>,
    routeParams: any,
    thisProject: project_detail<'standard'>,
    steps: Array<any>,
    rooms: Array<room_detail<'standard'>>
) => {
    const projectSteps = {
        'Project Details': 0,
        'First Quote': 1,
        'Master Construction Data': 2,
        'Room By Room': 3,
        'System Upgrades': 4,
        'Hot Water': 5,
        'ASHP Selection': 6,
        'Cylinder Selection': 7,
        'Noise Calculation': 8,
        'Report': 9
    };

    const sectionsWithMissingInfo: Array<sectionMissingInfo> = [];

    const isOrgFilledOut = await isOrganisationFilledOut(organisation, user);
    const orgSections = (await getGlobal('isOrganisationFilledOut')) as {
        isOrganisationFilledOut: boolean;
        isBillingInfoFilledOut: boolean;
        isBusinessSettingsFilledOut: boolean;
        isContractPreferencesFilledOut: boolean;
        isPricingFilledOut: boolean;
    }[];

    // ASHP Selection
    if (routeParams.nestedPath === 'heatPump') {
        if (!thisProject.heatingZones?.everythingFilledOut || !thisProject.rooms?.everythingFilledOut) {
            const targetIndex = projectSteps['Room By Room'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: 'The General Information section has missing field(s).'
            });
        }

        if (!thisProject.rooms?.everythingFilledOut) {
            const targetIndex = projectSteps['Room By Room'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: 'One of the rooms has missing field(s).'
            });
        }

        if (!thisProject.heatingZones_lwt?.everythingFilledOut) {
            sectionsWithMissingInfo.push({
                sectionIndex: 3,
                sectionTitle: steps[3].text.substring(3),
                boolVal: true
            });
        }
    }

    // Room By Room
    if (routeParams.nestedPath === 'heatingZones') {
        if (!thisProject.general?.everythingFilledOut) {
            const targetIndex = projectSteps['Project Details'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true
            });
        }
    }

    // System Upgrade
    if (routeParams.nestedPath === 'heatingZones_lwt') {

        let isAllRadiatorsFilledOut = true;
        let isSystemUpgradesRadiatorsFilledOut = true;

        if (!thisProject.general?.everythingFilledOut) {
            const targetIndex = projectSteps['Project Details'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true
            });
        }

        if (!thisProject.heatingZones?.everythingFilledOut) {
            const targetIndex = projectSteps['Room By Room'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: 'The General Information section has missing field(s).'
            });
        }

        if (thisProject.rooms?.everythingFilledOut !== null && !thisProject.rooms?.everythingFilledOut) {
            const targetIndex = projectSteps['Room By Room'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: 'One of the rooms has missing field(s).'
            });
        }

        for (const room of rooms) {
            if (room.existingRadiators) {
                for (const radiator of room.existingRadiators) {
                    if (!radiator.everythingFilledOut) {
                        isAllRadiatorsFilledOut = false;
                        break;
                    }
                }
            }
        }

        for (const room of rooms) {
            if (room.newRadiators) {
                for (const radiator of room.newRadiators) {
                    if (!radiator.everythingFilledOut && JSON.stringify(radiator) !== '{}') {
                        isSystemUpgradesRadiatorsFilledOut = false;
                        break;
                    }
                }
            }
        }

        if (!isAllRadiatorsFilledOut) {
            const targetIndex = projectSteps['Room By Room'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: "One of the radiators in Room By Room section hasn't been filled out. Please make sure that all radiators are filled out."
            });
        }

        if (!isSystemUpgradesRadiatorsFilledOut) {
            const targetIndex = projectSteps['System Upgrades'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: "Please save each radiator under each room in this current section (System Upgrades)."
            });
        }
    }

    // Noise Calculation
    if (routeParams.nestedPath === 'noise') {
        if (!thisProject.heatPump?.everythingFilledOut) {
            const targetIndex = projectSteps['ASHP Selection'];
            sectionsWithMissingInfo.push({
                sectionIndex: targetIndex,
                sectionTitle: steps[targetIndex].text.substring(3),
                boolVal: true,
                missingInfoDetails: "Please select a heat pump first."
            });
        }
    }

    // Reports
    if (routeParams.categoryOfAnswers === 'report' && !isOrgFilledOut) {
        if (!orgSections[0].isBillingInfoFilledOut) {
            sectionsWithMissingInfo.push({
                sectionIndex: 2,
                sectionTitle: 'Billing Information',
                boolVal: true
            });
        }

        if (!orgSections[0].isBusinessSettingsFilledOut) {
            sectionsWithMissingInfo.push({
                sectionIndex: 3,
                sectionTitle: 'Business Settings',
                boolVal: true
            });
        }

        if (!orgSections[0].isContractPreferencesFilledOut) {
            sectionsWithMissingInfo.push({
                sectionIndex: 4,
                sectionTitle: 'Contract Preferences',
                boolVal: true
            });
        }

        if (!orgSections[0].isPricingFilledOut) {
            sectionsWithMissingInfo.push({
                sectionIndex: 5,
                sectionTitle: 'Project Pricing',
                boolVal: true
            });
        }
    }

    return sectionsWithMissingInfo;
};
