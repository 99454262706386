import { getDocument } from './firebaseConnection';
import { orgExtra, orgBilling } from './types';
import { setGlobal } from "../util/asyncStorageConnection";

export const isOrganisationFilledOut = async (organisation: any, user: any) => {
    let isFilledOut = true;

    const organisationExtraInfoSections = [
        'businessSettings',
        'contractPreferences',
        'pricing'
    ];

    const extraInfo = await getDocument<orgExtra>(`organisations/${user.orgId}/extra`, 'extraInfo');
    const billingInfo = await getDocument<orgBilling<"standard">>(`organisations/${user.orgId}/extra`, 'billingInfo');

    const tmp = {
        ...organisation,
        extra: extraInfo,
        billing: billingInfo,
    } as any;

    for (const field of organisationExtraInfoSections) {
        if (!tmp.extra) {
            isFilledOut = false;
            continue;
        }

        if (!tmp.extra[field]) {
            isFilledOut = false;
            continue;
        }

        console.log(field + tmp.extra[field]?.everythingFilledOut)

        if (!tmp.extra[field]?.everythingFilledOut) {
            isFilledOut = false;
            break;
        }
    }

    if (!tmp?.everythingFilledOut || !tmp.billing?.everythingFilledOut) isFilledOut = false;

    setGlobal('isOrganisationFilledOut', [{
        isOrganisationFilledOut: isFilledOut,
        isBillingInfoFilledOut: tmp.billing?.everythingFilledOut,
        isBusinessSettingsFilledOut: tmp?.extra?.businessSettings?.everythingFilledOut || false,
        isContractPreferencesFilledOut: tmp?.extra?.contractPreferences?.everythingFilledOut || false,
        isPricingFilledOut: tmp?.extra?.pricing?.everythingFilledOut || false
    }]);

    return isFilledOut;
};

export const isReportDependenciesFilledOut = async (project: any, organisation: any, user: any) => {
    let isFilledOut = true;

    const sectionsBeforeReport = [
        'general',
        'firstQuote',
        'heatingZones',
        'heatingZones_lwt',
        'hotWater',
        'heatPump',
        'cylinder',
        'noise'
    ];

    for (const field of sectionsBeforeReport) {
        if (!project[field]) {
            isFilledOut = false;
            continue;
        }

        if (!project[field]?.everythingFilledOut) {
            isFilledOut = false;
            break;
        }
    }

    const isOrgFilledOut = await isOrganisationFilledOut(organisation, user);

    return isFilledOut && isOrgFilledOut;
};