import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { useContext, useState } from "react";
import { Appbar } from "react-native-paper";
import { HeaderDispatch, ProjectDetailContext } from "../../util/contexts";
import {
  projectDetailNavigationProps,
  questionListData,
} from "../../util/navigationProps";
// import { RoomByRoomFlatList } from "../../components/Flatlists";
import createSideNavigator from "../../components/SideNavigator";
import { QuestionList } from "../../components/QuestionList";
import { RoomByRoomFlatList } from "../../components/modules/RoomByRoom/RoomByRoomFlatList/RoomByRoomFlatList";

/**
 * Description: defining heating zones and creating a collection of rooms for the current project on mobile
 * @returns RoomByRoomFlatlist
 */
export const RoomByRoomMobile = ({
  route,
  navigation,
}: StackScreenProps<projectDetailNavigationProps, "RoomByRoom">) => {
  const setHeaderOptions = useContext(HeaderDispatch);
  useFocusEffect(() => {
    setHeaderOptions({
      title: "Room By Room",
      left: <Appbar.BackAction onPress={() => navigation.goBack()} />,
    });
  });
  return (
    <RoomByRoomFlatList
      navigate={(
        name: keyof projectDetailNavigationProps,
        params: questionListData | undefined
      ) => navigation.navigate({ name: name, params: params })}
      newRooms={route.params.newRooms}
    />
  );
};

/**
 * Description: defining heating zones and creating a collection of rooms for the current project on desktop
 * @returns RoomByRoomFlatlist
 */
export const RoomByRoomDesktop = ({
    route,
}: StackScreenProps<projectDetailNavigationProps, "RoomByRoom">) => {
    const [highlightedStep, setHighlightedStep] = useState(-0.1);
    const Side = createSideNavigator();
    const thisProject = useContext(ProjectDetailContext);

    return (
        <Side.Navigator
            initialRouteName="Questions"
            screenOptions={{
                left: (
                sideNavigate: (
                    name: keyof projectDetailNavigationProps,
                    params?: questionListData
                ) => void,
                    unmountSide
                ) =>
                    RoomByRoomFlatList({
                        navigate: (
                            name: keyof projectDetailNavigationProps,
                            params: questionListData | undefined
                        ) => sideNavigate(name, params),
                        highlightedStep: highlightedStep,
                        setHighlightedStep: (number) => {
                            setHighlightedStep(number);
                            if (number == -1) unmountSide();
                        },
                        newRooms: route.params.newRooms,
                    }),
            }}>

            <Side.Screen
                name="Questions"
                component={QuestionList}
                initialParams={{
                    displayedQuestionCollection: route.params.newRooms
                        ? "new-rooms-general"
                        : "roombyroom-general",
                    valueStoragePath: "projects",
                    valueStorageDocument: thisProject.id,
                    categoryOfAnswers: "project",
                    nestedPath: !route.params.newRooms
                        ? "heatingZones"
                        : "heatingZones_lwt",
                }}/>
        </Side.Navigator>
    );
};
