import React, { useEffect } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { Question } from '../../../components/Question';
import type { conditionalFunction, itemType, question_detail } from '../../../util/types';
import { isReplaceRadiatorToggle } from '../../../util/helper';
import { Headline, Text } from 'react-native-paper';
import { desktopStyle } from '../../../styles/desktop';
import { once } from 'lodash';

type PageQuestionProps = {
    question: question_detail,
    index: number,
    valueStorageDocument: string,
    productFiltersRef: any,
    items: itemType[],
    setIsSaveDisabled: (value: boolean) => void,
    questionSetAnswer: (
        newAnswer: string,
        prevAnswer: string,
        index: number,
        conditionalFunction?: conditionalFunction,
        productFilters?: string[][]) => void
    conditionalFunction?: conditionalFunction
}

/**
 * Description: reusable question component rendered within QuestionList
 * @param question question_detail object
 * @param index
 * @param conditionalFunction
 * @returns Question
 */
const PageQuestion: React.FC<PageQuestionProps> = ({
    question,
    index,
    valueStorageDocument,
    productFiltersRef,
    items,
    setIsSaveDisabled,
    questionSetAnswer,
    conditionalFunction
 }) => {
    const { colors } = useTheme();
    const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);
    const isSupplierField = question.variableName === 'organisationManufacturers';
    const isManufacturerField = ['heatPump.manufacturer', 'firstQuoteHeatPump.manufacturer'].includes(question?.variableName || '');
    const isBaxiField = isSupplierField || isManufacturerField;

    useEffect(() => {
        if (isReplaceRadiatorToggle(question)) {
            setTimeout(() => {
                once(() => changeAnswer((true).toString()))
            }, 1000);
        }

        if (isSupplierField && isBaxi) changeAnswer(',Baxi');

        if (isManufacturerField && isBaxi) {
            changeAnswer('Baxi')
        }
    }, []);

    function changeAnswer(answer: string) {
        if (answer === 'Picked') setIsSaveDisabled(false);
        if (isSupplierField && isBaxi && question.answer !== '') return;

        questionSetAnswer(
            answer,
            question.answer != undefined ? question.answer : "",
            index,
            conditionalFunction,
            productFiltersRef.current
        );
    }

    if (question.input_rule != 'listOfProducts') {
        if (isReplaceRadiatorToggle(question)) {
            return <></>;
        }

        return (
            <React.Fragment key={question.variableName}>
                <Question
                    question={question}
                    answer={question.answer != undefined ? question.answer : ""}
                    setAnswer={changeAnswer}
                    currentRoom={valueStorageDocument}
                    isBaxiField={isBaxiField} />
            </React.Fragment>
        );
    } else {
        if (question.answer) {
            return (
                <View style={[{ flex: 1, marginTop: 10 }, desktopStyle(colors).headlineQuestionList]}>
                    <Text>Chosen Product</Text>
                </View>
            );
        } else {
            return (
                <View style={[{ flex: 1 }, desktopStyle(colors).headlineQuestionList]}>
                    <View style={{ flexDirection: 'column' }}>
                        <Headline>Available Products</Headline>

                        {items.filter((item) => item.type == 'product').length == 0 && (
                            <Text>No products available</Text>
                        )}
                    </View>
                </View>
            );
        }
    }
};

export default PageQuestion;