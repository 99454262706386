import { StyleSheet } from "react-native";
export const mobileStyle = () =>
  StyleSheet.create({
    questionListFlatlist: {
      width: "100%",
    },
    memberInvite: {
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
    },
    memberInviteSwitch: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: 5,
    },
  });
