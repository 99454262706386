import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { projectDetailNavigationProps } from "../../util/navigationProps";
import { QuestionList } from "../../components/QuestionList";
import { View } from "react-native";
import { Headline } from "react-native-paper";
import basicStyles from "../../styles/basic";
import { SignupProps } from "../../util/types";

export const OrganisationCreation = (props: SignupProps) => {
  return (
    <View style={[basicStyles.container, { margin: 5 }]}>
      <View style={{ margin: 5 }}>
        <Headline style={{ fontSize: 20 }}>
          Enter the details for your organisation.
        </Headline>
      </View>
      <QuestionList
        navigation={
          {} as StackNavigationProp<projectDetailNavigationProps, "Questions">
        }
        route={{
          params: {
            displayedQuestionCollection: "organisationSignup",
            nestedPath: undefined,
            valueStorageDocument: "unknownOrgId",
            valueStoragePath: "organisations",
            categoryOfAnswers: "organisationSignup",
            extraData: props.uid,
          },
          key: "organisation",
          name: "Questions",
        }}
      />
    </View>
  );
};
