import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { projectDetailNavigationProps } from "../../util/navigationProps";
import { QuestionList } from "../../components/QuestionList";
import { View } from "react-native";
import { Headline } from "react-native-paper";
import basicStyles from "../../styles/basic";
import { getUser } from "../../util/firebaseConnection";

export const SettingsScreen = () => {
  const userUid = getUser()?.uid;

  return (
    <View style={[basicStyles.container, { margin: 5 }]}>
      <View style={{ margin: 5 }}>
        <Headline style={{ fontSize: 20 }}>
          Edit your personal details.
        </Headline>
      </View>
      <QuestionList
        navigation={
          {} as StackNavigationProp<projectDetailNavigationProps, "Questions">
        }
        route={{
          params: {
            displayedQuestionCollection: "installerSignup",
            nestedPath: undefined,
            valueStorageDocument: userUid,
            valueStoragePath: "installers",
            categoryOfAnswers: "installerSignup",
          },
          key: "installer",
          name: "Questions",
        }}
      />
    </View>
  );
};
