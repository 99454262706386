import { DocumentData } from "firebase/firestore";
import { createContext } from "react";
import { questionListData } from "./navigationProps";
import {
  installer_detail,
  organisation_detail,
  project_detail,
  room_detail,
} from "./types";

export const DesktopContext = createContext(false);
interface questionStackContextInterface extends questionListData {
  questionValues: DocumentData;
}
export const QuestionStackContext = createContext(
  <questionStackContextInterface>{}
);
export const ProjectContext = createContext([] as project_detail<"standard">[]);
export const InstallerContext = createContext(
  {} as installer_detail<"standard">
);

export const OrganisationContext = createContext(
  {} as organisation_detail<"standard">
);
export const RecalculatingResultsContext = createContext(true);
export const ProjectDetailContext = createContext(
  <project_detail<"standard">>{}
);
export const HeaderDispatch = createContext(new Function());
export const RoomsContext = createContext([] as room_detail<"standard">[]);
