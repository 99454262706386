import { useContext, useEffect } from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { List, IconButton, useTheme } from 'react-native-paper';
import { mergeDataWithDocument, } from '../../../../util/firebaseConnection';
import { setHighlightedTabsPerProject } from '../../../../redux/modules/roomByRoom';
import { desktopStylePressables, } from '../../../../styles/desktop';
import { Hoverable } from 'react-native-web-hover';
import { DesktopContext, ProjectDetailContext } from '../../../../util/contexts';
import { iconColor, titleStyle } from '../../../Flatlists';
import { getElementIndex } from '../../../../util/getElementIndex';
import type { element, rooms, room_detail } from '../../../../util/types';
import navigationStyles from '../../../../styles/navigation';
import styles from './roomElementTitleStyles';

type RoomElementTitleProps = {
    subIndex: number;
    highlightedStep: number;
    roomIndex: number;
    element: element<'standard'>;
    newRooms: rooms<'standard'>;
    room: room_detail<'standard'>;
    roomPath: string;
    setHighlightedStep: (highlightedStep: number) => void;
    navigate: any;
    roomsMasterData: any;
}

/**
* Description: Display room element title tab
* @param props subIndex, highlightedStep, roomIndex, element, newRooms, room, roomPath, setHighlightedStep, navigate
* @returns ReactNode for clickable room element title (floor, ceiling, wall)
*/
const RoomElementTitle: React.FC<RoomElementTitleProps> = ({
    subIndex,
    highlightedStep,
    roomIndex,
    element,
    newRooms,
    room,
    roomPath,
    setHighlightedStep,
    navigate,
    roomsMasterData
}) => {
    const dispatch = useAppDispatch();
    const isDesktop = useContext(DesktopContext);
    const thisProject = useContext(ProjectDetailContext);
    const { colors } = useTheme();

    const isPressed = roomIndex + (subIndex + 1) / 100 == highlightedStep;

    const elementTitle = element.type.charAt(0).toUpperCase() + element.type.slice(1) +
        (['ceiling', 'wall'].includes(element.type)
            ? ' ' + getElementIndex(room.elements, element.type, subIndex)
            : '');

    const elementInfo = element.everythingFilledOut == true ? element.heatLoss != undefined && element.heatLoss != null &&
        'Heat Loss: ' +
            Math.round(element.heatLoss) +
            'W'
        : element.everythingFilledOut == false &&
        'Missing Information';

    const onDeleteElement = () => {
        setHighlightedStep && setHighlightedStep(-1);
        room.elements.splice(subIndex, 1);
        mergeDataWithDocument(roomPath, room.id, {
            elements: room.elements,
        });
    };

    const onClickElementTitle = () => {
        const navigationDetails = {
            displayedQuestionCollection: element.type,
            valueStoragePath: roomPath,
            valueStorageDocument: room.id,
            categoryOfAnswers: 'room',
            nestedPath: 'elements[' + subIndex + ']',
            isProvidingInformationForArrayElement: 'elements',
            variablesToKeep: [
                'type',
                'heatLoss',
                'energyUsagePerYear'
            ],
            roomsMasterData
        };
        const highlightedStep = roomIndex + (subIndex + 1) / 100;

        navigate('Questions', navigationDetails);
        setHighlightedStep && setHighlightedStep(highlightedStep);

        dispatch(setHighlightedTabsPerProject({
            projectId: thisProject.id,
            valuesToSave: {
                previousHighlightedStep: highlightedStep,
                previousQuestionCollection: navigationDetails
            }
        }));
    };

    return (
        <Hoverable>
            {({ hovered }) => (
            <List.Item
                testID='room-by-room-step'
                titleStyle={titleStyle(
                    isDesktop,
                    isPressed,
                    hovered,
                    element.everythingFilledOut,
                    colors
                )}
                style={[
                    navigationStyles.listItem,
                    isDesktop &&
                        desktopStylePressables({
                            pressed: isPressed,
                            hovered: hovered,
                        },
                        colors
                        ).secondMenuButtons,
                ]}
                key={subIndex}
                left={() => (
                    <List.Icon
                        icon={element.type == 'floor' ? 'format-vertical-align-bottom'
                            : element.type == 'wall' ? 'wall'
                            : 'format-vertical-align-top'
                        }
                        color={iconColor(
                            isDesktop,
                            isPressed,
                            hovered,
                            element.everythingFilledOut,
                            colors
                        )}
                    />
                )}
                right={() =>
                    ['ceiling', 'wall'].includes(element.type) &&
                    !newRooms && (
                        <IconButton
                            icon='delete'
                            style={styles.deleteBtn}
                            onPress={onDeleteElement} />
                    )
                }
                description={elementInfo}
                title={elementTitle}
                onPress={onClickElementTitle} />
            )}
        </Hoverable>
    );
};

export default RoomElementTitle;
