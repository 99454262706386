import React, { ReactNode } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import {
  CommonActions,
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  StackActionHelpers,
  StackActions,
  StackNavigationState,
  StackRouter,
  StackRouterOptions,
  useNavigationBuilder,
} from "@react-navigation/native";
import { questionListData } from "../util/navigationProps";
import { isEqual } from "lodash";
// Props accepted by the view
type StackNavigationConfig = {
  tabBarStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

// Supported screen options
type StackNavigationOptions = {
  title?: string;
  left: (
    sideNavigate: (toRouteName: string, params?: questionListData) => void,
    unmountSide: () => void
  ) => ReactNode;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type StackNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};
type Props = DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap
> &
  StackRouterOptions &
  StackNavigationConfig;
//handle login logic
const SideNavigator = ({
  initialRouteName,
  children,
  screenOptions,
}: Props) => {
  //integrate in top https://reactnavigation.org/docs/custom-navigators/
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder<
      StackNavigationState<ParamListBase>,
      StackRouterOptions,
      StackActionHelpers<ParamListBase>,
      StackNavigationOptions,
      StackNavigationEventMap
    >(StackRouter, {
      children,
      screenOptions,
      initialRouteName,
    });
  const focusedRouteKey = state.routes[state.index].key;
  const [loaded, setLoaded] = React.useState([focusedRouteKey]);

  if (!loaded.includes(focusedRouteKey)) {
    setLoaded([...loaded, focusedRouteKey]);
  }
  function sideNavigate(toRouteName: string, params?: questionListData) {
    const route = state.routeNames.filter((route) => route == toRouteName)[0];
    /*const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
            data: {
                isAlreadyFocused: route.key === state.routes[state.index].key,
            },
        });        if (!event.defaultPrevented) {*/

    if (
      !isEqual(params, state.routes[state.index].params) ||
      !isEqual(toRouteName, state.routes[state.index].name)
    ) {
      navigation.dispatch({
        ...StackActions.replace(route, params),
        target: state.key,
      });
    }
    //navigation.setParams(params) //how to do push here
  }
  function unmountSide() {
    navigation.dispatch({
      ...CommonActions.setParams({
        displayedQuestionCollection: undefined,
        valueStoragePath: undefined,
        valueStorageDocument: undefined,
        nestedPath: undefined,
        isProvidingInformationForArrayElement: undefined,
        variablesToKeep: undefined,
      }),
      target: state.key,
    });
  }
  return (
    <NavigationContent>
      <View style={{ flexDirection: "row", height: "100%" }}>
        {descriptors[state.routes[state.index].key].options.left(
          sideNavigate,
          unmountSide
        )}
        <View style={[{ flex: 1 }]}>
          {state.routes.map((route, i) => {
            //this forces lazy loading
            return i === state.index || loaded.includes(route.key) ? (
              <View
                key={route.key}
                style={[
                  StyleSheet.absoluteFill,
                  { display: i === state.index ? "flex" : "none" },
                ]}
              >
                {descriptors[route.key].render()}
              </View>
            ) : null;
          })}
        </View>
      </View>
    </NavigationContent>
  );
};
export default createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap,
  typeof SideNavigator
>(SideNavigator);
