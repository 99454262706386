import type {
  heatPumpProduct,
  product,
  project_detail
} from './types';
import { get as objGet } from 'lodash';

export const computeHeatOutputCapacity = (thisProject: project_detail<'standard'>, product: product, flowTemp: number) => {
  let capacity = undefined as number | undefined; 
  let flowTempThreshBelow = 0;
  let flowTempThreshAbove = 0;

  for (let flowTempIter = 20; flowTempIter <= 70; flowTempIter += 5) {
      const flowTempCaps = objGet(product.content as heatPumpProduct<'string'>, 'cap' + flowTempIter, 'NA');

      if (flowTempCaps != 'NA' && flowTempCaps != '' && flowTempIter <= flowTemp) {
          flowTempThreshBelow = flowTempIter;
      }

      if (flowTempCaps != 'NA' && flowTempCaps != '' && flowTempIter >= flowTemp) {
          flowTempThreshAbove = flowTempIter;
          break;
      }
  }

  if (flowTempThreshAbove > 0 && flowTempThreshBelow > 0) {
      const ambientTemp = thisProject.locationParameters && thisProject.locationParameters.CIBSE;

      let ambientTempThreshBelowIndex = (product.content as heatPumpProduct<'string'>).capDef.findIndex((temp) =>
          parseFloat(temp) == parseFloat(ambientTemp as string)
      );

      let ambientTempThreshAboveIndex = -1;

      if (ambientTempThreshBelowIndex > -1) {
          ambientTempThreshAboveIndex = ambientTempThreshBelowIndex;
      } else {
          ambientTempThreshBelowIndex = (
              product.content as heatPumpProduct<'string'>
          ).capDef.findIndex(
              (temp, index, temps) =>
              parseFloat(temp) < parseFloat(ambientTemp as string) &&
              parseFloat(temps[index + 1]) > parseFloat(ambientTemp as string)
          );

          ambientTempThreshAboveIndex = ambientTempThreshBelowIndex + 1;
      }
      const flowTempBelowCaps = objGet(product.content as heatPumpProduct<'string'>, 'cap' + flowTempThreshBelow);
      const flowTempBelowCap = ambientTempThreshAboveIndex != ambientTempThreshBelowIndex
          ? parseFloat(flowTempBelowCaps[ambientTempThreshBelowIndex]) +
          ((parseFloat(ambientTemp as string) -
              parseFloat(
              (product.content as heatPumpProduct<'string'>).capDef[
                  ambientTempThreshBelowIndex
              ]
              )) /
              (parseFloat(
              (product.content as heatPumpProduct<'string'>).capDef[
                  ambientTempThreshAboveIndex
              ]
              ) -
              parseFloat(
                  (product.content as heatPumpProduct<'string'>).capDef[
                  ambientTempThreshBelowIndex
                  ]
              ))) *
              (parseFloat(flowTempBelowCaps[ambientTempThreshAboveIndex]) -
              parseFloat(flowTempBelowCaps[ambientTempThreshBelowIndex]))
          : parseFloat(flowTempBelowCaps[ambientTempThreshBelowIndex]);

      const flowTempAboveCaps = objGet(product.content as heatPumpProduct<'string'>, 'cap' + flowTempThreshAbove);
      const flowTempAboveCap =
          ambientTempThreshAboveIndex != ambientTempThreshBelowIndex
              ? parseFloat(flowTempAboveCaps[ambientTempThreshBelowIndex]) +
              ((parseFloat(ambientTemp as string) -
                  parseFloat(
                  (product.content as heatPumpProduct<"string">).capDef[
                      ambientTempThreshBelowIndex
                  ]
                  )) /
                  (parseFloat(
                  (product.content as heatPumpProduct<"string">).capDef[
                      ambientTempThreshAboveIndex
                  ]
                  ) -
                  parseFloat(
                      (product.content as heatPumpProduct<"string">).capDef[
                      ambientTempThreshBelowIndex
                      ]
                  ))) *
                  (parseFloat(flowTempAboveCaps[ambientTempThreshAboveIndex]) -
                  parseFloat(flowTempAboveCaps[ambientTempThreshBelowIndex]))
              : parseFloat(flowTempAboveCaps[ambientTempThreshBelowIndex]);

      capacity = flowTempThreshAbove != flowTempThreshBelow
          ? flowTempBelowCap + ((flowTemp - flowTempThreshBelow) / (flowTempThreshAbove - flowTempThreshBelow)) * (flowTempAboveCap - flowTempBelowCap)
          : flowTempBelowCap;
  }

  return capacity;
}