import { View } from 'react-native';
import Typography from '../Typography/Typography';
import { colors } from '../../../styles/colors';
import styles from './presetNoteStyles';

type PresetNoteProps = {
    routeParams: any,
    handlePress: () => void
}

const PresetNote: React.FC<PresetNoteProps> = ({ routeParams, handlePress }) => {
    return (
        <>
            {['floor', 'ceiling', 'wall'].includes(routeParams.displayedQuestionCollection) && routeParams.categoryOfAnswers === 'room' ?
                <View style={styles.container}>
                    <Typography>Save time by creating common presets</Typography>
                    <Typography size={14} color={colors.neutral300} style={{ marginTop: 5 }}>
                        Presets will be used for new {routeParams.displayedQuestionCollection}s created after set up
                    </Typography>
                    <Typography fontWeight='Medium' style={{ marginTop: 15 }}
                        onPress={handlePress}>GO TO MASTER CONSTRUCTION DATA</Typography>
                </View>
                : <></>
            }
        </>
    );
};

export default PresetNote;
