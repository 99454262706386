import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import {
  ComponentType,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Platform, useWindowDimensions } from "react-native";
import { Appbar } from "react-native-paper";
import { ProjectStepFlatList } from "../../components/Flatlists";
import { Stack } from "../../components/Stack";
import {
  HeaderDispatch,
  ProjectContext,
  ProjectDetailContext,
  RecalculatingResultsContext,
  RoomsContext,
} from "../../util/contexts";
import {
  getUser,
  setUpCollectionListener
} from "../../util/firebaseConnection";
import {
  projectDetailNavigationProps,
  projectStackNavigationProps,
  questionListData,
} from "../../util/navigationProps";
import createSideNavigator from "../../components/SideNavigator";
import { RoomByRoomDesktop, RoomByRoomMobile } from "./RoomByRoom";
import { project_detail, room_detail } from "../../util/types";
import { QuerySnapshot, Unsubscribe } from "firebase/firestore";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { calcRoomLoss } from "../../util/localCloudFunctions/MISCalcs";
import {
  cylinder,
  first_quote,
  heatPump,
  hotWater,
  locationData,
  noise,
  roomByRoom,
  upgrades,
} from "../../util/localCloudFunctions/projectDetailConsistency";
import { isEqual } from "lodash";
import { QuestionList } from "../../components/QuestionList";
import { ReportDesktop, ReportMobile } from "./Report";
import { calcRoomGeneration } from "../../util/localCloudFunctions/optimizeRadiators";
import { getGlobal } from "../../util/asyncStorageConnection";
import { loadQuestionsAndConstants } from "../../util/loadQuestionsAndConstants";
import { FirstQuoteDesktop, FirstQuoteMobile } from "./FirstQuote";

/**
 * Description: store context for projects, fetch project data from firestore on local project change
 * @returns project detail wrapper and mobile/desktop project detail screen
 */
export const ProjectDetailWrapper = ({
    route,
    navigation,
}: StackScreenProps<projectStackNavigationProps, "ProjectDetailStack">) => {
    const allProjects = useContext(ProjectContext); //todo: performance issue as thisProject not stored as state??
    const [thisProject, _setThisProject] = useState(
        {} as project_detail<"standard">
    );
    const thisProjectRef = useRef(thisProject);
    const setThisProject = (data: project_detail<"standard">) => {
        thisProjectRef.current = data;
        _setThisProject(data);
    };

    useEffect(() => {
        setThisProject(allProjects.filter((project) => project.id == route.params.projectId)[0]);
    }, []);

    async function checkVersion() {
        if (
            thisProject &&
            thisProject.version &&
            thisProject.version != (await getGlobal("version"))[0].version
        ) {
            loadQuestionsAndConstants(thisProject.version);
        }
    }

    useEffect(() => {
        checkVersion();
    }, [thisProject]);

    if (thisProject && thisProject.id) {
        return (
            <ProjectDetailContext.Provider value={thisProject}>
                <RoomsWrapper
                    navProps={{ route, navigation }}
                    setThisProject={setThisProject}
                    thisProjectRef={thisProjectRef}
                    thisRawProject={
                        allProjects.filter(
                        (project) => project.id == route.params.projectId
                        )[0]
                    } />
            </ProjectDetailContext.Provider>
        );
    } else return null;
};

const RoomsWrapper = (props: {
    navProps: StackScreenProps<projectStackNavigationProps, "ProjectDetailStack">;
    setThisProject: (data: project_detail<"standard">) => void;
    thisProjectRef: MutableRefObject<project_detail<"standard">>;
    thisRawProject: project_detail<"standard">;
}) => {
    const [rooms, _setRooms] = useState([] as room_detail<"standard">[]);
    const [lastEdited, setLastEdited] = useState(0);
    const [initializing, setInitializing] = useState(true);
    const [recalculatingResults, setRecalculatingResults] = useState(false);
    const roomsRef = useRef(rooms);
    const setRooms = (data: room_detail<"standard">[]) => {
        roomsRef.current = data;
        _setRooms(data);
    };
    const thisProject = useContext(ProjectDetailContext);
    const roomPath = "projects/" + thisProject.id + "/rooms";
    const windowWidth = useWindowDimensions().width;

    async function onRoomsChanged(
        query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
    ) {
        const rooms = roomsRef.current;
        const thisProject = props.thisProjectRef.current;
        const roomsTmp = [
            ...query.docs.map((doc) => {
                return ({ ...doc.data(), id: doc.id } as room_detail<"standard">) || {};
            }),
        ];

        if (!isEqual(roomsTmp, rooms)) {
            setRecalculatingResults(true);
            setRooms(roomsTmp);
            await onRoomsOrProjectChanged(thisProject, roomsTmp);
        }

        if (initializing) setInitializing(false);
        /*if (!isEqual(roomsTmp, rooms)) {
        onRoomsOrProjectChanged(thisProject, roomsTmp);
        }*/
    }

    async function onThisProjectChanged(
        newThisProject: project_detail<"standard">
    ) {
        setLastEdited(newThisProject.lastEdited);
        setRecalculatingResults(true);
        props.setThisProject({ ...thisProject, ...newThisProject });
        onRoomsOrProjectChanged(newThisProject);
    }

    async function onRoomsOrProjectChanged(
        thisProject: project_detail<"standard">,
        roomsTmp?: room_detail<"standard">[]
    ) {
        const tmpProject = {} as project_detail<"standard">;
        tmpProject.locationParameters = await locationData(thisProject);
        let tmpRooms = [] as room_detail<"standard">[];

        tmpRooms.push(...(roomsTmp || rooms));
        tmpRooms = await Promise.all(
            tmpRooms.map(async (room) => {
                room = calcRoomLoss(room, tmpRooms, { ...thisProject, ...tmpProject });
                room = await calcRoomGeneration(room, {
                    ...thisProject,
                    ...tmpProject,
                });
                console.log('ROOM LOSS COMPUTATION');
                console.log(room.heatLoss)
                return room;
            })
        );

        if (!isEqual(tmpRooms, rooms)) {
            setRooms(tmpRooms);
        }

        tmpProject.rooms = roomByRoom(tmpRooms, thisProject);
        tmpProject.upgrades = await upgrades(tmpRooms, thisProject);

        if (roomsTmp) {
            trySetProject(tmpProject, thisProject);
            setRecalculatingResults(false);
            return;
        }

        if (thisProject.hotWater) {
            tmpProject.hotWater = hotWater(thisProject);
        }

        tmpProject.firstQuote = await first_quote({
            ...thisProject,
            ...tmpProject,
        });

        if (thisProject.noise) {
            tmpProject.noise = await noise(thisProject);
        }

        [
            tmpProject.heatPump,
            tmpProject.cylinder,
            tmpProject.firstQuoteHeatPump,
            tmpProject.firstQuoteCylinder,
        ] = await Promise.all([
            heatPump({ ...thisProject, ...tmpProject }),
            cylinder({ ...thisProject, ...tmpProject }),
            heatPump({ ...thisProject, ...tmpProject }, true),
            cylinder({ ...thisProject, ...tmpProject }, true),
        ]);

        /*tmpProject.firstQuote = await first_quote({
        ...thisProject,
        ...tmpProject,
        });
        tmpProject.noise = await noise(thisProject);
        tmpProject.hotWater = hotWater(thisProject);
        tmpProject.heatPump = await heatPump({ ...thisProject, ...tmpProject });
        tmpProject.cylinder = await cylinder({ ...thisProject, ...tmpProject });
        */

        trySetProject(tmpProject, thisProject);
    }

    function trySetProject(
        tmpProject: project_detail<"standard">,
        thisProject: project_detail<"standard">
    ) {
        /*(!isEqual(tmpProject.rooms, thisProject.rooms) ||
        !isEqual(tmpProject.upgrades, thisProject.upgrades) ||
        !isEqual(tmpProject.locationParameters, thisProject.locationParameters) ||
        !isEqual(tmpProject.firstQuote, thisProject.firstQuote) ||
        !isEqual(tmpProject.noise, thisProject.noise) ||
        !isEqual(tmpProject.hotWater, thisProject.hotWater) ||
        !isEqual(tmpProject.cylinder, thisProject.cylinder) ||
        !isEqual(tmpProject.heatPump, thisProject.heatPump)) &&*/
        setRecalculatingResults(false);
        props.setThisProject({ ...thisProject, ...tmpProject });
    }

    useEffect(() => {
        const subscriber = setUpCollectionListener(
            roomPath,
            false,
            onRoomsChanged,
            [["accessRights.read", "array-contains", getUser()?.uid || ""]],
            ["created", "asc"]
        ) as Unsubscribe;

        return subscriber;
    }, []);

    useEffect(() => {
        if (!initializing && lastEdited != props.thisRawProject.lastEdited) {
            onThisProjectChanged(props.thisRawProject);
        }
    }, [props.thisRawProject, initializing]);

    return (
        <RecalculatingResultsContext.Provider value={recalculatingResults}>
            <RoomsContext.Provider value={rooms}>
                {Platform.OS != "ios" &&
                Platform.OS != "android" &&
                windowWidth > 500 ? (
                    <ProjectDetailDesktop
                        route={props.navProps.route}
                        navigation={props.navProps.navigation}
                    />
                ) : (
                    <ProjectDetailMobile />
                )}
            </RoomsContext.Provider>
        </RecalculatingResultsContext.Provider>
    );
};

/**
 * Description: handle navigation within project details screen for mobile
 * @returns stack starting on ProjectDetail screen
 */
export const ProjectDetailMobile = () => {
  const screens = [
    {
      name: "ProjectDetail",
      component: ProjectDetail as ComponentType,
    },
    {
      name: "Questions",
      component: QuestionList as ComponentType,
    },
    {
      name: "RoomByRoom",
      component: RoomByRoomMobile as ComponentType,
    },
    {
      name: "Report",
      component: ReportMobile as ComponentType,
    },
    {
      name: "FirstQuote",
      component: FirstQuoteMobile as ComponentType,
    },
  ];
  return <Stack screens={screens} initialRoute="ProjectDetail" />;
};

/**
 * Description: handle navigation within ProjectDetail screen for desktop
 * @returns project steps navigation and close project button to go back to projects list
 */
export const ProjectDetailDesktop = ({
  navigation,
}: StackScreenProps<projectStackNavigationProps, "ProjectDetailStack">) => {
  const [highlightedStep, setHighlightedStep] = useState(-1);
  const Side = createSideNavigator();
  return (
    <Side.Navigator
      initialRouteName="Questions"
      screenOptions={{
        left: (
          sideNavigate: (
            name: keyof projectDetailNavigationProps,
            params?: questionListData
          ) => void
        ) =>
          ProjectStepFlatList({
            navigate: (
              name: keyof projectDetailNavigationProps,
              params: questionListData
            ) => sideNavigate(name, params),
            closeProject: () => navigation.goBack(),
            highlightedStep: highlightedStep,
            setHighlightedStep: (number) => {
              setHighlightedStep(number);
            },
          }),
      }}
    >
      <Side.Screen name="RoomByRoom" component={RoomByRoomDesktop} />
      <Side.Screen name="Questions" component={QuestionList} />
      <Side.Screen name="Report" component={ReportDesktop} />
      <Side.Screen name="FirstQuote" component={FirstQuoteDesktop} />
    </Side.Navigator>
  );
};

/**
 * Description: project detail screen for mobile
 * @returns flatlist of projects steps and back button to return to projects list
 */
const ProjectDetail = ({
  navigation,
}: StackScreenProps<projectDetailNavigationProps, "ProjectDetail">) => {
  const setHeaderOptions = useContext(HeaderDispatch);
  const thisProject = useContext(ProjectDetailContext);
  useFocusEffect(() => {
    setHeaderOptions &&
      setHeaderOptions({
        title:
          thisProject.general && thisProject.general.customerName
            ? "Project for " + thisProject.general.customerName
            : "New Project",
        left: navigation.getParent() && (
          <Appbar.BackAction onPress={() => navigation.getParent()?.goBack()} />
        ),
      });
  });
  return (
    <ProjectStepFlatList
      navigate={(
        name: keyof projectDetailNavigationProps,
        params: questionListData
      ) => {
        navigation.navigate(name, params);
      }}
    />
  );
};
