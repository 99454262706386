import { Dimensions, StyleSheet } from "react-native";
import { PressableState } from "../util/types";

export const desktopStylePressables = (
  { pressed, hovered }: PressableState,
  colors: ReactNativePaper.ThemeColors
) => {
    return StyleSheet.create({
        topNavigatorButtons: {
            backgroundColor: hovered ? colors.text : colors.primary,
            width: 120,
            marginHorizontal: 5,
            alignItems: "center",
        },
        secondMenuButtons: {
            backgroundColor: hovered
                ? colors.surface
                : pressed
                ? colors.background
                : colors.accent,
        },
        itemTitle: {
            color: pressed ? colors.primary : colors.text,
            fontFamily: pressed ? "RobotoBold" : "Roboto",
        },
        firstMenuButtons: {
            backgroundColor: hovered
                ? colors.surface
                : pressed
                ? colors.accent
                : colors.secondary,
        },
    });
}

export const desktopIconColor = (
  { pressed }: PressableState,
  colors: ReactNativePaper.ThemeColors
) => {
  return pressed ? colors.primary : colors.text;
};
export const menuWidth = 270;
export const questionWidth = 350;
export const desktopStyle = (colors: ReactNativePaper.ThemeColors) =>
  StyleSheet.create({
    firstMenu: {
      width: menuWidth,
      backgroundColor: colors.secondary, //secondary
      /*borderRightWidth: 0.5,
        borderColor: "grey"*/
    },
    firstMenuText: {
      color: colors.text,
    },
    secondMenu: {
      backgroundColor: colors.accent,
      width: menuWidth,
    },
    questionListFlatlist: {
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "flex-start",
    },
    signupFlatlist: {
      flexWrap: "wrap",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    question: {
      width: questionWidth,
    },
    headlineQuestionList: {
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 5,
      width: Dimensions.get("window").width - 2 * menuWidth,
    },
    memberInvite: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
    },
    memberInviteSwitch: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 5,
    },
  });
