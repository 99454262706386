import { useState, useContext, ReactNode } from 'react';
import { useRoute } from '@react-navigation/native';
import type { zeroValuesMagicplanResponse } from '../../../util/types';
import { extractWallDoorWindowFabricType } from '../../../util/masterData';
import { View } from 'react-native';
import {
  Button,
  Dialog,
  Paragraph,
  Portal,
  ActivityIndicator,
  Text,
  IconButton
} from 'react-native-paper';
import _ from 'lodash';
import { getDocumentAsync } from 'expo-document-picker';
import { saveNewRoomTypes } from '../../../util/roomByRoom';
import AppEventEmitter, { AppEvent } from '../../../util/appEvent';
import { createDocument, mergeDataWithDocument } from '../../../util/firebaseConnection';
import { getRoomValuesFromMagicplanCSV } from '../../../util/firebaseConnection';
import { getRoomType } from '../../../util/getRoomType';
import { setGlobal } from '../../../util/asyncStorageConnection';
import { ProjectDetailContext } from '../../../util/contexts';
import { Typography, MagicPlanErrorMessage } from '../../base';
import uuid from 'react-native-uuid';
import styles from './magicPlanImportDialogStyles';

type MagicplanProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    children?: ReactNode;
}

/**
* Description: Displays an overlayed dialog box, which executes a function for uploading a csv file containing floor plan from magicplan
* @param props visible, setVisible, setCsvStoragePath, executedFunction
* @returns ReactNode of the shown confirmation box
*/
const Magicplan: React.FC<MagicplanProps> = ({
    visible,
    setVisible,
    children
}) => {
    const route = useRoute() as any;

    const thisProject = useContext(ProjectDetailContext);
    const roomPath = 'projects/' + thisProject.id + '/rooms';

    const [isProcessingCsv, setIsProcessingCsv] = useState(false);
    const [validationMsg, setValidationMsg] = useState('');
    const [result, setResult] = useState(null) as any;
    const [hasRecentlyUploaded, setHasRecentlyUploaded] = useState(false);
    const [zeroValues, setZeroValues] = useState([] as zeroValuesMagicplanResponse[]);

    const {
        wallElementFabric,
        wallUvalue,
        doorElementFabric,
        doorUvalue,
        windowElementFabric,
        windowUvalue
    } = extractWallDoorWindowFabricType(route.params); // NOTE: we only prepopulate when it's an external wall, to display the door and window elements as well

    const uploadCSV = async () => {
        if (result === null || isProcessingCsv) return;

        const base64String = result.uri.toString().replace('data:text/csv;base64,', '')

        try {
            const response = await getRoomValuesFromMagicplanCSV(base64String) as any; // prolly an array
            const rooms = response.toPopulate;

            if (response.zeroValues.length !== 0) {
                setZeroValues(response.zeroValues);
                setHasRecentlyUploaded(true);
            }

            if (rooms.length === 0) {
                setValidationMsg('Please upload a valid csv file from Magicplan.');
                return;
            }

            await createRooms(rooms as any);
            setValidationMsg('');

            if (response.zeroValues.length === 0) setVisible(false);

        } catch (err: any) {
            console.log(`Magicplan prepopulation: ${err}`);
            setIsProcessingCsv(false);
        }
    }; 

    const onClickImport = _.once(uploadCSV);

    const createRooms = async (rooms: Array<any>) => {
        setIsProcessingCsv(true);

        let addedRadiatorFlag = false;
        const newRoomTypes = [];

        for (const room of rooms) {
            const valueStorageDocument = uuid.v1().toString(); 
            const { roomType, isNew } = await getRoomType(room.type);
            const elements = [];
            const radiators = [];

            let heatingZones = [];
            let firstHeatingZoneIsEmpty = false;

            if (isNew) newRoomTypes.push(room.type);

            for (const wall of room.walls) {

                const windows = [];
                const doors = [];

                for (const element of wall.others) {
                    if (element.type.toLowerCase() === 'window') {
                        windows.push({
                            dim1: element.length.toFixed(2),
                            dim2: element.height.toFixed(2),
                            elementFabric: windowElementFabric,
                            Uvalue: windowUvalue,
                            everythingFilledOut: false
                        });
                    } else if (element.type.toLowerCase() === 'door') {
                        doors.push({
                            dim1: element.length.toFixed(2),
                            dim2: element.height.toFixed(2),
                            elementFabric: doorElementFabric,
                            Uvalue: doorUvalue,
                            everythingFilledOut: false
                        });
                    } else if (element.type.toLowerCase().includes('radiator')) {
                        radiators.push({
                            length: element.length.toString(), // '300'
                            height: element.height.toString(), // '300'
                            everythingFilledOut: false
                        });
                    }
                }

                const hasWindowsOrDoors = windows.length > 0 || doors.length > 0;

                elements.push({
                    type: 'wall',
                    dim1: wall.length.toFixed(2),
                    dim2: wall.height.toFixed(2),
                    wallType: hasWindowsOrDoors ? 'External Wall' : '',
                    elementFabric: hasWindowsOrDoors ? wallElementFabric : '',
                    Uvalue: hasWindowsOrDoors ? wallUvalue : '',
                    windows,
                    doors,
                    everythingFilledOut: false
                });
            }

            if (radiators.length > 0) {

                if (typeof thisProject?.heatingZones?.heatingZones[0]?.emitterType == 'undefined') {
                    firstHeatingZoneIsEmpty = true;
                    heatingZones = [{ emitterType: 'Radiator' }];
                } else {
                    firstHeatingZoneIsEmpty = false;
                    heatingZones = [
                        ...thisProject.heatingZones?.heatingZones as any,
                        { emitterType: 'Radiator' }
                    ];
                }

                AppEventEmitter.emit(AppEvent.UpdateThisProject, firstHeatingZoneIsEmpty);

                if (!addedRadiatorFlag) {
                    await mergeDataWithDocument('projects', thisProject.id, {
                        heatingZones:{
                            ...thisProject.heatingZones,
                            heatingZones
                        }
                    });

                    await setGlobal('isMagicplanUploaded', [{ isMagicplanUploaded: true }]);
                }

                addedRadiatorFlag = true;
            }

            await createDocument(roomPath, valueStorageDocument, {
                created: Date.now(),
                elements: [
                    {
                        type: 'floor',
                        dim1: room.length.toFixed(2),
                        dim2: room.width.toFixed(2),
                    },
                    { type: 'ceiling' },
                    ...elements,
                ],
                existingRadiators: radiators,
                newRadiators: radiators.map(() => { return {}; }),
                general: {
                    customName: room.customName,
                    roomType: roomType,
                    roomLength: room.length.toFixed(2),
                    roomWidth: room.width.toFixed(2),
                    roomHeight: room.height.toFixed(2),
                    zone: radiators.length > 0 ? (heatingZones.length - 1).toString() : '',
                    everythingFilledOut: false
                },
                area: room.area,
                volume: room.volume,
                accessRights: {
                    read: thisProject.accessRights.read,
                    write: thisProject.accessRights.write,
                },
            });
        }

        saveNewRoomTypes(newRoomTypes);
        setIsProcessingCsv(false);
    };

    const onUploadCSV = () => {
        getDocumentAsync({ type: 'text/csv' }).then((result) => {
            if (result.type == 'success') {
                setResult(result as any);
                setValidationMsg('');
                setHasRecentlyUploaded(false);
            }
        });
    };

    return (
        <View style={styles.container}>
            <Portal>
                <Dialog
                    visible={visible}
                    onDismiss={() => setVisible(false)}
                    dismissable={false}
                    style={styles.dialog}>

                    <View style={styles.header}>
                        <Dialog.Title>Import Magicplan CSV</Dialog.Title>
                        <IconButton
                            icon='close'
                            disabled={isProcessingCsv}
                            onPress={() => setVisible(false)} />
                    </View>
                    <Dialog.Content>
                        <Typography size={14} style={{ marginBottom: 15 }}>RECOMMENDED - Importing a statistics CSV from magic plan saves time. Your rooms, dimensions and other elements will prepopulate into your project. Ensure you only upload one CSV per project.</Typography>
                        {validationMsg !== '' && <Paragraph style={styles.validationMsg}>{validationMsg}</Paragraph>}
                        <Button
                            style={styles.button}
                            testID={'upload file'}
                            mode='contained'
                            uppercase={false}
                            onPress={onUploadCSV}>
                                Upload statistics CSV
                        </Button>
                        <Typography size={14}>{result?.name}</Typography>

                        {zeroValues.length !== 0 && <MagicPlanErrorMessage zeroValues={zeroValues} />}
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={onClickImport}
                            color={'#008000'}
                            disabled={isProcessingCsv || !result || hasRecentlyUploaded}
                            style={styles.cancelBtn}
                            mode='contained'>{isProcessingCsv ? <ActivityIndicator /> : <Text style={styles.importText}>Import</Text>}</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
};

export default Magicplan;
