import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import {
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { InstallerContext, OrganisationContext } from "../../util/contexts";
import {
  getDocument,
  getUser,
  setUpCollectionListener,
  setUpDocListener,
} from "../../util/firebaseConnection";
import {
  installer_detail,
  organisation_detail,
  orgBilling,
  orgExtra,
} from "../../util/types";
import { DesktopNavigator } from "./DesktopNavigator";
import { MobileNavigator } from "./MobileNavigator";
import { InstallerSignup } from "../SignUp/InstallerSignup";
import { OrganisationCreation } from "../SignUp/OrganisationCreation";
import { PasswordReset } from "../SignUp/PasswordReset";

export const Navigators = () => {
  const windowWidth = useWindowDimensions().width;
  return (
    <React.Fragment>
      {Platform.OS != "ios" && Platform.OS != "android" && windowWidth > 500 ? (
        <DesktopNavigator />
      ) : (
        <MobileNavigator />
      )}
    </React.Fragment>
  );
};

/**
 * Description: user is signed in, now check if they are an active user (their password is already set)
 */
export const ActiveUserCheck = () => {
  const user = getUser();
  const [activeUser, setActiveUser] = useState<boolean>(false); //isUserActive
  const [initializing, setInitializing] = useState(true);

  function onInstallersUpdate(
    doc: DocumentSnapshot | FirebaseFirestoreTypes.DocumentSnapshot
  ) {
    const tmp = { ...doc.data() } as installer_detail<"standard">;
    setActiveUser(tmp.active);
    setInitializing(false);
  }
  useEffect(() => {
    const subscriber = setUpDocListener(
      "installers",
      user?.uid || "none",
      onInstallersUpdate
    );
    return subscriber;
  }, []);
  if (!initializing)
    return !activeUser ? <PasswordReset /> : <InstallerCheck />;
  else return null;
};

/**
 * Description: user is signed in and active  , now check if they are an active user (their password is already set)
 */
const InstallerCheck = () => {
  const user = getUser();
  const [installerInfo, setInstallerInfo] = useState(
    {} as installer_detail<"standard">
  );
  const [initializing, setInitializing] = useState(true);
  function onInstallersUpdate(
    query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
  ) {
    let tmp = {} as installer_detail<"standard">;
    query.forEach((doc: DocumentData) => {
      tmp = {
        ...doc.data(),
        id: doc.id,
      } as installer_detail<"standard">;
    });

    setInstallerInfo(tmp);
    setInitializing(false);
  }

  useEffect(() => {
    const subscriber = setUpCollectionListener(
      "installers",
      false,
      onInstallersUpdate,
      [["firestoreDocId", "==", user?.uid || "none"]]
    ) as Unsubscribe;
    return subscriber;
  }, []);

  if (!user) {
    throw ReferenceError("user is not logged in");
  }
  if (!initializing)
    return (
      <InstallerContext.Provider value={installerInfo}>
        {!installerInfo.everythingFilledOut ? (
          <InstallerSignup uid={user.uid} />
        ) : (
          <OrganisationCheck />
        )}
      </InstallerContext.Provider>
    );
  else return null;
};

const OrganisationCheck = () => {
  const [orgData, setOrgData] = useState({} as organisation_detail<"standard">);
  const [initializing, setInitializing] = useState(true);
  const [isOrgMember, setIsOrgMember] = useState<boolean>(false);
  const user = getUser();

  async function onOrganisationsUpdate(
    query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
  ) {
    let tmp = {} as organisation_detail<"standard">;
    setIsOrgMember(query.docs.length > 0);
    query.docs.forEach((doc: DocumentData) => {
      tmp = { ...doc.data() };
    });
    setOrgData(tmp);
    setInitializing(false);
  }

  useEffect(() => {
    const subscriber = setUpCollectionListener(
      "organisations",
      false,
      onOrganisationsUpdate,
      [["installers", "array-contains", user?.uid || "none"]]
    ) as Unsubscribe;
    return subscriber;
  }, []);
  if (!user) {
    throw ReferenceError("user is not logged in");
  }
  if (!initializing)
    return (
      <OrganisationContext.Provider value={orgData}>
        {!isOrgMember ? (
          <OrganisationCreation uid={user.uid} />
        ) : (
          <Navigators />
        )}
      </OrganisationContext.Provider>
    );
  else return null;
};
