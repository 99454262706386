import { useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dimensions, FlatList, ListRenderItem, View, ScrollView } from "react-native";
import { setIsLoadingMoreProducts } from "../redux/modules/systemUpgrades";
import useRoomDataPrepopulation from "../util/customHooks/masterData/useRoomDataPrepopulation";
import type {
  conditionalFunction,
  itemType,
  orgBilling,
  orgExtra,
  product,
  question_detail,
  reportType,
  section,
  sectionMissingInfo
} from "../util/types";
import {
  createDocument,
  createReport,
  getDocument,
  mergeDataWithDocument,
  setUpCollectionListener,
} from "../util/firebaseConnection";
import { projectDetailNavigationProps } from "../util/navigationProps";
import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import {
  DesktopContext,
  HeaderDispatch,
  InstallerContext,
  OrganisationContext,
  ProjectDetailContext,
  RecalculatingResultsContext,
  RoomsContext,
} from "../util/contexts";
import {
  ActivityIndicator,
  Appbar,
  Button,
  Headline,
  HelperText,
  Text,
  useTheme,
} from "react-native-paper";
import AppEventEmitter, { AppEvent } from '../util/appEvent';
import { getGlobal, setGlobal } from "../util/asyncStorageConnection";
import _, { set as objSet, get as objGet, isEmpty } from "lodash";
import { desktopStyle, menuWidth } from "../styles/desktop";
import { mobileStyle } from "../styles/mobile";
import { findQuestionIndex, prepareItems } from "../util/conditionalFunctions";
import { checkIfInputRuleViolated } from "../util/checkIfInputRuleViolated";
import uuid from "react-native-uuid";
import { radiators } from "../util/localCloudFunctions/roomConsistency";
import { reports } from "../util/reports";
import { firstQuotes } from "../util/firstQuotes";
import { projectSteps } from "../util/projectSteps";
import { HeatingZoneDeleteDialog, FloorPlanDialog } from "./modules/dialogs";
import { addRadiatorToHeatingZones } from "../util/addRadiatorToHeatingZones";
import { addNoiseCalculationFactors } from "../util/noiseCalculation";
import { getSectionsWithMissingInfo } from "../util/redirectLinks";
import { hasElementTypeNull } from "../util/saveCheck";
import { MissingInfoRedirectLinks, PageSection, PresetNote } from "./base";
import { isDimensionZero } from "../util/helper";
import prepopulateFieldsFromMasterData from "../util/masterData/prepopulateFieldsFromMasterData";
import Product from "./modules/Product/Product";
import PageQuestion from "./base/PageQuestion/PageQuestion";
import MasterConstructionData from "./modules/MasterConstructionData/MasterConstructionDataContainer";
import Typography from "./base/Typography/Typography";
import styles from "./modules/MagicPlanImportDialog/magicPlanImportDialogStyles";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { TouchableOpacity } from "react-native-gesture-handler";
import { DesignSummary } from "./modules";

/**
 * Description: create a list of questions and sections to enable project detail input
 * @returns FlatList of questions and sections
 */
export const QuestionList = ({
  route,
  navigation,
}: StackScreenProps<projectDetailNavigationProps, "Questions">) => {
    const dispatch = useAppDispatch();
    const { prepopulateRoomData } = useRoomDataPrepopulation();

    if (
        !route.params ||
        isEmpty(route.params) ||
        !route.params.displayedQuestionCollection ||
        !route.params.valueStoragePath ||
        !route.params.valueStorageDocument
    ) {
        return null;
    }

    const [displayHeatingZoneMsg, setDisplayHeatingZongMsg] = useState(false);

    useEffect(() => {
        const updateThisProject = AppEventEmitter.addListener(AppEvent.UpdateThisProject, addRadiatorToItems);
        console.log(route.params.valueStoragePath + '/' + route.params.valueStorageDocument)

        return () => {
            updateThisProject.remove();
        };
    }, []);

    const addRadiatorToItems = (firstHeatingZoneIsEmpty: boolean) => {
        if (itemsRef.current?.length > 0 && itemsRef.current[0].content?.type == 'roombyroom-general') {
            addRadiatorToHeatingZones(firstHeatingZoneIsEmpty, itemsRef);
        }
    };

    async function saveChanges() {
        console.log('SAVE ROOMS')

        const storageValue = (await getGlobal('isMagicplanUploaded')) as {
            isMagicplanUploaded: boolean;
        }[];

        if (storageValue !== null && storageValue[0].isMagicplanUploaded) {
            await setGlobal('isMagicplanUploaded', [{ isMagicplanUploaded: false }]);
            return;
        }

        if (
            !route.params ||
            isEmpty(route.params) ||
            !route.params.displayedQuestionCollection ||
            !route.params.valueStoragePath ||
            !route.params.valueStorageDocument
        ) {
            throw ReferenceError("Parameters not set");
        }

        const items = itemsRef.current;

        // add fix for bug #1
        const questionsFilledOut = !items.some(
            (item) =>
            (item.content as question_detail).answer === "" ||
            (item.content as question_detail).answer === undefined
        );

        // disable save changes if there is a violation of input rule anywhere in items <====
        if (
            !items.some((item) => item.type === "question_detail" && (item.content as question_detail).inputRuleError && !isDimensionZero(item.content as question_detail)) &&
            // check that a section has at least one question
            !(items.some((item) => item.type === "section") && !items.some((item) => item.type === "question_detail")) &&
            // check that if this is sigup, mandatory fields are filled
            !(
                (route.params.categoryOfAnswers === "installerSignup" ||
                route.params.categoryOfAnswers === "organisationSignup") && //if this is signup, dont save anything incomplete
                questionsFilledOut == false
            )
        ) {
            let replacedValues = {} as any;
            let everythingFilledOut = true as boolean;

            items.forEach((item) => {
                if (item.type == "question_detail") {

                    const question = item.content as question_detail;

                    if (question.answer == undefined || question.answer == "" || isDimensionZero(question)) {
                        everythingFilledOut = false;
                    }

                    const answer =
                        question.answer != undefined && question.answer != ""
                        ? question.input_rule === "onlyNumber" ||
                            question.input_rule === "minMax"
                            ? parseFloat(question.answer)
                            : question.input_rule == "yesNo"
                            ? question.answer == "true"
                            : question.answer
                        : null;
                    replacedValues = objSet(
                        replacedValues,
                        question.variableName,
                        answer
                    );
                }
            });

            replacedValues = objSet(
                replacedValues,
                (route.params.nestedPath ? route.params.nestedPath + "." : "") + "everythingFilledOut",
                everythingFilledOut
            );

            if (typeof replacedValues?.noise !== 'undefined') {
                await addNoiseCalculationFactors(replacedValues, thisProject);
            }

            if (JSON.stringify(replacedValues) != '{}') {
                console.log('Saving replaced values...');
                if (route.params.isProvidingInformationForArrayElement && route.params.valueStorageDocument) {
                    console.log('Saving replaced values... (Case 1)');

                    console.log('replacedValues')

                    if (hasElementTypeNull(replacedValues)) return;

                    await mergeDataWithDocument(
                        route.params.valueStoragePath,
                        route.params.valueStorageDocument,
                        replacedValues,
                        true,
                        route.params.nestedPath,
                        route.params.variablesToKeep
                    );
                } else if (
                    route.params.categoryOfAnswers === "organisationSignup" &&
                    everythingFilledOut !== false &&
                    route.params.extraData
                ) {
                    console.log('Saving replaced values... (Case 2)');

                    const replacedValuesWithUsers = {
                        ...replacedValues,
                        admins: [route.params.extraData],
                        installers: [route.params.extraData]
                    };
                    const randomOrgUid = uuid.v1().toString();

                    createDocument(
                        route.params.valueStoragePath,
                        randomOrgUid,
                        replacedValuesWithUsers
                    );
                    createDocument(
                        `${route.params.valueStoragePath}/${randomOrgUid}/extra`,
                        "extraInfo",
                        {}
                    );
                    createDocument(
                        `${route.params.valueStoragePath}/${randomOrgUid}/extra`,
                        "billingInfo",
                        {}
                    );
                    await mergeDataWithDocument(
                        "installers",
                        route.params.extraData as string,
                        {
                            orgId: randomOrgUid,
                            role: "admin",
                        }
                    );
                } else if (route.params.categoryOfAnswers === "organisationSignup" && everythingFilledOut !== false) {
                    console.log('Saving replaced values... (Case 3)');
                    console.log(`${route.params.valueStoragePath}/${route.params.valueStorageDocument}`);
                    await mergeDataWithDocument(
                        route.params.valueStoragePath,
                        route.params.valueStorageDocument,
                        replacedValues
                    );
                } else if (
                    route.params.valueStorageDocument &&
                    !(
                        route.params.categoryOfAnswers === "installerSignup" &&
                        everythingFilledOut == false
                    ) &&
                    !(
                        route.params.categoryOfAnswers === "organisationSignup" &&
                        everythingFilledOut == false
                    )
                ) {
                    console.log('Saving replaced values... (Case 4)');

                    // make sure that user is not deleting all heating zones
                    // otherwise, room by room would just display a whitescreen
                    if (typeof replacedValues?.heatingZones !== 'undefined') {
                        if (typeof replacedValues.heatingZones?.heatingZones === 'undefined') {
                            setDisplayHeatingZongMsg(true);
                            return;
                        }
                    }

                    await mergeDataWithDocument(
                        route.params.valueStoragePath,
                        route.params.valueStorageDocument,
                        replacedValues
                    );

                    console.log('replacedValues')
                    console.log(JSON.stringify(replacedValues))

                    AppEventEmitter.emit(AppEvent.CheckDependencies);
                }
            }
        }
    }

    const isDesktop = useContext(DesktopContext);

    const { colors } = useTheme();
    const setHeaderOptions = useContext(HeaderDispatch); //todo: think about replacing this: leads to rerender of some parent components
    
    useFocusEffect(() => {
        navigation &&
        setHeaderOptions({
            title: "Questions",
            left: <Appbar.BackAction onPress={() => navigation.goBack()} />,
        });
    });

    const organisation = useContext(OrganisationContext);

    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const disableSaveBtn = route.params?.categoryOfAnswers === "organisationSignup"
        && route.params?.displayedQuestionCollection === "organisationBusiness"
        && isSaveDisabled;

    const rooms = useContext(RoomsContext);
    const thisProject = useContext(ProjectDetailContext);
    const recalculatingResults = useContext(RecalculatingResultsContext);
    const [items, _setItems] = useState([] as itemType[]);
    const itemsRef = useRef(items);
    const setItems = (data: itemType[]) => {
        itemsRef.current = data;
        _setItems(data);
    };
    const [initializing, setInitializing] = useState(true);
    const [questionsFilledOut, setQuestionsFilledOut] = useState(true);
    const user = useContext(InstallerContext);
    const [generatingReport, setGeneratingReport] = useState<boolean>(false);

    // if user has already uploaded a logo, enable the save button immediately upon rendering the questions
    useEffect(() => {
        const question = items[0]?.content as question_detail;

        if (question?.variableName === "businessSettings.logo") {
            if (question?.answer === "Picked") setIsSaveDisabled(false);
        }
    }, [items[0]?.content]);

    async function syncEverything() {
        console.log("thisProject", thisProject);
        await mergeDataWithDocument("/projects", thisProject.id, thisProject);
        const tmpRooms = await radiators(rooms);
        await Promise.all(
        tmpRooms.map(
            async (room) =>
            await mergeDataWithDocument(
                "/projects/" + thisProject.id + "/rooms",
                room.id,
                room
            )
        )
        );
    }

    function questionSetAnswer(
        newAnswer: string,
        prevAnswer: string,
        index: number,
        conditionalFunction?: conditionalFunction,
        productFilters?: string[][]
    ) {
        const tmpItems = itemsRef.current;
        (tmpItems[index].content as question_detail).answer = newAnswer;
        checkIfInputRuleViolated(
            newAnswer,
            (tmpItems[index].content as question_detail).input_rule,
            (tmpItems[index].content as question_detail).values
                ? (tmpItems[index].content as question_detail).values
                : (tmpItems[index].content as question_detail).options
        ).then((inputRuleError: boolean) => {

            (tmpItems[index].content as question_detail).inputRuleError = inputRuleError;

            if (conditionalFunction) {
                conditionalFunction({
                    //todo: add where parameters for product list
                    items: tmpItems,
                    index: index,
                    answer: newAnswer,
                    prevAnswer: prevAnswer,
                    productFilters: productFilters,
                    setProductFilters: setProductFilters,
                    extraData: route.params.extraData
                }).then((items) => {
                    const fieldName = (tmpItems[index].content as question_detail).question_text.toUpperCase();

                    prepopulateFieldsFromMasterData(items, route.params, fieldName);
                    setItems(items); //NOTE: it goes here when conditional fields dependent on a field get displayed
                });
            } else {
                setItems(tmpItems.slice());
            }
        });
    }

    /*
    START Product section
    */
    const [productFilters, _setProductFilters] = useState([] as string[][]);
    const productFiltersRef = useRef(productFilters);
    const setProductFilters = (data: string[][]) => {
        productFiltersRef.current = data;
        _setProductFilters(data);
    };
    const [lastProduct, setLastProduct] = useState(
        {} as QueryDocumentSnapshot<DocumentData>
    );
    const [loadMoreProducts, setLoadMoreProducts] = useState(false);
    const [orderByCondition, setOrderByCondition] = useState([] as string[]);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);

    useEffect(() => {
        setOrderByCondition(["price", "asc"]);
    }, []);

    useEffect(() => {
        const unitIndex = findQuestionIndex(items, "ean", true);

        if (unitIndex > -1) {
        if (!productFilters[1] || productFilters[1][0] != "ean") {
            if (!(items[unitIndex].content as question_detail).options) {
            throw ReferenceError("Options has to be defined");
            }
            if (
            (items[unitIndex].content as question_detail).answer &&
            (items[unitIndex].content as question_detail).answer != ""
            ) {
            setProductFilters([
                productFilters[0] || [
                "type",
                "==",
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (items[unitIndex].content as question_detail).options![0],
                ],
                [
                "ean",
                "==",
                (items[unitIndex].content as question_detail).answer || "",
                ],
            ]);
            } else if (
            !productFilters[0] ||
            productFilters[0][2] !=
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (items[unitIndex].content as question_detail).options![0]
            ) {
            setProductFilters([
                [
                "type",
                "==",
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (items[unitIndex].content as question_detail).options![0],
                ],
            ]);
            }
        } else {
            if (
            !(items[unitIndex].content as question_detail).answer ||
            (items[unitIndex].content as question_detail).answer == ""
            ) {
            setProductFilters([
                [
                "type",
                "==",
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (items[unitIndex].content as question_detail).options![0],
                ],
            ]);
            }
        }
        } else {
        if (items.findIndex((item) => item.type == "product") > -1)
            setItems(items.filter((item) => item.type != "product"));
        }
    }, [items]);

    useEffect(() => {
        console.log("testssstststst");
        if (!initializing && route.params.nestedPath == "cylinder") {
            setInitializing(true);
        }
    }, [thisProject.heatPump && thisProject.heatPump.ean]);

    useEffect(() => {
        if (!initializing && route.params.nestedPath == "firstQuoteCylinder") {
            setInitializing(true);
        }
    }, [thisProject.firstQuoteHeatPump && thisProject.firstQuoteHeatPump.ean]);

    useEffect(() => {
        if (productFilters.length > 0) {
            getProducts(false);
        }
    }, [productFilters, orderByCondition]);

    useEffect(() => {
        if (productFilters.length > 0 && loadMoreProducts) {
            if (!['heatPumpData', 'radiatorData'].includes(productFiltersRef?.current[0][2])) {
                getProducts(true);
            } else {
                getProducts(false);
            }
        }
    }, [loadMoreProducts]);

    useEffect(() => {
        dispatch(setIsLoadingMoreProducts(loadingProducts));
    }, [loadingProducts]);

    function getProducts(loadMore: boolean) {
        console.log("tried", !loadMore && productFilters);

        if (!loadingProducts) {
            console.log("triedAndIn", !loadMore && productFilters);
            const currentProductFilters = productFiltersRef.current;

            if (isBaxi && route?.params?.displayedQuestionCollection === 'newRadiator') {
                currentProductFilters.push(['manufacturer', '==', 'Baxi']);
            }

            setLoadingProducts(true);
            (
                setUpCollectionListener(
                    "data",
                    true,
                    undefined,
                    currentProductFilters,
                    orderByCondition,
                    10,
                    loadMore && lastProduct.id ? lastProduct : undefined
                ) as Promise<QuerySnapshot<DocumentData>>
            )
                .then((query) => {
                    const newProducts = [] as itemType[];

                    if (query && query.docs.length > 0) {
                        query.forEach((productContent) => {
                            const product = {} as product;
                            product.ean = productContent.data().ean;
                            product.price = productContent.data().price;
                            product.type = productContent.data().type;
                            const data = productContent.data();
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            product.content = data as any;
                            newProducts.push({
                                key: uuid.v4().toString(),
                                content: { ...product },
                                type: "product",
                            });
                        });

                        setLastProduct(
                        (query.docs.at(-1) as QueryDocumentSnapshot<DocumentData>) ||
                            ({} as QueryDocumentSnapshot<DocumentData>)
                        );
                    } else if (!loadMore) {
                        // console.log("i dont understand load again");
                        // setInitializing(true);
                    }

                    let tmpItems = itemsRef.current;
                    if (!loadMore) {
                        const listOfProductQuestionIndex = items.findIndex(
                        (item) =>
                            item.type == "question_detail" &&
                            (item.content as question_detail).input_rule == "listOfProducts"
                        );
                        tmpItems = tmpItems.slice(undefined, listOfProductQuestionIndex + 1);
                    }

                    tmpItems.push(...newProducts);
                    if (productFiltersRef.current == currentProductFilters) {
                        setItems(tmpItems);
                        setLoadMoreProducts(false);
                        setLoadingProducts(false);
                    } else {
                        setLoadMoreProducts(false);
                        setLoadingProducts(false);
                        getProducts(loadMore);
                    }
                })
                .catch((e) => {
                    throw Error(e);
                });
        }
    }
    /*
    END Product section
    */

    /**
     * Description: creates a renderQuestion/renderSection to display in the flatlist depending on item type
     * @returns renderQuestion | renderSection
     */
    const renderItem: ListRenderItem<itemType> = ({ item, index }) => {
        if (item.type == 'question_detail') {
            // if (item.content.question_text === 'U value') {
            //     alert(JSON.stringify(item.content))
            // }
            return (
                <PageQuestion
                    question={item.content as question_detail}
                    index={index}
                    valueStorageDocument={route.params.valueStorageDocument as string}
                    productFiltersRef={productFiltersRef}
                    items={items}
                    setIsSaveDisabled={setIsSaveDisabled}
                    questionSetAnswer={questionSetAnswer}
                    conditionalFunction={item.conditionalFunction} />
            );
        } else if (item.type == 'section') {
            return (
                <PageSection section={item.content as section}
                    index={index}
                    itemsRef={itemsRef}
                    routeParams={route.params}
                    setItems={setItems}
                    conditionalFunction={item.conditionalFunction} />
            );
        } else {
            return (
                <Product
                    product={item.content as product}
                    loadingProducts={loadingProducts}
                    items={items}
                    productFilters={productFilters}
                    routeParams={route.params}
                    thisProject={thisProject}
                    questionSetAnswer={questionSetAnswer} />
            );
        }
    };

    function getQuestions(answers: DocumentData) {
        getGlobal(route.params.displayedQuestionCollection).then(
            //<---- make sure displayedQuestionCollection matches loadAll
            async (initialItemsContent: question_detail[]) => { //NOTE: It's called initialItems because some conditional fields are to be displayed later on when selecting a value in a particular field
                if (initialItemsContent && initialItemsContent.length > 0) {
                    let items = prepareItems(
                        initialItemsContent,
                        route.params.nestedPath ? route.params.nestedPath + "." : "",
                        answers
                    );

                    for (let index = items.length - 1; index >= 0; index--) {
                        const initialItem = items[index];

                        prepopulateRoomData(initialItem);

                        if (initialItem.conditionalFunction) {
                            items = await initialItem.conditionalFunction({
                                items: items,
                                index: index,
                                answer: (initialItem.content as question_detail).answer,
                                productFilters: productFilters,
                                setProductFilters: setProductFilters,
                                extraData: route.params.extraData,
                            });
                        }
                    }

                    setItems(
                        items.filter(
                            (item) => {
                                return item.type != "question_detail" ||
                                (item.content as question_detail).input_rule != "hidden"
                            }
                        )
                    );
                }
                if (initializing == true) setInitializing(false);
            }
        );
    }

  useEffect(() => {
    console.log(
      "reloard",
      initializing && thisProject && thisProject.firstQuoteHeatPump
    );
    if (
      !route.params ||
      isEmpty(route.params) ||
      !route.params.displayedQuestionCollection ||
      !route.params.valueStoragePath ||
      !route.params.valueStorageDocument
    )
      throw ReferenceError("Parameters not set");
    if (initializing == true) {
      if (
        route.params.categoryOfAnswers == "project" ||
        route.params.categoryOfAnswers == "report"
      ) {
        getQuestions(thisProject);
      } else if (route.params.categoryOfAnswers == "room") {
        getQuestions(
          rooms.filter(
            (room) => room.id == route.params.valueStorageDocument
          )[0]
        );
        //when not able to read organisations collection (on first time org creation)
      } else if (
        //confused by this - JH
        route.params.categoryOfAnswers === "organisationSignup" &&
        route.params.extraData
      ) {
        getQuestions({} as DocumentData);
      } else {
        getDocument<DocumentData>(
          route.params.valueStoragePath,
          route.params.valueStorageDocument
        ).then((answers: DocumentData) => {
          getQuestions(answers);
        });
      }
    }
  }, [initializing]);

    function saveChangesOnDestruct() {
        saveChanges();
    }

    useEffect(() => {
        if (
            route.params.categoryOfAnswers !== "installerSignup" &&
            route.params.categoryOfAnswers !== "organisationSignup"
        ) { 
            return saveChangesOnDestruct;
        }
    }, []);

  /**
   * Description: determine if save button should display helper text (and disable saveChanges)
   */
  const showSaveHelperText =
    items.some(
      (item) =>
        item.type === "question_detail" &&
        (item.content as question_detail).inputRuleError
    ) ||
    (items.some((item) => item.type === "section") &&
      !items.some((item) => item.type === "question_detail"));


    const [notEverythingFilledOutDetails, setNotEverythingFilledOutDetails] = useState("Information missing. Also, check previous forms, if everything is filled out.");
    const [otherSectionsNotFilledOut, setOtherSectionsNotFilledOut] = useState<Array<sectionMissingInfo>>([]);
    const [organisationInfo, setOrganisationInfo] = useState({ extra: {} as orgExtra, billing: {} as orgBilling<"standard"> });
    const [isToastVisible, setIsToastVisible] = useState(false);
    const steps = projectSteps();

    // useEffect

    /**
     * Description: check if the signup form/account creation has been fully filled
     */
    async function checkEverythingFilledOut() {
        //let orgInfo = useContext(OrganisationContext)
        if (
            route.params.categoryOfAnswers === "installerSignup" ||
            route.params.categoryOfAnswers === "organisationSignup" ||
            route.params.categoryOfAnswers === "report"
        ) {
            const questionsFilledOut = !items.some(
                (item) =>
                (item.content as question_detail).answer === "" ||
                (item.content as question_detail).answer === undefined
            );

            let restFilledOut = true;

            if (route.params.categoryOfAnswers == "report") {
                const extraInfo = await getDocument<orgExtra>(`organisations/${user.orgId}/extra`, "extraInfo");
                const billingInfo = await getDocument<orgBilling<"standard">>(`organisations/${user.orgId}/extra`, "billingInfo");

                const tmp = {
                    ...organisation,
                    extra: extraInfo,
                    billing: billingInfo,
                };

                restFilledOut = restFilledOut &&
                    tmp.extra?.businessSettings?.everythingFilledOut == true &&
                    tmp.extra.contractPreferences?.everythingFilledOut == true &&
                    tmp.extra.pricing?.everythingFilledOut == true &&
                    tmp.billing?.everythingFilledOut == true;

                if (route.params.extraData && route.params.extraData[1] == "reports") {
                    restFilledOut =
                        restFilledOut &&
                        thisProject.heatPump?.everythingFilledOut == true &&
                        thisProject.cylinder?.everythingFilledOut == true;
                } else {
                    restFilledOut =
                        restFilledOut &&
                        thisProject.firstQuoteHeatPump?.everythingFilledOut == true &&
                        thisProject.firstQuoteCylinder?.everythingFilledOut == true;
                }

                setOrganisationInfo({ ...tmp });
            }

            setQuestionsFilledOut(questionsFilledOut && restFilledOut);
            return questionsFilledOut && restFilledOut;
        } else return true;
    }

    useEffect(() => {
        if (route.params?.categoryOfAnswers === 'project') {
            setOtherSectionsNotFilledOut([]);
            checkIfDependencyHasMissingInfo();
        }
    }, [route.params.nestedPath]);

    useFocusEffect(useCallback(() => {
        if (route.params && route.params.categoryOfAnswers == 'report') {
            setOtherSectionsNotFilledOut([]);
            checkIfDependencyHasMissingInfo();
        }
    }, []));

    const checkIfDependencyHasMissingInfo = async () => {
        const sectionsWithMissingInfo = await getSectionsWithMissingInfo(organisation, user, route.params, thisProject, steps, rooms);

        setOtherSectionsNotFilledOut((prevValues: any) => [ ...prevValues, ...sectionsWithMissingInfo ]);
    };


    const handleProceed = (sectionIndex: number) => {

        if (route.params.categoryOfAnswers === 'report' && otherSectionsNotFilledOut.length > 0) {
            navigation.navigate('Organisation', { sectionIndex });
        } else {
            goToProjectStepAndFillOut(sectionIndex);
        }
    };

    const goToProjectStepAndFillOut = (index: number) => {
        AppEventEmitter.emit(AppEvent.NavigateToProjectStep, steps[index], index);
    };

    // error messages to show under save changes button:
    const errorMessage = "Invalid input.";
    const notEverythingFilledOutMessage =
        route.params.categoryOfAnswers != "report"
        ? "Empty Fields."
        : 'Information Missing'; //"Information missing. Also, check other forms, if everything is filled out." 

    if (!initializing) {
        return (
            <ScrollView style={{ flex: 1 }}>
                {route.params?.nestedPath === 'master-data' &&
                    <MasterConstructionData routeParams={route.params} />}

                {route.params?.nestedPath === 'designSummary' && <DesignSummary />}

                <HeatingZoneDeleteDialog
                    visible={displayHeatingZoneMsg}
                    setVisible={setDisplayHeatingZongMsg} />

            <FlatList
                testID="question-list"
                contentContainerStyle={
                    route.params.categoryOfAnswers === "installerSignup" ||
                    route.params.categoryOfAnswers === "organisationSignup"
                        ? isDesktop
                        ? desktopStyle(colors).signupFlatlist
                        : mobileStyle().questionListFlatlist
                        : isDesktop
                        ? desktopStyle(colors).questionListFlatlist
                        : mobileStyle().questionListFlatlist
                }
                data={items}
                renderItem={renderItem}
                keyExtractor={(item) => item.key}
                initialNumToRender={20}
                onEndReached={() => setLoadMoreProducts(true)}
                ListFooterComponent={
                items.findIndex((item) => item.type == "question_detail") > -1 ||
                route.params.categoryOfAnswers == "report" ? (
                    <View
                        style={{
                            width: Dimensions.get("window").width - 2 * menuWidth,
                            flexDirection: "column",
                            alignItems: "flex-start",
                            margin: 20
                        }}
                    >
                        {/* <Snackbar
                            visible={isToastVisible}
                            onDismiss={() => setIsToastVisible(false)}
                            wrapperStyle={{
                                bottom: 0
                            }}
                            style={{
                                backgroundColor: 'green',
                                bottom: 0,
                                position: 'absolute'
                            }}
                            duration={2000}>
                            Saved
                        </Snackbar> */}

                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                testID="save-button"
                                mode="contained"
                                disabled={disableSaveBtn}
                                onPress={() => {
                                    saveChanges().then(() => {
                                        setIsToastVisible(true);
                                        checkEverythingFilledOut().then((filledOut) => {

                                            if (filledOut) {
                                                if (route.params.categoryOfAnswers == "report") {
                                                    setGeneratingReport(true);

                                                    syncEverything().then(() => {
                                                        console.log("thisProject", thisProject);

                                                        if (!user.orgId || !route.params.extraData) throw ReferenceError("Missing required information");

                                                        if (route.params.extraData[0] != "download-all") {
                                                            createReport(
                                                                thisProject.id,
                                                                thisProject?.general,
                                                                setGeneratingReport,
                                                                route.params.extraData[0] as reportType,
                                                                user.orgId,
                                                                user.id + "/logo.png",
                                                                route.params.extraData[1] == "first_quote"
                                                            );
                                                        } else {
                                                            reports(thisProject.general?.contractBeforeSurvey || false, thisProject.general?.inOfficeContract || false)
                                                                .forEach(async (report) => {
                                                                    if (report.extraData[0] != "download-all") {
                                                                        createReport(
                                                                            thisProject.id,
                                                                            thisProject?.general,
                                                                            setGeneratingReport,
                                                                            report.extraData[0] as reportType,
                                                                            user.orgId || "",
                                                                            user.id + "/logo.png",
                                                                            false
                                                                        );
                                                                    }
                                                                });

                                                            firstQuotes(thisProject.general?.contractBeforeSurvey || false, thisProject.general?.inOfficeContract || false)
                                                                .forEach(async (firstQuote) => {
                                                                    if (firstQuote.type == "report") {
                                                                        createReport(
                                                                            thisProject.id,
                                                                            thisProject?.general,
                                                                            setGeneratingReport,
                                                                            firstQuote.extraData[0] as reportType,
                                                                            user.orgId || "",
                                                                            user.id + "/logo.png",
                                                                            true
                                                                        );
                                                                    }
                                                                });
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                    });
                                }}
                                style={{ width: 200, margin: 5 }}
                            >
                                {route.params.categoryOfAnswers == "organisationSignup" &&
                                route.params.extraData
                                    ? "Create"
                                    : route.params.categoryOfAnswers == "report"
                                    ? "Generate Report"
                                    : "Save"}
                            </Button>
                            {(route?.params?.nestedPath === 'heatingZones' || route?.params?.categoryOfAnswers === 'room')  &&
                                <TouchableOpacity
                                    style={styles.floorPlanBtn}
                                    onPress={() => AppEventEmitter.emit(AppEvent.DisplayFloorPlanDialog)}>

                                    <Typography size={12} color='white'>View floor plan</Typography>
                                </TouchableOpacity>
                            }
                            {/* <Text>{JSON.stringify(route.params)}</Text> */}
                            <ActivityIndicator
                                animating={generatingReport}
                                color={colors.primary} />
                        </View>
                        <HelperText
                            style={{ fontSize: 14 }}
                            type="error"
                            visible={showSaveHelperText || !questionsFilledOut}
                        >
                            {!questionsFilledOut
                            ? notEverythingFilledOutMessage
                            : errorMessage}
                        </HelperText>

                        <View style={{ width: '100%' }}>
                            {otherSectionsNotFilledOut.map((section, index) => (
                                <MissingInfoRedirectLinks key={`section-${index}`}
                                    index={index}
                                    section={section}
                                    onRedirect={() => handleProceed(section.sectionIndex)}
                                />
                            ))}
                        </View>

                        <PresetNote routeParams={route.params} handlePress={() => goToProjectStepAndFillOut(2)} />
                    </View>
                ) : null
                }
            />
            </ScrollView>
        );
    } else {
        return null;
    }
};
