import uuid from 'react-native-uuid';

export const addRadiatorToHeatingZones = (firstHeatingZoneIsEmpty: boolean, itemsRef: any) => {
    const key = itemsRef.current[0].key;
    const heatingZonesLen = (itemsRef.current.length - 2) / 2;

    if (firstHeatingZoneIsEmpty) {
        itemsRef.current.splice(itemsRef.current.length - 1, 1, {
            ...itemsRef.current[itemsRef.current.length - 1],
            content: {
                ...itemsRef.current[itemsRef.current.length - 1].content,
                answer: 'Radiator'
            }
        });

        return;
    }

    itemsRef.current.push({
        key: uuid.v4().toString(),
        content: {
            text: `Heating zone ${heatingZonesLen + 1}`,
            typography: 'subheading',
            answer: 'delete'
        },
        type: 'section'
    });

    itemsRef.current.push({
        content: {
            hint: '',
            step: 0,
            version: '0',
            affix: '',
            options: [
                'Radiator',
                'UFH',
                'UFH/Radiator'
            ],
            input_rule: 'limitedOptions',
            conditionalFunctionType: '',
            type: 'heatingZones',
            question_text: 'Emitter Type',
            variableName: `heatingZones.heatingZones[${heatingZonesLen + 1}].emitterType`,
            market: 'UK',
            values: [
                'Radiator',
                'UFH',
                'UFH/Radiator'
            ],
            answer: 'Radiator'
        },
        type: 'question_detail',
        key: uuid.v4().toString()
    } as any);
}