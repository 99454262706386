import { useFocusEffect } from "@react-navigation/native";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import React, { ComponentType, useContext, useEffect } from "react";
import { Image, View } from "react-native";
import { Card, Headline, IconButton, Text } from "react-native-paper";
import { Stack } from "../../components/Stack";
import basicStyles from "../../styles/basic";
import { HeaderDispatch } from "../../util/contexts";
import { logout } from "../../util/firebaseConnection";
import { InstallerContext } from "../../util/contexts";
import { setCompany, setIsBaxi } from "../../redux/modules/whiteLabel";
export const HomeStack = () => {
  const screens = [
    {
      name: "Installer Tool",
      component: Home as ComponentType,
      options: { title: "Installer Tool" },
    },
  ];
  return <Stack screens={screens} initialRoute="Installer Tool" />;
};

export const Home = () => {
  const dispatch = useAppDispatch();
  const setHeaderOptions = useContext(HeaderDispatch);

  const installer = useContext(InstallerContext);
  const currentUsername = installer.installerName;
  const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);

  useFocusEffect(() => {
    setHeaderOptions({ title: "Carno Installer Tool" });
  });

  useEffect(() => {
    dispatch(setCompany(installer.company || ''));
    dispatch(setIsBaxi(installer?.company === 'baxi'));
  }, []);

  return (
    <View style={[basicStyles.container, { margin: 5 }]}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Headline testID="welcome-message">
          {currentUsername ? `Welcome ${currentUsername}!` : "Welcome!"}
        </Headline>
        <IconButton icon="power" onPress={logout} />
      </View>
      <Text>What would you like to do today?</Text>
      {isBaxi && <Text style={{ fontWeight: 'bold' }}>Hey, you're a BAXI user!</Text>}
      <Card style={{ maxWidth: 300, margin: 10, backgroundColor: "white" }}>
        <Card.Title title="Dashboard" />
        <Image
          testID="dashboard"
          style={basicStyles.logo}
          source={require("../../../assets/dashboard_mock.png")}
        />
      </Card>
      <Text style={{ fontSize: 10 }}>Version 4.4.0</Text>
    </View>
  );
};
