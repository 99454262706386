import { StyleSheet } from 'react-native';
import { colors } from '../../../styles/colors';
import { floorPlan } from '../../../styles/constants';

const styles = StyleSheet.create({
    container: {
        width: floorPlan.imgBoxWidth,
        height: floorPlan.imgBoxHeight,
        backgroundColor: colors.grey,
        justifyContent: 'center',
        alignItems: 'center',
    },
    uploadingText: {
        marginTop: 15,
        marginBottom: 10
    },
    removeBtn: {
        marginTop: 5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: { marginRight: 5 }
});

export default styles;