import { store } from '../../redux/store';
import { getDocument } from '../firebaseConnection';
import getTargetElement from './getTargetElement';
import { setIsMasterDataFetched, setFailedToGetMasterData, setGeneral } from '../../redux/modules/masterData';
import type { elementType, subElementType, Obj } from '../types';

const getMasterData = async (
    routeParams: any,
) => {

    const { elementTypes, isMasterDataFetched } = store.getState().masterData;

    if (isMasterDataFetched) return;

    getDocument(
        routeParams.valueStoragePath,
        routeParams.valueStorageDocument + '/roomsMasterData/general'
    ).then((generalData) => {
        store.dispatch(setGeneral(generalData as Obj<string>));
    }).catch((err) => {
        store.dispatch(setFailedToGetMasterData(true));
        console.error(`Fetch Room Data from Master Data: ${err}`);
    });

    for (const elementType of elementTypes) {
        getDocument(
            routeParams.valueStoragePath,
            routeParams.valueStorageDocument + '/roomsMasterData/' + elementType
        ).then((response: any) => {
            if (!response.accessRights) {
                store.dispatch(setFailedToGetMasterData(true));
            } else {
                setElementObject(elementType, response);
            }

            if (elementType === 'door') {
                store.dispatch(setIsMasterDataFetched(true));
            }
        }).catch((err) => {
            store.dispatch(setFailedToGetMasterData(true));
            console.error(`Fetch Master Data: ${err}`);
        });

    }
};

const setElementObject = (
    element: elementType | subElementType,
    response: any
) => {
    const { elementObject, setElementObject } = getTargetElement(element);

    Object.entries(elementObject).forEach(([key]) => {
        store.dispatch(setElementObject({
            subType: key as subElementType,
            fabricType: response[key].fabricType,
            uValue: response[key].uValue,
            adjacentSpace: response[key]?.adjacentSpace
        }));
    });
};

export default getMasterData;