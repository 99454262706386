import { element, project_detail, room_detail } from "../types";

//Todo: Cloud function triggered when a room is changed
export const calcRoomLoss = (
    room: room_detail<"standard">,
    rooms: room_detail<"standard">[],
    project: project_detail<"standard">
) => {
    let roomHeatLoss = 0;
    let roomEnergyUsagePerYear = 0;
    let somethingNotFilledOut = false;

    if (project.locationParameters) {
        for (const element of room.elements) {

            //todo: add comparison to before element to avoid unneccessary computation
            const adjacentRoom =
                element && element.adjacentSpace
                ? rooms.filter((room) => room.id == element.adjacentSpace)
                : undefined;

            const adjacentRoomTemp =
                adjacentRoom && adjacentRoom.length == 1
                ? adjacentRoom[0].general && adjacentRoom[0].general.designTemperature
                : undefined;

            if (
                element.everythingFilledOut &&
                room.general &&
                room.general.everythingFilledOut &&
                project.general &&
                project.general.everythingFilledOut &&
                (element.adjacentSpace == "Outside" ||
                element.adjacentSpace == "Unheated" ||
                element.adjacentSpace == "Heated" ||
                element.adjacentSpace == "Ground" ||
                adjacentRoomTemp) //if there is an adjacent room linked, the temperature is expected
            ) {
                const tmp = calcElementLosses(element, room, project, adjacentRoomTemp);

                element.heatLoss = tmp.heatLoss;
                element.energyUsagePerYear = tmp.energyUsagePerYear;
                element.adjacentSpaceTemp = tmp.adjacentSpaceTemp;

                if (adjacentRoomTemp) element.adjacentSpaceName = adjacentRoom[0].general?.customName;
                else element.adjacentSpaceName = element.adjacentSpace;

                console.log('ELEMENT HEAT LOSS')
                console.log(element.heatLoss)

                roomHeatLoss += element.heatLoss;
                roomEnergyUsagePerYear += element.energyUsagePerYear;
            } else {
                if (
                element.adjacentSpace != "Outside" &&
                element.adjacentSpace != "Unheated" &&
                element.adjacentSpace != "Heated" &&
                element.adjacentSpace != "Ground" &&
                (!adjacentRoom || adjacentRoom?.length == 0)) {
                    element.everythingFilledOut = false;
                }

                element.heatLoss = null;
                element.energyUsagePerYear = null;
                somethingNotFilledOut = true;
            }
        }

        if (
            project.general &&
            project.general.everythingFilledOut &&
            room.general &&
            room.general.everythingFilledOut &&
            !somethingNotFilledOut
        ) {

            const tmp = calcAdditionalRoomLosses(
                room,
                project,
                roomHeatLoss,
                roomEnergyUsagePerYear
            );

            console.log('sum of ventilationHeatLoss, exposedHeatLoss, intermittentHeatLoss, highCeilingHeatLOss')
            console.log(tmp.heatLoss)

            roomHeatLoss += tmp.heatLoss;
            roomEnergyUsagePerYear += tmp.energyUsagePerYear;

            return {
                ...room,
                heatLoss: roomHeatLoss,
                energyUsagePerYear: roomEnergyUsagePerYear,
            };
        } else {
            return { ...room, heatLoss: null, energyUsagePerYear: null };
        }
    }

    return room;
};
//Helper Functions
const calcElementLosses = (
    element: element<"standard">,
    room: room_detail<"standard">,
    project: project_detail<"standard">,
    adjacentRoomTemp?: number
) => {
    if (
        !project.locationParameters ||
        !project.locationParameters.CIBSE ||
        !project.locationParameters.meanAirTemp ||
        !project.locationParameters.degreeDays
    ) {
        throw ReferenceError("Location parameters not found");
    }

    let adjacentSpaceTemp = 0 as number | undefined;

    element.adjacentSpace == "Outside"
        ? (adjacentSpaceTemp = parseFloat(project.locationParameters.CIBSE))
        : element.adjacentSpace == "Unheated"
        ? (adjacentSpaceTemp = project.constants.unheatedTemp)
        : element.adjacentSpace == "Ground"
        ? (adjacentSpaceTemp = parseFloat(project.locationParameters.meanAirTemp))
        : element.adjacentSpace == "Heated"
        ? (adjacentSpaceTemp = project.constants.heatedTemp)
        : (adjacentSpaceTemp = adjacentRoomTemp); //adjacentRoomTemp?():throw ReferenceError("AdjacentRoomTemp not set");

    if (!adjacentSpaceTemp) {
        throw ReferenceError(
        "Temperature of adjacent space could not be determined"
        );
    }

    if (!room.general || !room.general.designTemperature) {
        throw ReferenceError("Information missing about room.");
    }

    const thermalBridgingPercentage =
        room.general.constructionDate == "2006-Present" &&
        element.thermalBridgesInsulated != undefined &&
        element.thermalBridgesInsulated == false
        ? 0.08
        : 0;
    const conductance = calcElementConductance(element);
    const energyUsagePerYear =
        element.wallType == "Internal Wall" ||
        element.ceilingType == "Intermediate Ceiling" ||
        element.floorType == "Intermediate Floor"
        ? 0
        : (conductance * parseFloat(project.locationParameters.degreeDays) * 24) /
            1000;

    return {
        heatLoss:
        conductance *
        (room.general.designTemperature - adjacentSpaceTemp) *
        (1 + thermalBridgingPercentage),
        energyUsagePerYear: energyUsagePerYear * (1 + thermalBridgingPercentage),
        adjacentSpaceTemp: adjacentSpaceTemp
    };
};

const calcElementConductance = (element: element<"standard">) => {
    if (!element.dim1 || !element.dim2 || !element.Uvalue) {
        throw ReferenceError("Information missing about element.");
    }

    let elementArea = element.dim1 * element.dim2;
    let conductance = 0;

    ["windows", "doors", "roofLights"].forEach((subelements: string) => {
        element[subelements as "windows" | "doors" | "roofLights"] &&
        element[subelements as "windows" | "doors" | "roofLights"]?.forEach(
            (subelement) => {
            if (!subelement.dim1 || !subelement.dim2 || !subelement.Uvalue) {
                throw ReferenceError("Information missing about element.");
            }
            elementArea -= subelement.dim1 * subelement.dim2;
            conductance += subelement.dim1 * subelement.dim2 * subelement.Uvalue;
            }
        );
    });

    conductance += elementArea * element.Uvalue;

    return conductance;
};

const calcAdditionalRoomLosses = (
    room: room_detail<"standard">,
    project: project_detail<"standard">,
    elementHeatLosses: number,
    elementEnergyUsagePerYear: number
) => {
    if (
        !project.locationParameters ||
        !project.locationParameters.CIBSE ||
        !project.locationParameters.meanAirTemp ||
        !project.locationParameters.degreeDays
    ) {
        throw ReferenceError("Location parameters not found");
    }

    if (project.general && room.general) {
        if (
            !room.general.roomHeight ||
            !room.general.roomLength ||
            !room.general.roomWidth ||
            !room.general.airChanges ||
            !room.general.designTemperature
        ) {
            throw ReferenceError("Information missing about room.");
        }

        //Ventilation
        const volume =
            room.general.roomHeight *
            room.general.roomLength *
            room.general.roomWidth;

        const ventilationHeatLoss =
            parseFloat(room.general.airChanges) *
            volume *
            project.constants.airChangeFactor *
            (room.general.designTemperature -
                parseFloat(project.locationParameters.CIBSE));

        const ventilationEnergyUsagePerYear =
            (parseFloat(room.general.airChanges) *
                volume *
                project.constants.airChangeFactor *
                parseFloat(project.locationParameters.degreeDays) *
                24) / 1000;

        //Additional heat losses
        const intermittentPercentage = project.general.intermittentHeating ? 0.2 : 0;
        /*const intermittentPercentage = project.general.intermittentHeating
        ? room.general.constructionDate == "2006-Present"
            ? 0.1
            : 0.2
        : 0;*/
        const intermittentHeatLoss = intermittentPercentage * elementHeatLosses;
        const intermittentEnergyUsagePerYear = intermittentPercentage * elementEnergyUsagePerYear;

        const exposedLocationHeatLoss = project.general.exposedLocation
            ? project.constants.exposedLocationFactor * elementHeatLosses
            : 0;
        
        const exposedLocationEnergyUsagePerYear = project.general.exposedLocation
            ? project.constants.exposedLocationFactor * elementEnergyUsagePerYear
            : 0;

        const ceilingLimit = project.constants.highCeilingLimits.findIndex((highCeilingLimit) =>
            room.general &&
            room.general.roomHeight &&
            room.general.roomHeight >= highCeilingLimit
        );

        const highCeilingPercentage = ceilingLimit > -1 ? project.constants.highCeilingFactor[ceilingLimit] : 0;
        const highCeilingHeatLoss = highCeilingPercentage * elementHeatLosses;
        const highCeilingEnergyUsagePerYear = highCeilingPercentage * elementEnergyUsagePerYear;

        const additionalHeatLoss =
            ventilationHeatLoss +
            intermittentHeatLoss +
            exposedLocationHeatLoss +
            highCeilingHeatLoss;

        const additionalEnergyUsagePerYear =
            ventilationEnergyUsagePerYear +
            intermittentEnergyUsagePerYear +
            exposedLocationEnergyUsagePerYear +
            highCeilingEnergyUsagePerYear;

        return {
            heatLoss: additionalHeatLoss,
            energyUsagePerYear: additionalEnergyUsagePerYear,
        };
    } else {
        return {
            heatLoss: 0,
            energyUsagePerYear: 0,
        };
    }
};
