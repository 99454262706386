import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { Button } from 'react-native-paper';
import { colors } from '../../../styles/colors';
import Icon from '@expo/vector-icons/AntDesign';
import Typography from '../Typography/Typography';
import styles from './floorPlanUploadSkeletonStyles';

export interface FloorPlanUploadSkeletonProps {
    fileName: string;
    style?: Object;
    onCancelImgUpload: () => void;
}

const FloorPlanUploadSkeleton: React.FC<FloorPlanUploadSkeletonProps> = ({
    fileName = '',
    style,
    onCancelImgUpload
}) => {
    return (
        <View style={[style]}>
            <View style={[styles.container]}>
                <ActivityIndicator size='large' color={colors.neutral500} />
                <Typography style={{ marginTop: 15, marginBottom: 10 }}>
                    Uploading....
                </Typography>
                <Typography color={colors.neutral300} size={12}noOfLines={1}>{fileName}</Typography>
            </View>

            <Button style={styles.removeBtn} uppercase={false} onPress={onCancelImgUpload}>
                <Icon
                    name={'close'}
                    size={12}
                    color={colors.supportErrorRed}
                    style={styles.icon} />
                <Typography size={12} color={colors.supportErrorRed}
                    style={{ letterSpacing: 0 }}>
                    Cancel
                </Typography>
            </Button>
        </View>
    );
};

export default FloorPlanUploadSkeleton;
