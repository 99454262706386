import { FirebaseAuthTypes } from "@react-native-firebase/auth";
import { User as sdkUser } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { getGlobal, setGlobal } from "../../util/asyncStorageConnection";
import { DesktopContext } from "../../util/contexts";
import {
  authListener,
  getDocument,
  loadAll,
} from "../../util/firebaseConnection";
import { question_detail } from "../../util/types";
import { LoginScreen } from "./LoginScreen";
import { ActiveUserCheck } from "./EntryListeners";
import { StackNavigationProp } from "@react-navigation/stack";
import { signUpNavigationProps } from "../../util/navigationProps";
import { RouteProp } from "@react-navigation/native";
import { loadQuestionsAndConstants } from "../../util/loadQuestionsAndConstants";

/**
 * Description: Load questions data for current session via firebase function loadAll then set as global parameters. Handle logic to determine which first component to render (login or navigators)
 * @returns LoginScreen || DesktopNavigator || MobileNavigator || Signup
 */
export const Entry = () => {
    const [initializing, setInitializing] = useState(true);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const windowWidth = useWindowDimensions().width;

    async function loadData() {
        const lastUpdatedDoc = await getDocument<{ lastUpdated: number }>(
            "constants",
            "lastUpdated"
        );

        const currentLastUpdated = (await getGlobal("lastUpdated")) as {
            lastUpdated: number;
        }[];

        if (
            !currentLastUpdated ||
            currentLastUpdated.length == 0 ||
            currentLastUpdated[0].lastUpdated != lastUpdatedDoc.lastUpdated
        ) {
            setGlobal("lastUpdated", [lastUpdatedDoc]);
            const version = await getDocument<{ version: string }>(
                "constants",
                "version"
            );

            setGlobal("version", [version]);
            //todo:  add promise.all and set initializing=true until all questions loaded -> otherwise could be errors!
            loadQuestionsAndConstants(version.version);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    /**
     * Description: log in user if authorised, otherwise stop initialisation and return login screen
     * @param authUser
     */
    function onAuthStateChanged(authUser: sdkUser | FirebaseAuthTypes.User | null) {
        //setUser(authUser&&{...authUser.providerData[0],uid:authUser.uid})
        if (authUser) setUserLoggedIn(true);
        else setUserLoggedIn(false);

        setInitializing(false);
    }

    useEffect(() => {
        const subscriber = authListener(onAuthStateChanged);

        return subscriber;
    }, []);

    if (!initializing) {
        return (
            <DesktopContext.Provider
                value={
                Platform.OS != "ios" && Platform.OS != "android" && windowWidth > 500
                }>

                {!userLoggedIn ? (
                    <LoginScreen
                        navigation={{} as StackNavigationProp<signUpNavigationProps, "parse">}
                        route={{} as RouteProp<signUpNavigationProps, "parse">} />
                ) : (
                    <ActiveUserCheck />
                )}
            </DesktopContext.Provider>
        );
    }

    return null;
};
