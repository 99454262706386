import AsyncStorage from "@react-native-async-storage/async-storage";
export const clear = () => {
  AsyncStorage.clear();
};
export const setGlobal = async (key: string | undefined, value: object[]) => {
  try {
    if (key) {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem(key, jsonValue);
    }
  } catch (e) {
    console.warn("Couldn't write in AsyncStorage");
  }
};
export const setMulti = async (keys: string[], values: object[] | string[]) => {
  try {
    const items = [] as string[][];
    values.map((value, index) => {
      const jsonValue = value === Object(value) ? JSON.stringify(value) : value;
      items.push([keys[index], jsonValue as string]);
    });
    await AsyncStorage.multiSet(items);
  } catch (e) {
    console.warn("Couldn't write in AsyncStorage");
  }
};
export const getGlobal = async (key: string | undefined) => {
  try {
    if (key) {
      const jsonValue = await AsyncStorage.getItem(key);

      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } else {
      return undefined;
    }
  } catch (e) {
    throw ReferenceError("Couldn't read from AsyncStorage");
  }
};
export const deleteGlobal = async (key: string | undefined) => {
  try {
    if (key) {
      await AsyncStorage.removeItem(key);
      return true;
    } else {
      return undefined;
    }
  } catch (e) {
    throw ReferenceError("Couldn't read from AsyncStorage");
  }
};
