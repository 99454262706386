import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SystemUpgradesState = {
    isLoadingMoreProducts: boolean;
};

const initialState: SystemUpgradesState = {
    isLoadingMoreProducts: false
};

export const systemUpgradesSlice = createSlice({
    name: 'systemUpgrades',
    initialState,
    reducers: {
        setIsLoadingMoreProducts: (state, action: PayloadAction<boolean>) => {
            state.isLoadingMoreProducts = action.payload;
        },
        clearSystemUpgrades: () => ({
            ...initialState,
        }),
    },
});

export const {
    setIsLoadingMoreProducts,
    clearSystemUpgrades
} = systemUpgradesSlice.actions;

export default systemUpgradesSlice.reducer;
