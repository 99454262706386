import React from 'react';
import { Text, TextProps } from 'react-native';
import { capitalize } from 'lodash';
import { colors } from '../../../styles/colors';

export interface Props extends TextProps {
    fontWeight?: 'Thin' | 'Regular' | 'Medium' | 'Bold' | 'Black' | 'Light' | 'Regular';
    size?: number;
    style?: Object;
    color?: string;
    noOfLines?: number;
    children: any;
}

const Typography: React.FC<Props> = ({
    fontWeight = 'regular',
    size = 16,
    style,
    color = colors.neutral500,
    noOfLines,
    children,
    ...extraProps
}) => {
    return (
        <Text
            {...extraProps}
            style={{
                fontSize: size,
                fontFamily: `Roboto${fontWeight ? capitalize(fontWeight) : 'Regular'}`,
                color: color,
                ...style,
            }}
            numberOfLines={noOfLines}
        >
            {children}
        </Text>
    );
};

export default Typography;
