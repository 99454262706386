import React, { useState } from 'react';
import Tooltip from 'react-native-walkthrough-tooltip';
import { View, Image, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import { colors } from '../../../styles/colors';
import Typography from '../Typography/Typography';
import styles from './floorPlanImageStyles';

export interface FloorPlanImageProps {
    imageUrl: string;
    fileName: string;
    onClickFileName: () => void;
    onDeleteImage: () => void;
}

const FloorPlanImage: React.FC<FloorPlanImageProps> = ({
    imageUrl,
    fileName,
    onClickFileName,
    onDeleteImage
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => setIsTooltipVisible(true)}>
                <Tooltip
                    isVisible={isTooltipVisible}
                    content={<Typography fontWeight='Medium'>{fileName}</Typography>}
                    placement='bottom'
                    onClose={() => setIsTooltipVisible(false)}
                    showChildInTooltip={false}
                    disableShadow={true}
                    contentStyle={{ flex: 1 }}
                >
                    <Image
                        style={styles.image}
                        source={{ uri: imageUrl }} />
                </Tooltip>
            </TouchableOpacity>

            <Button mode='outlined' style={styles.fileNameBtn} onPress={onClickFileName}>
                <Typography fontWeight='Medium' noOfLines={1}>{fileName}</Typography>
            </Button>

            <Button style={styles.removeBtn} uppercase={false} onPress={onDeleteImage}>
                <Typography size={12} color={colors.supportErrorRed}
                    style={{ letterSpacing: 0 }}>
                    Remove
                </Typography>
            </Button>
        </View>
    );
};

export default FloorPlanImage;
