import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { colors } from '../../../styles/colors';
import Icon from '@expo/vector-icons/AntDesign';
import Typography from '../Typography/Typography';
import styles from './uploadFloorPlanBtnStyles';

export interface UploadFloorPlanBtnProps {
    uploadImages: () => void;
    isUploading: boolean;
}

const UploadFloorPlanBtn: React.FC<UploadFloorPlanBtnProps> = ({
    uploadImages,
    isUploading
}) => {
    return (
        <TouchableOpacity style={[styles.container, { opacity: isUploading ? 0.5 : 1 }]}
            disabled={isUploading}
            onPress={uploadImages}>
            <View style={styles.iconWrapper}>
                <Icon name='pluscircleo'
                    size={27}
                    color={colors.neutral500}
                    style={styles.icon} />
            </View>
            <Typography>Upload floor plan</Typography>
        </TouchableOpacity>
    );
};

export default UploadFloorPlanBtn;
