import { StackScreenProps } from "@react-navigation/stack";
import {
  organisationDetailNavigationProps,
  questionListData,
} from "../../util/navigationProps";
import { InstallerOverview } from "./InstallerOverview";
import { ComponentType, useContext, useState } from "react";
import { HeaderDispatch } from "../../util/contexts";
import { useFocusEffect } from "@react-navigation/native";
import { Appbar } from "react-native-paper";
import createSideNavigator from "../../components/SideNavigator";
import { QuestionList } from "../../components/QuestionList";
import { OrganisationFlatlist } from "../../components/Flatlists";
import { Stack } from "../../components/Stack";

export const OrganisationScreenMobile = () => {
  const screens = [
    {
      name: "OrganisationScreen",
      component: OrganisationScreens as ComponentType,
    },
    {
      name: "InstallerOverview",
      component: InstallerOverview as ComponentType,
    },
    {
      name: "Questions",
      component: QuestionList as ComponentType,
    },
  ];
  return <Stack screens={screens} initialRoute="OrganisationScreen" />;
};

export const OrganisationScreens = ({
  //stack navigator like project detail mobile with OrganistionScreensList initial route
  navigation,
}: StackScreenProps<organisationDetailNavigationProps, "Organisation">) => {
  const setHeaderOptions = useContext(HeaderDispatch);
  useFocusEffect(() => {
    setHeaderOptions({
      title: "Organisation",
      left: <Appbar.BackAction onPress={() => navigation.goBack()} />,
    });
  });
  return (
    <OrganisationFlatlist
      navigate={(
        name: keyof organisationDetailNavigationProps,
        params: questionListData | undefined
      ) => navigation.navigate({ name: name, params: params })}
    />
  );
};

export const OrganisationScreenDesktop = () => {
    const Side = createSideNavigator();
    const [highlightedStep, setHighlightedStep] = useState(0);

    return (
        <Side.Navigator
            initialRouteName="InstallerOverview"
            screenOptions={{
                left: (
                    sideNavigate: (
                        name: keyof organisationDetailNavigationProps,
                        params?: questionListData
                    ) => void,
                    unmountSide
                ) =>
                    OrganisationFlatlist({
                        navigate: (
                            name: keyof organisationDetailNavigationProps,
                            params: questionListData | undefined
                        ) => sideNavigate(name, params),
                        highlightedStep: highlightedStep,
                        setHighlightedStep: (number) => {
                            setHighlightedStep(number);
                            if (number == -1) unmountSide();
                        },
                    }),
            }}>
            <Side.Screen name="InstallerOverview" component={InstallerOverview} />
            <Side.Screen name="Questions" component={QuestionList} />
        </Side.Navigator>
    );
};
