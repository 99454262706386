import { getDocument, mergeDataWithDocument } from '../firebaseConnection';

export const saveNewRoomTypes = async (newRoomTypes: string[]) => {
    const storagePath = 'saved_data';
    const storageDocument = 'magicplan-csv';

    // save new room types to firestore
    getDocument(storagePath, storageDocument).then((document: any) => {
        mergeDataWithDocument(storagePath, storageDocument, {
            newRoomTypes: [ ...new Set([...document.newRoomTypes, ...newRoomTypes]) ]
        });
    });
};
