import { Fragment, useContext, useEffect, useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { FlatList, ListRenderItem, View } from "react-native";
import {
  ActivityIndicator,
  Button,
  Divider,
  IconButton,
  List,
  Subheading,
  useTheme,
} from "react-native-paper";
import {
  DesktopContext,
  InstallerContext,
  OrganisationContext,
  ProjectDetailContext,
  RecalculatingResultsContext,
  RoomsContext,
} from "../util/contexts";
import {
  createDocument,
  deleteDocument,
  mergeDataWithDocument,
} from "../util/firebaseConnection";
import {
  organisationDetailNavigationProps,
  projectDetailNavigationProps,
  questionListData,
} from "../util/navigationProps";
import { projectSteps } from "../util/projectSteps";
import {
  firstQuote_flatlist,
  organisationScreen,
  project_detail,
  project_step,
  question_type,
  report,
  room_detail,
} from "../util/types";
import uuid from "react-native-uuid";
import {
  desktopIconColor,
  desktopStyle,
  desktopStylePressables,
} from "../styles/desktop";
import AddElementBtn from "./modules/RoomByRoom/AddElementBtn";
import { getElementIndex } from "../util/getElementIndex";
import { useRoute } from "@react-navigation/native";
import { Hoverable } from "react-native-web-hover";
import AppEventEmitter, { AppEvent } from '../util/appEvent';
import navigationStyles from "../styles/navigation";
import { Confirmation, Share } from "./Dialogs";
import { Magicplan } from "./modules/dialogs";
import { get as objGet } from "lodash";
import { reports } from "../util/reports";
import { firstQuotes } from "../util/firstQuotes";
import { isReportDependenciesFilledOut } from "../util/checkFilledOutFields"
import { getDocument } from "../util/firebaseConnection";

//import { optimizeRadiators } from "../util/localCloudFunctions/optimizeRadiators";
export const titleStyle = (
    isDesktop: boolean,
    pressed: boolean,
    hovered: boolean,
    everythingFilledOut: boolean | undefined | null,
    colors: ReactNativePaper.ThemeColors
) => {
    if (isDesktop && !pressed && everythingFilledOut != undefined) {
        return [
            desktopStylePressables({ pressed: pressed, hovered: hovered }, colors).itemTitle,
            { color: everythingFilledOut ? "green" : "red" },
        ];
    } else if (isDesktop) {
        return [
            desktopStylePressables({ pressed: pressed, hovered: hovered }, colors).itemTitle,
            { color: everythingFilledOut === undefined ? colors.text : everythingFilledOut ? "green" : "red" },
        ];
    } else if (everythingFilledOut == undefined) {
        return { color: colors.text };
    } else {
        return { color: everythingFilledOut ? "green" : "red" };
    }
};

export const iconColor = (
  isDesktop: boolean,
  pressed: boolean,
  hovered: boolean,
  everythingFilledOut: boolean | null | undefined,
  colors: ReactNativePaper.ThemeColors
) => {
  if (isDesktop && !pressed && everythingFilledOut != undefined)
    return everythingFilledOut ? "green" : "red";
  else if (isDesktop)
    return desktopIconColor({ pressed: pressed, hovered: hovered }, colors);
  else if (everythingFilledOut == undefined) return colors.text;
  else return everythingFilledOut ? "green" : "red";
};

function resultDescription(
  thisProject: project_detail<"standard">,
  item: project_step | firstQuote_flatlist,
  everythingFilledOut: boolean
) {
  let projectStepDescription = "";
  everythingFilledOut
    ? item.results &&
      item.results?.forEach((result) => {
        let value = objGet(
          thisProject,
          (item.nestedPath ? item.nestedPath + "." : "") + result.value
        );

        if (typeof value == "number") value = Math.round(value);
        if (typeof value == "boolean") value = value == true ? "Yes" : "No";
        projectStepDescription +=
          value != undefined && value != null
            ? result.text + value + result.affix + "\n"
            : "";
      })
    : objGet(
        thisProject,
        (item.nestedPath ? item.nestedPath + "." : "") + "everythingFilledOut"
      ) == false && (projectStepDescription = "Missing Information");
  return projectStepDescription;
}

export const ProjectStepFlatList = (props: {
    navigate: (
        name: keyof projectDetailNavigationProps,
        params: questionListData
    ) => void;
    closeProject?: () => void;
    highlightedStep?: number;
    setHighlightedStep?: (index: number) => void;
}) => {

    const route = useRoute() as any;
    const isDesktop = useContext(DesktopContext);
    const { colors } = useTheme();
    const thisProject = useContext(ProjectDetailContext);
    const organisation = useContext(OrganisationContext);
    const installer = useContext(InstallerContext);
    const recalculatingResults = useContext(RecalculatingResultsContext);
    const [staleProject, setStaleProject] = useState(
        {} as project_detail<"standard">
    );

    const [isDependenciesFilledout, setIsDependenciesFilledOut] = useState(true);
    const [shareVisible, setShareVisible] = useState(false);

    useEffect(() => {
        if (!recalculatingResults) setStaleProject(thisProject);

        checkDependencies(); // marker: realtime section text color changing
    }, [recalculatingResults, thisProject]);

    useEffect(() => {
        const goToProjectStep = AppEventEmitter.addListener(AppEvent.NavigateToProjectStep, press);

        return () => {
            goToProjectStep.remove();
        };
    }, []);

    useFocusEffect(useCallback(() => {
        checkDependencies();
    }, []))

    const checkDependencies = async () => {
        const boolValue = await isReportDependenciesFilledOut(thisProject, organisation, installer);

        setIsDependenciesFilledOut(boolValue);
        console.log('IS DEPENDENCIES FOR REPORTS ALL FILLED OUT?');
        console.log(boolValue);
    };

    const getMasterData = async () => {
        const elements = ['wall', 'floor', 'ceiling', 'roof', 'window', 'door', 'general'];
        const promises = [];

        for (const element of elements) {
            promises.push(getDocument('projects', thisProject.id + '/roomsMasterData/' + element));
        }

        try {
            const responses = await Promise.all(promises);

            return {
                wall: responses[0],
                floor: responses[1],
                ceiling: responses[2],
                roof: responses[3],
                window: responses[4],
                door: responses[5],
                general: responses[6]
            };
        } catch (err) {
            console.log(`Fetch master data: ${err}`);
        }
    };

    const press = async (
        item: {
            text: string;
            nestedPath?: string;
            question_collection?: string;
            navigateTo?: string;
            params?: object;
        },
        index: number
    ) => {
        let roomsMasterData = undefined as any;

        props.setHighlightedStep && props.setHighlightedStep(index ?? -1);

        if (item.nestedPath === 'rooms') {
            roomsMasterData = await getMasterData();
        }
        // checkDependencies();

        if (item.question_collection) {
            return props.navigate("Questions", {
                displayedQuestionCollection: item.question_collection as question_type,
                valueStoragePath: "projects",
                valueStorageDocument: thisProject.id,
                nestedPath: item.nestedPath,
                categoryOfAnswers: "project"
            });
        } else if (item.navigateTo) {
            return props.navigate(
                item.navigateTo as keyof projectDetailNavigationProps,
                { ...item.params, roomsMasterData }
            );
        }

        return {};
    };

    const renderStep: ListRenderItem<project_step> = ({ item, index }) => {
        if (!item.items) {
            const everythingFilledOut = item.id === 'report' ?
                isDependenciesFilledout :
                objGet(
                    staleProject,
                    (item.nestedPath ? item.nestedPath + "." : "") + "everythingFilledOut"
                ) == true;
            const projectStepDescription = resultDescription(
                staleProject,
                item,
                everythingFilledOut
            );

            const isNotHighlighted = item.nestedPath && ['master-data', 'designSummary'].includes(item.nestedPath);

            return (
                <Hoverable>
                    {({ hovered }) => {
                        return (
                        <List.Item
                            testID={item.text}
                            titleStyle={titleStyle(
                                isDesktop,
                                index == props.highlightedStep,
                                hovered, // || everythingFilledOut
                                isNotHighlighted ? undefined : everythingFilledOut,
                                colors
                            )}
                            title={`${item.text}`}
                            style={[
                                navigationStyles.listItem,
                                isDesktop &&
                                    desktopStylePressables(
                                    {
                                        pressed: index == props.highlightedStep,
                                        hovered: hovered,
                                    },
                                    colors
                                    ).firstMenuButtons
                            ]}
                            onPress={() => press(item, index)}
                            description={
                                projectStepDescription != "" ||
                                !everythingFilledOut ||
                                !item.results ? (
                                    projectStepDescription
                                ) : (
                                    <ActivityIndicator />
                                )
                            }
                        />
                        );
                    }}
                </Hoverable>
            );
        }
        //left={(_props) => <List.Icon color={isDesktop ? desktopIconColor({ pressed: index == props.highlightedStep, hovered: hovered }, colors) : colors.text} icon="check-circle-outline" />}
        else {
            return (
                <Hoverable>
                    {({ hovered }) => (
                        <List.Accordion
                            style={[
                                navigationStyles.listItem,
                                isDesktop &&
                                desktopStylePressables(
                                    {
                                    pressed: index == props.highlightedStep,
                                    hovered: hovered,
                                    },
                                    colors
                                ).firstMenuButtons,
                            ]}
                            titleStyle={titleStyle(
                                isDesktop,
                                index == props.highlightedStep,
                                hovered,
                                undefined,
                                colors
                            )}
                            title={item.text}
                            id={index}>

                            {item.items &&
                                item.items.map(
                                    (subItem, subIndex) => (
                                        <Hoverable key={subIndex}>
                                            {" "}
                                            {/*ok to have subindex key twice?*/}
                                            {({ hovered }) => (
                                                <List.Item
                                                    titleStyle={titleStyle(
                                                        isDesktop,
                                                        index + (subIndex + 1) / 100 ==
                                                        props.highlightedStep,
                                                        hovered,
                                                        undefined,
                                                        colors
                                                    )}
                                                    left={() => (
                                                        <List.Icon
                                                            color={
                                                                isDesktop
                                                                ? desktopIconColor(
                                                                    {
                                                                        pressed:
                                                                        index + (subIndex + 1) / 100 ==
                                                                        props.highlightedStep,
                                                                        hovered: hovered,
                                                                    },
                                                                    colors
                                                                    )
                                                                : colors.text
                                                            }
                                                            icon={subItem.icon} />
                                                    )}
                                                    style={[
                                                        navigationStyles.listItem,
                                                        isDesktop &&
                                                        desktopStylePressables(
                                                            {
                                                            pressed:
                                                                index + (subIndex + 1) / 100 ==
                                                                props.highlightedStep,
                                                            hovered: hovered,
                                                            },
                                                            colors
                                                        ).firstMenuButtons,
                                                    ]}
                                                    key={subIndex}
                                                    title={subItem.text}
                                                    onPress={() =>
                                                        press(subItem, index + (subIndex + 1) / 100)
                                                    }
                                                />
                                            )}
                                        </Hoverable>
                                ) //todo: this poses a maximum number of elements
                            )}
                        </List.Accordion>
                    )}
                </Hoverable>
            );
        }
    };

    return (
        <View style={isDesktop ? desktopStyle(colors).firstMenu : { flex: 1 }}>
            <Share visible={shareVisible} setVisible={setShareVisible} />
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 10, marginTop: 30,
                }}>
                    <Subheading
                        style={[
                            { marginLeft: 32, fontFamily: 'RobotoMedium' },
                            isDesktop && desktopStyle(colors).firstMenuText,
                        ]}>
                        {thisProject.general && thisProject.general.address
                            ? thisProject.general.address
                            : "New Project"}
                    </Subheading>

                    <IconButton
                        icon="share"
                        style={{ alignSelf: "center", marginRight: 32 }}
                        onPress={() => setShareVisible(true)} />
            </View>
            <Divider />
            <List.AccordionGroup>
                <FlatList
                    testID="project-step-list"
                    data={projectSteps()}
                    renderItem={renderStep}
                    keyExtractor={(item) => item.id}
                    onEndReachedThreshold={2}
                    initialNumToRender={10} />
            </List.AccordionGroup>
            <Divider />
            <View style={{ justifyContent: "flex-end" }}>
                {props.closeProject && (
                    <Button
                        testID="close-project"
                        mode="contained"
                        onPress={props.closeProject}
                        style={{ margin: 20, alignSelf: "center" }} >
                        Close Project
                    </Button>
                )}
            </View>
        </View>
    );
};

export const ReportFlatlist = (props: {
    navigate: (
        name: keyof projectDetailNavigationProps,
        params: questionListData | undefined
    ) => void;
    highlightedStep?: number;
    setHighlightedStep?: (index: number) => void;
}) => {
    const isDesktop = useContext(DesktopContext);
    const [expandedId, setExpandedId] = useState(-1);
    const { colors } = useTheme();
    const thisProject = useContext(ProjectDetailContext);
    const organisation = useContext(OrganisationContext);
    const installer = useContext(InstallerContext);

    const [isDependenciesFilledOut, setIsDependenciesFilledOut] = useState(false);

    const checkDependencies = async () => {
        const boolValue = await isReportDependenciesFilledOut(thisProject, organisation, installer);

        setIsDependenciesFilledOut(boolValue);
    };

    useFocusEffect(useCallback(() => {
        checkDependencies();
    }, []));

    const press = (item: report, index: number) => {
        props.setHighlightedStep && props.setHighlightedStep(index ?? -1);
        return props.navigate("Questions", {
        displayedQuestionCollection: item.question_collection as question_type,
        valueStoragePath: "projects",
        valueStorageDocument: thisProject.id,
        nestedPath: item.nestedPath,
        categoryOfAnswers: "report",
        extraData: item.extraData,
        });
    };

    const renderReport: ListRenderItem<report> = ({ item, index }) => {
        return (
            <Hoverable>
                {({ hovered }) => (
                    <List.Item
                        titleStyle={titleStyle(
                            isDesktop,
                            props.highlightedStep == index,
                            hovered,
                            isDependenciesFilledOut,
                            colors
                        )}
                        testID="report"
                        titleNumberOfLines={2}
                        style={[
                        navigationStyles.listItem,
                        isDesktop &&
                            desktopStylePressables(
                            {
                                pressed: index == props.highlightedStep,
                                hovered: hovered,
                            },
                            colors
                            ).secondMenuButtons,
                        ]}
                        title={item.text}
                        //description={item.description}
                        //descriptionNumberOfLines={3}
                        onPress={() => press(item, index)}
                    />
                )}
            </Hoverable>
        );
    };

    return (
        <View style={isDesktop ? desktopStyle(colors).secondMenu : { flex: 1 }}>
            <List.AccordionGroup
                expandedId={expandedId}
                onAccordionPress={(newExpandedId) => {
                    if (newExpandedId != expandedId)
                        setExpandedId(newExpandedId as number);
                    else setExpandedId(-1);
                }}>
                <FlatList
                    testID="report-flatlist"
                    data={reports(
                        thisProject.general?.contractBeforeSurvey || false,
                        thisProject.general?.inOfficeContract || false
                    )}
                    renderItem={renderReport}
                    keyExtractor={(item) => item.text}
                    onEndReachedThreshold={2}
                    initialNumToRender={10} />
            </List.AccordionGroup>
        </View>
    );
};
export const FirstQuoteFlatlist = (props: {
  navigate: (
    name: keyof projectDetailNavigationProps,
    params: questionListData | undefined
  ) => void;
  highlightedStep?: number;
  setHighlightedStep?: (index: number) => void;
}) => {
  const isDesktop = useContext(DesktopContext);
  const [expandedId, setExpandedId] = useState(-1);
  const { colors } = useTheme();
  const thisProject = useContext(ProjectDetailContext);
  const recalculatingResults = useContext(RecalculatingResultsContext);
  const [staleProject, setStaleProject] = useState(
    {} as project_detail<"standard">
  );
  console.log("updateddd", JSON.parse(JSON.stringify(thisProject)));
  useEffect(() => {
    if (!recalculatingResults || !staleProject.id) setStaleProject(thisProject);
  }, [recalculatingResults, thisProject]);
  const press = (item: firstQuote_flatlist, index: number) => {
    props.setHighlightedStep && props.setHighlightedStep(index ?? -1);
    return props.navigate("Questions", {
      displayedQuestionCollection: item.question_collection as question_type,
      valueStoragePath: "projects",
      valueStorageDocument: thisProject.id,
      nestedPath: item.nestedPath,
      categoryOfAnswers: item.type == "report" ? "report" : "project",
      extraData: item.extraData,
    });
  };
  const renderFirstQuote: ListRenderItem<firstQuote_flatlist> = ({
    item,
    index,
  }) => {
    const everythingFilledOut =
      objGet(
        staleProject,
        (item.nestedPath ? item.nestedPath + "." : "") + "everythingFilledOut"
      ) == true;
    console.log(
      "whaaatss",
      objGet(
        staleProject,
        (item.nestedPath ? item.nestedPath + "." : "") + "everythingFilledOut"
      )
    );
    const description = resultDescription(
      staleProject,
      item,
      everythingFilledOut
    );
    return (
      <Hoverable>
        {({ hovered }) => (
          <List.Item
            titleStyle={titleStyle(
              isDesktop,
              props.highlightedStep == index,
              hovered,
              everythingFilledOut,
              colors
            )}
            titleNumberOfLines={2}
            testID="firstQuote"
            style={[
              navigationStyles.listItem,
              isDesktop &&
                desktopStylePressables(
                  {
                    pressed: index == props.highlightedStep,
                    hovered: hovered,
                  },
                  colors
                ).secondMenuButtons,
            ]}
            title={item.text}
            description={
              description != "" || !everythingFilledOut || !item.results ? (
                description
              ) : (
                <ActivityIndicator />
              )
            }
            descriptionNumberOfLines={3}
            onPress={() => press(item, index)}
          />
        )}
      </Hoverable>
    );
  };

  return (
    <View style={isDesktop ? desktopStyle(colors).secondMenu : { flex: 1 }}>
      <List.AccordionGroup
        expandedId={expandedId}
        onAccordionPress={(newExpandedId) => {
          if (newExpandedId != expandedId)
            setExpandedId(newExpandedId as number);
          else setExpandedId(-1);
        }}
      >
        <FlatList
          testID="firstQuote-flatlist"
          data={firstQuotes(
            thisProject.general?.contractBeforeSurvey || false,
            thisProject.general?.inOfficeContract || false
          )}
          renderItem={renderFirstQuote}
          keyExtractor={(item) => item.text}
          onEndReachedThreshold={2}
          initialNumToRender={10}
        />
      </List.AccordionGroup>
    </View>
  );
};

export const OrganisationFlatlist = (props: {
    navigate: (
        name: keyof organisationDetailNavigationProps,
        params: questionListData | undefined
    ) => void;
    highlightedStep?: number;
    setHighlightedStep?: (index: number) => void;
}) => {
    const route = useRoute() as { params: { sectionIndex: number } };
    const userInfo = useContext(InstallerContext);
    const isDesktop = useContext(DesktopContext);
    const [expandedId, setExpandedId] = useState(-1);
    const { colors } = useTheme();
    const settings: organisationScreen[] = [
        {
            text: "Installer Overview",
            navigateTo: "InstallerOverview",
        },
        {
            text: "Organisation Details",
            question_collection: "organisationSignup",
            valueStorageDocument: userInfo.orgId,
        },
        {
            text: "Billing Information",
            question_collection: "organisationBilling",
            valueStorageDocument: `${userInfo.orgId}/extra/billingInfo`,
        },
        {
            text: "Business Settings",
            question_collection: "organisationBusiness",
            valueStorageDocument: `${userInfo.orgId}/extra/extraInfo`,
            nestedPath: "businessSettings",
        },
        {
            text: "Contract Preferences",
            question_collection: "organisationContract",
            valueStorageDocument: `${userInfo.orgId}/extra/extraInfo`,
            nestedPath: "contractPreferences",
        },
        {
            text: "Project Pricing",
            question_collection: "organisationPricing",
            valueStorageDocument: `${userInfo.orgId}/extra/extraInfo`,
            nestedPath: "pricing",
        },
    ];

    

    useEffect(() => {
        if (route?.params?.sectionIndex) {
            const { sectionIndex } = route.params;

            press(settings[sectionIndex], sectionIndex);
        }

    }, [route?.params?.sectionIndex])

    const press = (item: organisationScreen, index: number) => {
        props.setHighlightedStep && props.setHighlightedStep(index ?? -1);

        if (item.question_collection) {
            return props.navigate("Questions", {
                displayedQuestionCollection: item.question_collection as question_type,
                valueStoragePath: "organisations",
                valueStorageDocument: item.valueStorageDocument,
                nestedPath: item.nestedPath,
                categoryOfAnswers: "organisationSignup",
            });
        } else if (item.navigateTo) {
            return props.navigate(
                item.navigateTo as keyof organisationDetailNavigationProps,
                item.params as object
            );
        }

        return {};
    };

    const renderSetting: ListRenderItem<organisationScreen> = ({
        item,
        index,
    }) => {
        return (
            <Hoverable>
                {({ hovered }) => (
                    <List.Item
                        testID="organisation-flatlist-item"
                        titleStyle={titleStyle(
                            isDesktop,
                            props.highlightedStep == index,
                            hovered,
                            undefined,
                            colors
                        )}
                        style={[
                            navigationStyles.listItem,
                            isDesktop &&
                                desktopStylePressables(
                                {
                                    pressed: index == props.highlightedStep,
                                    hovered: hovered,
                                },
                                colors
                                ).firstMenuButtons,
                        ]}
                        title={item.text}
                        descriptionNumberOfLines={3}
                        onPress={() => press(item, index)}
                    />
                )}
            </Hoverable>
        );
    };

    return (
        <View style={isDesktop ? desktopStyle(colors).firstMenu : { flex: 1 }}>
            <List.AccordionGroup
                expandedId={expandedId}
                onAccordionPress={(newExpandedId) => {
                    if (newExpandedId != expandedId) setExpandedId(newExpandedId as number);
                    else setExpandedId(-1);
                }}>

                <FlatList
                    testID="organisation-flatlist"
                    data={settings}
                    renderItem={renderSetting}
                    keyExtractor={(item) => item.text}
                    onEndReachedThreshold={2}
                    initialNumToRender={10} />
            </List.AccordionGroup>
        </View>
    );
};
