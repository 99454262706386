import { useContext } from 'react';
import { List, useTheme } from 'react-native-paper';
import { mergeDataWithDocument, } from '../../../util/firebaseConnection';
import { desktopStylePressables, } from '../../../styles/desktop';
import { Hoverable } from 'react-native-web-hover';
import { DesktopContext } from '../../../util/contexts';
import type { room_detail } from '../../../util/types';
import navigationStyles from '../../../styles/navigation';


type AddElementBtnProps = {
    elementType: 'Ceiling' | 'Wall';
    roomPath: string;
    item: room_detail<'standard'>;
    index: number;
    props: any;
    roomsMasterData?: any;
}

/**
* Description: Add ceiling or wall element int room by room
* @param props elementType, roomPath, item, index, props
* @returns ReactNode for the add element button
*/
const AddElementBtn: React.FC<AddElementBtnProps> = ({
    elementType,
    roomPath,
    item,
    index,
    props,
    roomsMasterData
}) => {
    const isDesktop = useContext(DesktopContext);
    const { colors } = useTheme();

    return (
        <Hoverable>
            {({ hovered }) => (
                <List.Item
                    title={`Add ${elementType}`}
                    style={[
                        navigationStyles.listItem,
                        isDesktop &&
                        desktopStylePressables(
                            { pressed: false, hovered: hovered },
                            colors
                        ).secondMenuButtons,
                    ]}
                    left={() => <List.Icon icon="plus" />}
                    onPress={() => {
                        mergeDataWithDocument(roomPath, item.id, {
                            elements: [...item.elements, { type: elementType.toLowerCase() }],
                        }).then(() => {
                            props.setHighlightedStep &&
                                props.setHighlightedStep(index + (item.elements.length + 1) / 100);
                            props.navigate('Questions', {
                                displayedQuestionCollection: elementType.toLowerCase(),
                                valueStoragePath: roomPath,
                                valueStorageDocument: item.id,
                                categoryOfAnswers: 'room',
                                nestedPath: 'elements[' + item.elements.length + ']',
                                isProvidingInformationForArrayElement: 'elements',
                                variablesToKeep: [
                                    'type',
                                    'heatLoss',
                                    'energyUsagePerYear',
                                ],
                                roomsMasterData
                            });
                        });
                    }}
                />
            )}
        </Hoverable>
    );
};

export default AddElementBtn;
