import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center'
    },
    dialog: {
        minWidth: 400,
        maxWidth: 400,
        alignSelf: 'center',
        backgroundColor: 'white'
    },
    button: {
        flex: 1,
        marginBottom: 10
    },
    validationMsg: {
        color: 'red',
        marginBottom: 15
    }
});

export default styles;