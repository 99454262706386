import React, { useContext } from "react";
import { Divider } from "react-native-paper";
import { View } from "react-native";
import basicStyles from "../../styles/basic";
import { MemberInvite } from "./MemberInvite";
import { MemberList } from "./MemberList";
import { OrganisationContext } from "../../util/contexts";

/**
 * Description: Creates a screen for the organisation management tab
 */
export const InstallerOverview = () => {
  const orgInfo = useContext(OrganisationContext);
  return (
    <View style={[basicStyles.container, { margin: 5 }]}>
      <MemberInvite />
      <Divider />
      <MemberList orgInfo={orgInfo} />
    </View>
  );
};
