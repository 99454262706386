export const hasElementTypeNull = (replacedValues: any) => {
    if (replacedValues?.elements) {
        for (const element of replacedValues.elements) {
            if (element == undefined) continue;

            if (typeof element.Uvalue !== 'undefined' && JSON.stringify(element.Uvalue) == 'null') {
                return true; // don't save if element type is ceiling nad ceiling type is null
            }
        }
    }

    return false;
};
