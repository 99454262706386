import { StyleSheet } from 'react-native';
import { screenWidth, mobileScreenWidth } from '../../../styles/constants';
import { isMobile } from '../../../styles/constants';

const styles = StyleSheet.create({
    container: {
        margin: 40
    },
    title: {
        fontSize: 40,
        fontFamily: 'RobotoMedium'
    },
    description: {
        fontSize: 16,
        marginBottom: 20
    },
    listSection: {
        borderColor: '#919BB7',
        borderWidth: 1
    },
    listAccordionTitle: {
        fontSize: 24,
        marginLeft: 20,
        paddingVertical: 5,
        fontFamily: 'RobotoMedium'
    },
    accordionContent: {
        padding: 20,
        paddingHorizontal: 40,
        borderTopColor: '#919BB7',
        borderTopWidth: 1
    },
    accordionRow: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: screenWidth > mobileScreenWidth ? 10 : 0,
    },
    accordionRowTitle: {
        minWidth: 150,
        flexDirection: 'row',
        marginTop: isMobile ? 20 : 0,
    },
    dropdownWrapper: {
        flex: 1,
        minWidth: 150,
        marginTop: isMobile ? 10 : 0,
        marginLeft: isMobile ? 0 : 20,
        marginRight: isMobile ? 0 : 10
    },
    textInputWrapper: {
        flex: 1,
        minWidth: 150,
        marginTop: isMobile ? 10 : 0,
        marginLeft: isMobile ? 0 : 10,
        marginRight: isMobile ? 0 : 20,
        flexDirection: 'row'
    },
    hint: {
        justifyContent: 'center',
        minWidth: 50,
        alignItems: 'center'
    },
    saveBtnWrapper: {
        marginTop: 10,
        width: '30%'
    },
    generalFieldWrapper: {
        minWidth: 150,
        flexDirection: isMobile ? 'column' : 'row',
        marginTop: isMobile ? 10 : 0,
        marginRight: isMobile ? 0 : 10
    },
    tempDropField: {
        marginLeft: isMobile ? 0 : 10
    },
    subHeader: {
        marginTop: 30,
        marginBottom: 10
    }
});

export default styles;