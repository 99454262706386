import { Dimensions } from 'react-native';

export const {
    width: screenWidth,
    height: screenHeight
} = Dimensions.get('window');

export const floorPlan = {
    imgBoxWidth: 188,
    imgBoxHeight: 174
};

export const mobileScreenWidth = 500;

export const isMobile = screenWidth <= mobileScreenWidth;