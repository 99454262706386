import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialUValue } from '../../util/masterData/prepopulateFieldsFromMasterData';
import {
    isAdjacentSpaceTypeParams,
    isFabricAndAdjacentSpaceTypeParams
} from '../../util/typeGuards';
import type {
    elementType,
    option,
    question_detail,
    SetDropdownParams,
    SetElementObjectParams,
    subElementType,
    Obj,
    ElementProperties,
    SetFabricTypeParams,
    SetAdjacentSpaceParams,
    SetGeneralDropdownParams
} from '../../util/types';

export type MasterDataState = {
    generalDropdowns: {
        constructionDate: boolean,
        temperatureDrop: boolean
    },
    isGeneralDropdownsFetched: boolean,
    generalDropdownOptions: {
        constructionDate: option[],
        temperatureDrop: option[]
    },
    general: {
        constructionDate: string,
        temperatureDrop: string
    },
    elementTypes: Array<elementType | subElementType>,
    expandedElement: elementType | subElementType | '',
    expanded: {
        wall: boolean,
        floor: boolean,
        ceiling: boolean,
        roof: boolean,
        window: boolean,
        door: boolean
    },
    dropdowns: {
        floor: {
            ground: boolean,
            intermediate: boolean,
            party: boolean
        },
        wall: {
            external: boolean,
            internal: boolean,
            party: boolean
        },
        ceiling: {
            roof: boolean,
            intermediate: boolean,
            party: boolean
        },
        roof: {
            roofLight: boolean
        },
        window: {
            window: boolean
        },
        door: {
            door: boolean
        }
    },
    adjacentSpaceDropdowns: {
        floor: {
            ground: boolean,
            party: boolean
        },
        wall: {
            external: boolean,
            party: boolean
        },
        ceiling: {
            roof: boolean,
            party: boolean
        }
    },
    isFabricTypesFetched: boolean,
    fabricTypes: {
        wall: {
            internal: option[],
            external: option[],
            party: option[]
        },
        floor: {
            ground: option[],
            intermediate: option[],
            party: option[]
        },
        ceiling: {
            roof: option[],
            intermediate: option[],
            party: option[]
        },
        roof: {
            roofLight: option[]
        },
        window: {
            window: option[]
        },
        door: {
            door: option[]
        }
    },
    isAdjacentSpaceTypesFetched: boolean,
    adjacentSpace: {
        wall: {
            external: option[],
            party: option[]
        },
        floor: {
            ground: option[],
            party: option[]
        },
        ceiling: {
            roof: option[],
            party: option[]
        }
    },
    isMasterDataFetched: boolean,
    failedToGetMasterData: boolean,
    floor: {
        ground: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        },
        intermediate: {
            fabricType: string,
            uValue: question_detail
        },
        party: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        }
    },
    ceilings: {
        roof: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        },
        intermediate: {
            fabricType: string,
            uValue: question_detail
        },
        party: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        }
    },
    walls: {
        internal: {
            fabricType: string,
            uValue: question_detail
        },
        external: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        },
        party: {
            fabricType: string,
            uValue: question_detail,
            adjacentSpace: string
        }
    },
    roofs: {
        roofLight: {
            fabricType: string,
            uValue: question_detail
        }
    },
    windows: {
        window: {
            fabricType: string,
            uValue: question_detail
        }
    },
    doors: {
        door: {
            fabricType: string,
            uValue: question_detail
        }
    }
};

export const initialState: MasterDataState = {
    generalDropdowns: {
        constructionDate: false,
        temperatureDrop: false
    },
    isGeneralDropdownsFetched: false,
    generalDropdownOptions: {
        constructionDate: [],
        temperatureDrop: []
    },
    general: {
        constructionDate: '',
        temperatureDrop: ''
    },
    elementTypes: [
        'wall',
        'floor',
        'ceiling',
        'roof',
        'window',
        'door'
    ],
    expandedElement: '',
    expanded: {
        wall: false,
        floor: false,
        ceiling: false,
        roof: false,
        window: false,
        door: false
    },
    dropdowns: {
        floor: {
            ground: false,
            intermediate: false,
            party: false
        },
        wall: {
            external: false,
            internal: false,
            party: false
        },
        ceiling: {
            roof: false,
            intermediate: false,
            party: false
        },
        roof: {
            roofLight: false
        },
        window: {
            window: false
        },
        door: {
            door: false
        }
    },
    adjacentSpaceDropdowns: {
        floor: {
            ground: false,
            party: false
        },
        wall: {
            external: false,
            party: false
        },
        ceiling: {
            roof: false,
            party: false
        } 
    },
    isFabricTypesFetched: false,
    fabricTypes: {
        wall: {
            internal: [],
            external: [],
            party: []
        },
        floor: {
            ground: [],
            intermediate: [],
            party: []
        },
        ceiling: {
            roof: [],
            intermediate: [],
            party: []
        },
        roof: {
            roofLight: []
        },
        window: {
            window: []
        },
        door: {
            door: []
        }
    },
    isAdjacentSpaceTypesFetched: false,
    adjacentSpace: {
        wall: {
            external: [],
            party: []
        },
        floor: {
            ground: [],
            party: []
        },
        ceiling: {
            roof: [],
            party: []
        }
    },
    isMasterDataFetched: false,
    failedToGetMasterData: false,
    floor: {
        ground: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'floor'},
            adjacentSpace: ''
        },
        intermediate: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'floor'}
        },
        party: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'floor'},
            adjacentSpace: ''
        }
    },
    ceilings: {
        roof: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'ceiling' },
            adjacentSpace: ''
        },
        intermediate: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'ceiling' }
        },
        party: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'ceiling' },
            adjacentSpace: ''
        }
    },
    walls: {
        internal: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'wall' }
        },
        external: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'wall' },
            adjacentSpace: ''
        },
        party: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'wall' },
            adjacentSpace: ''
        }
    },
    roofs: {
        roofLight: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'roof' }
        }
    },
    windows: {
        window: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'window' }
        }
    },
    doors: {
        door: {
            fabricType: '',
            uValue: { ...initialUValue, type: 'door' }
        }
    }
};

export const masterDataSlice = createSlice({
    name: 'masterData',
    initialState,
    reducers: {
        setExpanded: (state, action: PayloadAction<elementType | subElementType>) => {
            const element = action.payload;
            state.expanded = {
                ...state.expanded,
                [element]: !state.expanded[element as keyof typeof state.expanded]
            };
        },
        setExpandedElement: (state, action: PayloadAction<MasterDataState['expandedElement']>) => {
            state.expandedElement = action.payload;
        },
        setFabricTypes: (state, action: PayloadAction<Obj<option[]>>) => {
            state.fabricTypes = { ...state.fabricTypes, ...action.payload };
        },
        setAdjacentSpaceTypes: (state, action: PayloadAction<Obj<option[]>>) => {
            state.adjacentSpace = { ...state.adjacentSpace, ...action.payload };
        },
        setGeneralDropdownOptions: (state, action: PayloadAction<Obj<option[]>>) => {
            state.generalDropdownOptions = { ...state.generalDropdownOptions, ...action.payload };
        },
        setGeneral: (state, action: PayloadAction<Obj<string>>) => {
            state.general = { ...state.general, ...action.payload };
        },
        setFloor: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.floor = setElementState(state.floor, action.payload) as MasterDataState['floor'];
        },
        setCeilings: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.ceilings = setElementState(state.ceilings, action.payload) as MasterDataState['ceilings'];
        },
        setWalls: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.walls = setElementState(state.walls, action.payload) as MasterDataState['walls'];
        },
        setRoofs: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.roofs = setElementState(state.roofs, action.payload) as MasterDataState['roofs'];
        },
        setWindows: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.windows = setElementState(state.windows, action.payload) as MasterDataState['windows'];
        },
        setDoors: (state, action: PayloadAction<SetElementObjectParams>) => {
            state.doors = setElementState(state.doors, action.payload)  as MasterDataState['doors'];
        },
        setDropdowns: (state, action: PayloadAction<SetDropdownParams>) => {
            const { element, subType, value } = action.payload;

            state.dropdowns = {
                ...state.dropdowns,
                [element]: {
                    [subType]: value
                }
            };
        },
        setAdjacentSpaceDropdowns: (state, action: PayloadAction<SetDropdownParams>) => {
            const { element, subType, value } = action.payload;

            state.adjacentSpaceDropdowns = {
                ...state.adjacentSpaceDropdowns,
                [element]: {
                    [subType]: value
                }
            };
        },
        setGeneralDropdowns: (state, action: PayloadAction<SetGeneralDropdownParams>) => {
            const { field, value } = action.payload;

            state.generalDropdowns = {
                ...state.generalDropdowns,
                [field]: value
            };
        },
        setIsFabricTypesFetched: (state, action: PayloadAction<boolean>) => {
            state.isFabricTypesFetched = action.payload;
        },
        setIsAdjacentSpaceTypesFetched: (state, action: PayloadAction<boolean>) => {
            state.isAdjacentSpaceTypesFetched = action.payload;
        },
        setIsGeneralOptionsFetched: (state, action: PayloadAction<boolean>) => {
            state.isAdjacentSpaceTypesFetched = action.payload;
        },
        setIsMasterDataFetched: (state, action: PayloadAction<boolean>) => {
            state.isMasterDataFetched = action.payload;
        },
        setFailedToGetMasterData: (state, action: PayloadAction<boolean>) => {
            state.failedToGetMasterData = action.payload;
        },
        clearExpanded: (state) => {
            state.expanded = { ...initialState.expanded };
        },
        clearElementObjects: (state) => ({
            ...initialState,
            isFabricTypesFetched: state.isFabricTypesFetched,
            fabricTypes: state.fabricTypes
        }),
        clearMasterData: () => ({
            ...initialState,
        }),
    },
});

const setElementState = (
    elementObject: Obj<ElementProperties>,
    payload: SetFabricTypeParams | SetAdjacentSpaceParams
): Obj<ElementProperties> => {
    const { subType } = payload;

    if (isFabricAndAdjacentSpaceTypeParams(payload)) {
        const { fabricType, uValue } = payload;

        return {
            ...elementObject,
            [subType]: {
                ...elementObject[subType],
                fabricType,
                uValue: {
                    ...elementObject[subType].uValue,
                    ...uValue
                },
                adjacentSpace: payload.adjacentSpace
            }
        };
    }

    if (isAdjacentSpaceTypeParams(payload)) {
        return {
            ...elementObject,
            [subType]: {
                ...elementObject[subType],
                adjacentSpace: payload.adjacentSpace
            }
        };
    }

    const { fabricType, uValue } = payload;

    return {
        ...elementObject,
        [subType]: {
            ...elementObject[subType],
            fabricType,
            uValue: {
                ...elementObject[subType].uValue,
                ...uValue
            }
        }
    };
};

export const {
    setExpanded,
    setExpandedElement,
    setFabricTypes,
    setFloor,
    setCeilings,
    setWalls,
    setRoofs,
    setWindows,
    setDoors,
    setGeneral,
    setDropdowns,
    setGeneralDropdowns,
    setAdjacentSpaceTypes,
    setAdjacentSpaceDropdowns,
    setIsAdjacentSpaceTypesFetched,
    setIsGeneralOptionsFetched,
    setGeneralDropdownOptions,
    setIsFabricTypesFetched,
    setIsMasterDataFetched,
    setFailedToGetMasterData,
    clearExpanded,
    clearElementObjects,
    clearMasterData
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
