import { StyleSheet } from 'react-native';
import { colors } from '../../../styles/colors';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center'
    },
    dialog: {
        minWidth: 400,
        maxWidth: 700,
        alignSelf: 'center',
        backgroundColor: 'white'
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        flex: 1,
        marginTop: 15,
        marginBottom: 10
    },
    validationMsg: {
        color: 'red',
        marginBottom: 15
    },
    importText: {
        fontFamily: 'RobotoBold',
        color: 'white',
        marginLeft: 5,
        padding: 5
    },
    errorMessage: {
        flexDirection: 'row',
        marginTop: 10
    },
    errorIcon: {
        marginRight: 10
    },
    floorPlanBtn: {
        backgroundColor: colors.blueGreen,
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 10
    },
    cancelBtn: { marginRight: 10 }
});

export default styles;