import { Fragment } from 'react';
import { View } from 'react-native';
import { elementType, subElementType } from '../../../util/types';
import Typography from '../Typography/Typography';
import styles from './masterDataSectionSummaryStyles';

type MasterDataSectionSummaryProps = {
    element: elementType | subElementType,
    elementObject: any,
    expanded: any
}

const MasterDataSectionSummary: React.FC<MasterDataSectionSummaryProps> = ({ element, elementObject, expanded }) => {

    if (expanded[element]) return <></>;

    return (
        <View style={styles.container}>
            {Object.keys(elementObject).map((key, index) => {
                return (
                    <Fragment key={`summary-${index}`}>
                        {['wall', 'floor', 'ceiling'].includes(element) ?
                            <>
                                <View style={styles.type}>
                                    <Typography fontWeight='Medium' size={14} color='#3A4A74'>{key.charAt(0).toUpperCase() + key.slice(1)} </Typography>
                                </View>
                                <Typography size={14} color='#919BB7'>
                                    {elementObject[key].fabricType ? elementObject[key].fabricType : 'Not defined' }
                                </Typography>

                                {index <= Object.keys.length &&
                                    <Typography size={5} color='#919BB7' style={{ marginHorizontal: 5 }}>{'\u2B24'}</Typography>
                                }
                            </>
                            : <Typography fontWeight='Medium' size={14} color='#3A4A74'>
                                {elementObject[key].fabricType ? elementObject[key].fabricType : 'Not defined' }
                            </Typography>
                        }
                    </Fragment>
                )
            })}
        </View>
    );
};

export default MasterDataSectionSummary;
