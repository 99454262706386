import { StyleSheet } from "react-native";

const basicStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  smallBox: {
    width: 400,
    maxWidth: "80%",
  },
  logo: {
    width: 300,
    height: 200,
    resizeMode: "contain",
  },
});
export default basicStyles;
