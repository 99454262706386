import {
  Action,
  configureStore,
  ThunkAction,
  combineReducers,
  AnyAction,
} from '@reduxjs/toolkit';
// import { composeWithDevTools } from 'remote-redux-devtools';
import thunk from 'redux-thunk';
import * as reducers from './reducers';
// composeWithDevTools(applyMiddleware(...middleware),

import { persistStore, persistReducer } from 'redux-persist';

import AsyncStorage from '@react-native-async-storage/async-storage';
// import { setLoggingOut } from './modules/authentication';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: [
      'confirmSheet',
      'signUp',
      'signIn',
      'flashNotification',
      'editPage',
      'buildPage'
  ]
};
const appReducers = combineReducers(reducers);
const rootReducers = (
  state: ReturnType<typeof appReducers>,
  action: AnyAction,
) => {
  // if (action.type === setLoggingOut.type) {
  //     return appReducers(undefined, { type: undefined });
  // }
  return appReducers(state, action);
};

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducers);

// const createFlipperDebugger = require('redux-flipper').default;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
          immutableCheck: false, // { warnAfter: 128 },
          serializableCheck: false,
      }).concat(thunk), // createFlipperDebugger()
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
