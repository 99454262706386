import { project_step } from "./types";

export const projectSteps = () => {
  const steps = [
    {
      id: "project",
      text: "1. Project Details",
      question_collection: "project-detail-questions",
      nestedPath: "general",
    },
    {
      id: "firstQuote",
      text: "2. First Quote",
      navigateTo: "FirstQuote",
      nestedPath: "firstQuote",
      /*results: [
        { text: "Hardware costs: £", value: "price", affix: "" },
        { text: "Running costs: £", value: "runningCosts", affix: "/year" },
      ],*/
    },
    {
      id: "master",
      text: "3. Master Construction Data",
      question_collection: "master-construction-data", //NOTE: project step with this field uses the QuestionList component
      nestedPath: "master-data",
    },
    {
      id: "rooms",
      text: "4. Room By Room",
      navigateTo: "RoomByRoom",
      params: { newRooms: false },
      nestedPath: "rooms",
      results: [
        { text: "Heat Loss: ", value: "heatLoss", affix: "W" },
        {
          text: "Energy Usage: ",
          value: "energyUsagePerYear",
          affix: "kWh/year",
        },
      ],
    },
    {
      id: "upgrades",
      text: "5. System Upgrades",
      navigateTo: "RoomByRoom",
      params: { newRooms: true },
      nestedPath: "upgrades",
      results: [
        {
          text: "Sufficient heat generation: ",
          value: "enoughHeat",
          affix: "",
        },
        {
          text: "Price: £",
          value: "price",
          affix: "",
        },
      ],
    },
    {
      id: "hotWater",
      text: "6. Hot Water",
      question_collection: "hot-water",
      nestedPath: "hotWater",
      results: [
        { text: "Usage Per Day: ", value: "usagePerDay", affix: "L" },
        {
          text: "Energy Usage: ",
          value: "energyUsagePerYear",
          affix: "kWh/year",
        },
      ],
    },
    {
      id: "heatPumpSelection",
      text: "7. ASHP Selection",
      question_collection: "heatPumpSelection",
      nestedPath: "heatPump",
      results: [
        { text: "Price: £", value: "price", affix: "" },
        { text: "Heating costs: £", value: "runningCosts", affix: "/year" },
      ],
    },
    {
      id: "cylinderSelection",
      text: "8. Cylinder Selection",
      question_collection: "cylinderSelection",
      nestedPath: "cylinder",
      results: [
        { text: "Price: £", value: "price", affix: "" },
        { text: "DHW costs: £", value: "runningCosts", affix: "/year" },
      ],
    },
    {
      id: "noise",
      text: "9. Noise Calculation",
      question_collection: "noise",
      nestedPath: "noise",
      results: [
        { text: "Noise: ", value: "noise", affix: "dB" },
        { text: "Passed: ", value: "passed", affix: "" },
      ],
    },
    {
      id: "report",
      text: "10. Report",
      navigateTo: "Report",
    },
    {
      id: "designSummary",
      text: "11. Design Summary",
      nestedPath: "designSummary",
      question_collection: "design-summary"
    },
  ] as project_step[];
  return steps;
};
