export const firstQuotes = (
  contractBeforeSurvey: boolean,
  inOfficeContract: boolean
) => {
  let firstQuotes = [
    {
      text: "Rough Heat Loss Calculation",
      question_collection: "first-quote-questions",
      nestedPath: "firstQuote",
      results: [
        { text: "Heat Loss: ", value: "heatLoss", affix: "W" },
        { text: "Cylinder Size: ", value: "cylinderSize", affix: "L" },
      ],
    },
    {
      text: "Provisional ASHP Selection",
      question_collection: "heatPumpSelection",
      nestedPath: "firstQuoteHeatPump",
      extraData: "first_quote",
      results: [
        { text: "Price: £", value: "price", affix: "" },
        { text: "Heating costs: £", value: "runningCosts", affix: "/year" },
      ],
    },
    {
      text: "Provisional Cylinder Selection",
      question_collection: "cylinderSelection",
      nestedPath: "firstQuoteCylinder",
      extraData: "first_quote",
      results: [
        { text: "Price: £", value: "price", affix: "" },
        { text: "DHW costs: £", value: "runningCosts", affix: "/year" },
      ],
    },
    {
      text: "Proposal - Journey 2",
      question_collection: "projectContract",
      description:
        "Model covering letter for proposals - contract agreed before full site survey",
      extraData: [
        "journey-2-model-covering-letter-for-proposals-parameters",
        "first_quote",
      ],
      nestedPath: "contract",
      type: "report",
    },
    {
      text: "Performance estimate - Journey 2",
      question_collection: "NA",
      description: "Produce a performance estimate report for the design",
      extraData: ["journey-2-model-performance-estimate", "first_quote"],
      nestedPath: "performance_estimate",
      type: "report",
    },
    {
      text: "MCS Performance Estimate Table",
      question_collection: "mcsPerformance",
      description: "Run cost overview according to MCS standards",
      extraData: ["MCS-performance-estimate", "first_quote"],
      nestedPath: "mcsPerformance",
      type: "report",
    },
    {
      text: "Quotation and Order Form - Journey 2",
      question_collection: "projectQuote",
      description: "Produce a quote and order form for the customer",
      extraData: ["journey-2-model-quotation-and-order-form", "first_quote"],
      nestedPath: "quote",
      type: "report",
    },
  ];
  if (!contractBeforeSurvey) {
    firstQuotes = firstQuotes.filter(
      (firstQuote) =>
        !firstQuote.extraData || !firstQuote.extraData[0].includes("journey-2")
    );
  }
  if (inOfficeContract) {
    firstQuotes = firstQuotes.filter(
      (firstQuote) =>
        !firstQuote.extraData?.includes("who-do-sell-in-the-home") ||
        !firstQuote.extraData?.includes("MCS-performance-estimate")
    );
  }
  return firstQuotes;
};
