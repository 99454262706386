import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import {
  TextInput,
  Switch,
  Text,
  useTheme,
  Button,
} from "react-native-paper";
import {
  DesktopContext,
  ProjectDetailContext,
  RoomsContext,
} from "../util/contexts";
import { desktopStyle } from "../styles/desktop";
import uuid from "react-native-uuid";
import { getReadableDate, getTextInputBackgroundColor } from "../util/helper";
import { Hint } from "./Hint";
import { getDocumentAsync } from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import { question_detail } from "../util/types";
import { isDimensionZero } from "../util/helper";
import {
  createDocument,
  getUser,
  uploadFile
} from "../util/firebaseConnection";
import DropDown from "./DropDown";
import Typography from "./base/Typography/Typography";
import { colors as styleColors } from "../styles/colors";
import { useAppSelector } from "../redux/hooks";
//todo: aggregate updates and only write once component is closed

/**
 * Description: create a question based on inputRule (dropdown, switch, textInput)
 * @returns
 */
export const Question = (props: {
    question: question_detail;
    answer: string;
    setAnswer: (answer: string) => void;
    currentRoom?: string;
    isBaxiField?: boolean;
}) => {
    const [answer, setAnswer] = useState(props.answer);
    const [showDropDown, setShowDropDown] = useState(false);
    const [feedback, setFeedback] = useState("");
    const isDesktop = useContext(DesktopContext);
    const { colors } = useTheme();
    const thisProject = useContext(ProjectDetailContext);
    const roomPath = "projects/" + thisProject.id + "/rooms";
    const rooms = useContext(RoomsContext);

    const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);

    //to check if a question needs to be assigned an a special ID depending on variableName
    const specialCasesMinMax = [
        "general.designTemperature",
        "general.targetTemperature",
        "general.flowTemperature",
    ];

    useEffect(() => {
        setAnswer(props.answer);
        // alert(props.answer)

        console.log('Answer to text field changed...');
    }, [props.answer]);

    function dropDownList() {
        if (props.question.input_rule == "listOfRooms") {
            const roomList = rooms
                .filter((room) => room.id != props.currentRoom)
                .map((room) => {
                    return {
                        label:
                        ((room.general && room.general.customName) || "New Room") +
                        " | " +
                        getReadableDate(new Date(room.created)),
                        value: room.id,
                    };
                });

            return [...roomList, { label: "Add room", value: "AddRoom" }];

        } else if (props.question.input_rule == "listOfZones") {
            return thisProject.heatingZones != undefined &&
                thisProject.heatingZones.heatingZones != undefined &&
                thisProject.heatingZones.heatingZones[0] &&
                thisProject.heatingZones.heatingZones[0].emitterType
                ? thisProject.heatingZones.heatingZones.map((emitter, index) => {
                    return {
                    label: index + 1 + " " + emitter.emitterType,
                    value: index.toString(),
                    };
                })
                : [];
        } else {
            if (props.question.options) {
                return props.question.options.map((option, index) => {
                return {
                    label: option,
                    value:
                    props.question.values != undefined
                        ? props.question.values[index]
                        : option,
                };
                });
            } else {
                throw TypeError("If input_rule=='limitedOptions',options must be set");
            }
        }
    }

    const hasError = props.question.inputRuleError || isDimensionZero(props.question);

    return (
        <View
            style={[
                {
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: 5,
                },
                isDesktop ? desktopStyle(colors).question : {},
            ]}>

            {props.question.input_rule != "imageOptions" ? (
                <View style={{ flexDirection: "row", flex: 1 }}>
                    <View style={{ height: 65, justifyContent: "flex-start", flex: 1 }}>
                        {props.question.input_rule == "yesNo" ? (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flex: 1,
                                    padding: 5,
                                }}>

                                <Text>{props.question.question_text}</Text>
                                <Switch
                                    testID={props.question.input_rule}
                                    value={answer == "true"}
                                    color={"green"}
                                    onValueChange={() => {
                                        setAnswer((!(answer == "true")).toString());
                                        props.setAnswer((!(answer == "true")).toString());
                                    }}/>
                            </View>
                        ) : props.question.input_rule == "documentPicker" ? (
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flex: 1,
                                    padding: 5,
                                }}
                            >
                                <Button
                                    style={{ flex: 1 }}
                                    testID={props.question.input_rule}
                                    mode="outlined"
                                    onPress={() => {
                                        if (!props.question.options) {
                                        throw ReferenceError("Option must be set");
                                        }
                                        if (props.question.options[0] == "image") {
                                            ImagePicker.launchImageLibraryAsync({
                                                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                                                allowsEditing: true,
                                                quality: 1,
                                            }).then((result) => {
                                                // alert(props.question.options![1] + ".png")
                                                if (!result.cancelled) {
                                                setFeedback("Uploading...");
                                                uploadFile(
                                                    (getUser() || {}).uid || "",
                                                    result.uri,
                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                    props.question.options![1] + ".png"
                                                ).then((_result) => {
                                                    props.setAnswer("Picked");
                                                    setFeedback("Logo changed successfully");
                                                });
                                                }
                                            });
                                        } else {
                                            getDocumentAsync({ type: "image/*" }).then((result) => {
                                                if (result.type == "success")

                                                uploadFile(
                                                    (getUser() || {}).uid || "",
                                                    result.uri,
                                                    result.name
                                                );
                                            });
                                        }
                                    }}>
                                    {feedback != "" ? feedback : props.question.question_text}
                                </Button>
                            </View>
                        ) : props.question.input_rule == "limitedOptions" ||
                        props.question.input_rule == "listOfRooms" ||
                        props.question.input_rule == "listOfZones" ||
                        props.question.input_rule == "multipleOptions" ? (
                            <DropDown
                                testID={props.question.input_rule}
                                multiSelect={props.question.input_rule == "multipleOptions"}
                                label={props.question.question_text}
                                mode="outlined"
                                visible={showDropDown && !(isBaxi && props.isBaxiField)}
                                showDropDown={() => setShowDropDown(true)}
                                onDismiss={() => {
                                    setShowDropDown(false);
                                }}
                                value={answer}
                                setValue={(value) => {
                                    if (value == "AddRoom") {
                                        const valueStorageDocument = uuid.v1().toString();
                                        createDocument(roomPath, valueStorageDocument, {
                                        created: Date.now(),
                                        elements: [{ type: "floor" }, { type: "ceiling" }],
                                        accessRights: {
                                            read: thisProject.accessRights.read,
                                            write: thisProject.accessRights.write,
                                        },
                                        });
                                        value = valueStorageDocument;
                                    }
                                    // setAnswer(value as string);
                                    props.setAnswer(
                                        value as string
                                    ); /*setDisabled(value != "");*/
                                }}
                                list={dropDownList()}
                                textStyle={{
                                    backgroundColor: getTextInputBackgroundColor(props.question.question_text)
                                }}
                            />
                        ) : (
                            <View>
                                <TextInput
                                    testID={
                                        specialCasesMinMax.includes(props.question.variableName)
                                        ? props.question.variableName.split(".")[1]
                                        : props.question.input_rule
                                    }
                                    maxLength={
                                        props.question.input_rule == "epcNumber" ? 20 : undefined
                                    }
                                    autoComplete="off"
                                    dense={false}
                                    mode="outlined"
                                    label={props.question.question_text}
                                    right={
                                        props.question.affix && (
                                            <TextInput.Affix text={props.question.affix} />
                                        )
                                    }
                                    value={answer}
                                    error={hasError}
                                    onChangeText={(newAnswer) => setAnswer(newAnswer)}
                                    onFocus={
                                        isDesktop
                                        ? (e) => e.target.setAttribute("autocomplete", "off")
                                        : undefined
                                    }
                                    onBlur={() => {
                                        props.setAnswer(answer);
                                    }}
                                    style={{
                                        backgroundColor: hasError
                                            ? styleColors.lightRed
                                            : getTextInputBackgroundColor(props.question.question_text)
                                    }} />
                                {isDimensionZero(props.question) &&
                                    <Typography size={10} color={'red'} style={{ alignSelf: 'flex-end' }}>Please input a non-zero value</Typography>
                                }
                            </View>
                        )}
                    </View>
                    <View style={{ justifyContent: "center" }}>
                        <Hint text={props.question.hint} />
                    </View>
                </View>
            ) : (
                <View style={{ flex: 1 }}></View>
            )}
        </View>
    );
};
