import { useRoute } from '@react-navigation/native';
import { updateOrCreateDoc, createDocument, mergeDataWithDocument } from '../../firebaseConnection';
import { setFailedToGetMasterData } from '../../../redux/modules/masterData';
import { elementType, subElementType } from '../../types';
import { useAppSelector } from '../../../redux/hooks';
import { useAppDispatch } from '../../../redux/hooks';
import { getUser } from '../../firebaseConnection';
import { getTargetElement } from '../../masterData';
import { isUndefined } from 'lodash';
import { store } from '../../../redux/store';

interface routeParams {
    valueStoragePath: string,
    valueStorageDocument: string
}

const useSaveToFirebase = () => {

    const dispatch = useAppDispatch();
    const route = useRoute();
    const { params } = route;

    const {
        general,
        elementTypes,
        failedToGetMasterData
    } = useAppSelector(({ masterData }) => masterData);

    const saveToFirebase = async () => {
        const now = Date.now();
        const promises = [];
        const routeParams = params as routeParams;

        if (isUndefined(routeParams)) return;

        promises.push(updateOrCreateDoc(
            routeParams.valueStoragePath,
            routeParams.valueStorageDocument + '/roomsMasterData/general',
            {
                accessRights: { read: [getUser()?.uid], write: [getUser()?.uid] },
                ...store.getState().masterData.general
            }
        ));

        if (failedToGetMasterData) {
            for (const element of elementTypes) {
                const { elementObject } = getTargetElement(element as elementType | subElementType);

                promises.push(createDocument(
                    routeParams.valueStoragePath,
                    routeParams.valueStorageDocument + '/roomsMasterData/' + element,
                    {
                        accessRights: { read: [getUser()?.uid], write: [getUser()?.uid] },
                        ...elementObject,
                        created: now,
                        creationDate: new Date(now).toLocaleDateString(),
                        lastUpdated: now,
                    }
                ));
            }

            dispatch(setFailedToGetMasterData(false));
            await Promise.all(promises);

            return;
        }

        for (const element of elementTypes) {
            const { elementObject } = getTargetElement(element as elementType | subElementType);

            promises.push(mergeDataWithDocument(
                routeParams.valueStoragePath,
                routeParams.valueStorageDocument + '/roomsMasterData/' + element,
                {
                    ...elementObject,
                    lastUpdated: now,
                }
            ));
        }

        await Promise.all(promises);
    };

    return {
        saveToFirebase
    }
};

export default useSaveToFirebase;