import { StyleSheet } from 'react-native';
import { floorPlan } from '../../../styles/constants';
import { colors } from '../../../styles/colors';

const styles = StyleSheet.create({
    container: {
        width: floorPlan.imgBoxWidth,
        height: floorPlan.imgBoxHeight,
        borderWidth: 3,
        borderStyle: 'dashed',
        borderColor: colors.neutral300,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 28
    },
    iconWrapper: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: { marginBottom: 15 }
});

export default styles;