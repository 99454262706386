import { useContext } from 'react';
import { List, IconButton, useTheme } from 'react-native-paper';
import { useAppDispatch } from '../../../../redux/hooks';
import { setHighlightedTabsPerProject } from '../../../../redux/modules/roomByRoom';
import { desktopStylePressables, } from '../../../../styles/desktop';
import { Hoverable } from 'react-native-web-hover';
import { DesktopContext, ProjectDetailContext } from '../../../../util/contexts';
import { iconColor, titleStyle } from '../../../Flatlists';
import type { rooms, room_detail } from '../../../../util/types';
import navigationStyles from '../../../../styles/navigation';
import styles from './roomDataTitleStyles';

type RoomDataTitleProps = {
    highlightedStep: number;
    roomIndex: number;
    newRooms: rooms<'standard'>;
    room: room_detail<'standard'>;
    roomPath: string;
    setHighlightedStep: (highlightedStep: number) => void;
    setRoomIdToDelete: (roomId: string) => void;
    setAlertVisible: (visible: boolean) => void;
    navigate: any;
    roomsMasterData: any;
}

/**
* Description: Display room data title tab
* @param props subIndex, highlightedStep, roomIndex, element, newRooms, room, roomPath, setHighlightedStep, setRoomIdToDelete, setAlertVisible, navigate
* @returns ReactNode for clickable room data title (Room Data)
*/
const RoomDataTitle: React.FC<RoomDataTitleProps> = ({
    highlightedStep,
    roomIndex,
    newRooms,
    room,
    roomPath,
    setHighlightedStep,
    setRoomIdToDelete,
    setAlertVisible,
    navigate,
    roomsMasterData
}) => {
    const dispatch = useAppDispatch();
    const isDesktop = useContext(DesktopContext);
    const thisProject = useContext(ProjectDetailContext);
    const { colors } = useTheme();

    const elementInfo = room.general && room.general.everythingFilledOut == true ? room.heatLoss != undefined && room.heatLoss != null &&
        'Heat Loss: ' + Math.round(room.heatLoss) + 'W'
        : room.general && room.general.everythingFilledOut == false && 'Missing Information'

    const onDeleteElement = () => {
        setRoomIdToDelete(room.id);
        setAlertVisible(true);
    };

    const onClickDataTitle = () => {
        const navigationDetails = {
            displayedQuestionCollection: 'general',
            valueStoragePath: roomPath,
            valueStorageDocument: room.id,
            nestedPath: 'general',
            categoryOfAnswers: 'room',
            roomsMasterData: { roomsMasterData: roomsMasterData?.general }
        };

        setHighlightedStep && setHighlightedStep(roomIndex);
        navigate('Questions', navigationDetails);

        dispatch(setHighlightedTabsPerProject({
            projectId: thisProject.id,
            valuesToSave: {
                previousHighlightedStep: roomIndex,
                previousQuestionCollection: navigationDetails
            }
        }));
    };

    return (
        <Hoverable>
            {({ hovered }) => (
            <List.Item
                testID='room-by-room-step'
                key={'room-data'}
                title={'Room Data'}
                titleStyle={titleStyle(
                    isDesktop,
                    roomIndex == highlightedStep,
                    hovered,
                    room.general && room.general.everythingFilledOut,
                    colors
                )}
                style={[
                    navigationStyles.listItem,
                    isDesktop &&
                        desktopStylePressables(
                        {
                            pressed: roomIndex == highlightedStep,
                            hovered: hovered,
                        },
                        colors
                        ).secondMenuButtons,
                    ]}
                left={() => (
                    <List.Icon
                        icon='information-outline'
                        color={iconColor(
                            isDesktop,
                            roomIndex == highlightedStep,
                            hovered,
                            room.general && room.general.everythingFilledOut,
                            colors
                        )} />
                )}
                right={() =>
                    !newRooms && (
                        <IconButton
                            icon='delete'
                            style={styles.deleteBtn}
                            onPress={onDeleteElement} />
                    )
                }
                description={elementInfo}
                onPress={onClickDataTitle} />
            )}
        </Hoverable>
    );
};

export default RoomDataTitle;
