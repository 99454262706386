import { StyleSheet } from 'react-native';
import { mobileScreenWidth, screenHeight, screenWidth } from '../../../styles/constants';

const styles = StyleSheet.create({
    container: {
        height: screenHeight * 0.9
    },
    contentWrapper: {
        height: '100%',
        width: '100%'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    fileNameTextWrapper: { marginLeft: 40 },
    image: {
        height: '100%',
        width: '100%',
        flex: 1,
    },
    zoomWrapper: {
        flex: 1,
        backgroundColor: 'white'
    },
    instruction: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: screenWidth <= mobileScreenWidth ? 20 : 0
    }
});

export default styles;