import { useState, useContext } from 'react';
import { ListRenderItem, ScrollView } from 'react-native';
import { RoomsContext } from '../../../util/contexts';
import { FlatList } from 'react-native';
import { TooltipTableHeader, Typography } from '../../base';
import { DataTable } from 'react-native-paper';
import { room_detail, room_general } from '../../../util/types';
import { colors } from '../../../styles/colors';
import styles from './designSummaryStyles';

// eslint-disable-next-line @typescript-eslint/ban-types
type DesignSummaryProps = {}

const DesignSummary: React.FC<DesignSummaryProps> = () => {
    const rooms = useContext(RoomsContext) as room_detail<'standard'>[];

    const [isTooltipVisible, setIsTooltipVisible] = useState({
        header1: false,
        header2: false,
        header3: false,
        header4: false
    });

    const _renderRoom: ListRenderItem<room_detail<'standard'>> = ({
        item
    }) => {
        if (!item?.heatLoss || !item?.heatGeneration) return <></>;

        const heatLoss = item?.heatLoss || 1;
        const heatGeneration = item?.heatGeneration || 1;
        const percentage = (heatGeneration / heatLoss) * 100;

        return (
            <DataTable.Row>
                <DataTable.Cell>{(item?.general as room_general<'standard'>)?.customName}</DataTable.Cell>
                <DataTable.Cell>{heatLoss.toFixed(2)}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 2 }}>{heatGeneration.toFixed(2)}</DataTable.Cell>
                <DataTable.Cell style={{
                    flex: 1,
                    justifyContent: 'center',
                    backgroundColor: percentage >= 100 ?
                        colors.supportSuccessGreen :
                        colors.supportErrorRed }}>
                    <Typography color={percentage >= 100 ? colors.supportSuccessLightGreen : colors.supportErrorLightRed}>
                        {percentage.toFixed(2)}%
                    </Typography>
                </DataTable.Cell>
            </DataTable.Row>
        );
    };

    const updateTooltipVisibility = (key: 'header1' | 'header2' | 'header3' | 'header4', value: boolean) => {
        setIsTooltipVisible({
            ...isTooltipVisible,
            [key]: value
        });
    };

    return (
        <ScrollView>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title>
                        <Typography size={12} color={colors.neutral500}>Room name</Typography>
                    </DataTable.Title>
                    <DataTable.Title
                        onPress={() => updateTooltipVisibility('header2', true)}
                        style={styles.header}>
                        <TooltipTableHeader
                            text='Total Heat Loss in Watts [W]'
                            isTooltipVisible={isTooltipVisible['header2']}
                            onClose={() => updateTooltipVisibility('header2', false)} />
                    </DataTable.Title>
                    <DataTable.Title
                        onPress={() => updateTooltipVisibility('header3', true)}
                        style={[styles.header, { flex: 2 }]}>
                        <TooltipTableHeader
                            text='Total Heat Generated in Watts [W]'
                            isTooltipVisible={isTooltipVisible['header3']}
                            onClose={() => updateTooltipVisibility('header3', false)} />
                    </DataTable.Title>
                    <DataTable.Title
                        onPress={() => updateTooltipVisibility('header4', true)}
                        style={styles.header}>
                        <TooltipTableHeader
                            text='% of Required Output met by New Radiators'
                            isTooltipVisible={isTooltipVisible['header4']}
                            onClose={() => updateTooltipVisibility('header4', false)} />
                    </DataTable.Title>
                </DataTable.Header>

                <FlatList
                    renderItem={_renderRoom}
                    data={rooms as []}
                    onEndReachedThreshold={10}
                    initialNumToRender={10} />
            </DataTable>
        </ScrollView>
    );
};

export default DesignSummary;
