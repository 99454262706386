import React, { useState } from "react";
import { Button, HelperText, TextInput, Headline } from "react-native-paper";
import { View } from "react-native";
import { resetPassword } from "../../util/firebaseConnection";
import basicStyles from "../../styles/basic";

export const PasswordReset = () => {
  const [password, setPassword] = useState<string>("");

  const handleResetPassword = () => {
    password.length >= 6 && //todo: implement a proper password checker
      resetPassword(password, true);
  };

  return (
    //might need to add styles container around the outer View
    <View style={[basicStyles.container, { margin: 5 }]}>
      <View style={{ margin: 5 }}>
        <Headline style={{ fontSize: 20 }}>Set a secure password.</Headline>
      </View>
      <TextInput
        style={{ width: 200, marginLeft: 5 }}
        mode="outlined"
        label={"set password"}
        secureTextEntry={true}
        value={password}
        onChangeText={(text) => setPassword(text)}
        error={password.length > 0 && password.length < 6}
      />
      <HelperText
        style={{ fontSize: 14 }}
        type="error"
        visible={password.length > 0 && password.length < 6}
      >
        weak password
      </HelperText>
      <Button
        mode="contained"
        onPress={() => {
          handleResetPassword();
        }}
        style={{ width: 100, marginLeft: 5 }}
      >
        reset
      </Button>
    </View>
  );
};
