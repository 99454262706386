import { NavigationContainer } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { LogBox, View, Text } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { Entry } from "./src/screens/Entry/AppEntry";
import basicStyles from "./src/styles/basic";
import { samsungTheme, carnoTheme } from "./src/styles/theme";
import { enableOffline } from "./src/util/firebaseConnection";
import * as Sentry from "sentry-expo";
import { createStackNavigator } from "@react-navigation/stack";
import { LoginScreen } from "./src/screens/Entry/LoginScreen";
import { useFonts } from "expo-font";
import { Provider } from 'react-redux';
import { store, persistor } from "./src/redux/store";
import { PersistGate } from "redux-persist/integration/react";

export const theme = "Carno" as string;

const Stack = createStackNavigator();

Sentry.init({
  dsn: "https://d59e45d2de3e40aab210d3b8d9129aa9@o1132347.ingest.sentry.io/6177851",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  debug: true,
  enableInExpoDevelopment: true,
});

/**
 * Description: hide warning messages
 */
LogBox &&
  LogBox.ignoreLogs([
    "Setting a timer",
    /.*triggering instrumentation.*/g, //otherwise throws logger error on mobile. Refer to https://github.com/getsentry/sentry-javascript/issues/2809
    /.*cannot serialize.*/g,
  ]);

/**
 * Description: top level parent component, sets global theme, manages linking, and handles logic for rendering AppEntry
 */
export default function App() {
    const [loading, setLoading] = useState(true);
    const [fontsLoaded] = useFonts({
        'Roboto': require("./assets/fonts/Roboto-Regular.ttf"),
        'RobotoBlack': require("./assets/fonts/Roboto-Black.ttf"),
        'RobotoBlackItalic': require("./assets/fonts/Roboto-BlackItalic.ttf"),
        'RobotoBold': require("./assets/fonts/Roboto-Bold.ttf"),
        'RobotoBoldItalic': require("./assets/fonts/Roboto-BoldItalic.ttf"),
        'RobotoLight': require("./assets/fonts/Roboto-Light.ttf"),
        'RobotoLightItalic': require("./assets/fonts/Roboto-LightItalic.ttf"),
        'RobotoMedium': require("./assets/fonts/Roboto-Medium.ttf"),
        'RobotoMediumItalic': require("./assets/fonts/Roboto-MediumItalic.ttf"),
        'RobotoRegular': require("./assets/fonts/Roboto-Regular.ttf"),
        'RobotoThin': require("./assets/fonts/Roboto-Thin.ttf"),
        'RobotoThinItalic': require("./assets/fonts/Roboto-ThinItalic.ttf")
    });

    useEffect(() => {
        enableOffline().then(() => {
            setLoading(false);
        });
    }, []);

    const linking = {
        prefixes: [
            "http://localhost:19006/",
            "https://app.carno.io/",
            "https://*.vercel.app",
        ],
        config: {
            screens: {
                InviteEntry: {
                path: "Signup/:email/",
                parse: {
                    email: (email: string) => email.split("=")[1],
                },
                },
                Entry: "*",
            },
        },
    };

    if (!fontsLoaded) return <></>;

    if (!loading) {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <PaperProvider theme={theme === "Samsung" ? samsungTheme : carnoTheme}>
                        <View style={basicStyles.container}>
                            <NavigationContainer
                                linking={linking}
                                fallback={<Text>Loading...</Text>}
                                theme={theme === "Samsung" ? samsungTheme : carnoTheme}>

                                <Stack.Navigator
                                    screenOptions={{
                                        headerShown: false,
                                    }}>
                                    <Stack.Screen name="Entry" component={Entry} />
                                    <Stack.Screen name="InviteEntry" component={LoginScreen} />
                                </Stack.Navigator>
                            </NavigationContainer>
                        </View>
                    </PaperProvider>
                </PersistGate>
            </Provider>
        );
    } else return null;
}
