import { DocumentData } from "firebase/firestore";
import { getGlobal } from "./asyncStorageConnection";
import {
  existingRadiator,
  itemType,
  question_detail,
  roomAirChanges,
  roomDesignTemp,
  section,
  flueAirChanges,
  input_rule_type,
  product,
} from "./types";
import { get as objGet } from "lodash";
import uuid from "react-native-uuid";
/*export const consistentUvalue = (answers: DocumentData, variableName: string, answer: string) => {
    let newAnswers = answers
    if (variableName.split(".").at(-1) == "elementFabric") {
        let UvalueName = variableName.replace(/(.*\.)(.*)$/g, "$1Uvalue") //replaces elementFabric with Uvalue
        const Uvalue = answer.split("(")[1]?.split(")")[0]
        if (Uvalue && Uvalue != "")
            newAnswers = objSet(newAnswers, UvalueName, Uvalue)
    }
    return newAnswers
}*/

/**
 * Description: find index of a specific question from a list of items
 * @return index of question (number)
 */
export function findQuestionIndex(
  items: itemType[],
  searchVariable?: string,
  continueIfNotFound?: boolean
): number {
  const questionIndex = items.findIndex(
    (item) =>
      item.type == "question_detail" &&
      (item.content as question_detail).variableName.split(".").at(-1) ==
        searchVariable
  );
  if (!continueIfNotFound && questionIndex == -1) {
    throw ReferenceError("Can't find expected question.");
  }
  return questionIndex;
}
const answerToString = (answer: unknown, input_rule: input_rule_type) => {
  return answer && answer != ""
    ? input_rule === "onlyNumber" || input_rule === "minMax"
      ? (answer as number).toString()
      : input_rule === "yesNo"
      ? (answer as boolean).toString()
      : (answer as string)
    : input_rule === "yesNo"
    ? "false"
    : "";
};
export function prepareItems(
  questions: question_detail[],
  variablePath: string,
  answers?: DocumentData
) {
  const initialItems = [] as itemType[]; // = questions.map(questions => { return { content: questions, type: "question_detail" } as itemType })
  questions.forEach((question) => {
    let initialItem = {} as itemType;
    const variableName = variablePath + question.variableName;
    const answer = objGet(answers, variableName, "");
    initialItem.content = {
      ...question,
      variableName: variableName,
      answer: question?.default && answer === "" ? question.default : answerToString(answer, question.input_rule),
    };
    initialItem.type = "question_detail";
    initialItem.key = uuid.v1().toString();
    initialItem = addConditionalFunction(initialItem, variablePath, answers);
    initialItems.push(initialItem);
  });
  return initialItems.slice();
}

function addConditionalFunction(
    initialItem: itemType,
    nestedPath: string | undefined,
    answers?: DocumentData
) {
    async function loadNewQuestions(
        items: itemType[],
        index: number,
        answer?: string,
        prevAnswer?: string
    ) {
        if (nestedPath == undefined) {
            throw ReferenceError("Nested Path not set.");
        }
    
        if (prevAnswer != answer) {
            const prevQuestions = (await getGlobal(prevAnswer)) as question_detail[];
            const prevQuestionLength = (prevQuestions && prevQuestions.length) || 0;
            const newQuestions = (await getGlobal(answer)) as question_detail[];

            if (newQuestions && newQuestions.length > 0 && prevQuestionLength == 0) {
                items.splice(
                    index + 1,
                    prevQuestionLength,
                    ...prepareItems(newQuestions, nestedPath, answers)
                );
            } else if (newQuestions && newQuestions.length > 0 && answer != prevAnswer) {
                items.splice(
                    index + 1,
                    prevQuestionLength,
                    ...prepareItems(newQuestions, nestedPath, answers) //before:answers was set to undefined?
                );
            }
                
            else items.splice(index + 1, prevQuestionLength);
        }

        return items.slice(); //ensure that we are returning a copy of the array
    }

    /**
     * Description: Adds a heading for a specific subelement (e.g. windows, doors), if answer==answerWhereSubelementsShown.
     * All existing subelements are pushed to the end of items and for each subelement the according questions are shown.
     * Also, this function takes care of clean up when the answer is changed.
     *
     * If pathToSubelements is set, the results are assumed to be read-only and the existing subelements are looked up under pathToSubelemens.
     * @param items
     * @param answer
     * @param prevAnswer
     * @param answerWhereSubelementsShown
     * @param question_collection
     * @param label
     * @param pathToSubelements
     * @returns
     */
    async function addSubelements(
        items: itemType[],
        answer: string | undefined,
        prevAnswer: string | undefined,
        answerWhereSubelementsShown: string,
        question_collection: string,
        label: string,
        pathToSubelements?: string
    ) {
        if (prevAnswer != answer) {
            const subelementsQuestion = (await getGlobal(question_collection)) as question_detail[];
            const subelements = objGet(answers, pathToSubelements ? pathToSubelements : nestedPath + question_collection) as object[];

            if (answer == answerWhereSubelementsShown) {
                items.push({
                    key: uuid.v1().toString(),
                    content: {
                        text: label.charAt(0).toUpperCase() + label.slice(1) + "s", // marker: Heating Zones title under Room By Room General Info
                        typography: "headline",
                        answer: pathToSubelements ? undefined : "plus-circle-outline",
                    },
                    type: "section",
                    conditionalFunction: (props: { items: itemType[]; index: number }) =>
                        subelementsFunction(
                            props.items,
                            props.index,
                            subelementsQuestion,
                            question_collection,
                            label
                        ),
                });

                subelements?.forEach((subelement, index) => {
                    items.push({
                        key: uuid.v1().toString(),
                        content: {
                            text:
                                label.charAt(0).toUpperCase() +
                                label.slice(1) +
                                " " +
                                (index + 1),
                            typography: "subheading",
                            answer: pathToSubelements ? undefined : "delete",
                        },
                        type: "section",
                        conditionalFunction: (props: {
                            items: itemType[];
                            index: number;
                        }) => subelementFunction(
                            props.items,
                            props.index,
                            subelementsQuestion.length,
                            question_collection,
                            label
                        ),
                    });

                    items.push(
                        ...prepareItems(
                            subelementsQuestion,
                            nestedPath + question_collection + "[" + index + "].",
                            answers
                        )
                    );
                });
            } else if (prevAnswer == answerWhereSubelementsShown) {
                let index = 0; //manually keep track of it to deal with deletion etc
                const itemCopy = items.slice();

                itemCopy.forEach((item) => {
                    if (
                        item.type == "section" &&
                        RegExp(label.charAt(0).toUpperCase() + label.slice(1) + " ").test((item.content as section).text)
                    ) {
                        items.splice(index, subelementsQuestion ? subelementsQuestion.length + 1 : 0);
                        index -= subelementsQuestion ? subelementsQuestion.length + 1 : 0;
                    }
                    if (
                        item.type == "section" &&
                        RegExp(label.charAt(0).toUpperCase() + label.slice(1) + "s").test((item.content as section).text)
                    ) {
                        items.splice(index, 1);
                        index -= 1;
                    }

                    index += 1;
                });

                items = items.slice();
            }
        }

        return items;
    }

    async function subelementsFunction(
        items: itemType[],
        index: number,
        questions: question_detail[],
        question_collection: string,
        label: string
    ) {
        let numberOfSubelements = 0;

        items.slice(index + 1).forEach((item) => {
            if (item.type == "section" && RegExp(label.charAt(0).toUpperCase() + label.slice(1) + " ").test((item.content as section).text)) {
                numberOfSubelements += 1;
            }
        });

        const newItems = [
            {
                content: {
                text:
                    label.charAt(0).toUpperCase() +
                    label.slice(1) +
                    " " +
                    (numberOfSubelements + 1),
                typography: "subheading",
                answer: "delete",
                },
                type: "section",
                conditionalFunction: (props: { items: itemType[]; index: number }) =>
                subelementFunction(
                    props.items,
                    props.index,
                    questions.length,
                    question_collection,
                    label
                ),
            },
        ] as itemType[];

        newItems.push(
            ...prepareItems(
                questions,
                nestedPath + question_collection + "[" + numberOfSubelements + "]."
            )
        );

        items.splice(
            index + 1 + numberOfSubelements * (questions.length + 1),
            0,
            ...newItems
        );

        return items.slice(); //ensure that we are returning a copy of the array
    }

    async function subelementFunction(
        items: itemType[],
        index: number,
        numQuestions: number,
        question_collection: string,
        label: string
    ) {
        items.splice(index, numQuestions + 1); // marker: deleting a heating zone

        let itemsAfterwards = items.slice(index);

        itemsAfterwards = itemsAfterwards.map((itemAfterwards) => {
            if (itemAfterwards.type == "question_detail") {
                const newVariableName = (itemAfterwards.content as question_detail).variableName.replace(
                    new RegExp(question_collection + "\\[(.*)\\]"),
                    (_match, number) =>
                        question_collection + "[" + (parseInt(number) - 1) + "]"
                );
                return {
                ...itemAfterwards,
                content: { ...itemAfterwards.content, variableName: newVariableName },
                };
            } else {
                const newText = (itemAfterwards.content as section).text.replace(
                    new RegExp(
                        label.charAt(0).toUpperCase() + label.slice(1) + " ([0-9]+)$"
                    ),
                    (_match, number) =>
                        label.charAt(0).toUpperCase() +
                        label.slice(1) +
                        " " +
                        (parseInt(number) - 1)
                );

                return {
                    ...itemAfterwards,
                    content: { ...itemAfterwards.content, text: newText },
                };
            }
        });

        items.splice(index, items.length - index, ...itemsAfterwards);

        return items.slice(); //ensure that we are returning a copy of the array
    }

  if (initialItem.type == "question_detail") {
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "wallType"
    ) {
      //assumes that we got answers!
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        let items = props.items;
        items = await addSubelements(
          items,
          props.answer,
          props.prevAnswer,
          "External Wall",
          "windows",
          "window"
        );
        items = await addSubelements(
          items,
          props.answer,
          props.prevAnswer,
          "External Wall",
          "doors",
          "door"
        );
        items = await loadNewQuestions(
          items,
          props.index,
          props.answer,
          props.prevAnswer
        );
        return items.slice(); //ensure that we are returning a copy of the array
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "floorType"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer,
            props.prevAnswer
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "zones"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        let items = props.items;
        items = await addSubelements(
          items,
          props.answer,
          props.prevAnswer,
          "",
          "heatingZones",
          "heating zone"
        );
        return items.slice(); //ensure that we are returning a copy of the array
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "zones_lwt"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        let items = props.items;
        items = await addSubelements(
          items,
          props.answer,
          props.prevAnswer,
          "",
          "heatingZones_lwt",
          "heating zone",
          "heatingZones.heatingZones"
        );
        return items.slice(); //ensure that we are returning a copy of the array
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "ceilingType"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        let items = props.items;
        items = await addSubelements(
          items,
          props.answer,
          props.prevAnswer,
          "Roof",
          "roofLights",
          "roof light"
        );
        return (
          await loadNewQuestions(
            items,
            props.index,
            props.answer,
            props.prevAnswer
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "Uvalue"
    ) {
      //doesnt need answers
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        if (!props.answer) {
          throw ReferenceError("Answer must be provided to get U value.");
        }
        if (props.prevAnswer || props.prevAnswer == "")
          (props.items[props.index + 1].content as question_detail).answer =
            props.answer.split("(")[1]?.split(")")[0];
        return props.items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "availableDims" //do this
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
      }) => {

        if (!answers || !nestedPath) {
          throw ReferenceError("For availableDims, answers has to be set");
        }

        const isNewlyAddedRadiator = nestedPath.includes('addedRadiators');
        let items = props.items;
        let text = '';

        if (!isNewlyAddedRadiator) {
            const existingRadiatorsPath =
                'existingRadiators[' +
                    nestedPath.slice(nestedPath.length - 3, nestedPath.length - 2) +
                '].';

            const existingRadiator = objGet(
                    answers,
                    existingRadiatorsPath.slice(
                        undefined,
                        existingRadiatorsPath.length - 1
                    )
                ) as existingRadiator<'standard'>;

            
            if (existingRadiator.everythingFilledOut) {
                text +=
                  'Existing radiator (LxH): ' +
                  existingRadiator.length +
                  'x' +
                  existingRadiator.height +
                  ' (' +
                  existingRadiator.radiatorType +
                  ')';
                text +=
                  '\nAvailable dimensions (LxHxD): ' +
                  existingRadiator.availableLength +
                  'x' +
                  existingRadiator.availableHeight +
                  'x' +
                  existingRadiator.availableDepth;
                if (existingRadiator.doNotReplace == true) {
                    text += '\nThis radiator is supposed to not be replaced.';
                } else {
                    items = await loadNewQuestions(
                    props.items,
                    props.index,
                    'maybeReplaceRadiator',
                    ''
                    );
                    items =
                    (items.at(-1) &&
                        items.at(-1)?.conditionalFunction &&
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        (await items.at(-1)?.conditionalFunction!({
                        items: items,
                        index: items.length,
                        answer:
                            answerToString(
                            objGet(
                                answers,
                                (items.at(-1)?.content as question_detail).variableName
                            ),
                            (items.at(-1)?.content as question_detail).input_rule
                            ) == 'true'
                            ? 'true'
                            : 'false',
                        prevAnswer: '',
                        productFilters: props.productFilters,
                        setProductFilters: props.setProductFilters,
                        }))) ||
                    items;
                }
            } else {
                text +=
                    'Please fill out all information about the existing radiator first.';
            }
        } else {
            text += 'New radiator\n';
            items = await loadNewQuestions(
                props.items,
                props.index,
                'maybeReplaceRadiator',
                ''
            );

            items =
                (items.at(-1) &&
                    items.at(-1)?.conditionalFunction &&
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    (await items.at(-1)?.conditionalFunction!({
                    items: items,
                    index: items.length,
                    answer:
                        answerToString(
                        objGet(
                            answers,
                            (items.at(-1)?.content as question_detail).variableName
                        ),
                        (items.at(-1)?.content as question_detail).input_rule
                        ) == 'true'
                        ? 'true'
                        : 'false',
                    prevAnswer: '',
                    productFilters: props.productFilters,
                    setProductFilters: props.setProductFilters,
                    }))) ||
                items;
        }

        const startItem = {
          type: 'section',
          key: uuid.v1().toString(),
          content: { typography: 'text', text: text },
        } as itemType;

        items.unshift(startItem);

        return items.slice();
      };
    }
    //======== conditional function for air changes based on volume & build year =======
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "flueAirChanges"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        const chimneyOrFlue = (
          props.items[findQuestionIndex(props.items, "openFire")]
            .content as question_detail
        ).answer;
        const dimensionVariableNames = [
          "general.roomLength",
          "general.roomWidth",
          "general.roomHeight",
        ];
        const dimensionsArray = props.items
          .filter(
            (item) =>
              item.type === "question_detail" &&
              dimensionVariableNames.includes(
                (item.content as question_detail).variableName
              ) &&
              !(item.content as question_detail).inputRuleError &&
              !(item.content as question_detail).answer !== undefined
          )
          .map((item) => (item.content as question_detail).answer);
        if (
          chimneyOrFlue &&
          dimensionsArray &&
          chimneyOrFlue !== "Not Present"
        ) {
          if (dimensionsArray[0] && dimensionsArray[1] && dimensionsArray[2]) {
            const roomVolume =
              parseFloat(dimensionsArray[0]) *
              parseFloat(dimensionsArray[1]) *
              parseFloat(dimensionsArray[2]);

            if (roomVolume <= 70) {
              const flueAirChangeData = (await getGlobal(
                "flueAirChanges"
              )) as flueAirChanges[];
              (
                props.items[findQuestionIndex(props.items, "airChanges")]
                  .content as question_detail
              ).answer = flueAirChangeData.find(
                (data) =>
                  data.throatRestrictor === chimneyOrFlue &&
                  roomVolume < parseFloat(data.roomVolume)
              )?.airChanges;
            }
          }
        }
        return props.items.slice();
      };
    }
    const selectionFilters = async (
      props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
      },
      listOf: string,
      filtersToLoad: string,
      filtersBefore?: string
    ) => {
      let items = props.items;
      let unitIndex = findQuestionIndex(items, "ean", true);
      let unitCurrentAnswer = undefined as string | undefined;
      if (unitIndex > -1) {
        unitCurrentAnswer = (items[unitIndex].content as question_detail)
          .answer;
        items = items.filter(
          (item) =>
            item.type != "question_detail" ||
            (item.content as question_detail).conditionalFunctionType != listOf
        );
      }
      items = await loadNewQuestions(
        items,
        props.index,
        filtersToLoad,
        filtersBefore || ""
      );
      unitIndex = findQuestionIndex(items, "ean", true);
      if (unitIndex > -1 && unitCurrentAnswer != undefined) {
        (items[unitIndex].content as question_detail).answer =
          unitCurrentAnswer;
      }
      if (
        unitIndex > -1 &&
        (items[unitIndex].content as question_detail).answer &&
        (items[unitIndex].content as question_detail).answer != ""
      ) {
        items = //execute removal of filters if unit is selected
          (items.at(unitIndex) &&
            items.at(unitIndex)?.conditionalFunction &&
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (await items.at(unitIndex)?.conditionalFunction!({
              items: items,
              index: items.length,
              answer: (items[unitIndex].content as question_detail).answer,
              prevAnswer: (items[unitIndex].content as question_detail).answer,
              productFilters: props.productFilters,
              setProductFilters: props.setProductFilters,
            }))) ||
          items;
      }
      if (props.answer != "true") {
        if (!props.setProductFilters) {
          throw ReferenceError(
            "setProductFilters function has to be provided."
          );
        }
        props.setProductFilters([]);
        items = items.filter((item) => item.type != "product");
      }
      return items.slice();
    };
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "replaceRadiator"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
      }) => {
        return selectionFilters(
          props,
          "listOfRadiators",
          props.answer == "true" ? "replaceRadiator" : "",
          props.prevAnswer == "true" ? "replaceRadiator" : ""
        );
      };
    }
    if (
        (initialItem.content as question_detail).conditionalFunctionType ==
        "filterRadiators"
    ) {
        initialItem.conditionalFunction = async (props: {
            items: itemType[];
            index: number;
            answer?: string;
            prevAnswer?: string;
            setProductFilters?: (data: string[][]) => void;
        }) => {
            if (!props.setProductFilters) {
                throw ReferenceError(
                    "setProductFilters function has to be provided."
                );
            }

            const items = props.items;
            const tmpProductFilters = [] as string[][];

            tmpProductFilters.push(["type", "==", "radiatorData"]);
            let questionIndex = findQuestionIndex(items, "lengthCat", false);

            (items[questionIndex].content as question_detail).answer &&
                (items[questionIndex].content as question_detail).answer != "" &&
                    tmpProductFilters.push([
                        "lengthCat",
                        "==",
                        (items[questionIndex].content as question_detail).answer || "",
                    ]);

            questionIndex = findQuestionIndex(items, "heightCat", false);

            (items[questionIndex].content as question_detail).answer &&
                (items[questionIndex].content as question_detail).answer != "" &&
                    tmpProductFilters.push([
                        "heightCat",
                        "==",
                        (items[questionIndex].content as question_detail).answer || "",
                    ]);

            questionIndex = findQuestionIndex(items, "radiatorType", false);

            (items[questionIndex].content as question_detail).answer &&
                (items[questionIndex].content as question_detail).answer != "" &&
                    tmpProductFilters.push([
                        "radiatorType",
                        "==",
                        (items[questionIndex].content as question_detail).answer || "",
                    ]);

            //todo: does this work with multiple data sources??
            props.setProductFilters(tmpProductFilters);

            return props.items;
        };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "loadRadiatorData"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        const items = props.items;
        const possibleRadiatorSizesDocs = await getGlobal(
          "possibleRadiatorSizes"
        );
        const possibleRadiatorSizes = possibleRadiatorSizesDocs[0]
          .availableRadiators as {
          availableLengths: string[];
          height: string[];
          outputPerMeter: string[];
          type: string;
        }[];
        let questionIndex = findQuestionIndex(items, "radiatorType", false);
        (items[questionIndex].content as question_detail).options =
          possibleRadiatorSizes.map(
            (possibleRadiator) => possibleRadiator.type
          );
        questionIndex = findQuestionIndex(items, "length", false);
        (items[questionIndex].content as question_detail).options =
          possibleRadiatorSizes[0].availableLengths;
        questionIndex = findQuestionIndex(items, "height", false);
        (items[questionIndex].content as question_detail).options =
          possibleRadiatorSizes[0].height;
        return items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "listOfRadiators"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        setProductFilters?: (data: string[][]) => void;
      }) => {
        let items = props.items;
        if (!props.setProductFilters) {
          throw ReferenceError(
            "setProductFilters function has to be provided."
          );
        }
        if (props.answer && props.answer != "") {
          items = items.filter(
            (item) =>
              ((item.type != "question_detail" ||
                (item.content as question_detail).conditionalFunctionType ==
                  "replaceRadiator" ||
                (item.content as question_detail).conditionalFunctionType ==
                  "listOfRadiators") &&
                item.type != "product") ||
              (item.content as product).ean == props.answer
          );
        } else {
          const replaceIndex = findQuestionIndex(items, "replace", false);

          items = // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (await items[replaceIndex].conditionalFunction!({
              items: items,
              index: replaceIndex,
              answer: (items[replaceIndex].content as question_detail).answer,
              prevAnswer: "",
              setProductFilters: props.setProductFilters,
            })) || items;
        }
        return items.slice();
      };
    }
    //=========== conditional function for room design temp ============
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "airChangesAndDesignTemp"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        const roomType = (
          props.items[findQuestionIndex(props.items, "roomType")]
            .content as question_detail
        ).answer;
        const constructionDate = (
          props.items[findQuestionIndex(props.items, "constructionDate")]
            .content as question_detail
        ).answer;

        const buildingCategory =
          constructionDate === "Pre 2000"
            ? "catA"
            : constructionDate === "2000-2005"
            ? "catB"
            : "catC";
        if (constructionDate && roomType) {
          if (
            ["Not Present", "", undefined].includes(
              (
                props.items[findQuestionIndex(props.items, "openFire")]
                  .content as question_detail
              ).answer
            )
          ) {
            const airChangeData = (await getGlobal(
              "roomAirChanges"
            )) as roomAirChanges[];
            (
              props.items[findQuestionIndex(props.items, "airChanges")]
                .content as question_detail
            ).answer = airChangeData.filter(
              (room) => room.room === roomType
            )[0][buildingCategory as keyof roomAirChanges];
          }
          const designTempData = (await getGlobal(
            "roomDesignTemp"
          )) as roomDesignTemp[];
          (
            props.items[findQuestionIndex(props.items, "designTemperature")]
              .content as question_detail
          ).answer =
            constructionDate === "2006-Present" &&
            roomType !== "Bathroom" &&
            roomType !== "Store" &&
            roomType !== "Conservatory"
              ? "21"
              : designTempData.find((roomData) => roomData.room === roomType)
                  ?.designTemp;
        }
        return props.items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "heatPumpSelectionFilters"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        setProductFilters?: (data: string[][]) => void;
      }) => {
        if (
          //doesnt work!!!
          answers &&
          !(objGet(answers, "general.everythingFilledOut") == true)
        ) {
          return [
            {
              type: "section",
              key: uuid.v1().toString(),
              content: {
                typography: "subheading",
                text: "Please fill out the previous sections first",
              },
            },
          ] as itemType[];
        }
        return selectionFilters(
          props,
          "listOfHeatPumps",
          "heatPumpSelectionFilters"
        );
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "filterHP"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        setProductFilters?: (data: string[][]) => void;
      }) => {
        if (!props.setProductFilters) {
          throw ReferenceError(
            "setProductFilters function has to be provided."
          );
        }
        const items = props.items;
        const tmpProductFilters = [] as string[][];
        tmpProductFilters.push(["type", "==", "heatPumpData"]);
        const questionIndex = findQuestionIndex(items, "manufacturer", false);
        (items[questionIndex].content as question_detail).answer &&
          (items[questionIndex].content as question_detail).answer != "" &&
          tmpProductFilters.push([
            "manufacturer",
            "==",
            (items[questionIndex].content as question_detail).answer || "",
          ]);
        //NOTE: when product filter option is clicked, goes here
        props.setProductFilters(tmpProductFilters);
        return props.items;
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "listOfHeatPumps"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        setProductFilters?: (data: string[][]) => void;
      }) => {
        let items = props.items;
        if (!props.setProductFilters) {
          throw ReferenceError(
            "setProductFilters function has to be provided."
          );
        }
        if (props.answer && props.answer != "") {
          items = items.filter(
            (item) =>
              ((item.type != "question_detail" ||
                (item.content as question_detail).conditionalFunctionType ==
                  "listOfHeatPumps") &&
                item.type != "product") ||
              (item.content as product).ean == props.answer
          );
        } else {
          items = // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (await selectionFilters(
              {
                items: items,
                index: 0,
                answer: "",
                prevAnswer: "",
                setProductFilters: props.setProductFilters,
              },
              "listOfHeatPumps",
              "heatPumpSelectionFilters"
            )) || items;
        }
        
        return items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "cylinderSelectionFilters"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
        extraData?: string | string[];
      }) => {
        if (
          !(objGet(answers, "general.everythingFilledOut") == true) ||
          (props.extraData != "first_quote" &&
            !(objGet(answers, "heatPump.everythingFilledOut") == true)) ||
          (props.extraData == "first_quote" &&
            !(
              objGet(answers, "firstQuoteHeatPump.everythingFilledOut") == true
            ))
        ) {
          return [
            {
              type: "section",
              key: uuid.v1().toString(),
              content: {
                typography: "subheading",
                text: "Please fill out the previous sections first",
              },
            },
          ] as itemType[];
        }
        const items = await selectionFilters(
          props,
          "listOfCylinders",
          "cylinderSelectionFilters"
        );
        const unitIndex = findQuestionIndex(items, "ean", false);
        if (
          !(items[unitIndex].content as question_detail).options ||
          (items[unitIndex].content as question_detail).options?.length == 0
        ) {
          if (props.extraData != "first_quote") {
            (items[unitIndex].content as question_detail).options = [
              objGet(answers, "heatPump.ean"),
            ];
          } else {
            (items[unitIndex].content as question_detail).options = [
              objGet(answers, "firstQuoteHeatPump.ean"),
            ];
          }
        }
        return items;
      };
    }
    /*if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "filterCylinder"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
      }) => {
        if (
          !props.setProductFilters ||
          !props.productFilters ||
          !props.productFilters[0]
        ) {
          throw ReferenceError("product filters function have to be provided.");
        }
        const items = props.items;
        const tmpProductFilters = [] as string[][];
        tmpProductFilters.push(props.productFilters[0]);
        const questionIndex = findQuestionIndex(items, "cylinderType", false);
        (items[questionIndex].content as question_detail).answer &&
          (items[questionIndex].content as question_detail).answer != "" &&
          tmpProductFilters.push([
            "cylinderType",
            "==",
            (items[questionIndex].content as question_detail).answer || "",
          ]);
        props.setProductFilters(tmpProductFilters);
        return props.items;
      };
    }*/
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "listOfCylinders"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
        productFilters?: string[][];
        setProductFilters?: (data: string[][]) => void;
        extraData?: string | string[];
      }) => {
        let items = props.items;
        if (!props.setProductFilters) {
          throw ReferenceError(
            "setProductFilters function has to be provided."
          );
        }
        if (props.answer && props.answer != "") {
          items = items.filter(
            (item) =>
              ((item.type != "question_detail" ||
                (item.content as question_detail).conditionalFunctionType ==
                  "listOfCylinders") &&
                item.type != "product") ||
              (item.content as product).ean == props.answer
          );
        } else {
          items = // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (await selectionFilters(
              {
                items: items,
                index: 0,
                answer: "",
                prevAnswer: "",
                productFilters: props.productFilters,
                setProductFilters: props.setProductFilters,
              },
              "listOfCylinders",
              "cylinderSelectionFilters"
            )) || items;
          const unitIndex = findQuestionIndex(items, "ean", false);
          if (
            !(items[unitIndex].content as question_detail).options ||
            (items[unitIndex].content as question_detail).options?.length == 0
          ) {
            if (props.extraData != "first_quote") {
              (items[unitIndex].content as question_detail).options = [
                objGet(answers, "heatPump.ean"),
              ];
            } else {
              (items[unitIndex].content as question_detail).options = [
                objGet(answers, "firstQuoteHeatPump.ean"),
              ];
            }
          }
        }
        return items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "usageKnown"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        let items = props.items;
        let extensionIndex = findQuestionIndex(items, "extension", true);
        let extension = "" as string | undefined;
        if (extensionIndex > -1) {
          extension = (items[extensionIndex].content as question_detail).answer;
        }
        if (extensionIndex == -1 && props.answer == "false") {
          extensionIndex = items.length - 1;
          extension = answerToString(
            objGet(answers, nestedPath + "extension"),
            "yesNo"
          );
        }
        if (extensionIndex > -1) {
          items = await loadNewQuestions(
            props.items,
            extensionIndex,
            props.answer == "false" && extension == "true"
              ? "first-quote-extension"
              : "",
            props.prevAnswer == "false" && extension == "true"
              ? "first-quote-extension"
              : ""
          );
        }
        items = await loadNewQuestions(
          props.items,
          props.index,
          props.answer == "true"
            ? "first-quote-energyUsage"
            : props.answer == "false"
            ? "first-quote-unknownEnergyUsage"
            : "",
          props.prevAnswer == "true"
            ? "first-quote-energyUsage"
            : props.prevAnswer == "false"
            ? "first-quote-unknownEnergyUsage"
            : ""
        );
        return items.slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "extension"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "first-quote-extension" : "",
            props.prevAnswer == "true" ? "first-quote-extension" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "MCSDetails"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "organisationBusinessMCS" : "",
            props.prevAnswer == "true" ? "organisationBusinessMCS" : ""
          )
        ).slice();
      };
    }

    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "epcAvailable"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "epcAvailable" : "",
            props.prevAnswer == "true" ? "epcAvailable" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "loadDelayQuestions"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "projectContractDelay" : "",
            props.prevAnswer == "true" ? "projectContractDelay" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "loadBillingAddress"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "projectMCSComplianceAddress" : "",
            props.prevAnswer == "true" ? "projectMCSComplianceAddress" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "loadSubcontractorQuestions"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "projectQuoteSubcontract" : "",
            props.prevAnswer == "true" ? "projectQuoteSubcontract" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "loadGoodsCollection"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "true" ? "projectQuoteGoodsCollection" : "",
            props.prevAnswer == "true" ? "projectQuoteGoodsCollection" : ""
          )
        ).slice();
      };
    }
    if (
      (initialItem.content as question_detail).conditionalFunctionType ==
      "policyDocument"
    ) {
      initialItem.conditionalFunction = async (props: {
        items: itemType[];
        index: number;
        answer?: string;
        prevAnswer?: string;
      }) => {
        return (
          await loadNewQuestions(
            props.items,
            props.index,
            props.answer == "false" ? "organisationContractPolicyDocument" : "",
            props.prevAnswer == "false"
              ? "organisationContractPolicyDocument"
              : ""
          )
        ).slice();
      };
    }
  }
  return initialItem;
}

// function setOptionsBasedOnData(
//   items: itemType[],
//   variablesToSetOptionsFor: string[],
//   data: { [key: string]: string }[]
// ) {
//   const radiatorItemIndexes = {} as { [key: string]: number };
//   const radiatorItemsAnswers = {} as {
//     [key: string]: string | undefined;
//   };
//   //go through all questions
//   variablesToSetOptionsFor.forEach((constraint: string) => {
//     //get index for question
//     const questionIndex = findQuestionIndex(items, constraint, true);
//     if (questionIndex > -1) {
//       radiatorItemIndexes[constraint] = questionIndex;
//       //get answer for quetion
//       radiatorItemsAnswers[constraint] = (
//         items[radiatorItemIndexes[constraint]].content as question_detail
//       ).answer;
//       //set options for question depending on available data
//       (
//         items[radiatorItemIndexes[constraint]].content as question_detail
//       ).options = Array.from(
//         new Set(data.map((data) => data[constraint].toString())).values()
//       );
//       //if current answer is not part of options, set it to ""
//       if (
//         (items[radiatorItemIndexes[constraint]].content as question_detail)
//           .answer != "" &&
//         !(
//           items[radiatorItemIndexes[constraint]].content as question_detail
//         ).options?.includes(radiatorItemsAnswers[constraint] || "")
//       ) {
//         (
//           items[radiatorItemIndexes[constraint]].content as question_detail
//         ).answer = "";
//         items[radiatorItemIndexes[constraint]].key = uuid.v1().toString();
//       }
//       //narrow down available data given the criteria
//       if (radiatorItemsAnswers[constraint])
//         data = data.filter(
//           (data) => data[constraint] == radiatorItemsAnswers[constraint]
//         );
//     }
//   });

//   return items;
// }
