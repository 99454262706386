import { setGlobal } from "./asyncStorageConnection";
import { loadAll } from "./firebaseConnection";
import { question_detail } from "./types";

export function loadQuestionsAndConstants(version: string) {
  loadAll<question_detail>("questions", version, true).then((questions) => {
    setGlobal(
      "project-detail-questions",
      questions.filter((question) => question.type == "project-detail")
    );
    setGlobal(
      "first-quote-questions",
      questions.filter((question) => question.type == "first-quote")
    );
    setGlobal(
      "first-quote-energyUsage",
      questions.filter((question) => question.type == "first-quote-energyUsage")
    );
    setGlobal(
      "first-quote-unknownEnergyUsage",
      questions.filter(
        (question) => question.type == "first-quote-unknownEnergyUsage"
      )
    );
    setGlobal(
      "first-quote-extension",
      questions.filter((question) => question.type == "first-quote-extension")
    );
    setGlobal(
      "roombyroom-general",
      questions.filter((question) => question.type == "roombyroom-general")
    );
    setGlobal(
      "noise",
      questions.filter((question) => question.type == "noise")
    );
    setGlobal(
      "hot-water",
      questions.filter((question) => question.type == "hot-water")
    );
    setGlobal(
      "heatingZones",
      questions.filter((question) => question.type == "heatingZones")
    );
    setGlobal(
      "existingRadiator",
      questions.filter((question) => question.type == "existingRadiator")
    );
    setGlobal(
      "general",
      questions.filter((question) => question.type == "general")
    );
    setGlobal(
      "wall",
      questions.filter(
        (question) => question.type == "wall" && !question.subType
      )
    );
    setGlobal(
      "Internal Wall",
      questions.filter(
        (question) => question.type == "wall" && question.subType == "internal"
      )
    );
    setGlobal(
      "External Wall",
      questions.filter(
        (question) => question.type == "wall" && question.subType == "external"
      )
    );
    setGlobal(
      "Party Wall",
      questions.filter(
        (question) => question.type == "wall" && question.subType == "party"
      )
    );
    setGlobal(
      "windows",
      questions.filter(
        (question) => question.type == "wall" && question.subType == "window"
      )
    );
    setGlobal(
      "doors",
      questions.filter(
        (question) => question.type == "wall" && question.subType == "door"
      )
    );
    setGlobal(
      "floor",
      questions.filter(
        (question) => question.type == "floor" && !question.subType
      )
    );
    setGlobal(
      "Party Floor",
      questions.filter(
        (question) => question.type == "floor" && question.subType == "party"
      )
    );
    setGlobal(
      "Ground Floor",
      questions.filter(
        (question) => question.type == "floor" && question.subType == "ground"
      )
    );
    setGlobal(
      "Intermediate Floor",
      questions.filter(
        (question) =>
          question.type == "floor" && question.subType == "intermediate"
      )
    );
    setGlobal(
      "Party Ceiling",
      questions.filter(
        (question) => question.type == "ceiling" && question.subType == "party"
      )
    );
    setGlobal(
      "Intermediate Ceiling",
      questions.filter(
        (question) =>
          question.type == "ceiling" && question.subType == "intermediate"
      )
    );
    setGlobal(
      "Roof",
      questions.filter(
        (question) => question.type == "ceiling" && question.subType == "roof"
      )
    );
    setGlobal(
      "roofLights",
      questions.filter(
        (question) =>
          question.type == "ceiling" && question.subType == "roofLight"
      )
    );
    setGlobal(
      "ceiling",
      questions.filter(
        (question) => question.type == "ceiling" && !question.subType
      )
    );
    setGlobal(
      "new-rooms-general",
      questions.filter((question) => question.type == "new-rooms-general")
    );
    setGlobal(
      "heatingZones_lwt",
      questions.filter((question) => question.type == "heatingZones_lwt")
    );
    setGlobal(
      "newRadiator",
      questions.filter((question) => question.type == "newRadiator")
    );
    setGlobal(
      "maybeReplaceRadiator",
      questions.filter((question) => question.type == "maybeReplaceRadiator")
    );
    setGlobal(
      "replaceRadiator",
      questions.filter((question) => question.type == "replaceRadiator")
    );
    setGlobal(
      "installerSignup",
      questions.filter((question) => question.type == "installerSignup")
    );
    setGlobal(
      "organisationSignup",
      questions.filter((question) => question.type == "organisationSignup")
    );
    setGlobal(
      "heatPumpSelection",
      questions.filter((question) => question.type == "heatPumpSelection")
    );
    setGlobal(
      "heatPumpSelectionFilters",
      questions.filter(
        (question) => question.type == "heatPumpSelectionFilters"
      )
    );
    setGlobal(
      "cylinderSelection",
      questions.filter((question) => question.type == "cylinderSelection")
    );
    setGlobal(
      "cylinderSelectionFilters",
      questions.filter(
        (question) => question.type == "cylinderSelectionFilters"
      )
    );
    setGlobal(
      "organisationContract",
      questions.filter((question) => question.type == "organisationContract")
    );
    setGlobal(
      "organisationBusiness",
      questions.filter((question) => question.type == "organisationBusiness")
    );
    setGlobal(
      "projectContract",
      questions.filter((question) => question.type == "projectContract")
    );
    setGlobal(
      "projectMCSCompliance",
      questions.filter((question) => question.type == "projectMCSCompliance")
    );
    setGlobal(
      "projectMCSComplianceAddress",
      questions.filter(
        (question) => question.type == "projectMCSComplianceAddress"
      )
    );
    setGlobal(
      "epcAvailable",
      questions.filter((question) => question.type == "epcAvailable")
    );
    setGlobal(
      "projectContractRadiator",
      questions.filter((question) => question.type == "projectContractRadiator")
    );
    setGlobal(
      "organisationBusinessMCS",
      questions.filter((question) => question.type == "organisationBusinessMCS")
    );
    setGlobal(
      "mcsPerformance",
      questions.filter((question) => question.type == "mcsPerformance")
    );
    setGlobal(
      "organisationPricing",
      questions.filter((question) => question.type == "organisationPricing")
    );
    setGlobal(
      "organisationBilling",
      questions.filter((question) => question.type == "organisationBilling")
    );
    setGlobal(
      "projectQuote",
      questions.filter((question) => question.type == "projectQuote")
    );
    setGlobal(
      "projectQuoteSubcontract",
      questions.filter((question) => question.type == "projectQuoteSubcontract")
    );
    setGlobal(
      "projectContractDelay",
      questions.filter((question) => question.type == "projectContractDelay")
    );
    setGlobal(
      "projectQuoteGoodsCollection",
      questions.filter(
        (question) => question.type == "projectQuoteGoodsCollection"
      )
    );
    setGlobal(
      "projectContractVariation",
      questions.filter(
        (question) => question.type == "projectContractVariation"
      )
    );
  });
  loadAll<{
    type:
      | "location"
      | "roomAirChanges"
      | "flueAirChanges"
      | "roomDesignTemp"
      | "noiseDistanceReduction"
      | "noiseDecibelCorrection"
      | "first-quote"
      | "possibleRadiatorSizes"
      | "other";
  }>("constants", version).then((constants) => {
    setGlobal(
      "first-quote",
      constants.filter((constant) => constant.type == "first-quote")
    );
    setGlobal(
      "possibleRadiatorSizes",
      constants.filter((constant) => constant.type == "possibleRadiatorSizes")
    );
    setGlobal(
      "location",
      constants.filter((constant) => constant.type == "location")
    );
    setGlobal(
      "roomAirChanges",
      constants.filter((constant) => constant.type == "roomAirChanges")
    );
    setGlobal(
      "flueAirChanges",
      constants.filter((constant) => constant.type == "flueAirChanges")
    );
    setGlobal(
      "roomDesignTemp",
      constants.filter((constant) => constant.type == "roomDesignTemp")
    );
    setGlobal(
      "noiseDistanceReduction",
      constants.filter((constant) => constant.type == "noiseDistanceReduction")
    );
    setGlobal(
      "noiseDecibelCorrection",
      constants.filter((constant) => constant.type == "noiseDecibelCorrection")
    );
    setGlobal(
      "otherConstants",
      constants.filter((constant) => constant.type == "other")
    );
  });
}
