import React from 'react';
import type { zeroValuesMagicplanResponse } from '../../../util/types';
import { View } from 'react-native';
import { colors } from '../../../styles/colors';
import { capitalize } from 'lodash';
import InfoBanner from '../InfoBanner/InfoBanner';
import Icon from '@expo/vector-icons/AntDesign';
import Typography from '../Typography/Typography';
import styles from './magicPlanErrorMessageStyles';


export interface MagicPlanErrorMessageProps {
    zeroValues: zeroValuesMagicplanResponse[];
}

const MagicPlanErrorMessage: React.FC<MagicPlanErrorMessageProps> = ({
    zeroValues = [],
}) => {
    return (
        <View>
            <InfoBanner type='success' message='' style={{ flexDirection: 'row', marginTop: 15 }}>
                <Icon name={'checkcircle'}
                    size={15}
                    color={colors.supportSuccessGreen}
                    style={styles.icon} />
                <Typography color={colors.supportSuccessGreen}>CSV uploaded successfully</Typography>
            </InfoBanner>

            <InfoBanner type='info' message='' style={{ marginTop: 30 }}>
                <Typography color={colors.supportInfoBlue}>
                    <Typography fontWeight='Bold' color={colors.supportInfoBlue}>Note: </Typography>
                    The following rooms above have been uploaded into Carno but may cause issues with the calculation.
                    You can add or update the values for small rooms manually if you wish.
                </Typography>
            </InfoBanner>

            {zeroValues.map((field, index) => (
                <View style={styles.errorMessage}>
                    <InfoBanner type='error' message='' style={{ flex: 1 }}>
                        <Typography color={colors.supportErrorRed}>
                            <Icon name={'exclamationcircleo'}
                                size={15}
                                color={colors.supportErrorRed}
                                style={styles.icon} />
                            Detected a zero value in {capitalize(field.param)}: {field.customName}
                        </Typography>
                    </InfoBanner>
                </View>
            ))}
        </View>
    );
};

export default MagicPlanErrorMessage;
