import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { setUpCollectionListener } from "../firebaseConnection";
import { newRadiator, room_detail } from "../types";

export const radiators = async (rooms: room_detail<"standard">[]) => {
    const fields = ['newRadiators', 'addedRadiators'];

    return Promise.all(
        rooms.map(async (room: any) => {
            for (const field  of fields) {
                if (room[field]) { //Note: room.newRadiators
                    room[field] = await Promise.all(
                        (room[field] as newRadiator<'standard'>[]).map(async (newRadiator: newRadiator<'standard'>) => {
                            if (newRadiator.ean && newRadiator.ean != "") {
                                const radiator = (
                                    (await setUpCollectionListener("data", true, undefined, [
                                    ["ean", "==", newRadiator.ean],
                                    ])) as QuerySnapshot<DocumentData>
                                ).docs[0].data();
                                newRadiator = { ...newRadiator, ...radiator };
                            }
                            return newRadiator;
                        })
                    );
                }
            }

            return room;
        })
    );
};
