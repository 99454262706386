import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { elementType, roomType } from '../../util/types';

export type RoomByRoomState = {
    roomTypes: roomType[],
    highlightedTabsPerProject: {
        [projectIdKey: string]: {
            previousCollapsedRoom: number,
            previousHighlightedStep: number,
            previousQuestionCollection: {
                displayedQuestionCollection: elementType,
                valueStoragePath: string,
                valueStorageDocument: string,
                categoryOfAnswers: string,
                nestedPath: string,
                isProvidingInformationForArrayElement: string,
                variablesToKeep: string[],
                roomsMasterData: any
            },
        }
    }
};

const initialState: RoomByRoomState = {
    roomTypes: [],
    highlightedTabsPerProject: {},
};

export const roomByRoomSlice = createSlice({
    name: 'roomByRoom',
    initialState,
    reducers: {
        setRoomTypes: (state, action: PayloadAction<roomType[]>) => {
            state.roomTypes = action.payload;
        },
        setHighlightedTabsPerProject: (state, action: PayloadAction<{ projectId: string, valuesToSave: any }>) => {
            const { projectId, valuesToSave } = action.payload;

            if (!state.highlightedTabsPerProject) {
                state.highlightedTabsPerProject = {}
            }

            const savedValuesInProject = state.highlightedTabsPerProject[projectId]
                ? { ...state.highlightedTabsPerProject[projectId] }
                : {};

            state.highlightedTabsPerProject = {
                ...state.highlightedTabsPerProject,
                [projectId]: {
                    ...savedValuesInProject,
                    ...valuesToSave
                }
            };
        },
        clearRoomByRoom: () => ({
            ...initialState,
        }),
    },
});

export const {
    setRoomTypes,
    setHighlightedTabsPerProject,
    clearRoomByRoom
} = roomByRoomSlice.actions;

export default roomByRoomSlice.reducer;
