import Tooltip from 'react-native-walkthrough-tooltip';
import Typography from '../Typography/Typography';
import { colors } from '../../../styles/colors';

type TooltipTableHeaderProps = {
    text: string,
    isTooltipVisible: boolean,
    onClose: () => void
}

const TooltipTableHeader: React.FC<TooltipTableHeaderProps> = ({ text, isTooltipVisible, onClose }) => {
    return (
        <Tooltip
            isVisible={isTooltipVisible}
            content={<Typography size={12}>{text}</Typography>}
            placement='bottom'
            onClose={onClose}
            showChildInTooltip={false}
            disableShadow={true}
            contentStyle={{ flex: 1 }}>
            <Typography size={12} color={colors.neutral500}>{text}</Typography>
        </Tooltip>
    );
};

export default TooltipTableHeader;
