import React, { useContext } from 'react';
import { DesktopContext } from '../../../util/contexts';
import { View } from 'react-native';
import type {
  heatPumpProduct,
  itemType,
  product,
  project_detail,
  question_detail,
  radiatorProduct
} from '../../../util/types';
import { List, useTheme } from 'react-native-paper';
import { conditionalFunction } from '../../../util/types';
// import { Cylinder } from '../../QuestionList';
import { computeHeatOutputCapacity as computeCapacity } from '../../../util/heatOutput';
import Cylinder from './Cylinder';
import { desktopStyle } from '../../../styles/desktop';

type ProductProps = {
    product: product,
    loadingProducts: boolean,
    items: itemType[],
    productFilters: string[][],
    routeParams: any,
    thisProject: project_detail<'standard'>,
    questionSetAnswer: (
        newAnswer: string,
        prevAnswer: string,
        index: number,
        conditionalFunction?: conditionalFunction,
        productFilters?: string[][]) => void
}

const Product: React.FC<ProductProps> = ({
    product,
    loadingProducts,
    items,
    productFilters,
    routeParams,
    thisProject,
    questionSetAnswer
}) => {
    const isDesktop = useContext(DesktopContext);
    const { colors } = useTheme();

    const onPress = () => {
        if (!loadingProducts) {
            const listOfProductQuestionIndex = items.findIndex((item: itemType) =>
                item.type == 'question_detail' &&
                (item.content as question_detail).input_rule == 'listOfProducts'
            );

            questionSetAnswer(
                !(items[listOfProductQuestionIndex].content as question_detail).answer || (items[listOfProductQuestionIndex].content as question_detail).answer == ""
                ? product.ean : '',
                (items[listOfProductQuestionIndex].content as question_detail).answer || "",
                listOfProductQuestionIndex,
                items[listOfProductQuestionIndex].conditionalFunction,
                productFilters
            );
        }
    };

    if (product.type == 'radiatorData') {
        //NOTE: Displayed in System Upgrades > Radiator > Available Products
        return (
            <View style={isDesktop ? desktopStyle(colors).headlineQuestionList : { width: '100%' }}>
                <List.Item
                    title={
                        (product.content as radiatorProduct<'string'>).manufacturer +
                        ' ' +
                        (product.content as radiatorProduct<'string'>).range +
                        ' (£' +
                        product.price +
                        ')'
                    }
                    titleNumberOfLines={2}
                    description={
                        'Dimensions (LxH): ' +
                        (product.content as radiatorProduct<'string'>).length +
                        'x' +
                        (product.content as radiatorProduct<'string'>).height +
                        ' | Type: ' +
                        (product.content as radiatorProduct<'string'>).radiatorType +
                        ' | Heat Output: ' +
                        (product.content as radiatorProduct<'string'>).heatOutput
                    }
                    onPress={onPress}/>
            </View>
        );
    
    } else if (product.type == 'heatPumpData') {
        let flowTemp = 0 as number | undefined;
        let capacity = undefined as number | undefined;

        if (routeParams.extraData != 'first_quote') {
            flowTemp = thisProject.heatingZones_lwt && Math.max( ...thisProject?.heatingZones_lwt?.heatingZones_lwt?.map(
                (heatingZone_lwt) => heatingZone_lwt.flowTemperature)
            );
        } else {
            flowTemp = thisProject.firstQuote?.flowTemperature;
        }

        if (thisProject.locationParameters && thisProject.locationParameters.CIBSE && flowTemp) {
            capacity = computeCapacity(thisProject, product, flowTemp);
        }

        return (
            //NOTE: Displayed in ASHP Selection
            <View style={
                isDesktop
                    ? desktopStyle(colors).headlineQuestionList
                    : { width: '100%' }
                }>

                <List.Item
                    titleNumberOfLines={2}
                    title={
                        product.content.manufacturer +
                        ' ' +
                        (product.content as heatPumpProduct<'string'>).refrigerantType +
                        ' ' +
                        (product.content as heatPumpProduct<'string'>).model +
                        ' (£' +
                        product.price +
                        ')'
                    }
                    description={
                        'Noise Level [db]: ' +
                        (product.content as heatPumpProduct<'string'>).soundPower +
                        (capacity != undefined
                        ? ' | Heat Output at flow temperature and outdoor conditions: ' +
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            capacity!.toPrecision(2) +
                            'kW'
                        : ' | Heat Output could not be determined at flow temperature and outdoor conditions.')
                    }
                    onPress={onPress}
                />
            </View>
        );
    } else {
        return <Cylinder onPress={onPress} product={product} colors={colors} />;
    }
};

export default Product;