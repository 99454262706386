import { useContext } from 'react';
import { useRoute } from '@react-navigation/native';
import { ProjectDetailContext } from '../../contexts';
import { itemType, question_detail, Obj } from '../../types';

interface RoomsMasterData {
    roomsMasterData: Obj<string>
}

const usePrepopulationFromMasterData = () => {

    const route = useRoute();
    const { params: routeParams } = route;
    const thisProject = useContext(ProjectDetailContext);
    const isUndefinedRouteParams = typeof routeParams === 'undefined';

    const prepopulateRoomData = (item: itemType) => {

        if (isUndefinedRouteParams) return;

        const itemContent = item.content as question_detail;
        const masterData = (routeParams as Obj<RoomsMasterData>)?.roomsMasterData;

        if (item.content && itemContent.question_text && itemContent.question_text.toUpperCase() === 'YEAR OF CONSTRUCTION') {
            const hasExtensionFromFirstQuote = thisProject.firstQuote && thisProject.firstQuote.extension;

            if (itemContent.answer === '' && !hasExtensionFromFirstQuote) itemContent.answer = masterData?.roomsMasterData?.constructionDate;
        }

        if (item.content && itemContent.question_text && itemContent.question_text.toUpperCase() === 'TEMPERATURE DROP DURING SETBACK') {
            if (itemContent.answer === '') {
                itemContent.answer = masterData?.roomsMasterData?.temperatureDrop;
            }
        }
    };

    return {
        prepopulateRoomData
    };
};

export default usePrepopulationFromMasterData;