import { input_rule_type, locationParameters } from "./types";
import { getGlobal } from "./asyncStorageConnection";

const getLocationData = async () => {
  return await getGlobal("location");
};

/**
 * Description: verifies that the user answer is valid for the question in accordance to input_rule
 * @param answer user text input
 * @param input_rule stipulated data pattern
 * @returns false if no violation, true if violation
 */
export async function checkIfInputRuleViolated(
  answer: string,
  input_rule: input_rule_type,
  values?: string[]
) {
  if (answer) {
    if (
      input_rule === "onlyNumber" ||
      input_rule === "minMax" ||
      input_rule === "epcNumber"
    ) {
      const numberRegex = /^[0-9]*\.?[0-9]*$/;
      const isNotNumber = !numberRegex.test(answer);

      if (!isNotNumber && input_rule == "minMax") {
        if (!values)
          throw ReferenceError("Options must be set for the minMax rule");
        return (
          parseFloat(answer) < parseFloat(values[0]) ||
          parseInt(answer) > parseInt(values[1])
        );
      } else return isNotNumber;
    } else if (input_rule === "phone") {
      const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
      return !phoneRegex.test(answer);
    } else if (input_rule === "email") {
      const emailRegex =
        /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
      return !emailRegex.test(answer);
    } else if (input_rule === "postcode") {
      const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
      const postCodeArea = new RegExp(/(^[A-Z]{2})|([A-Z]{1})/).exec(
        answer.toUpperCase()
      );
      const dataAvailable = (await getLocationData()) as locationParameters[];
      return (
        !postcodeRegEx.test(answer) ||
        !postCodeArea ||
        !dataAvailable.some(
          (location) => location.postcode == postCodeArea[0].toUpperCase()
        )
      );
    }
  }
  return false;
}
