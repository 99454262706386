import type { roomType } from './types';
import { store } from '../redux/store';
import { setRoomTypes } from '../redux/modules/roomByRoom';
import { getDocument } from './firebaseConnection';

export const getRoomType = async (type: roomType) => {
    const { roomTypes: roomTypesState } = store.getState().roomByRoom;
    let roomTypes = [] as roomType[];
    let roomTypeAlreadyExists = false;
    let roomTypeValue = '';

    if (roomTypesState.length === 0) {
        const response = await getDocument('questions', 'room-general-questions3v0') as any;

        store.dispatch(setRoomTypes(response.options));
        roomTypes = response.options;
    } else {
        roomTypes = roomTypesState;
    }

    // special case for Hall since it's named as Hallway
    if (type.toLowerCase().includes('hall')) return { roomType: 'Hallway', isNew: false };

    for (const roomType of roomTypes) {
        if (type.toLocaleLowerCase().includes(roomType.toLocaleLowerCase())) {
            roomTypeAlreadyExists = true;
            roomTypeValue = roomType;
            break;
        }
    }

    return { roomType: roomTypeValue, isNew: !roomTypeAlreadyExists }
};
