import type { elementType } from './types';

export const getElementIndex = (elements: Array<any>, type: elementType, subIndex: number) => {

    if (type === 'floor') return '';

    const ceilings = [];
    const walls = [];

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        if (element.type === 'ceiling') {
            ceilings.push({ ...element, subIndex: i });

            if (subIndex === i) return (ceilings.length - 1).toString();

        } else if (element.type === 'wall') {
            walls.push({ ...element, subIndex: i });

            if (subIndex === i) return (walls.length - 1).toString();
        }
    }

    return '';
};
