import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        width: '32%',
        paddingVertical: 15,
        paddingHorizontal: 30,
        backgroundColor: 'white'
    }
});

export default styles;