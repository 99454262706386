import React, { ComponentType, useContext, useEffect, useState } from "react";
import { clearElementObjects } from "../../redux/modules/masterData";
import { useAppDispatch } from "../../redux/hooks";
import { FlatList, ListRenderItem, View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { Divider, FAB, IconButton, List, useTheme } from "react-native-paper";
import { Stack } from "../../components/Stack";
import basicStyles from "../../styles/basic";
import buttonStyles from "../../styles/buttons";
import { ProjectDetailWrapper } from "./ProjectDetail";
import { projectStackNavigationProps } from "../../util/navigationProps";
import {
  createDocument,
  deleteProject,
  getDocument,
  getUser,
  mergeDataWithDocument,
  setUpCollectionListener,
} from "../../util/firebaseConnection";
import { QuerySnapshot, Unsubscribe } from "firebase/firestore";
import { FirebaseFirestoreTypes } from "@react-native-firebase/firestore";
import { project_detail, roombyroomconstants } from "../../util/types";
import { HeaderDispatch, ProjectContext } from "../../util/contexts";
import { useFocusEffect } from "@react-navigation/native";
import uuid from "react-native-uuid";
import { getReadableDate } from "../../util/helper";
import { Confirmation } from "../../components/Dialogs";
import { getGlobal } from "../../util/asyncStorageConnection";

/**
 * Description: fetches list of user's projects from Firestore
 * @returns project overview
 */
export const ProjectsStack = () => {
    const [projects, setProjects] = useState([] as project_detail<"standard">[]);

    function onProjectsUpdate(
        query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
    ) {
        const tmp = [] as project_detail<"standard">[];

        query.forEach((doc) => {
            tmp.push({ ...doc.data(), id: doc.id } as project_detail<"standard">);
        });

        setProjects(tmp);
    }

    useEffect(() => {
        const subscriber = setUpCollectionListener(
            "projects",
            false,
            onProjectsUpdate,
            [["accessRights.read", "array-contains", getUser()?.uid ?? ""]],
            ["created", "asc"]
        ) as Unsubscribe; //same issue as accountScreen

        return subscriber;
    }, []);

    const screens = [
        {
            name: "Overview",
            component: Projects as ComponentType,
        },
        {
            name: "ProjectDetailStack",
            component: ProjectDetailWrapper as ComponentType,
        },
    ];

    return (
        //todo: on resizing of window doesnt have access to projectID anymore
        <ProjectContext.Provider value={projects}>
            <Stack screens={screens} initialRoute="Overview" />
        </ProjectContext.Provider>
    );
};

/**
 * Description: maps projects array to a flatlist of clickable project previews
 * @returns list of projects for current user, create new project button
 */
export const Projects = ({
    navigation,
}: StackScreenProps<projectStackNavigationProps, "Overview">) => {
    const { colors } = useTheme();
    const projects = useContext(ProjectContext);
    const setHeaderOptions = useContext(HeaderDispatch);
    const [alertVisible, setAlertVisible] = useState("");
    const dispatch = useAppDispatch();

    const renderProject: ListRenderItem<project_detail<"standard">> = ({
        item,
    }) => {
        //replace with project type once kevin submits questions
        return (
            <React.Fragment>
                <List.Item
                    onPress={() => {
                        dispatch(clearElementObjects());
                        navigation.navigate({
                            name: "ProjectDetailStack",
                            params: { projectId: item.id },
                        })
                    }}
                    style={{ margin: 5, minWidth: "80%", alignSelf: "center" }}
                    title={
                        item.general && item.general.customerName
                        ? "Project for " + (item.general && item.general.customerName)
                        : "New project"
                    }
                    description={
                        getReadableDate(new Date(item.lastEdited)) + " | " + item.id
                    }
                    right={() => (
                        <IconButton
                            icon="delete"
                            style={{ alignSelf: "center" }}
                            onPress={() => setAlertVisible(item.id)} />
                    )} />
                <Divider />
            </React.Fragment>
        );
    };

    useFocusEffect(() => {
        setHeaderOptions({ title: "Projects" });
    });

    async function createNewProject() {
        const version = (await getGlobal("version"))[0].version;
        const valueStorageDocument = uuid.v1().toString();

        const now = Date.now();

        dispatch(clearElementObjects());

        createDocument("projects", valueStorageDocument, {
            created: now,
            creationDate: new Date(now).toLocaleDateString(),
            createdBy: getUser()?.uid,
            version: version,
            lastEdited: now,
            heatingZones: { heatingZones: [{}] },
            accessRights: { read: [getUser()?.uid], write: [getUser()?.uid] },
            general: {},
        }).then(() =>
            navigation.navigate({
                name: "ProjectDetailStack",
                params: { projectId: valueStorageDocument },
            })
        );
        getDocument<roombyroomconstants>(
            "constants",
            `projectConstants1v${version.split(".")[0]}`
        ).then((constants) => {
            mergeDataWithDocument("projects", valueStorageDocument, {
                constants: constants,
            });
        });
    }

    return (
        <View style={basicStyles.container}>
            <Confirmation
                visible={alertVisible != ""}
                setVisible={(visible: boolean) =>
                    setAlertVisible(visible ? "error" : "")
                }
                executedFunction={() => {
                    deleteProject(alertVisible);
                }} />

            <FlatList
                data={projects}
                renderItem={renderProject}
                keyExtractor={(item) => item.id}
                onEndReachedThreshold={10}
                initialNumToRender={3} />

            <FAB
                testID="new-project"
                style={[{ backgroundColor: colors.primary }, buttonStyles.fab]}
                small
                icon="plus"
                onPress={() => createNewProject()} //navigate to question stack with params
            />
        </View>
    );
};
