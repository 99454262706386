import React, { useEffect, useState, ReactNode } from "react";
import { List, Divider } from "react-native-paper";
import { getDocument } from "../../util/firebaseConnection";
import { FlatList } from "react-native";
import { installer_detail, organisation_detail } from "../../util/types";

interface OrganisationMemberProps {
  item: string;
  right?: (id: string) => ReactNode;
}
const OrganisationMember = (props: OrganisationMemberProps) => {
  const [memberInfo, setMemberInfo] = useState(
    {} as installer_detail<"standard">
  );
  const [initializing, setInitializing] = useState(true);
  useEffect(() => {
    const fetchMemberData = async (uid: string) => {
      const docData = await getDocument<installer_detail<"standard">>(
        "installers",
        uid
      );
      setMemberInfo(docData);
      setInitializing(false);
    };
    fetchMemberData(props.item);
  }, []);
  if (!initializing) {
    return (
      <React.Fragment>
        <List.Item
          style={{
            margin: 5,
            alignSelf: "flex-start",
            width: 400,
            justifyContent: "center",
          }}
          title={`${memberInfo.installerName || "new user"}`}
          description={`email: ${memberInfo.installerEmail} | status: ${
            memberInfo.active ? "active" : "invite sent"
          }`}
          right={() => props.right && props.right(props.item)}
        />
        <Divider />
      </React.Fragment>
    );
  } else
    return (
      <React.Fragment>
        <List.Item
          style={{ margin: 5, alignSelf: "center", width: 400 }}
          title={"Loading"}
        />
        <Divider />
      </React.Fragment>
    );
};
export const MemberList = (props: {
  orgInfo: organisation_detail<"standard">;
  right?: (id: string) => ReactNode;
}) => {
  return (
    <FlatList
      renderItem={({ item }) => (
        <OrganisationMember item={item} right={props.right} />
      )}
      data={props.orgInfo.installers}
      keyExtractor={(item) => item}
      onEndReachedThreshold={10}
      initialNumToRender={3}
    />
  );
};
