import { useEffect } from 'react';
import getGeneralDropdownOptions from '../../../util/masterData/getGeneralDropdownOptions';
import { initialUValue } from '../../../util/masterData/prepopulateFieldsFromMasterData';
import useSaveToFirebase from '../../../util/customHooks/masterData/useSaveToFirebase';
import { checkIfInputRuleViolated } from '../../../util/checkIfInputRuleViolated';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setExpanded } from '../../../redux/modules/masterData';
import {
    getFabricTypes,
    getAdjacentSpaceTypes,
    getMasterData,
    getTargetElement
} from '../../../util/masterData';
import {
    setDropdowns,
    setExpandedElement,
    clearExpanded,
    setAdjacentSpaceDropdowns,
    setGeneralDropdowns,
    setGeneral
} from '../../../redux/modules/masterData';
import type {
    subElementType,
    elementType,
    input_rule_type,
    RoomElement,
    GeneralDropdowns,
    PerElementDropdowns
} from '../../../util/types';
import MasterConstructionData from './MasterConstructionData';

type MasterConstructionDataProps = {
    routeParams: any
}

const MasterConstructionDataContainer: React.FC<MasterConstructionDataProps> = ({ routeParams }) => {

    const dispatch = useAppDispatch();
    const {
        dropdowns,
        generalDropdowns,
        adjacentSpaceDropdowns,
        elementTypes,
        expandedElement
    } = useAppSelector(({ masterData }) => masterData);
    const { saveToFirebase } = useSaveToFirebase();

    useEffect(() => {
        dispatch(clearExpanded());

        getFabricTypes();
        getAdjacentSpaceTypes();
        getGeneralDropdownOptions();
        getMasterData(routeParams);

        return () => {
            saveToFirebase();
        };
    }, []);

    const handlePress = (element: elementType | subElementType) => {
        dispatch(setExpandedElement(expandedElement === element ? '' : element));
        dispatch(setExpanded(element));
    };

    const saveFabricType = async (elementType: string, value: string, type: subElementType) => {
        const regExp = /\(([^)]+)\)/;
        const matches = regExp.exec(value) as string[] | null;
        const uValue = matches ? matches[1] : '';

        const { setElementObject } = getTargetElement(elementType as elementType | subElementType);

        const uValueInputRuleError = await checkIfInputRuleViolated(
            uValue,
            initialUValue.input_rule as input_rule_type,
            initialUValue.values
                ? initialUValue.values
                : initialUValue.options
        );

        dispatch(setElementObject({
            subType: type,
            fabricType: value,
            uValue: {
                answer: matches ? matches[1] : '',
                inputRuleError: uValueInputRuleError
            }
        })); // NOTE: values in redux already saved via this call
    };

    const saveAdjacentSpace = async (elementType: string, value: string, type: subElementType) => {
        const { setElementObject } = getTargetElement(elementType as elementType | subElementType);

        dispatch(setElementObject({
            subType: type,
            adjacentSpace: value
        }));
    };

    const saveGeneralField = async (field: string, value: string) => {
        dispatch(setGeneral({ [field]: value }));
    };

    const isDisabled = (subType: subElementType) => ['internal', 'intermediate'].includes(subType);

    const setDropdownValue = (
        field: PerElementDropdowns,
        value: boolean,
        element: RoomElement,
        subType: subElementType
    ) => {
        if (isDisabled(subType) && field === 'Adjacent Space') return;

        const params = { element, subType, value };
        dispatch(field === 'Fabric Type' ? setDropdowns(params) : setAdjacentSpaceDropdowns(params));
    };

    const setGeneralDropdownValue = (field: GeneralDropdowns, value: boolean) => {
        dispatch(setGeneralDropdowns({ field, value }));
    };

    return (
        <MasterConstructionData
            elementTypes={elementTypes}
            dropdowns={dropdowns}
            generalDropdowns={generalDropdowns}
            adjacentSpaceDropdowns={adjacentSpaceDropdowns}
            isDisabled={isDisabled}
            handlePress={handlePress}
            saveGeneralField={saveGeneralField}
            setDropdownValue={setDropdownValue}
            saveAdjacentSpace={saveAdjacentSpace}
            saveFabricType={saveFabricType}
            saveToFirebase={saveToFirebase}
            setGeneralDropdownValue={setGeneralDropdownValue} />
    );
};

export default MasterConstructionDataContainer;