import { getDocumentAsync } from 'expo-document-picker';
import { uploadFile } from '../firebaseConnection';
import { getUser } from '../firebaseConnection';
import { getImageLink } from '../firebaseConnection';
import type { floorPlanImage, project_detail } from '../types';

export const prepareImagesForUpload = async (thisProject: project_detail<'standard'>) => {
    const result = await getDocumentAsync({
        type: 'image/*',
        multiple: true // parameter only works for web
    });

    if (result.type == 'success') {
        const files: any = result.output;

        const results = await Promise.all(
            Object.keys(files).map(
                (_key, i) =>
                    new Promise((resolve) => {
                    const reader = new FileReader() as any;
                    const file = files[i];

                    reader.onload = () => {
                        resolve({
                            dataUri: reader.result as string,
                            name: file.name.replaceAll('%20', ' '),
                            size: file.size,
                            type: reader.result.substring('data:image/'.length, reader.result.indexOf(';base64'))
                        });
                    };
                    reader.readAsDataURL(file);
                })
            )
        ) as { dataUri: string, name: string, size: string, type: string }[];

        const { uniqueFileNames, duplicateFileNames, invalidFormat } = removeDuplicateAndInvalidFiles(thisProject.floorPlanFileNames as string[] || [], results || []) as any;

        return { uniqueFileNames, duplicateFileNames, invalidFormat };
    }

    return { uniqueFileNames: [], duplicateFileNames: [], invalidFormat: [] };
};

const removeDuplicateAndInvalidFiles = (floorPlanFileNames: string[], results: floorPlanImage[]) => {
    const uniqueFileNames = [ ...results ];
    const duplicateFileNames = [] as string[];

    const validTypes = ['png', 'jpg', 'jpeg', 'svg', 'svg+xml'];
    const invalidFormat: string[] = [];

    for (let i = 0; i < results.length; i++) {
        if (floorPlanFileNames.length !== 0 &&
            floorPlanFileNames.includes(results[i].name.replaceAll('%20', ' '))
        ) {
            duplicateFileNames.push(uniqueFileNames[i].name.replaceAll('%20', ' '));
            uniqueFileNames.splice(i, 1);
        } else if (!validTypes.includes(results[i].type.toLowerCase())) {
            invalidFormat.push(uniqueFileNames[i].name.replaceAll('%20', ' '));
            uniqueFileNames.splice(i, 1);
        }
    }

    return { uniqueFileNames, duplicateFileNames, invalidFormat };
};

export const uploadFloorPlan = async (fileList: floorPlanImage[], projectId: string) => {
    const promises = [];
    const imageUrlPromises = []; 

    for (const file of fileList) {
        promises.push(uploadFile(
            getUser()?.uid + '/' + projectId + '/floorPlans',
            file.dataUri,
            file.name
        ));
    }

    const responses = await Promise.all(promises);

    for (const response of responses) {
        imageUrlPromises.push(getImageLink(response.metadata.fullPath));
    }

    const imageUrls = await Promise.all(imageUrlPromises);
    const imageUrlsArr = imageUrls.map((url, index) => ({ url, name: extractFileName(url) }));

    return imageUrlsArr;
};

export const extractFileName = (url: string) => {
    return url.split('%2F')[3].split('?')[0];
};
