import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { getGlobal } from "../asyncStorageConnection";
import { getSCOPs, setUpCollectionListener, mergeDataWithDocument } from "../firebaseConnection";
import {
  buildTypeArray,
  cylinderProduct,
  firstQuote,
  heatPumpProduct,
  hotWater as hotWaterType,
  locationParameters,
  noise as noiseType,
  noiseDistance,
  pressureLevel,
  project_detail,
  room_detail,
} from "../types";

const SHCWater = 4187;
const JTokWh = 3600000;
const tempToKillLegionaires = 60;

export const locationData = async (project: project_detail<"standard">) => {
  if (
    project.general &&
    project.general.everythingFilledOut &&
    project.general.postcode
  ) {
    const postCodeArea = new RegExp(/(^[A-Z]{2})|([A-Z]{1})/).exec(
      project.general.postcode.toUpperCase()
    );

    const before =
      project.locationParameters && project.locationParameters.postcode;
    if (postCodeArea && (!before || postCodeArea[0] != before)) {
      const locationSpecificData = (
        (await getGlobal("location")) as locationParameters[]
      ).filter((location) => location.postcode == postCodeArea[0]);

      if (locationSpecificData) {
        return {
          postcode: postCodeArea[0],
          CIBSE: locationSpecificData[0].CIBSE,
          degreeDays: locationSpecificData[0].degreeDays,
          meanAirTemp: locationSpecificData[0].meanAirTemp,
        };
      } else {
        throw ReferenceError("Cannot find details about given postcode.");
      }
    } else if (!postCodeArea) {
      throw ReferenceError("Postcode has invalid format");
    } else return project.locationParameters;
  } else return undefined;
};

export const upgrades = async (
    rooms: room_detail<"standard">[],
    project: project_detail<"standard">
) => {
    if (
        rooms.length > 0 &&
        project.general &&
        project.general.everythingFilledOut
    ) {
        const everythingFilledOut =
            project.heatingZones_lwt &&
            project.heatingZones_lwt.everythingFilledOut &&
            rooms.every(
                (room) => {
                    let isAllNewRadiatorsFilledOut = true;
                    let isAllExistingRadiatorsFilledOut = true;

                    if (room.newRadiators) {
                        for (const newRadiator of room.newRadiators) {
                            if (JSON.stringify(newRadiator) === '{}') continue;
                            
                            if (!newRadiator.everythingFilledOut) isAllNewRadiatorsFilledOut = false;
                        }
                    }

                    if (room.existingRadiators) {
                        isAllExistingRadiatorsFilledOut = room.existingRadiators.every((existingRadiator) => {
                            return existingRadiator.everythingFilledOut;
                        })
                    }

                    return !room.existingRadiators || (isAllNewRadiatorsFilledOut && isAllExistingRadiatorsFilledOut);
                    // return !room.existingRadiators || (room.newRadiators && room.newRadiators.every((newRadiator) => {
                    //     return newRadiator.heatOutput ? true : newRadiator.everythingFilledOut;
                    // }));
                }
            );

        if (everythingFilledOut) {
            const enoughHeat = rooms.every((room) => {
                if (!room.heatLoss || !room.heatGeneration) return true;
                return room.heatLoss <= room.heatGeneration;
            });

            const price = (
                await Promise.all(
                    rooms.map(async (room) => {
                        if (room.newRadiators) {
                            const ret = (
                                await Promise.all(
                                room.newRadiators.map(async (newRadiator) => {
                                    if (newRadiator.replace) {
                                    const price = parseFloat(
                                        (
                                        await (setUpCollectionListener(
                                            "data",
                                            true,
                                            undefined,
                                            [
                                            ["type", "==", "radiatorData"],
                                            ["ean", "==", newRadiator.ean],
                                            ]
                                        ) as Promise<QuerySnapshot<DocumentData>>)
                                        ).docs[0].data().price
                                    ) as number;
                                    return price;
                                    } else return 0;
                                })
                                )
                            ).reduce((partialSum, a) => partialSum + a, 0);

                            return ret;
                        } else {
                            const ret = 0;

                            return ret;
                        }
                    })
                )).reduce((partialSum, a) => partialSum + a, 0);

            return {
                price: price,
                enoughHeat: enoughHeat,
                everythingFilledOut: true,
            };
        } else {
            return {
                price: null,
                enoughHeat: null,
                everythingFilledOut: false,
            };
        }
    } else {
        return {
            price: null,
            enoughHeat: null,
            everythingFilledOut: null,
        };
    }
};

export const roomByRoom = (
  rooms: room_detail<"standard">[],
  project: project_detail<"standard">
) => {
  if (
    rooms.length > 0 &&
    project.general &&
    project.general.everythingFilledOut
  ) {
    const everythingFilledOut =
      rooms.every(
        (room) =>
          room.general &&
          room.general.everythingFilledOut &&
          room.elements &&
          room.elements.every((element) => element.everythingFilledOut)
      ) && project.heatingZones?.everythingFilledOut;
    if (everythingFilledOut) {
      let heatLoss = 0;
      let energyUsagePerYear = 0;
      rooms.forEach((room) => {
        if (!room.heatLoss || !room.energyUsagePerYear)
          throw EvalError(
            "Room heat loss is not defined even though everythingFilledOut==true"
          );
        heatLoss += room.heatLoss;
        energyUsagePerYear += room.energyUsagePerYear;
      });
      return {
        heatLoss: heatLoss,
        energyUsagePerYear: energyUsagePerYear,
        everythingFilledOut: true,
      };
    } else
      return {
        heatLoss: null,
        energyUsagePerYear: null,
        everythingFilledOut: false,
      };
  } else
    return {
      heatLoss: null,
      energyUsagePerYear: null,
      everythingFilledOut: null,
    };
};
export const first_quote = async (project: project_detail<"standard">) => {
  const heatLoss_per_sqm = (await getGlobal("first-quote"))[0] as {
    [K in typeof buildTypeArray[number]]: string;
  };
  if (project.firstQuote) {
    const firstQuote = {} as firstQuote<"standard">;
    if (project.firstQuote.everythingFilledOut && project.locationParameters) {
      if (!project.firstQuote.numberOfBeds)
        throw ReferenceError(
          "Some values of firstQuote undefined, even though everythingFilledOut==true"
        );
      if (!project.firstQuote.usageKnown) {
        if (!project.firstQuote.floorArea)
          throw ReferenceError(
            "Some values of firstQuote undefined, even though everythingFilledOut==true"
          );
        firstQuote.heatLoss =
          parseFloat(heatLoss_per_sqm[project.firstQuote.houseBuildType]) *
          project.firstQuote.floorArea;
        if (project.firstQuote.extension) {
          if (!project.firstQuote.extensionArea)
            throw ReferenceError(
              "Some values of firstQuote undefined, even though everythingFilledOut==true"
            );
          firstQuote.heatLoss +=
            parseFloat(
              heatLoss_per_sqm[project.firstQuote.extensionBuildType]
            ) * project.firstQuote.extensionArea;
        }
        firstQuote.energyUsagePerYear =
          (firstQuote.heatLoss *
            parseFloat(project.locationParameters.degreeDays) *
            24) /
          1000 /
          ((project.firstQuote.designTemp || 0) -
            parseFloat(project.locationParameters.CIBSE));
      } else {
        if (
          !project.firstQuote.energyUsageInput ||
          !project.firstQuote.designTemp
        )
          throw ReferenceError(
            "Some values of firstQuote undefined, even though everythingFilledOut==true"
          );
        firstQuote.heatLoss =
          (project.firstQuote.energyUsageInput *
            (project.firstQuote.designTemp -
              parseFloat(project.locationParameters.CIBSE))) /
          ((parseFloat(project.locationParameters.degreeDays) * 24) / 1000);
        firstQuote.energyUsagePerYear = project.firstQuote.energyUsageInput;
      }
      firstQuote.cylinderSize = (project.firstQuote.numberOfBeds + 1) * 50;
      firstQuote.waterEnergyUsage = waterEnergyUsagePerYear(
        firstQuote.cylinderSize || 0,
        55,
        10,
        70
      );
      return { ...project.firstQuote, ...firstQuote };
    } else {
      return {
        ...project.firstQuote,
        everythingFilledOut: null,
        heatLoss: null,
        cylinderSize: null,
      };
    }
  } else {
    return undefined;
  }
};
function waterEnergyUsagePerYear(
  usagePerDay: number,
  targetTemperature: number,
  startTemperature: number,
  efficiency: number
) {
  return (
    (365 * usagePerDay * (targetTemperature - startTemperature) * SHCWater) /
    JTokWh /
    (efficiency / 100)
  );
}
//what do we do with cylinder recoveries?
export const hotWater = (project: project_detail<"standard">) => {
  if (project.hotWater && project.hotWater.everythingFilledOut) {
    const hotWater = {} as hotWaterType<"standard">;
    if (
      !project.hotWater.numberBedrooms ||
      !project.hotWater.numberOccupants ||
      !project.hotWater.hotWaterUsePerOccupant ||
      !project.hotWater.targetTemperature ||
      !project.hotWater.cylinderStartTemperature ||
      !project.hotWater.immersionCyclesPerWeek ||
      !project.hotWater.efficiency
    ) {
      throw ReferenceError(
        "Some values of hotWater undefined, even though everythingFilledOut==true"
      );
    }
    hotWater.usagePerDay =
      (((project.hotWater.numberBedrooms + 1) *
        project.hotWater.numberOccupants) /
        project.hotWater.numberBedrooms) *
      project.hotWater.hotWaterUsePerOccupant;
    hotWater.energyUsagePerYear = waterEnergyUsagePerYear(
      hotWater.usagePerDay,
      project.hotWater.targetTemperature,
      project.hotWater.cylinderStartTemperature,
      project.hotWater.efficiency
    );

    project.hotWater.immersionEnergyUsagePerYear =
      project.hotWater.immersionCyclesPerWeek *
      52 *
      (project.hotWater.targetTemperature < tempToKillLegionaires
        ? (SHCWater *
            hotWater.usagePerDay *
            (tempToKillLegionaires - project.hotWater.targetTemperature)) /
          JTokWh
        : 0);
    return { ...project.hotWater, ...hotWater };
  } else if (project.hotWater) {
    return {
      ...project.hotWater,
      immersionEnergyUsagePerYear: null,
      usagePerDay: null,
      energyUsagePerYear: null,
    };
  } else return undefined;
};
const distanceLookUp = [...noiseDistance, "pressureLevel"];
const possibleDifferences = [
  -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3,
  4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
];
export const noise = async (project: project_detail<"standard">) => {
  if (
    project.heatPump &&
    project.heatPump.everythingFilledOut &&
    project.noise &&
    project.noise.everythingFilledOut
  ) {
    const noise = {} as noiseType<"standard">;
    if (
      project.noise.distance == undefined ||
      project.noise.obstruction == undefined ||
      project.heatPump.ean == undefined
    )
      throw ReferenceError(
        "Some values of noise undefined, even though everythingFilledOut==true"
      );
    const dbDistanceReduction = (
      (await getGlobal("noiseDistanceReduction")) as {
        [K in typeof distanceLookUp[number]]: string | pressureLevel;
      }[]
    ).filter(
      (row) =>
        row.pressureLevel == (project.noise && project.noise.pressureLevel)
    )[0][project.noise.distance];
    const soundPower = (
      (await setUpCollectionListener("data", true, undefined, [
        ["ean", "==", project.heatPump.ean],
      ])) as QuerySnapshot<DocumentData>
    ).docs[0].data().soundPower;
    const pressureLevelAtAssessmentPosition =
      parseFloat(soundPower) +
      parseFloat(dbDistanceReduction) +
      parseFloat(project.noise.obstruction);
    const differenceHPBackground =
      parseFloat(project.constants.backgroundNoiseLevel) -
      pressureLevelAtAssessmentPosition;
    const correction = (
      (await getGlobal("noiseDecibelCorrection")) as {
        [K in typeof possibleDifferences[number]]: string;
      }[]
    )[0][Math.floor(differenceHPBackground)];
    const decibelCorrection =
      (pressureLevelAtAssessmentPosition >
      parseFloat(project.constants.backgroundNoiseLevel)
        ? pressureLevelAtAssessmentPosition
        : parseFloat(project.constants.backgroundNoiseLevel)) +
      parseFloat(correction);
    noise.noise = Math.ceil(decibelCorrection);
    noise.passed =
      noise.noise <=
      (
        (await getGlobal("otherConstants")) as { maximumAllowedNoise: number }[]
      )[0].maximumAllowedNoise;

    return { ...project.noise, ...noise, dbDistanceReduction, soundPower };
  } else if (project.noise)
    return { ...project.noise, everythingFilledOut: null };
  else return undefined;
};
export const heatPump = async (
  project: project_detail<"standard">,
  first_quote?: boolean
) => {
  if (project.heatPump || project.firstQuoteHeatPump) {
    let flowTemp = undefined as number | undefined;
    let heatPumpEan = undefined as string | undefined;
    let energyUsagePerYear = undefined as number | undefined;
    if (
      !first_quote &&
      project.heatPump?.everythingFilledOut &&
      project.heatingZones_lwt &&
      project.heatingZones_lwt.everythingFilledOut &&
      project.rooms &&
      project.rooms.everythingFilledOut &&
      project.general &&
      project.general.everythingFilledOut
    ) {
      if (
        !project.heatPump.ean ||
        project.heatPump.ean == "" ||
        !project.rooms.heatLoss ||
        !project.rooms.energyUsagePerYear
      ) {
        throw ReferenceError("Everythingfilled out but values undefined");
      }
      flowTemp = Math.max(
        ...project?.heatingZones_lwt?.heatingZones_lwt.map(
          (heatingZone_lwt) => heatingZone_lwt.flowTemperature
        )
      );
      heatPumpEan = project.heatPump.ean;
      energyUsagePerYear = project.rooms.energyUsagePerYear;
    } else if (
      first_quote &&
      project.firstQuoteHeatPump?.everythingFilledOut &&
      project.firstQuote?.everythingFilledOut &&
      project.general?.everythingFilledOut
    ) {
      if (!project.firstQuote.flowTemperature) {
        throw ReferenceError("Everythingfilled out but values undefined");
      }
      flowTemp = project.firstQuote.flowTemperature;
      heatPumpEan = project.firstQuoteHeatPump.ean || "";
      energyUsagePerYear = project.firstQuote.energyUsagePerYear || 0;
    }
    if (flowTemp) {
      let heatPump = {} as heatPumpProduct<"standard">;
      let performance = {} as { SCOP: number; runningCosts: number };
      [heatPump, performance] = await Promise.all([
        (
          (await setUpCollectionListener("data", true, undefined, [
            ["ean", "==", heatPumpEan || ""],
            ["type", "==", "heatPumpData"],
          ])) as QuerySnapshot<DocumentData>
        ).docs[0].data() as heatPumpProduct<"standard">,
        await getPerformance(
          project,
          energyUsagePerYear || 0,
          flowTemp,
          true,
          first_quote
        ),
      ]);
      return {
        everythingFilledOut: true,
        ...(!first_quote && { ...project.heatPump }),
        ...(first_quote && { ...project.firstQuoteHeatPump }),
        price: heatPump.price,
        SCOP: performance.SCOP,
        runningCosts: performance.runningCosts,
      };
    } else {
      return {
        ...(!first_quote && { ...project.heatPump }),
        ...(first_quote && { ...project.firstQuoteHeatPump }),
        everythingFilledOut: false,
      };
    }
  } else {
    return { everythingFilledOut: null };
  }
};
export const cylinder = async (
  project: project_detail<"standard">,
  first_quote?: boolean
) => {
  const start = new Date().getTime();
  if (project.cylinder || project.firstQuoteCylinder) {
    if (
      (!first_quote &&
        project.heatPump &&
        project.heatPump.everythingFilledOut &&
        project.general &&
        project.general.everythingFilledOut &&
        project.hotWater &&
        project.hotWater.everythingFilledOut &&
        project.heatPump &&
        project.heatPump.ean &&
        project.cylinder?.everythingFilledOut) ||
      (first_quote &&
        project.general?.everythingFilledOut &&
        project.firstQuoteHeatPump?.everythingFilledOut &&
        project.firstQuoteCylinder?.everythingFilledOut &&
        project.firstQuote?.everythingFilledOut &&
        project.firstQuoteCylinder?.everythingFilledOut)
    ) {
      if (
        !project.general ||
        !project.general.electricityTariff ||
        (!first_quote &&
          (!project.cylinder?.ean ||
            project.cylinder?.ean == "" ||
            !project.hotWater ||
            !project.hotWater.targetTemperature ||
            !project.hotWater.energyUsagePerYear == undefined ||
            !project.hotWater.immersionEnergyUsagePerYear == undefined)) ||
        (first_quote && !project.firstQuote?.cylinderSize)
      ) {
        throw ReferenceError("Everythingfilled out but ean undefined");
      }
      let cylinder = {} as cylinderProduct<"standard">;
      let performance = {} as { SCOP: number; runningCosts: number };
      let combination = {} as QueryDocumentSnapshot<DocumentData>[];
      [cylinder, performance, combination] = await Promise.all([
        (
          (await setUpCollectionListener("data", true, undefined, [
            [
              "ean",
              "==",
              first_quote
                ? project.firstQuoteCylinder?.ean || ""
                : project.cylinder?.ean || "",
            ],
            ["type", "==", "cylinderData"],
          ])) as QuerySnapshot<DocumentData>
        ).docs[0].data() as cylinderProduct<"standard">,
        await getPerformance(
          project,
          !first_quote
            ? project.hotWater?.energyUsagePerYear || 0
            : project.firstQuote?.waterEnergyUsage || 0,
          !first_quote ? project.hotWater?.targetTemperature || 0 : 55,
          undefined,
          first_quote
        ),
        (
          (await setUpCollectionListener("data", true, undefined, [
            [
              "ean",
              "==",
              first_quote
                ? project.firstQuoteCylinder?.ean || ""
                : project.cylinder?.ean || "",
            ],
            [
              "type",
              "==",
              first_quote
                ? project.firstQuoteHeatPump?.ean || ""
                : project.heatPump?.ean || "",
            ],
          ])) as QuerySnapshot<DocumentData>
        ).docs,
      ]);
      if (combination && combination[0] && combination[0].data()) {
        return {
          everythingFilledOut: true,
          ...(!first_quote && { ...project.cylinder }),
          ...(first_quote && { ...project.firstQuoteCylinder }),
          price: combination[0].data().price,
          manufacturer: cylinder.manufacturer,
          SCOP: performance.SCOP,
          runningCosts:
            performance.runningCosts +
            (!first_quote
              ? (project.hotWater?.immersionEnergyUsagePerYear ||
                  0 * project.general.electricityTariff) / 100
              : 0),
        };
      } else {
        return { everythingFilledOut: null };
      }
    } else {
      return { everythingFilledOut: false };
    }
  } else {
    return { everythingFilledOut: null };
  }
};
async function getPerformance(
  project: project_detail<"standard">,
  energy: number,
  temp: number,
  onlyConsiderHeatingSeason?: boolean,
  first_quote?: boolean
) {
  if (
    !project.locationParameters ||
    !project.locationParameters.postcode ||
    !project.general ||
    !project.general.electricityTariff ||
    (!first_quote && (!project.heatPump || !project.heatPump.ean)) ||
    (first_quote &&
      (!project.firstQuoteHeatPump || !project.firstQuoteHeatPump.ean))
  )
    throw ReferenceError("For getperformance these values have to be defined");
  // if (onlyConsiderHeatingSeason) {
  //   console.log("heree", project.locationParameters.postcode);
  //   console.log("heree", first_quote);
  //   console.log("heree", project.heatPump?.ean);
  //   console.log("heree", temp);
  //   console.log();
  // }
  const SCOP = await getSCOPs(
    project.locationParameters.postcode,
    !first_quote
      ? project.heatPump?.ean || ""
      : project.firstQuoteHeatPump?.ean || "",
    temp,
    onlyConsiderHeatingSeason ? "heatingOnly" : "year"
  );
  //by definition heating season is until April 30 and from october 1
  // const SCOPbelowIndex = Math.floor((temp - 30) / 5);
  // const SCOP =
  //   temp % 5 != 0
  //     ? parseFloat(heatPump.SCOP[SCOPbelowIndex]) +
  //       ((temp - Math.floor(temp / 5) * 5) /
  //         (Math.ceil(temp / 5) * 5 - Math.floor(temp / 5) * 5)) *
  //         (parseFloat(heatPump.SCOP[SCOPbelowIndex + 1]) -
  //           parseFloat(heatPump.SCOP[SCOPbelowIndex]))
  //     : parseFloat(heatPump.SCOP[SCOPbelowIndex]);
  const runningCosts =
    ((energy / SCOP) * project.general.electricityTariff) / 100;
  return { SCOP: SCOP, runningCosts: runningCosts };
}
