import React, { ReactNode } from "react";
import {
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { Appbar, useTheme } from "react-native-paper";
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabActions,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from "@react-navigation/native";
import { desktopStylePressables } from "../styles/desktop";
import { PressableState } from "../util/types";
// Props accepted by the view
type TabNavigationConfig = {
  tabBarStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

// Supported screen options
type TabNavigationOptions = {
  title?: string;
  header: (children: ReactNode | null) => ReactNode;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};
type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig;
//handle login logic
const TopNavigator = ({ initialRouteName, children, screenOptions }: Props) => {
    //integrate in top https://reactnavigation.org/docs/custom-navigators/
    const { state, navigation, descriptors, NavigationContent } =
        useNavigationBuilder<
        TabNavigationState<ParamListBase>,
        TabRouterOptions,
        TabActionHelpers<ParamListBase>,
        TabNavigationOptions,
        TabNavigationEventMap
        >(TabRouter, {
        children,
        screenOptions,
        initialRouteName,
        });
    const { colors } = useTheme();

    return (
        <NavigationContent>
            {descriptors[state.routes[state.index].key].options.header(
                <View style={{ flexDirection: "row", height: "100%" }}>
                    {state.routes.map((route) => (
                        <Pressable
                            key={route.key}
                            style={({ pressed, hovered, focused }: PressableState) =>
                                desktopStylePressables({ pressed, hovered, focused }, colors).topNavigatorButtons
                            }
                            onPress={() => {
                                const event = navigation.emit({
                                    type: "tabPress",
                                    target: route.key,
                                    canPreventDefault: true,
                                    data: {
                                        isAlreadyFocused:
                                        route.key === state.routes[state.index].key,
                                    },
                                });

                                if (!event.defaultPrevented) {
                                    navigation.dispatch({
                                        ...TabActions.jumpTo(route.name),
                                        target: state.key,
                                    });
                                }
                            }}>

                            <Appbar.Content
                                testID={descriptors[route.key].options.title || route.name}
                                key={route.key}
                                title={descriptors[route.key].options.title || route.name}
                                style={{ justifyContent: "center" }}
                                titleStyle={{ fontFamily: 'RobotoBold' }} />
                        </Pressable>
                    ))}
                </View>
            )}

            <View style={[{ flex: 1 }]}>
                {state.routes.map((route, i) => {
                    return (
                        <View
                            key={route.key}
                            style={[
                                StyleSheet.absoluteFill,
                                { display: i === state.index ? "flex" : "none" },
                            ]}>
                            {descriptors[route.key].render()}
                        </View>
                    );
                })}
            </View>
        </NavigationContent>
    );
};
export default createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof TopNavigator
>(TopNavigator);
