import {
  LayoutChangeEvent,
  ScrollView,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import {
  Checkbox,
  Divider,
  List,
  Menu,
  TextInput,
  TouchableRipple,
  useTheme,
} from "react-native-paper";
import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
  ReactNode,
} from "react";
import { Theme } from "react-native-paper/lib/typescript/types";
const DropDown = (props: DropDownPropsInterface) => {
  const activeTheme = useTheme();
  const {
    testID,
    multiSelect = false,
    visible,
    onDismiss,
    showDropDown,
    value,
    setValue,
    activeColor,
    mode,
    label,
    placeholder,
    inputProps,
    list,
    dropDownContainerMaxHeight,
    dropDownContainerHeight,
    theme,
    disable,
    dropDownStyle,
    dropDownItemStyle,
    dropDownItemSelectedStyle,
    dropDownItemTextStyle,
    dropDownItemSelectedTextStyle,
    accessibilityLabel,
    textStyle
  } = props;
  const [displayValue, setDisplayValue] = useState("");
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const onLayout = (event: LayoutChangeEvent) => {
    setInputLayout(event.nativeEvent.layout);
  };
  useEffect(() => {
    if (multiSelect) {
      const _labels = list
        .filter((_) => (value as unknown[]).indexOf(_.value) !== -1)
        .map((_) => _.label)
        .join(", ");
      setDisplayValue(_labels);
    } else {
      const _label = list.find((_) => _.value === value)?.label;
      if (_label) {
        setDisplayValue(_label);
      }
    }
  }, [list, value]);
  const isActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        return (value as unknown[]).indexOf(currentValue) !== -1;
      } else {
        return value === currentValue;
      }
    },
    [value]
  );
  const setActive = useCallback(
    (currentValue) => {
      if (multiSelect) {
        const valueIndex = (value as unknown[]).indexOf(currentValue);
        const values = (value as string).split(",");
        if (valueIndex === -1) {
          setValue([...values, currentValue].join(","));
        } else {
          setValue(
            [...values].filter((value) => value !== currentValue).join(",")
          );
        }
      } else {
        setValue(currentValue);
      }
    },
    [value]
  );
  return (
    <Menu
      visible={visible}
      onDismiss={onDismiss}
      anchor={
        <TouchableRipple
          testID={testID}
          onPress={showDropDown}
          onLayout={onLayout}
          accessibilityLabel={accessibilityLabel}
          disabled={disable}
        >
          <View pointerEvents={"none"}>
            <TextInput
              dense={false}
              value={displayValue}
              mode={mode}
              label={label}
              placeholder={placeholder}
              pointerEvents={"none"}
              right={
                <TextInput.Icon name={visible ? "menu-up" : "menu-down"} />
              }
              {...inputProps}
              selectionColor={inputProps?.selectionColor as string | undefined}
              style={{ opacity: disable ? 0.5 : 1, ...textStyle }}
            />
          </View>
        </TouchableRipple>
      }
      style={{
        maxWidth: inputLayout?.width,
        width: inputLayout?.width,
        marginTop: inputLayout?.height,
        ...dropDownStyle,
      }}
    >
      <ScrollView
        testID="dropdown-view"
        bounces={false}
        style={{
          ...(dropDownContainerHeight
            ? {
                height: dropDownContainerHeight,
              }
            : {
                maxHeight: dropDownContainerMaxHeight || 200,
              }),
        }}
      >
        {list.map((_item) => (
          <Fragment key={_item.value}>
            <TouchableRipple
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
              onPress={() => {
                setActive(_item.value);
                if (!multiSelect && onDismiss) {
                  onDismiss();
                }
              }}
            >
              <Fragment>
                <List.Item
                  testID={_item.label}
                  titleStyle={{
                    color: isActive(_item.value)
                      ? activeColor || (theme || activeTheme).colors.primary
                      : (theme || activeTheme).colors.text,
                    ...(isActive(_item.value)
                      ? dropDownItemSelectedTextStyle
                      : dropDownItemTextStyle),
                  }}
                  title={_item.custom || _item.label}
                  titleNumberOfLines={3}
                  style={{
                    flex: 1,
                    maxWidth: inputLayout?.width,
                    ...(isActive(_item.value)
                      ? dropDownItemSelectedStyle
                      : dropDownItemStyle),
                  }}
                />
                {multiSelect && (
                  <Checkbox.Android
                    theme={{
                      colors: { accent: activeTheme?.colors.primary },
                    }}
                    status={isActive(_item.value) ? "checked" : "unchecked"}
                    onPress={() => setActive(_item.value)}
                  />
                )}
              </Fragment>
            </TouchableRipple>
            <Divider />
          </Fragment>
        ))}
      </ScrollView>
    </Menu>
  );
};

interface DropDownPropsInterface {
  testID?: string | undefined;
  disable?: boolean;
  visible: boolean;
  multiSelect?: boolean;
  onDismiss: () => void;
  showDropDown: () => void;
  value: unknown;
  setValue: (_value: unknown) => void;
  label?: string | undefined;
  placeholder?: string | undefined;
  mode?: "outlined" | "flat" | undefined;
  inputProps?: TextInputProps;
  list: Array<{
    label: string;
    value: string | number;
    custom?: ReactNode;
  }>;
  dropDownContainerMaxHeight?: number;
  dropDownContainerHeight?: number;
  activeColor?: string;
  theme?: Theme;
  dropDownStyle?: ViewStyle;
  dropDownItemSelectedTextStyle?: TextStyle;
  dropDownItemSelectedStyle?: ViewStyle;
  dropDownItemStyle?: ViewStyle;
  dropDownItemTextStyle?: TextStyle;
  accessibilityLabel?: string;
  textStyle?: any;
}
export default DropDown;
