import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    icon: {
        marginRight: 10
    },
    errorMessage: {
        flexDirection: 'row',
        marginTop: 10
    }
});

export default styles;