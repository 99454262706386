import React from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { projectDetailNavigationProps } from "../../util/navigationProps";
import { QuestionList } from "../../components/QuestionList";
import { View } from "react-native";
import { Headline } from "react-native-paper";
import basicStyles from "../../styles/basic";
import { SignupProps } from "../../util/types";

export const InstallerSignup = (props: SignupProps) => {
  return (
    <View style={[basicStyles.container, { margin: 5 }]}>
      <View style={{ margin: 5 }}>
        <Headline style={{ fontSize: 20 }}>
          Welcome to the Carno sizing tool, please complete the form to create
          an account.
        </Headline>
      </View>
      <QuestionList
        navigation={
          {} as StackNavigationProp<projectDetailNavigationProps, "Questions">
        }
        route={{
          params: {
            displayedQuestionCollection: "installerSignup",
            nestedPath: undefined,
            valueStorageDocument: props.uid,
            valueStoragePath: "installers",
            categoryOfAnswers: "installerSignup",
          },
          key: "installer",
          name: "Questions",
        }}
      />
    </View>
  );
};
