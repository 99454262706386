import React, { useContext, useState } from "react";
import {
  Button,
  TextInput,
  Headline,
  Text,
  Switch,
  useTheme,
} from "react-native-paper";
import { View } from "react-native";
import { Notification } from "../../components/Dialogs";
import {
  DesktopContext,
  InstallerContext,
  OrganisationContext,
} from "../../util/contexts";
import { sendSignInLink } from "../../util/firebaseConnection";
import { desktopStyle } from "../../styles/desktop";
import { mobileStyle } from "../../styles/mobile";

export const MemberInvite = () => {
  const [email, setEmail] = useState<string>("");
  const [admin, setAdmin] = useState<boolean>(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const userInfo = useContext(InstallerContext);
  const orgInfo = useContext(OrganisationContext);
  const isDesktop = useContext(DesktopContext);
  const { colors } = useTheme();

  return (
    <View style={{ margin: 5 }}>
      <Notification
        visible={alertVisible}
        setVisible={setAlertVisible}
        invitee={email}
      />
      <Headline>Invite members to join {orgInfo.organisationName}</Headline>
      <View
        style={
          isDesktop
            ? desktopStyle(colors).memberInvite
            : mobileStyle().memberInvite
        }
      >
        <TextInput
          style={isDesktop ? desktopStyle(colors).question : { width: "80%" }}
          mode="outlined"
          label={"recipient email"}
          value={email}
          error={errorVisible}
          onChangeText={(text) => setEmail(text)}
        />
        <View
          style={
            isDesktop
              ? desktopStyle(colors).memberInviteSwitch
              : mobileStyle().memberInviteSwitch
          }
        >
          <Button
            mode="contained"
            onPress={() => {
              sendSignInLink(
                email,
                admin,
                userInfo.orgId || "",
                setAlertVisible,
                setErrorVisible
              );
              setAdmin(false);
            }}
            style={{ width: 100, height: 35, margin: 5 }}
          >
            invite
          </Button>
          <View
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Text style={{ padding: 5 }}>invite as admin</Text>
            <View style={{ padding: 5 }}>
              <Switch
                value={admin}
                color={"green"}
                onValueChange={() => {
                  setAdmin(!admin);
                }}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
