import { Platform } from "react-native";
import { store } from "../redux/store";
import { clearMasterData } from "../redux/modules/masterData";
import nativeFirestore, {
  FirebaseFirestoreTypes,
} from "@react-native-firebase/firestore";
import nativeStorage from "@react-native-firebase/storage";
import nativeFunctions from "@react-native-firebase/functions";
import nativeAuth, { FirebaseAuthTypes } from "@react-native-firebase/auth";
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  OrderByDirection,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  runTransaction,
  setDoc,
  startAfter,
  updateDoc,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import auth from "@react-native-firebase/auth";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  updatePassword,
  User as sdkUser,
} from "firebase/auth";
import firebase from "@react-native-firebase/app";
import { FirebaseError, initializeApp } from "firebase/app";
import { authError } from "./errors";
import { get as objGet, set as objSet } from "lodash";
import {
  getFunctions,
  HttpsCallable,
  httpsCallable,
} from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import * as Linking from "expo-linking";
import Constants from "expo-constants";
import { project_general, reportType } from "./types";
//import { ENVIRONMENT } from "react-native-dotenv";
//for uploading files: https://firebase.google.com/docs/storage/web/upload-files

if (
  !Constants.manifest ||
  !Constants.manifest.extra ||
  !Constants.manifest.extra.environment ||
  !Constants.manifest.extra.forceFirebaseSDK
) {
  throw TypeError("Manifest not found");
}
const forceFirebaseSDK =
  Constants.manifest.extra.forceFirebaseSDK == "true" ? true : false;
const firebaseConfig =
  Constants.manifest.extra.environment === "development"
    ? {
        apiKey: "AIzaSyDpzLMV-g0DTXqMXP0qDBOGvACVX6fkhw4",
        authDomain: "heatrify-dev.firebaseapp.com",
        projectId: "heatrify-dev",
        storageBucket: "heatrify-dev.appspot.com",
        messagingSenderId: "941089446104",
        appId: "1:941089446104:web:e4f530a1b31be4d1609fad",
        measurementId: "G-991N71EWTD",
      }
    : Constants.manifest.extra.environment !== "production"
    ? {
        apiKey: "AIzaSyCk971YIB9lMNgnvkDIiHhJCs1kW9g3TBU",
        authDomain: "carnostaging.firebaseapp.com",
        projectId: "carnostaging",
        storageBucket: "carnostaging.appspot.com",
        messagingSenderId: "100080135838",
        appId: "1:100080135838:web:1066abbaaee7bbedd5d58f",
        measurementId: "G-ECD9YR2NWF"
      }    
    : {
        apiKey: "AIzaSyBvI7AdUquJUgaXwRhFt6-w7-7z6dkDFm0",
        authDomain: "heatrify.firebaseapp.com",
        projectId: "heatrify",
        storageBucket: "heatrify.appspot.com",
        messagingSenderId: "574212586502",
        appId: "1:574212586502:web:1d018f86fefe4317db1787",
        measurementId: "G-CJ23RRSE5L",
      };

const firebaseApp = initializeApp(firebaseConfig); //next step: set up real time listener for changes
const firestore = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp);

//for local tests:
//Constants.manifest.extra.environment !== "production" &&
//  connectFunctionsEmulator(functions, "localhost", 5001);
//Constants.manifest.extra.environment !== "production" &&
//  connectFirestoreEmulator(firestore, "localhost", 8082);

export async function enableOffline() {
  if (Platform.OS != "ios" && Platform.OS != "android") {
    //ONLY WORKS FOR CERTAIN BROWSERS!!! TODO: ensure that not multiple browser windows open!!
    //await enableIndexedDbPersistence(firestore);
  } else if (!forceFirebaseSDK) {
    await nativeFirestore().settings({
      //apparently automatically enabled: https://rnfirebase.io/firestore/usage#offline-capabilities
      persistence: true, // disable offline persistence
    });
  }
  return true;
}
/**
 * Description: get the document data from firebase firestore
 * @param collection
 * @param docID
 * @returns the target document data
 */
export async function getDocument<T>(
  collection: string,
  docID: string
): Promise<T> {
  let document: T;
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const q = doc(firestore, collection, docID);
    document = (await getDoc(q)).data() as T;
  } else {
    const doc = nativeFirestore().collection(collection).doc(docID);
    document = (await doc.get()).data() as T;
  }
  return document;
}

/**
 * Description:
 * @param collectionName
 * @param oneOffCall
 * @param callback
 * @param whereConditions
 * @param orderByCondition
 * @param limitCondition
 * @param startAfterCondition
 * @returns
 */
export const setUpCollectionListener = (
    collectionName: string,
    oneOffCall: boolean,
    callback?: (
        query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
    ) => void,
    whereConditions?: string[][],
    orderByCondition?: string[],
    limitCondition?: number,
    startAfterCondition?: QueryDocumentSnapshot<DocumentData>
) => {
    if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
        const queryConstraints =
        (whereConditions &&
            whereConditions.map((whereCondition) =>
                where(
                    whereCondition[0] === "firestoreDocId"
                    ? documentId()
                    : whereCondition[0],
                    whereCondition[1] as WhereFilterOp,
                    whereCondition[2]
                )
            )) ||
        ([] as QueryConstraint[]);

        orderByCondition &&
            orderByCondition.length > 0 &&
            queryConstraints.push(
                orderBy(orderByCondition[0], orderByCondition[1] as OrderByDirection)
            );

        if ((whereConditions as string[][]).length <= 1) {
          limitCondition && queryConstraints.push(limit(limitCondition));
        }

        startAfterCondition &&
            queryConstraints.push(startAfter(startAfterCondition));

        if (oneOffCall) {
            return getDocs(
                query(collection(firestore, collectionName), ...queryConstraints)
            );
        } else {
            if (!callback) throw ReferenceError("Callback has to be set");
            return onSnapshot(
                query(collection(firestore, collectionName), ...queryConstraints),
                (query) => callback(query)
            );
        }
    } else {
        let query = nativeFirestore().collection(collectionName);

        whereConditions &&
            whereConditions.forEach((whereCondition) => {
                query = query.where(
                    whereCondition[0] === "firestoreDocId"
                        ? firebase.firestore.FieldPath.documentId()
                        : whereCondition[0],
                    whereCondition[1] as WhereFilterOp,
                    whereCondition[2]
                ) as FirebaseFirestoreTypes.CollectionReference<FirebaseFirestoreTypes.DocumentData>;
            });

        if (orderByCondition && orderByCondition.length > 0) {
            query = query.orderBy(
                orderByCondition[0],
                orderByCondition[1] as OrderByDirection
            ) as FirebaseFirestoreTypes.CollectionReference<FirebaseFirestoreTypes.DocumentData>;
        }

        if (limitCondition) {
            query = query.limit(
                limitCondition
            ) as FirebaseFirestoreTypes.CollectionReference<FirebaseFirestoreTypes.DocumentData>;
        }

        if (startAfterCondition && startAfterCondition.id) {
            query = query.startAfter(
                startAfterCondition
            ) as FirebaseFirestoreTypes.CollectionReference<FirebaseFirestoreTypes.DocumentData>;
        }

        if (oneOffCall) {
            return query.get();
        } else {
            if (!callback) throw ReferenceError("Callback has to be set");
            return query.onSnapshot((query) => callback(query));
        }
    }
};

/**
 * Description: fetch all docs from a collection on firestore
 * @param collectionName: collection name to fetch from, e.g. "questions"
 * @returns loadAll: question_detail array (question objects fetched from firestore)
 */
export async function loadAll<T>(
  collectionName: string,
  version: string,
  step?: boolean
): Promise<T[]> {
  //todo: seperate user into extra table
  const loadAll = [] as T[];
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    let queryResults = {} as QuerySnapshot<DocumentData>;
    if (step)
      queryResults = await getDocs(
        query(
          collection(firestore, collectionName),
          where("version", "==", version.split(".")[0]),
          orderBy("step", "asc")
        )
      );
    else
      queryResults = await getDocs(
        query(
          collection(firestore, collectionName),
          where("version", "==", version.split(".")[0])
        )
      );
    queryResults.forEach((doc) => {
      let tmp = doc.data();
      if (tmp["\ufeffquestion_text"]) {
        //todo: find out why he is receiving \ufeff
        tmp = { ...doc.data(), question_text: tmp["\ufeffquestion_text"] };
        delete tmp["\ufeffquestion_text"];
      }
      if (tmp["\ufeffpressureLevel"]) {
        //todo: find out why he is receiving \ufeff
        tmp = { ...doc.data(), pressureLevel: tmp["\ufeffpressureLevel"] };
        delete tmp["\ufeffpressureLevel"];
      }
      loadAll.push(tmp as T);
    });
  } else {
    let query =
      {} as FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>;
    if (step)
      query = await nativeFirestore()
        .collection(collectionName)
        .where("version", "==", version.split(".")[0])
        .orderBy("step", "asc")
        .get();
    else
      query = await nativeFirestore()
        .collection(collectionName)
        .where("version", "==", version.split(".")[0])
        .get();
    query.forEach((doc) => {
      let tmp = doc.data();
      if (tmp["\ufeffquestion_text"]) {
        //todo: find out why he is receiving \ufeff
        tmp = { ...doc.data(), question_text: tmp["\ufeffquestion_text"] };
        delete tmp["\ufeffquestion_text"];
      }
      if (tmp["\ufeffpressureLevel"]) {
        //todo: find out why he is receiving \ufeff
        tmp = { ...doc.data(), pressureLevel: tmp["\ufeffpressureLevel"] };
        delete tmp["\ufeffpressureLevel"];
      }
      loadAll.push(tmp as T);
    });
  }
  return loadAll;
}
export const setUpDocListener = (
  path: string,
  document: string,
  callback: (
    doc: DocumentSnapshot | FirebaseFirestoreTypes.DocumentSnapshot
  ) => void
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return onSnapshot(doc(firestore, path, document), (doc) => callback(doc));
  } else {
    return nativeFirestore()
      .collection(path)
      .doc(document)
      .onSnapshot((doc) => callback(doc));
  }
};

/*
export const setUpCollectionListenere = (
  path: string,
  callback: (
    query: QuerySnapshot | FirebaseFirestoreTypes.QuerySnapshot
  ) => void
) => {
  //todo: add limit potentially
  if (!getUser()) throw ReferenceError("User has to be logged in");
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return onSnapshot(
      query(
        collection(firestore, path),
        orderBy("created", "asc"),
        where("accessRights.read", "array-contains", getUser()?.uid)
      ),
      (query) => callback(query)
    );
  } else {
    return nativeFirestore()
      .collection(path)
      .orderBy("created", "asc")
      .where("accessRights.read", "array-contains", getUser()?.uid)
      .onSnapshot((query) => callback(query));
  }
};
*/

export const updateOrCreateDoc = async (
    path: string,
    document: string,
    updatedValues: object
) => {
    if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
        await setDoc(doc(firestore, path, document), {
            ...updatedValues,
            lastEdited: Date.now()
        }, { merge: true });
    } else {
        await nativeFirestore()
            .collection(path)
            .doc(document)
            .set({ ...updatedValues, lastEdited: Date.now() });
    }
}

export const mergeDataWithDocument = async (
    path: string,
    document: string,
    updatedValues: object,
    isArrayMerge?: boolean,
    pathToArrayElement?: string,
    variablesToKeepInArray?: string[]
) => {
    if (!isArrayMerge) {
        if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
            await updateDoc(doc(firestore, path, document), {
                ...updatedValues,
                lastEdited: Date.now(),
            });
        } else {
            await nativeFirestore()
                .collection(path)
                .doc(document)
                .update({ ...updatedValues, lastEdited: Date.now() });
        }
    } else {
        if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {

            await runTransaction(firestore, async (transaction) => {
                const docRef = doc(firestore, path, document);
                const snapshot = await transaction.get(docRef);

                if (!pathToArrayElement) {
                    throw ReferenceError(
                        "If isArrayMerge, then pathToArrayElement must be set."
                    );
                }

                let updatedElement = objSet(
                    snapshot.data() || {},
                    pathToArrayElement,
                    objGet(updatedValues, pathToArrayElement)
                );

                variablesToKeepInArray?.forEach((variableToKeepInArray) => {
                    const current = objGet(
                        snapshot.data(),
                        pathToArrayElement + "." + variableToKeepInArray
                    );
                    updatedElement = objSet(
                        updatedElement,
                        pathToArrayElement + "." + variableToKeepInArray,
                        current != undefined ? current : ""
                    );
                });

                transaction.update(docRef, {
                    ...updatedElement,
                    lastEdited: Date.now(),
                });
            });
        } else {
            await nativeFirestore().runTransaction(async (transaction) => {
                const docRef = nativeFirestore().collection(path).doc(document);
                const snapshot = await transaction.get(docRef);

                if (!pathToArrayElement) {
                    throw ReferenceError(
                        "If isArrayMerge, then pathToArrayElement must be set."
                    );
                }

                let updatedElement = objSet(
                    snapshot.data() || {},
                    pathToArrayElement,
                    objGet(updatedValues, pathToArrayElement)
                );

                variablesToKeepInArray?.forEach((variableToKeepInArray) => {
                    const current = objGet(
                        snapshot.data(),
                        pathToArrayElement + "." + variableToKeepInArray
                    );

                    updatedElement = objSet(
                        updatedElement,
                        pathToArrayElement + "." + variableToKeepInArray,
                        current != undefined ? current : ""
                    );
                });

                transaction.update(docRef, {
                    ...updatedElement,
                    lastEdited: Date.now(),
                });
            });
        }
    }
};

export const createDocument = async (
  path: string,
  document: string,
  values: object
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    await setDoc(doc(firestore, path, document), values);
  } else {
    await nativeFirestore().collection(path).doc(document).set(values);
  }
};
export const addToArray = async (
  path: string,
  document: string,
  arrayPath: string,
  valueToChange: string
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    await updateDoc(
      doc(firestore, path, document),
      objSet({}, arrayPath, arrayUnion(valueToChange))
    );
  } else {
    await nativeFirestore()
      .collection(path)
      .doc(document)
      .update(
        objSet(
          {},
          arrayPath,
          firebase.firestore.FieldValue.arrayUnion(valueToChange)
        )
      );
  }
};

export const deleteDocument = async (path: string, document: string) => {
    if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
        await deleteDoc(doc(firestore, path, document));
    } else {
        await nativeFirestore().collection(path).doc(document).delete();
    }
};

export const deleteProject = async (projectId: string) => {
  const rooms = await (setUpCollectionListener(
    `projects/${projectId}/rooms`,
    true,
    undefined,
    [["accessRights.read", "array-contains", getUser()?.uid ?? ""]]
  ) as Promise<QuerySnapshot<DocumentData>>);
  rooms.forEach((room) => {
    deleteDocument(`projects/${projectId}/rooms`, room.id);
  });
  deleteDocument("projects", projectId);
};
export const loadStorage = (filePath: string) => {
  //CORS ORIGIN ERROR: https://firebase.google.com/docs/storage/web/download-files#cors_configuration
  //https://cloud.google.com/storage/docs/configuring-cors
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, filePath);
    getDownloadURL(storageRef).then((url) => {
      Linking.openURL(url);
    }); //getBlob for images
  } else {
    nativeStorage()
      .ref(filePath)
      .getDownloadURL()
      .then((url) => {
        Linking.openURL(url);
      });
  }
};
export const getImageLink = async (filePath: string) => {
  let imageUrl = '';

  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, filePath);

    imageUrl = await getDownloadURL(storageRef);
  } else {
    imageUrl = await nativeStorage().ref(filePath).getDownloadURL();
  }

  return imageUrl;
};

export const deleteFile = async (filePath: string) => {
  if ((Platform.OS != 'ios' && Platform.OS != 'android') || forceFirebaseSDK) {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, filePath);

    await deleteObject(storageRef);
  } else {
    await nativeStorage().ref(filePath).delete();
  }
};

/**
 * Description: handles user authentication, called in LoginScreen login button
 * @param email
 * @param password
 * @param changeError
 */
export const emailLogin = (
  email: string,
  password: string,
  changeError: (errorMail?: string, errorPW?: string) => void
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, email, password).catch((error) => {
      authError(error, changeError);
    });
  } else {
    nativeAuth()
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        authError(error, changeError);
      });
  }
};

export const emailLinkSignIn = (email: string, API_KEY: string) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const auth = getAuth(firebaseApp);
    signInWithEmailLink(auth, email, API_KEY).catch((error) => {
      console.error(error);
    });
  } else {
    firebase
      .auth()
      .signInWithEmailLink(email, API_KEY)
      .catch((error) => {
        console.error(error);
      });
  }
};

export const uploadFile = async (
  uid: string,
  localUri: string,
  filePath: string
) => {
  const blob = (await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", localUri, true);
    xhr.send(null);
  })) as Blob;
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, uid + "/" + filePath);
    return await uploadBytes(storageRef, blob);
  } else {
    const ref = nativeStorage().ref(uid + "/" + filePath);
    return await ref.put(blob);
  }
};
/**
 * Description: reset user password, if this is their first time setting a password (added through invite link), then update their installer doc
 * @param password
 * @param activation true if first time setting password (signup through invite link)
 */
export const resetPassword = (password: string, activation: boolean) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const auth = getAuth();
    const user = auth.currentUser;
    user &&
      updatePassword(user, password)
        .then(() => {
          activation &&
            mergeDataWithDocument("installers", user?.uid, {
              active: true,
            });
        })
        .catch((error) => {
          console.error(error);
        });
  } else {
    const user = firebase.auth().currentUser;
    user &&
      user
        .updatePassword(password)
        .then(() => {
          activation &&
            mergeDataWithDocument("installers", user?.uid, {
              active: true,
            });
        })
        .catch((error) => {
          console.error(error);
        });
  }
};
/**
 * Description: handles user authentication, called in LoginScreen login button
 * @param email
 * @param password
 * @param changeError
 */
export const createUser = async (
  email: string,
  password: string,
  changeError: (errorMail?: string, errorPW?: string) => void
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const company = urlParams.get('company') || '';

  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const auth = getAuth(firebaseApp);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        createDocument("installers", userCredentials.user.uid, {
          active: true,
          everythingFilledOut: false,
          installerEmail: email,
          company
        });
      })
      .catch((error) => {
        authError(error, changeError);
      });
  } else {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredentials) => {
        createDocument("installers", userCredentials.user.uid, {
          active: true,
          everythingFilledOut: false,
          installerEmail: email,
          company
        });
      })
      .catch((error) => {
        authError(error, changeError);
      });
  }
};
/**
 * Description: creates a user in the backend to be called when an admin sends an email invite to join their org
 * @param email (string) email typed in by the admin
 */
export const createUserInBackend = async (email: string) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const fetchUid = httpsCallable(functions, "createUser");

    const uid = (await fetchUid({ email: email })) as {
      data: string;
    };
    return uid.data;
  } else {
    const fetchUid = nativeFunctions().httpsCallable("createUser");
    const uid = (await fetchUid({ email: email })) as { data: string };
    return uid.data;
  }
};
/**
 * Description: sends a sign in link, creates an installer doc and adds the user to the organisation document
 * @param email: (string) email to send installer invite link to
 * @param admin: (boolean) determines logic for
 */
export const sendSignInLink = (
  email: string,
  admin: boolean,
  orgId: string,
  setAlertVisible: (input: boolean) => void,
  setErrorVisible: (input: boolean) => void
) => {
  const hostName = window.location.href.split("Entry")[0];
  if (!Constants.manifest || !Constants.manifest.extra) {
    throw TypeError("Manifest not found");
  }
  const bundleID =
    Constants.manifest.extra.environment !== "production"
      ? "com.heatrify-dev.installerTool"
      : "com.heatrify.installerTool";

  createUserInBackend(email)
    .then(async (uid) => {
      const actionCodeSettings = {
        handleCodeInApp: true,
        url: `${hostName}Signup/email=${email}`,
        iOS: {
          bundleId: bundleID,
        },
        android: {
          packageName: bundleID,
          installApp: true,
          minimumVersion: "12",
        },
      };
      //send the sign in link, then add admins to both installers/admins arrays, and installers only to installers array
      if (Platform.OS != "ios" && Platform.OS != "android") {
        const webAuth = getAuth();
        sendSignInLinkToEmail(webAuth, email, actionCodeSettings).then(() => {
          setAlertVisible(true);
          if (admin) {
            addToArray("organisations", orgId, "admins", uid);
          }
          addToArray("organisations", orgId, "installers", uid);
          createDocument("installers", uid, {
            active: false,
            everythingFilledOut: false,
            installerEmail: email,
            orgId: orgId,
            role: admin ? "admin" : "installer",
          })
            .then(() => setErrorVisible(false))
            .catch((error: FirebaseError) => {
              if (error.code == "permission-denied") {
                console.warn("Installer Document already exists.");
                setErrorVisible(true);
              } else throw error;
            });
        });
      } else {
        await auth()
          .sendSignInLinkToEmail(email, actionCodeSettings)
          .then(() => {
            setAlertVisible(true);
            if (admin) {
              addToArray("organisations", orgId, "admins", uid);
            }
            addToArray("organisations", orgId, "installers", uid);
            createDocument("installers", uid, {
              active: false,
              everythingFilledOut: false,
              installerEmail: email,
              orgId: orgId,
            })
              .then(() => setErrorVisible(false))
              .catch((error: FirebaseError) => {
                if (error.code == "permission-denied") {
                  console.warn("Installer Document already exists.");
                  setErrorVisible(true);
                } else throw error;
              });
          });
      }
    })
    .catch((error) => console.error(error));
};

export const authListener = (
  observer: (authChanged: sdkUser | FirebaseAuthTypes.User | null) => void
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return getAuth(firebaseApp).onAuthStateChanged(observer);
  } else {
    return nativeAuth().onAuthStateChanged(observer);
  }
};
export const getUser = () => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return getAuth().currentUser;
  } else {
    return nativeAuth().currentUser;
  }
};
export const getPlatformAuth = () => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return getAuth();
  } else {
    return nativeAuth();
  }
};
export const logout = () => {
  store.dispatch(clearMasterData());

  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    return getAuth().signOut();
  } else {
    return nativeAuth().signOut();
  }
};

//FUNCTIONS //POTENTIALLY ADJUST REGION: https://stackoverflow.com/questions/57547745/how-to-use-httpscallable-on-a-region-other-then-us-central1-for-web
export const createReport = (
    projectId: string,
    projectInfo: project_general<'standard'>,
    setGeneratingReport: (input: boolean) => void,
    type: reportType,
    orgId: string,
    imageURI: string,
    first_quote: boolean
) => {
    if ((Platform.OS != 'ios' && Platform.OS != 'android') || forceFirebaseSDK) {
        let generateReport: HttpsCallable<unknown, unknown>;
        let fileType: '.xlsx' | '.docx';

        if (type == 'MCS-performance-estimate') fileType = '.xlsx';
        else fileType = '.docx';

        if (fileType == '.docx') generateReport = httpsCallable(functions, 'generateReport');
        else generateReport = httpsCallable(functions, 'generateExcel');

        const fileName = type === 'standard'
            ? `${projectInfo.propertyAddressLine1}, ${projectInfo.postcode} Heat Loss summary, room and radiator schedule${fileType}`
            : type + fileType;

        generateReport({
            projectId: projectId,
            type: type,
            orgId: orgId,
            imageURI: imageURI,
            sheetNumber: 1,
            first_quote: first_quote,
            fileName
        }).then(() => {
            loadStorage(getUser()?.uid + '/' + fileName);
            setGeneratingReport(false);
        });
    } else {
        let generateReport: HttpsCallable;
        let fileType: '.xlsx' | '.docx';

        if (type == 'MCS-performance-estimate') fileType = '.xlsx';
        else fileType = '.docx';

        if (fileType == '.docx') generateReport = nativeFunctions().httpsCallable('generateReport');
        else generateReport = nativeFunctions().httpsCallable('generateExcel');

        const fileName = type === 'standard'
            ? `${projectInfo.propertyAddressLine1}, ${projectInfo.postcode} Heat Loss summary, room and radiator schedule${fileType}`
            : type + fileType;

        generateReport({
            projectId: projectId,
            type: type,
            orgId: orgId,
            sheetNumber: 1,
            first_quote: first_quote,
            fileName
        }).then(() => {
            loadStorage(getUser()?.uid + '/' + fileName);
            setGeneratingReport(false);
        });
    }
};

export const getSCOPs = async (
  postcode: string,
  ean: string,
  flowTemp: number,
  mode: "heatingOnly" | "year"
) => {
  if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
    const calcSCOP = httpsCallable(functions, "calcSCOP");
    return (
      (await calcSCOP({
        postcode: postcode,
        ean: ean,
        flowTemp: flowTemp,
        mode: mode,
      })) as {
        data: number;
      }
    ).data;
  } else {
    const calcSCOP = nativeFunctions().httpsCallable("calcSCOP");
    return (
      (await calcSCOP({
        postcode: postcode,
        ean: ean,
        flowTemp: flowTemp,
        mode: mode,
      })) as {
        data: number;
      }
    ).data;
  }
};

export const getRoomValuesFromMagicplanCSV = async (base64Data: string) => {
    if ((Platform.OS != 'ios' && Platform.OS != 'android') || forceFirebaseSDK) {
        const extractFromMagicPlanCSV = httpsCallable(functions, 'generateJSONFromCSV');
        return (
            (await extractFromMagicPlanCSV({
                base64Data
            })) as {
                data: Object;
            }
        ).data;
    } else {
        const extractFromMagicPlanCSV = nativeFunctions().httpsCallable('generateJSONFromCSV');
        return (
            (await extractFromMagicPlanCSV({
                base64Data
            })) as {
                data: Object;
            }
        ).data;
    }
};

export const updateDocument = async (
    path: string,
    document: string,
    updatedValues: object
) => {
    if ((Platform.OS != "ios" && Platform.OS != "android") || forceFirebaseSDK) {
        await updateDoc(doc(firestore, path, document), {
            ...updatedValues,
            lastEdited: Date.now(),
        });
    } else {
        await nativeFirestore()
            .collection(path)
            .doc(document)
            .update({ ...updatedValues, lastEdited: Date.now() });
    }
};
