import { getDocument, setUpCollectionListener } from "./../firebaseConnection";
import {
  existingRadiator,
  newRadiator,
  project_detail,
  radiatorType,
  room_detail,
  zones_lwt,
} from "../types";
import { DocumentData, getDoc, QuerySnapshot } from "firebase/firestore";
import { getGlobal } from "../asyncStorageConnection";
import { isUndefined } from "lodash";

const radiatorHeatGeneration = async (
    existingRadiator: existingRadiator<"standard"> | any,
    newRadiator: newRadiator<"standard">,
    correctionFactor: number
) => {
    let heatGeneration = 0;
    let radiatorHeatOutput = 0;

    if (existingRadiator.everythingFilledOut) {
        const radiatorSizes = (
            (await getGlobal("possibleRadiatorSizes")) as {
                availableRadiators: {
                    type: radiatorType;
                    availableLengths: string[];
                    height: string[];
                    outputPerMeter: string[];
                }[];
            }[]
        )[0].availableRadiators.filter(
            (sizes) => sizes.type == existingRadiator.radiatorType
        )[0];

        if (!isUndefined(radiatorSizes)) {
            radiatorHeatOutput =
                (1 / 1000) *
                parseInt(
                    radiatorSizes.outputPerMeter[
                        radiatorSizes.height.findIndex(
                        (height) => height == existingRadiator.height
                        )
                    ]
                ) *
                parseInt(existingRadiator.length);
            existingRadiator.heatOutput = (radiatorHeatOutput || 0) * correctionFactor;
        }

        if (existingRadiator.doNotReplace == true || newRadiator.replace != true) {
            newRadiator.heatOutput = (radiatorHeatOutput || 0) * correctionFactor;
        } else if (newRadiator && newRadiator.everythingFilledOut) {
            newRadiator.heatGenerated = newRadiator.heatOutput = // dito in-output yung heat generated per radiator
                (
                await (setUpCollectionListener("data", true, undefined, [
                    ["type", "==", "radiatorData"],
                    ["ean", "==", newRadiator.ean],
                ]) as Promise<QuerySnapshot<DocumentData>>)
                )?.docs[0].data().heatOutput * correctionFactor;
            }
            heatGeneration += newRadiator.heatOutput ? newRadiator.heatOutput : 0;
    } else {
        heatGeneration = 0;
    }

    return heatGeneration;
};

export const calcRoomGeneration = async (
  room: room_detail<"standard">,
  project: project_detail<"standard">
) => {
    const hasRadiatorArray = (room.existingRadiators && room.newRadiators) || room.addedRadiators;

    if (
        hasRadiatorArray &&
        room.general &&
        room.general.zone &&
        room.general.designTemperature &&
        project.heatingZones_lwt &&
        project.heatingZones_lwt.heatingZones_lwt[room.general.zone] &&
        project.heatingZones_lwt.heatingZones_lwt[room.general.zone]
        .flowTemperature &&
        project.heatingZones &&
        project.heatingZones.heatingZones[room.general.zone] &&
        (project.heatingZones.heatingZones[room.general.zone].emitterType ==
        "Radiator" ||
        project.heatingZones.heatingZones[room.general.zone].emitterType ==
            "UFH/Radiator")
    ) {

        const flowTemp =
            project.heatingZones_lwt.heatingZones_lwt[room.general.zone]
                .flowTemperature;
        const deltaT =
            5 * Math.floor((flowTemp - 4 - room.general.designTemperature) / 5);

        const correctionFactorData = await (setUpCollectionListener(
                "data",
                true,
                undefined,
                [
                    ["type", "==", "correctionFactor"],
                    ["deltaT", "==", deltaT.toString()],
                ]
            ) as Promise<QuerySnapshot<DocumentData>>);

        if (!correctionFactorData || correctionFactorData.docs.length == 0) {
            throw ReferenceError(
                "Can't find correctionFactorData for given flowTemp: " +
                flowTemp +
                " and designTemp: " +
                room.general.designTemperature
            );
        }

        const correctionFactor = (
            correctionFactorData.docs[0].data() as {
                correctionFactor: number;
            }
        ).correctionFactor;

        let heatGeneration = 0;
        let additionalHeatGeneration = 0;

        if (room.existingRadiators && room.newRadiators) {
            for (let index = 0; index < room.existingRadiators.length; index++) {
                const radiatorHeat = await radiatorHeatGeneration(
                    room.existingRadiators[index],
                    room.newRadiators[index],
                    correctionFactor
                );
    
                if (radiatorHeat == 0) {
                    heatGeneration = 0;
                    break;
                } else heatGeneration += radiatorHeat;
            }
        }

        //Note: add additional heat generation from addedRadiators
        if (room.addedRadiators) {
            for (let index = 0; index < room.addedRadiators.length; index++) {
                const radiatorHeat = await radiatorHeatGeneration(
                    room?.addedRadiators[index],
                    room?.addedRadiators[index],
                    correctionFactor
                );
        
                if (radiatorHeat == 0) {
                    additionalHeatGeneration = 0;
                    break;
                } else additionalHeatGeneration += radiatorHeat;
            }
        }

        return { ...room, heatGeneration: heatGeneration + additionalHeatGeneration }
    }

    return { ...room, heatGeneration: 0 };
};

// export const optimizeRadiators = async (
//   rooms: room_detail<"standard">[],
//   project: project_detail<"standard">
// ) => {
//   const maximumPossibleFlowTemp = 65;
//   const minimumPossibleFlowTemp = 30;
//   //todo:make this as a cloud function
//   let availableRadiators = [] as newRadiator<"standard">[];
//   const radiatorsDocs = await (setUpCollectionListener(
//     "data",
//     true,
//     undefined,
//     [["type", "==", "radiatorData"]]
//   ) as Promise<QuerySnapshot<DocumentData>>);
//   radiatorsDocs.forEach((radiatorDoc) => {
//     availableRadiators.push(radiatorDoc.data() as newRadiator<"standard">);
//   });
//   availableRadiators = availableRadiators.sort((a, b) =>
//     a.heatOutput < b.heatOutput ? 1 : a.heatOutput > b.heatOutput ? -1 : 0
//   );
//   const possibleFlowTemps = Array.from(
//     { length: project.heatingZones.heatingZones.length },
//     () => Array.from({ length: rooms.length }, () => -1)
//   ) as number[][];
//   //if lwt heatingZones not yet defined, define it
//   if (!project.heatingZones_lwt || !project.heatingZones_lwt.heatingZones_lwt) {
//     project.heatingZones_lwt = { heatingZones_lwt: [{}] } as zones_lwt;
//   }
//   project.heatingZones.heatingZones.forEach((_emitter, index) => {
//     !project.heatingZones_lwt.heatingZones_lwt[index] &&
//       project.heatingZones_lwt.heatingZones_lwt.push({ flowTemperature: 30 });
//   });
//   //minimize flow temperature for each room
//   rooms = await Promise.all(
//     rooms.map(async (room, roomIndex) => {
//       if (
//         room.heatLoss &&
//         room.existingRadiators &&
//         room.existingRadiators.length > 0
//       ) {
//         //get the biggest radiators possible
//         room.existingRadiators.forEach((existingRadiator, index) => {
//           if (existingRadiator.doNotReplace == false) {
//             let maxHeatOutput = 0;
//             availableRadiators.forEach((availableRadiator) => {
//               if (
//                 parseInt(availableRadiator.length) <
//                   existingRadiator.availableLength &&
//                 parseInt(availableRadiator.height) <
//                   existingRadiator.availableHeight &&
//                 availableRadiator.radiatorType == "K2" &&
//                 availableRadiator.heatOutput > maxHeatOutput
//               ) {
//                 if (!room.newRadiators) {
//                   throw ReferenceError(
//                     "If existingradiators are defined, new radiators has to be defined as well."
//                   );
//                 }
//                 maxHeatOutput = availableRadiator.heatOutput;
//                 room.newRadiators[index] = {
//                   ...availableRadiator,
//                   replace: true,
//                   everythingFilledOut: true,
//                 };
//               }
//             });
//           }
//         });
//         //calculate the flow temperature for each room so that enough heat generated
//         let maxFlow = maximumPossibleFlowTemp;
//         let minFlow = minimumPossibleFlowTemp;
//         if (!room.general || !room.general.zone) {
//           throw ReferenceError("Room general not defined");
//         }
//         do {
//           project.heatingZones_lwt.heatingZones_lwt[
//             room.general?.zone || -1
//           ].flowTemperature = (minFlow + maxFlow) / 2;
//           room = await calcRoomGeneration(room, project);
//           if (
//             room.heatGeneration &&
//             room.heatLoss &&
//             room.heatGeneration > room.heatLoss
//           )
//             maxFlow =
//               project.heatingZones_lwt.heatingZones_lwt[
//                 room.general?.zone || -1
//               ].flowTemperature;
//           else if (
//             room.heatGeneration &&
//             room.heatLoss &&
//             room.heatGeneration <= room.heatLoss
//           )
//             minFlow =
//               project.heatingZones_lwt.heatingZones_lwt[
//                 room.general?.zone || -1
//               ].flowTemperature;
//           else
//             throw ReferenceError(
//               "Heat Generation is 0, would end in infinite loop"
//             );
//         } while (
//           (Math.abs(maxFlow - minFlow) > 1 &&
//             room.heatGeneration &&
//             room.heatGeneration >= room.heatLoss &&
//             Math.abs(
//               minimumPossibleFlowTemp -
//                 project.heatingZones_lwt.heatingZones_lwt[
//                   room.general?.zone || -1
//                 ].flowTemperature
//             ) > 1) ||
//           (room.heatGeneration &&
//             room.heatGeneration < room.heatLoss &&
//             Math.abs(
//               maximumPossibleFlowTemp -
//                 project.heatingZones_lwt.heatingZones_lwt[
//                   room.general?.zone || -1
//                 ].flowTemperature
//             ) > 1)
//         );
//         possibleFlowTemps[room.general?.zone || -1][roomIndex] = Math.ceil(
//           project.heatingZones_lwt.heatingZones_lwt[room.general?.zone || -1]
//             .flowTemperature
//         );
//       }
//       return room;
//     })
//   );
//   //pick maximum necessary flow temperature
//   project.heatingZones_lwt.heatingZones_lwt.forEach(
//     (heatingZone_lwt, index) => {
//       heatingZone_lwt.flowTemperature = Math.max(...possibleFlowTemps[index]);
//       if (
//         heatingZone_lwt.flowTemperature < minimumPossibleFlowTemp ||
//         heatingZone_lwt.flowTemperature > maximumPossibleFlowTemp
//       )
//         throw ReferenceError("Flow temperature outside allowed boundaries");
//     }
//   );
//   return { rooms: rooms, project: project };
//   //adjust radiators so that not oversized
//   //PRICE DATA MISSING
//   /*
//   rooms = await Promise.all(
//     rooms.map(async (room, roomIndex) => {
//       if (
//         room.heatLoss &&
//         possibleFlowTemps[room.general.zone][roomIndex] >
//           project.heatingZones_lwt[room.general.zone].flowTemperature
//       ) {
//         //get the cheapest radiators possible -> todo: check price
//         room.existingRadiators.forEach((existingRadiator, index) => {
//           if (existingRadiator.doNotReplace == false) {
//             for(let index=0;index<availableRadiators.length;index++){
//               const availableRadiator=availableRadiators[index]
//               if (
//                 availableRadiator.length < existingRadiator.availableLength &&
//                 availableRadiator.height < existingRadiator.availableHeight &&
//                 availableRadiator.radiatorType == "K2" &&
//                 availableRadiator.heatOutput < room.newRadiators[index].heatOutput
//               ) {
//                 room = await calcRoomGeneration(room, project);
//                 break;
//               }
//             }
//           }
//         });

//       }
//       return room;
//     })
//   );
//   */
// };
