import { xor } from "lodash";
import { Fragment, useContext, useState } from "react";
import { View } from "react-native";
import {
  Button,
  Checkbox,
  Dialog,
  Paragraph,
  Portal
} from "react-native-paper";
import { MemberList } from "../screens/Organisation/MemberList";
import { OrganisationContext, ProjectDetailContext } from "../util/contexts";
import { getUser, mergeDataWithDocument, uploadFile } from "../util/firebaseConnection";

/**
 * Description: Displays an overlayed confirmation box, which executes a function, when confirmed
 * @param props visible, setVisible, executedFunction
 * @returns ReactNode of the shown confirmation box
 */
export const Confirmation = (props: {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    executedFunction: () => void;
}) => {
    return (
        <Fragment>
            <View style={{ justifyContent: "center" }}>
                <Portal>
                    <Dialog
                        visible={props.visible}
                        onDismiss={() => props.setVisible(false)}
                        style={{ maxWidth: 400, alignSelf: "center" }}
                    >
                        <Dialog.Title>Delete</Dialog.Title>
                        <Dialog.Content>
                        <Paragraph>
                            This action is not reversible. Are you sure you want to
                            continue?
                        </Paragraph>
                        </Dialog.Content>
                        <Dialog.Actions>
                        <Button onPress={() => props.setVisible(false)}>Cancel</Button>
                        <Button
                            onPress={() => {
                            props.setVisible(false);
                            props.executedFunction();
                            }}
                        >
                            Delete
                        </Button>
                        </Dialog.Actions>
                    </Dialog>
                </Portal>
            </View>
        </Fragment>
    );
};

export const Notification = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  invitee: string;
}) => {
  return (
    <Fragment>
      <View style={{ justifyContent: "center" }}>
        <Portal>
          <Dialog
            visible={props.visible}
            onDismiss={() => props.setVisible(false)}
            style={{ maxWidth: 400, alignSelf: "center" }}
          >
            <Dialog.Title>Invite sent</Dialog.Title>
            <Dialog.Content>
              <Paragraph>
                {props.invitee} will receive an invite link in their inbox to
                join your organisation, press OK to continue.
              </Paragraph>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={() => props.setVisible(false)}>Ok</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
    </Fragment>
  );
};
export const Share = (props: {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}) => {
  const orgInfo = useContext(OrganisationContext);
  const thisProject = useContext(ProjectDetailContext);
  const ShareList = () => {
    const [installersWithAccess, setInstallersWithAccess] = useState(
      thisProject.accessRights.write
    );
    return (
      <Fragment>
        <Dialog.Title>Share Project</Dialog.Title>
        <MemberList
          orgInfo={orgInfo}
          right={(installerId) => (
            <View
              style={{
                marginRight: 10,
                alignSelf: "center",
              }}
            >
              <Checkbox
                status={
                  installersWithAccess.includes(installerId) == true
                    ? "checked"
                    : "unchecked"
                }
                color={"black"}
                disabled={installerId == getUser()?.uid}
                onPress={() =>
                  setInstallersWithAccess(
                    xor(installersWithAccess, [installerId])
                  )
                }
              />
            </View>
          )}
        />

        <Dialog.Actions>
          <Button onPress={() => props.setVisible(false)}>Cancel</Button>
          <Button
            onPress={() => {
              props.setVisible(false);
              mergeDataWithDocument("projects", thisProject.id, {
                accessRights: {
                  read: installersWithAccess,
                  write: installersWithAccess,
                },
              });
            }}
          >
            Confirm
          </Button>
        </Dialog.Actions>
      </Fragment>
    );
  };
  return (
    <View style={{ justifyContent: "center" }}>
      <Portal>
        <Dialog
          visible={props.visible}
          onDismiss={() => props.setVisible(false)}
          style={{ alignSelf: "center", maxHeight: "80%" }}
        >
          <ShareList />
        </Dialog>
      </Portal>
    </View>
  );
};
