import { View, ScrollView } from 'react-native';
import {
  Dialog,
  IconButton,
  Portal
} from 'react-native-paper';
import { colors } from '../../../styles/colors';
import {
    InfoBanner,
    Typography,
    FloorPlanImage,
    UploadFloorPlanBtn,
    FloorImageModal,
    FloorPlanUploadSkeleton,
    ErrorFileList
} from '../../base';
import styles from './floorPlanDialogStyles';

import type { floorPlanImage } from '../../../util/types';

type FloorPlanDialogProps = {
    visible: boolean;
    isUploading: boolean;
    displayError: boolean;
    duplicateFileNames: string[];
    invalidFileTypes: string[];
    noOfImagesUploaded: number;
    displayedImages: { url: string, name: string }[];
    floorPlanFileNames: string[];
    fileList: floorPlanImage[];
    displayFullImage: {
        display: boolean;
        imgUrl: string;
        fileName: string;
    };
    uploadImages: () => void;
    onCloseModal: () => void;
    onDeleteImage: (index: number) => void;
    onCancelImgUpload: (index: number) => void;
    setDisplayFullImage: (fullImage: {
        display: boolean;
        imgUrl: string;
        fileName: string;
    }) => void;
}

const FloorPlanDialog: React.FC<FloorPlanDialogProps> = ({
    visible,
    isUploading,
    displayError,
    duplicateFileNames,
    invalidFileTypes,
    noOfImagesUploaded,
    displayedImages = [],
    floorPlanFileNames = [],
    fileList = [],
    displayFullImage,
    uploadImages,
    onCloseModal,
    onDeleteImage,
    onCancelImgUpload,
    setDisplayFullImage
}) => {
    return (
        <View style={styles.container}>
            <Portal>
                <Dialog
                    visible={visible}
                    onDismiss={onCloseModal}
                    style={styles.dialog}>

                    <IconButton
                        icon='window-close'
                        size={40}
                        style={{ alignSelf: 'flex-end' }}
                        onPress={onCloseModal} />

                    <Dialog.Title style={{ marginTop: -10 }}>
                        <Typography size={32} fontWeight='Medium' color={colors.neutral}>Floor Plans</Typography>
                        <View style={{ marginLeft: 21 }}>
                            <Typography size={20} fontWeight='Regular'
                                color={colors.neutral300}> {noOfImagesUploaded} out of 5 uploaded</Typography>
                        </View>
                    </Dialog.Title>

                    <View style={styles.content}>
                        <ScrollView contentContainerStyle={styles.imageList}>
                            <View style={styles.description}>
                                <Typography>
                                    <Typography fontWeight='Bold'>RECOMMENDED</Typography>
                                    : Uploading the floor plan JPEG, PNG, or SVG will allow you to access the floor plan file from within Carno and therefore speed up data entry.
                                </Typography>
                            </View>

                            {isUploading &&
                                <View style={styles.bannerWrapper}>
                                    <InfoBanner message='While uploading, please do not close or reload the browser' />
                                </View>
                            }

                            {displayError &&
                                <View style={styles.bannerWrapper}>
                                    <InfoBanner type='error' message='You have exceeded the number of floor plans allowed per project. Please select up to 5 floor plans to continue.' />
                                </View>
                            }

                            {duplicateFileNames.length !== 0 &&
                                <ErrorFileList title={'The following file names already exist:'} fileNames={duplicateFileNames} />
                            }

                            {invalidFileTypes.length !== 0 &&
                                <ErrorFileList title={'The following files have invalid types:'} fileNames={invalidFileTypes} />
                            }

                            {displayedImages.length !== 0 && displayedImages.map((image, index) => (
                                <View key={`floor-plan-image-${index}`}>
                                    {floorPlanFileNames[index] &&
                                        <FloorPlanImage
                                            imageUrl={image.url}
                                            fileName={image.name.replace(/\.[^\/.]+$/, '').toUpperCase().replaceAll('%20', ' ')}
                                            onClickFileName={() => setDisplayFullImage({
                                                display: true,
                                                imgUrl: image.url,
                                                fileName: image.name.replaceAll('%20', ' ')
                                            })}
                                            onDeleteImage={() => onDeleteImage(index)} />
                                    }
                                </View>
                            ))}

                            {isUploading && fileList.map((file: floorPlanImage, index) => (
                                <FloorPlanUploadSkeleton
                                    key={`floor-plan-image-skeleton-${index}`}
                                    fileName={file.name}
                                    style={styles.uploadSkeleton}
                                    onCancelImgUpload={() => onCancelImgUpload(index)} />
                            ))}

                            {displayedImages.length < 5 &&
                                <View style={styles.uploadBtn}>
                                    <UploadFloorPlanBtn uploadImages={uploadImages} isUploading={isUploading || fileList.length !== 0} />
                                </View>
                            }
                        </ScrollView>
                    </View>
                </Dialog>

                <FloorImageModal isVisible={displayFullImage.display}
                    imgFileName={displayFullImage.fileName.replace(/\.[^\/.]+$/, '')}
                    imageUrl={displayFullImage.imgUrl}
                    onDismissDialog={() => setDisplayFullImage({ display: false, imgUrl: '', fileName: '' })}
                    closeModal={() => setDisplayFullImage({ display: false, imgUrl: '', fileName: '' })} />
            </Portal>
        </View>
    );
};

export default FloorPlanDialog;
