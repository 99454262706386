import { DocumentData } from "firebase/firestore";
import { MasterDataState } from "../redux/modules/masterData";
import React from "react";

//Glossary:
//energyUsage refers to thermal energy!
export interface weather {
  temps: string[] | { [K in string]: number };
}
export interface consistency {
  everythingFilledOut: boolean | null;
}
export interface project_step {
  id: string;
  text: string;
  question_collection?: string;
  navigateTo?: string;
  params?: object;
  nestedPath?: string;
  results?: { text: string; value: string; affix?: string }[];
  items?: {
    text: string;
    question_collection?: string;
    navigateTo?: string;
    nestedPath?: string;
    icon: string;
  }[];
}
export interface report {
  text: string;
  question_collection: string;
  description: string;
  nestedPath: string;
  extraData: string | string[];
}
export interface firstQuote_flatlist {
  text: string;
  question_collection: string;
  nestedPath: string;
  results?: { text: string; value: string; affix?: string }[];
  extraData?: string | string[];
  type?: string;
}
export interface organisationScreen {
  text: string;
  question_collection?: string;
  navigateTo?: string;
  valueStorageDocument?: string;
  params?: object;
  nestedPath?: string;
}
export type conditionalFunction = (props: {
  items: itemType[];
  answers?: DocumentData;
  index: number;
  answer?: string;
  prevAnswer?: string;
  productFilters?: string[][];
  setProductFilters?: (data: string[][]) => void;
  extraData?: string | string[];
}) => Promise<itemType[]>;
export type section = {
  text: string;
  typography: "headline" | "subheading" | "text";
  answer?: string;
};
export type itemType = {
  content: section | question_detail | product;
  key: string;
  type: "section" | "question_detail" | "product";
  conditionalFunction?: conditionalFunction;
};
export interface product extends accessRights {
  price: number;
  stocked: boolean;
  ean: string;
  type: "radiatorData" | "heatPumpData" | string;
  content:
    | radiatorProduct<"string">
    | heatPumpProduct<"string">
    | cylinderProduct<"string">;
}

export type input_rule_type =
  | "onlyNumber"
  | "epcNumber"
  | "postcode"
  | "none"
  | "yesNo"
  | "email"
  | "address"
  | "phone"
  | "limitedOptions"
  | "multipleOptions"
  | "listOfRooms"
  | "listOfZones"
  | "imageOptions"
  | "hidden"
  | "minMax"
  | "listOfProducts"
  | "documentPicker";
export type reportType =
  | "standard"
  | "journey-1-model-covering-letter-for-proposals-parameters"
  | "journey-2-model-covering-letter-for-proposals-parameters"
  | "MCS-compliance-certificate"
  | "MCS-performance-estimate"
  | "model-contract";
export interface question_detail {
  question_text: string;
  input_rule: input_rule_type;
  variableName?: string;
  hint?: string;
  options?: string[];
  values?: string[];
  type?: question_type;
  subType?: subElementType;
  affix?: string;
  answer?: string;
  default?:string;
  conditionalFunctionType?: string;
  inputRuleError?: boolean;
}
export interface questionResponse extends question_detail {
  options: string[];
  subType: subElementType;
}
type email = string;
type heatingSystems =
  | "Electric"
  | "Gas"
  | "LPG"
  | "Oil"
  | "Air Source Heat Pump"
  | "Ground Source Heat Pump"
  | "Smokeless Fuel"
  | "Coal"
  | "Seasoned Wood"
  | "Pellets";
export interface roombyroomconstants {
  unheatedTemp: number;
  exposedLocationFactor: number;
  heatedTemp: number;
  highCeilingFactor: number[];
  highCeilingLimits: number[];
  airChangeFactor: number;
  mainsTemp: number;
  backgroundNoiseLevel: string;
}
export interface hotWater<T extends "string" | "standard">
  extends consistency,
    accessRights {
  numberBedrooms?: T extends "string" ? string : number;
  numberOccupants?: T extends "string" ? string : number;
  hotWaterUsePerOccupant?: T extends "string" ? string : number;
  targetTemperature?: T extends "string" ? string : number;
  cylinderStartTemperature?: T extends "string" ? string : number;
  immersionCyclesPerWeek?: T extends "string" ? string : number;
  usagePerDay?: T extends "string" ? string : number | null;
  energyUsagePerYear?: T extends "string" ? string : number | null;
  immersionEnergyUsagePerYear?: T extends "string" ? string : number | null;
  efficiency: T extends "string" ? string : number | null;
}
export interface heatPump extends consistency {
  ean?: string;
  price?: number;
  SCOP?: number;
  runningCosts?: number;
}
export interface cylinder extends consistency {
  ean?: string;
  price?: number;
  SCOP?: number;
  runningCosts?: number;
}
export const noiseDistance = [
  "1",
  "1.5",
  "2",
  "3",
  "4",
  "5",
  "6",
  "8",
  "10",
  "12",
  "15",
  "20",
  "25",
  "30",
];
export type pressureLevel = "Q2" | "Q4" | "Q8";
export interface noise<T extends "string" | "standard">
  extends consistency,
    accessRights {
  pressureLevel?: pressureLevel;
  distance?: typeof noiseDistance[number];
  obstruction?: "-10" | "-5" | "0";
  noise?: T extends "string" ? string : number; //[db]
  passed?: T extends "string" ? string : boolean;
  calculationFactors?: noiseCalculationFactors;
}

export type noiseCalculationFactors = {
    pressureLevelComputation: number,
    dbDistanceReduction: string,
    heatPumpNoiseLevel: string,
    noiseLevelDifference: number,
    everythingFilledOut: boolean
}

export interface locationParameters {
  postcode: string;
  CIBSE: string;
  degreeDays: string;
  meanAirTemp: string;
}
//=== for airChangesAndDesignTemp conditional function ===
export interface roomAirChanges {
  room: roomType;
  catA: string;
  catB: string;
  catC: string;
}

//=== for airChangesAndDesignTemp conditional function ===
export interface flueAirChanges {
  roomVolume: string;
  throatRestrictor: string;
  airChanges: string;
}

//=== for airChangesAndDesignTemp conditional function ===
export interface roomDesignTemp {
  room: roomType;
  designTemp: string;
}
interface accessRights {
  accessRights: { read: string[]; write: string[] };
}
export interface projectAccessRights {
  accessRights: { read: string[]; write: string[]; share: string[] };
}
export const buildTypeArray = [
  "New Build-Building Regs+",
  "New Build-Building Regs",
  "Barn Conversion(New Build Standards)",
  "Barn Conversion(100 Years Old)",
  "Retro(5-25 years old)",
  "Retro(26-50 years old)",
  "Retro(51-99 Years old)",
  "Listed(100 Years old+)",
];
type buildType = typeof buildTypeArray[number];
export interface firstQuote<T extends "string" | "standard">
  extends consistency {
  usageKnown: T extends "string" ? string : boolean;
  energyUsageInput?: T extends "string" ? string : number;
  designTemp?: T extends "string" ? string : number;
  floorArea?: T extends "string" ? string : number;
  houseBuildType: buildType;
  numberOfBeds?: T extends "string" ? string : number;
  extension?: T extends "string" ? string : boolean;
  extensionArea?: T extends "string" ? string : number;
  extensionBuildType: buildType;
  heatLoss?: T extends "string" ? string : number | null;
  energyUsagePerYear?: T extends "string" ? string : number;
  cylinderSize?: T extends "string" ? string : number | null;
  hardwareCosts?: T extends "string" ? string : number | null;
  runningCosts?: T extends "string" ? string : number | null;
  flowTemperature: T extends "string" ? string : number;
  waterEnergyUsage?: T extends "string" ? string : number;
}
export interface project_general<T extends "string" | "standard"> extends consistency {
  customerName?: string;
  email?: email;
  phoneNumber?: T extends "string" ? string : number;
  postcode?: string;
  address?: string;
  propertyAddressLine1: string;
  exposedLocation?: T extends "string" ? string : boolean;
  intermittentHeating?: T extends "string" ? string : boolean;
  electricityTariff?: T extends "string" ? string : number;
  epcAvailable: boolean;
  EPC: string;
  epcNumber: T extends "string" ? string : number;
  epcSpaceHeating: T extends "string" ? string : number;
  epcHotWater: T extends "string" ? string : number;
  contractBeforeSurvey: T extends "string" ? string : boolean;
  inOfficeContract?: boolean;
}
interface project_contract<T extends "string" | "standard">
  extends consistency {
  delayDays?: T extends "string" ? string : number;
  walesOrNothernIreland?: T extends "string" ? string : boolean;
  customBuild?: T extends "string" ? string : boolean;
  compulsoryMetering?: T extends "string" ? string : boolean;
  MMSP?: T extends "string" ? string : boolean;
  offerBuildingNoticeApplication?: T extends "string" ? string : boolean;
  EPCMeasures?: string;
}
interface mcsPerformance<T extends "string" | "standard"> extends consistency {
  existingSystem: heatingSystems;
  hotWaterHeatSource: heatingSystems;
  existingSystemAge: "Pre-1994" | "1994-2007" | "Post-2007";
  existingSystemEfficiency: T extends "string" ? string : number;
  existingTariff: T extends "string" ? string : number;
  priceDate: string;
}
export interface rooms<T extends "string" | "standard"> extends consistency {
  heatLoss: T extends "string" ? string : number | null;
  energyUsagePerYear: T extends "string" ? string : number | null;
}
export interface upgrades<T extends "string" | "standard"> extends consistency {
  price: T extends "string" ? string : number | null;
  enoughHeat: T extends "string" ? string : boolean | null;
}
export interface project_detail<T extends "string" | "standard">
  extends projectAccessRights {
  tempFunction: (newObj: any) => void;
  lastEdited: T extends "string" ? string : number;
  created: T extends "string" ? string : number;
  createdBy: string;
  version: string;
  id: string;
  general?: project_general<T>;
  constants: roombyroomconstants;
  locationParameters?: locationParameters;
  heatingZones?: zones;
  heatingZones_lwt?: zones_lwt;
  hotWater?: hotWater<T>;
  firstQuote?: firstQuote<T>;
  firstQuoteHeatPump?: heatPump;
  firstQuoteCylinder?: cylinder;
  floorPlanFileNames?: string[];
  heatPump?: heatPump;
  cylinder?: cylinder;
  noise?: noise<T>;
  rooms?: rooms<T>;
  upgrades: upgrades<T>;
  contract?: project_contract<T>;
  mcsPerformance?: mcsPerformance<T>;
}
export interface installer_detail<T extends "string" | "standard">
  extends consistency {
  installerName?: string;
  installerEmail: T extends "string" ? string : email;
  installerPhone?: T extends "string" ? string : number;
  installerAddress?: string;
  company?: string;
  active: boolean;
  orgId?: string;
  role?: string;
  id?: string;
  key?: string;
}
export interface organisation_detail<T extends "string" | "standard">
  extends consistency {
  organisationName: string;
  organisationEmail: T extends "string" ? string : email;
  organisationPhone: T extends "string" ? string : number;
  organisationWebsite: T extends "string" ? string : number;
  organisationAddress: string;
  installers?: string[];
  admins?: string[];
  id?: string;
  extra?: orgExtra;
  billing?: orgBilling<"standard">;
}
export interface orgExtra {
  businessSettings?: businessSettings<"standard">;
  contractPreferences?: contractPreferences<"standard">;
  pricing?: pricing<"standard">;
}
export interface orgBilling<T extends "string" | "standard">
  extends consistency {
  accountName?: string;
  accountNumber?: T extends "string" ? string : number;
  sortCode?: T extends "string" ? string : number;
}
export interface businessSettings<T extends "string" | "standard">
  extends consistency {
  VATNumber: T extends "string" ? string : number;
  legalForm: string;
  companyNumber: T extends "string" ? string : number;
  insuranceProvider: string;
  workmanshipGuaranteeYears: T extends "string" ? string : number;
  WorkmanshipWarrantyInsurer: string;
  RECCMembershipNumber: T extends "string" ? string : number;
  MCSCertified: boolean;
  MCSBody: string;
  MCSNumber: T extends "string" ? string : number;
}
export interface contractPreferences<T extends "string" | "standard">
  extends consistency {
  installationDays: T extends "string" ? string : number;
  orderProcessingWeeks: T extends "string" ? string : number;
  offerServicing: boolean;
  offerEPCAssessment: boolean;
  requireDeposit: boolean;
  requireAdvancePayment: boolean;
}
export interface pricing<T extends "string" | "standard"> extends consistency {
  MCSRegistrationPrice: T extends "string" ? string : number;
  EPCAssessmentPrice: T extends "string" ? string : number;
  installerHourlyRate: T extends "string" ? string : number;
  radiatorInstallationPrice: T extends "string" ? string : number;
}
export type PressableState = Readonly<{
  pressed: boolean;
  hovered?: boolean;
  focused?: boolean;
}>;
export interface headerOptionsType {
  title?: string;
  closeAction?: () => void;
  left?: React.ReactNode;
}
export type roomType =
  | "Living Room"
  | "Dining Room"
  | "Bedroom"
  | "Hallway"
  | "Kitchen"
  | "Bathroom"
  | "WC"
  | "Office"
  | "Playroom"
  | "Conservatory"
  | "Store"
  | "Landing";
export type elementType = "wall" | "floor" | "ceiling";
export type question_type =
  | "project-detail"
  | "projectContract"
  | "projectContractDelay"
  | "projectContractRadiator"
  | "projectMCSCompliance"
  | "projectMCSComplianceAddress"
  | "projectQuote"
  | "projectQuoteSubcontract"
  | "projectQuoteGoodsCollection"
  | "projectContractVariation"
  | "first-quote"
  | "first-quote-energyUsage"
  | "first-quote-unknownEnergyUsage"
  | "first-quote-extension"
  | "roombyroom-general"
  | "new-rooms-general"
  | "heatingZones"
  | "heatingZones_lwt"
  | "general"
  | "existingRadiator"
  | "newRadiator"
  | "replaceRadiator"
  | "maybeReplaceRadiator"
  | "hot-water"
  | "noise"
  | "obstruction-noise"
  | "installerSignup"
  | "organisationSignup"
  | "organisationBusiness"
  | "organisationPricing"
  | "organisationBilling"
  | "organisationBusinessMCS"
  | "organisationContract"
  | "organisationContractPolicyDocument"
  | "heatPumpSelection"
  | "heatPumpSelectionFilters"
  | "cylinderSelection"
  | "cylinderSelectionFilters"
  | "epcAvailable"
  | "mcsPerformance"
  | elementType
  | subElementType;

export type subElementType =
  | "window"
  | "door"
  | "internal"
  | "external"
  | "party"
  | "roofLight"
  | "ground"
  | "roof"
  | "intermediate";
type adjacentSpace = "Outside" | "Unheated" | "Ground" | "Heated" | string; //adjacentRoomId

interface conductance<T extends "string" | "standard"> {
  Uvalue?: T extends "string" ? string : number;
  dim1?: T extends "string" ? string : number;
  dim2?: T extends "string" ? string : number;
}
export interface element<T extends "string" | "standard">
  extends conductance<T>,
    consistency,
    Object {
  //minimum amount of data we expect
  type: elementType;
  thermalBridgesInsulated?: T extends "string" ? string : boolean;
  heatLoss?: T extends "string" ? string : number | null;
  energyUsagePerYear?: T extends "string" ? string : number | null;
  adjacentSpace?: adjacentSpace;
  adjacentSpaceName?: string;
  adjacentSpaceTemp?: T extends "string" ? string : number | null;
  windows?: conductance<T>[];
  doors?: conductance<T>[];
  roofLights?: conductance<T>[];
  wallType?: "External Wall" | "Internal Wall" | "Party Wall";
  ceilingType?: "Roof" | "Intermediate Ceiling" | "Party Ceiling";
  floorType?: "Ground Floor" | "Party Floor" | "Intermediate Floor";
}
export interface room_general<T extends "string" | "standard"> extends consistency {
  customName?: string;
  designTemperature?: T extends "string" ? string : number;
  constructionDate?: "Pre 2000" | "2000-2005" | "2006-Present";
  roomLength?: T extends "string" ? string : number;
  roomWidth?: T extends "string" ? string : number;
  roomHeight?: T extends "string" ? string : number;
  airChanges?: string;
  openFire?: "Open Fire" | "Open Fire with Throat Restrictor" | "Not Present";
  zone?: T extends "string" ? string : number;
  roomType?: roomType;
}
export interface room_detail<T extends "string" | "standard">
  extends accessRights {
  lastEdited: T extends "string" ? string : number;
  general?: room_general<T>;
  elements: element<T>[];
  existingRadiators?: existingRadiator<T>[];
  newRadiators?: newRadiator<T>[];
  addedRadiators?: newRadiator<T>[];
  created: T extends "string" ? string : number;
  heatLoss?: T extends "string" ? string : number | null;
  heatGeneration?: T extends "string" ? string : number | null;
  energyUsagePerYear?: T extends "string" ? string : number | null;
  id: string;
}

export type radiatorType = "11" | "21" | "22" | "33";
export type emitterType = "Radiator" | "UFH" | "UFH/Radiator";
export interface zones extends consistency {
  heatingZones: { emitterType: emitterType }[];
}
export interface zones_lwt extends consistency {
  heatingZones_lwt: { flowTemperature: number }[];
}
export interface radiator {
  length: string;
  height: string;
  radiatorType: radiatorType;
}
export interface radiatorProduct<T extends "string" | "standard">
  extends radiator {
  heatOutput: T extends "string" ? string : number; //at dt50
  manufacturer: string;
  range: string;
  weight: number;
}

export interface heatPumpProduct<T extends "string" | "standard"> {
  soundPower: T extends "string" ? string : number; //[db]
  refrigerantType: string;
  ean: string;
  manufacturer: string;
  price: T extends "string" ? string : number;
  model: string;
  capDef: string[];
  cap25?: string[];
  cap30?: string[]; //EVERYTHING IN KW
  cap35?: string[];
  cap40?: string[];
  cap45?: string[];
  cap50?: string[];
  cap55?: string[];
  cap60?: string[];
  COP25?: string[];
  COP30?: string[];
  COP35?: string[];
  COP40?: string[];
  COP45?: string[];
  COP50?: string[];
  COP55?: string[];
  COP60?: string[];
}
export interface cylinderProduct<T extends "string" | "standard"> {
  ean: string;
  size?: T extends "string" ? string : number; //[db]
  width?: T extends "string" ? string : number;
  height?: T extends "string" ? string : number;
  length?: T extends "string" ? string : number;
  manufacturer?: string;
  price: T extends "string" ? string : number;
  cylinderType?: "Standard" | "Solar Thermal";
  cylinderModelName?: string;
}
export interface newRadiator<T extends "string" | "standard">
  extends consistency {
  ean: string;
  replace: T extends "string" ? string : boolean;
  heatOutput?: T extends "string" ? string : number; //at dT according to flowTemp
  manufacturer?: string;
  range?: string;
  radiatorType?: radiatorType;
  height?: string;
  width?: string;
}
export interface existingRadiator<T extends "string" | "standard">
  extends consistency,
    radiator {
  availableLength: T extends "string" ? string : number;
  availableHeight: T extends "string" ? string : number;
  availableDepth: T extends "string" ? string : number;
  doNotReplace: T extends "string" ? string : boolean;
}
export interface SignupProps {
  uid: string;
}
export interface pricing<T extends "string" | "standard"> {
  MCSRegistrationPrice: T extends "string" ? string : number;
  EPCAssessmentPrice: T extends "string" ? string : number;
  installerHourlyRate: T extends "string" ? string : number;
  radiatorInstallationPrice: T extends "string" ? string : number;
}
export interface orgBilling<T extends "string" | "standard"> {
  accountName?: string;
  accountNumber?: T extends "string" ? string : number;
  sortCode?: T extends "string" ? string : number;
}

export interface sectionMissingInfo {
  sectionIndex: number;
  sectionTitle: string;
  boolVal: boolean;
  missingInfoDetails?: string;
}

export interface option {
  label: string;
  value: string;
}

export interface wallFabricTypes {
  internal: option[];
  external: option[];
  party: option[];
}

export interface wallsMasterData {
  internal: masterDataValues;
  external: masterDataValues;
  party: masterDataValues;
}

export interface masterDataValues {
  fabricType: string;
  uValue: question_detail;
}

export interface zeroValuesMagicplanResponse {
  customName: string;
  name: string;
  type: string;
  param: string;
}

export interface floorPlanImage {
  dataUri: string,
  name: string,
  size: string,
  type: string
}

export type bannerType = 'success' | 'error' | 'info';

export type MCDDropdowns =
  | 'Fabric Type'
  | 'Adjacent Space'
  | 'Year of Construction'
  | 'Temperature Drop';

export type Obj<T = unknown> = Record<string, T>;

export type RoomElement = elementType & subElementType;

export type ElementProperties = {
  fabricType: string,
  uValue: question_detail,
  adjacentSpace?: string
};

export type wallFabrictypes = {
  internal: option[],
  external: option[],
  party: option[]
}

export interface floorFabricTypes {
  ground: option[],
  intermediate: option[],
  party: option[]
}

export interface ceilingFabricTypes {
  roof: option[],
  intermediate: option[],
  party: option[]
}

export interface roofFabricTypes {
  roofLight: option[]
}

export interface windowFabricTypes {
  window: option[]
}

export interface doorFabricTypes {
  door: option[]
}

export type SetFabricTypesParams = Obj<wallFabrictypes
  | floorFabricTypes
  | ceilingFabricTypes
  | roofFabricTypes
  | windowFabricTypes
  | doorFabricTypes>;

export type ElementObject = MasterDataState['floor'] 
  | MasterDataState['ceilings'] 
  | MasterDataState['walls'] 
  | MasterDataState['floor']
  | MasterDataState['roofs']
  | MasterDataState['windows']
  | MasterDataState['doors']

export interface SetFabricTypeParams {
  subType: subElementType,
  fabricType: string,
  uValue: {
    answer: string,
    inputRuleError: boolean
  }
}

export interface SetAdjacentSpaceParams {
  subType: subElementType,
  adjacentSpace: string
}

export type SetElementObjectParams = SetFabricTypeParams | SetAdjacentSpaceParams;

export type SetDropdownParams ={
  element: RoomElement,
  subType: subElementType,
  value: boolean
};

export type PerElementDropdowns = 'Fabric Type' | 'Adjacent Space';

export type GeneralDropdowns = 'constructionDate' | 'temperatureDrop';

export type SetGeneralDropdownParams ={
  field: GeneralDropdowns,
  value: boolean
};

export type SetElementObject = (params: SetElementObjectParams) => void;

export interface TargetElement {
  elementObject: Obj<ElementProperties>,
  setElementObject: SetElementObject
}
