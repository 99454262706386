const extractWallDoorWindowFabricType = (routeParams: any) => {
    const wallElementFabric = routeParams?.roomsMasterData?.wall?.external?.fabricType || '';
    const wallUvalue = routeParams?.roomsMasterData?.wall?.external?.uValue?.answer || '';
    const doorElementFabric = routeParams?.roomsMasterData?.door?.door?.fabricType || '';
    const doorUvalue = routeParams?.roomsMasterData?.door?.door?.uValue?.answer || '';
    const windowElementFabric = routeParams?.roomsMasterData?.window?.window?.fabricType || '';
    const windowUvalue = routeParams?.roomsMasterData?.window?.window?.uValue?.answer || '';

    return {
        wallElementFabric,
        wallUvalue,
        doorElementFabric,
        doorUvalue,
        windowElementFabric,
        windowUvalue
    };
};

export default extractWallDoorWindowFabricType;
