import React, { useContext } from "react";
import { useAppSelector } from "../../redux/hooks";
import { Image } from "react-native";
import { ProjectsStack } from "../Projects/Projects";
import { HomeStack } from "../Home/Home";
import { HeaderBar } from "../../components/HeaderBar";
import createTopNavigator from "../../components/TopNavigator";
import { HeaderDispatch, InstallerContext } from "../../util/contexts";
import { OrganisationScreenDesktop } from "../Organisation/Organisation";
import { SettingsScreen } from "../Settings/Settings";

/**
 * Description: Create a navigation tab on top of screen for desktop user, home/projects buttons handle which stacks to render
 *  @returns Top.Navigator with Home and Projects options (+ Organisation and Settings tabs)
 */
export const DesktopNavigator = () => {
    const userInfo = useContext(InstallerContext);
    const Top = createTopNavigator();

    const { isBaxi } = useAppSelector(({ whiteLabel }) => whiteLabel);
    const logoSource = isBaxi ? require("../../../assets/baxi-logo-white.jpg") : require("../../../assets/carno-logo-white.png");

    return (
        <HeaderDispatch.Provider value={new Function()}>
            <Top.Navigator
                screenOptions={{
                    header: (right: React.ReactNode | null) =>
                        HeaderBar({
                            title: "Sizing Tool",
                            left: (
                                <Image
                                    style={{
                                        resizeMode: "center",
                                        height: 30,
                                        width: 150,
                                        marginLeft: 20
                                    }}
                                    source={logoSource} />
                            ),
                            right: right
                        }),
                    }}>

                <Top.Screen name="Home" component={HomeStack} />
                <Top.Screen name="Projects" component={ProjectsStack} />
                <Top.Screen name="Settings" component={SettingsScreen} />

                {userInfo.role === "admin" && (
                    <Top.Screen
                        name="Organisation"
                        component={OrganisationScreenDesktop} />
                )}
            </Top.Navigator>
        </HeaderDispatch.Provider>
    );
};
