import { useState } from "react";
import { IconButton, Text } from "react-native-paper";
import Tooltip from "react-native-walkthrough-tooltip";
export const Hint = (props: { text: string | undefined }) => {
  const [visible, setVisible] = useState(false);
  if (props.text) {
    return (
      <Tooltip
        isVisible={visible}
        content={<Text>{props.text}</Text>}
        placement="left"
        onClose={() => setVisible(false)}
        showChildInTooltip={false}
        disableShadow={true}
        contentStyle={{ flex: 1 }}
      >
        <IconButton
          icon="help-circle-outline"
          onPress={() => setVisible(true)}
        />
      </Tooltip>
    );
  } else {
    return null;
  }
};
