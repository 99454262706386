import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { ComponentProps, useContext, useState } from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ProjectsStack } from "../Projects/Projects";
import { HomeStack } from "../Home/Home";
import {
  DesktopContext,
  HeaderDispatch,
  InstallerContext,
} from "../../util/contexts";
import { HeaderBar } from "../../components/HeaderBar";
import { headerOptionsType } from "../../util/types";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { OrganisationScreenMobile } from "../Organisation/Organisation";
import { SettingsScreen } from "../Settings/Settings";

/**
 * Description: Create a navigation tab on the bottom of mobile screen, home/projects buttons handle which stacks to render
 * @returns Tab.Navigator with Home and Projects options
 */
export const MobileNavigator = () => {
  const [headerOptions, setHeaderOptions] = useState({} as headerOptionsType);
  const Tab = createBottomTabNavigator();
  const userInfo = useContext(InstallerContext);

  // const OpenSettings = () => {
  //   return (
  //     <Appbar.Action
  //       icon="account"
  //       onPress={() => {navigation.navigate("Settings")}}
  //     />
  //   );
  // };

  return (
    <DesktopContext.Provider value={false}>
      <HeaderDispatch.Provider value={setHeaderOptions}>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            tabBarIcon: ({ focused, color, size }) => {
              let iconName: string;
              if (route.name === "Home") {
                iconName = focused ? "home" : "home-outline";
              } else if (route.name === "Projects") {
                iconName = focused ? "city-variant" : "city-variant-outline";
              } else if (route.name === "Organisation") {
                iconName = focused ? "account-group" : "account-group-outline";
              } else if (route.name === "Settings") {
                iconName = focused ? "account" : "account-outline";
              } else {
                throw ReferenceError("Can't find specified route");
              }
              return (
                <Icon
                  name={
                    iconName as ComponentProps<
                      typeof MaterialCommunityIcons
                    >["name"]
                  }
                  size={size}
                  color={color}
                />
              );
            },
            header: () =>
              HeaderBar({
                title: headerOptions.title,
                //right: OpenSettings(),
                left: headerOptions.left,
              }),
          })}
        >
          <Tab.Screen name="Home" component={HomeStack} />
          <Tab.Screen name="Projects" component={ProjectsStack} />
          <Tab.Screen name="Settings" component={SettingsScreen} />
          {userInfo.role === "admin" && (
            <Tab.Screen
              name="Organisation"
              component={OrganisationScreenMobile}
            />
          )}
        </Tab.Navigator>
      </HeaderDispatch.Provider>
    </DesktopContext.Provider>
  );
};
