import { StyleSheet } from 'react-native';
import { colors } from '../../../styles/colors';
import { screenHeight, screenWidth, isMobile } from '../../../styles/constants';

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center'
    },
    dialog: {
        width: isMobile ? '90%' : '80%',
        alignSelf: 'center',
        backgroundColor: colors.white,
        paddingHorizontal: isMobile ? 0 : 20,
        paddingTop: 30,
        paddingBottom: isMobile ? 0 : 10,
        maxWidth: screenWidth,
        maxHeight: screenHeight * 0.9,
    },
    button: {
        flex: 1,
        marginBottom: 10
    },
    validationMsg: {
        marginBottom: 15
    },
    uploadSkeleton: {
        marginRight: isMobile ? 0 : 28
    },
    uploadBtn: {
        marginBottom: isMobile ? 100 : 0,
    },
    iconBtnWrapper: {
        justifyContent: 'flex-end'
    },
    content: {
        flex: 1,
        maxHeight: screenHeight
    },
    text: {
        textAlign: 'center',
        width: '50%'
    },
    description: {
        marginBottom: 50,
        width: '100%',
        padding: isMobile ? 20 : 0
    },
    bannerWrapper: {
        marginBottom: 40,
        marginTop: -10,
        width: '100%'
    },
    imageList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: isMobile ? 'center' : 'flex-start',
        marginHorizontal: isMobile ? 0 : 25,
        maxHeight: 500
    }
});

export default styles;