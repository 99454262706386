import type { Obj, option, questionResponse, question_detail } from './types';
import { store } from '../redux/store';
import { setCompany, setIsBaxi } from '../redux/modules/whiteLabel';

export const getReadableDate = (date: Date) => {
    return (
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " @ " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
};

export const getTextInputBackgroundColor = (questionText: string) => {
    return [
        'FABRIC TYPE',
        'U VALUE',
        'ADJACENT SPACE',
        'YEAR OF CONSTRUCTION',
        'TEMPERATURE DROP DURING SETBACK'
    ].includes(questionText.toLocaleUpperCase()) ? '#FFE6CE' : undefined;
};

export const isInputHighlighted = (questionText: string) => {
    return ['FABRIC TYPE', 'U VALUE'].includes(questionText.toLocaleUpperCase());
};

export const isDimensionZero = (question: question_detail) => {
    const fields = ['roomWidth', 'roomHeight', 'roomLength', 'dim1', 'dim2', 'dim3'];

    if (question?.variableName && question.answer &&
        fields.some((field) => question.variableName?.includes(field)) &&
        parseFloat(question.answer) === 0) {
        return true;
    }

    return false;
};

export const isReplaceRadiatorToggle = (question: question_detail) => {
   return question?.type === 'maybeReplaceRadiator' && question?.variableName?.includes('addedRadiators');
};

export const getURLParam = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const company = urlParams.get('company') || '';

    store.dispatch(setCompany(company));
    store.dispatch(setIsBaxi(company === 'baxi'));
};

export const getOptions = (responses: questionResponse[], key: string): Obj<option[]> => {
    const dropdownOptions = {} as Obj<option[]>;

    for (const response of responses) {
        const options = [];
        const optionsKey = response[key as keyof typeof response];

        for (let i = 0; i < response.options.length; i++) {
            options.push({
                label: response.options[i],
                value: response.options[i]
            });
        }

        dropdownOptions[optionsKey as string] = options;
    }

    return dropdownOptions;
};
