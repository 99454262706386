export const removeCancelledFiles = (uploadedFiles: { url: string, name: string }[], filesToCancel: string[]) => {
    const filteredUploadedFiles = uploadedFiles.filter((file) => { 
        const fileName = file.name.replaceAll('%20', ' ');
        if (!filesToCancel.includes(fileName)) { // handle %20 spacing in mac
            return {
                ...file,
                name: fileName
            };
        }
    });

    return filteredUploadedFiles;
};