import { View } from 'react-native';
import {
  Button,
  Dialog,
  Paragraph,
  Portal
} from 'react-native-paper';
import styles from './heatingZonesDeleteDialogStyles';

type HeatingZoneDeleteDialogProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

/**
 * Description: Displays an overlayed message for heating zone deletion
 * @param props visible, setVisible
 * @returns ReactNode for the warning message
 */
 const HeatingZoneDeleteDialog: React.FC<HeatingZoneDeleteDialogProps> = ({
    visible,
    setVisible
}) => {
    return (
        <View style={styles.container}>
            <Portal>
                <Dialog
                    visible={visible}
                    onDismiss={() => setVisible(false)}
                    style={styles.dialog}>

                    <Dialog.Title>Heating zones</Dialog.Title>
                    <Dialog.Content>
                    <Paragraph>
                        Unable to save recent changes. There should be at least one heating zone in the list.
                    </Paragraph>
                    </Dialog.Content>
                    <Dialog.Actions>
                    <Button onPress={() => setVisible(false)}>Understood</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
};

export default HeatingZoneDeleteDialog;
