import { useContext } from 'react';
import Icon from '@expo/vector-icons/Ionicons';
import { useAppSelector } from '../../../redux/hooks';
import { getTargetElement } from '../../../util/masterData';
import { View } from 'react-native';
import { Button, List, Text, TextInput } from 'react-native-paper';
import { isMobile } from '../../../styles/constants';
import { DesktopContext } from '../../../util/contexts';
import { saveUvalue } from '../../../util/masterData';
import type {
    subElementType,
    elementType,
    question_detail,
    RoomElement,
    GeneralDropdowns
} from '../../../util/types';
import { Hint } from '../../Hint';
import { capitalize } from 'lodash';
import DropDown from '../../DropDown';
import { MasterDataSectionSummary } from '../../base';
import { MasterDataState } from '../../../redux/modules/masterData';
import styles from './masterConstructionDataStyles';
import Typography from '../../base/Typography/Typography';

type MasterConstructionDataProps = {
    elementTypes: elementType[],
    dropdowns: MasterDataState['dropdowns'],
    generalDropdowns: MasterDataState['generalDropdowns'],
    adjacentSpaceDropdowns: MasterDataState['adjacentSpaceDropdowns'],
    isDisabled: (subType: subElementType) => boolean,
    handlePress: (element: elementType | subElementType) => void,
    saveGeneralField: (field: string, value: string) => void,
    setDropdownValue: (
        field: 'Fabric Type' | 'Adjacent Space',
        value: boolean,
        element: RoomElement,
        subType: subElementType
    ) => void,
    saveFabricType: (elementType: string, value: string, type: subElementType) => void,
    saveAdjacentSpace: (elementType: string, value: string, type: subElementType) => void,
    saveToFirebase: () => void,
    setGeneralDropdownValue: (field: GeneralDropdowns, value: boolean) => void

}

const MasterConstructionData: React.FC<MasterConstructionDataProps> = ({
    elementTypes,
    dropdowns,
    generalDropdowns,
    adjacentSpaceDropdowns,
    isDisabled,
    handlePress,
    saveGeneralField,
    setDropdownValue,
    saveFabricType,
    saveAdjacentSpace,
    saveToFirebase,
    setGeneralDropdownValue
}) => {
    const isDesktop = useContext(DesktopContext);
    const {
        expanded,
        fabricTypes,
        adjacentSpace,
        generalDropdownOptions,
        general,
        expandedElement
    } = useAppSelector(({ masterData }) => masterData);

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Master Construction Data</Text>
            <Text style={styles.description}>
                Set up the common presets that occur frequently to multiple rooms upfront to save time
            </Text>

            <Typography size={20} fontWeight='Medium' style={{ marginBottom: 10 }}>General Info</Typography>
            <View style={styles.generalFieldWrapper}>
                <View>
                    <DropDown
                        multiSelect={false}
                        label='Year of Construction'
                        mode='outlined'
                        visible={generalDropdowns.constructionDate}
                        showDropDown={() => setGeneralDropdownValue('constructionDate', true)}
                        onDismiss={() => setGeneralDropdownValue('constructionDate', false)}
                        value={general.constructionDate}
                        setValue={(value) => saveGeneralField('constructionDate', value as string)}
                        list={generalDropdownOptions.constructionDate}
                    />
                </View>
                <View style={styles.tempDropField}>
                    <DropDown
                        multiSelect={false}
                        label='Temperature drop during setback'
                        mode='outlined'
                        visible={generalDropdowns.temperatureDrop}
                        showDropDown={() => setGeneralDropdownValue('temperatureDrop', true)}
                        onDismiss={() => setGeneralDropdownValue('temperatureDrop', false)}
                        value={general.temperatureDrop}
                        setValue={(value) => saveGeneralField('temperatureDrop', value as string)}
                        list={generalDropdownOptions.temperatureDrop}
                    />
                </View>
            </View>

            <Typography size={20} fontWeight='Medium' style={{ marginTop: 30, marginBottom: 10 }}>Room Elements</Typography>
            {elementTypes.map((element: elementType | subElementType, index: number) => {
                const { elementObject, setElementObject } = getTargetElement(element); // walls | floors | ceilings states

                return (
                    <List.Section key={`element-${element}-${index}`} style={styles.listSection}>
                        <List.Accordion
                            title={
                                <View style={{ width: '100%' }}>
                                    <Text style={styles.listAccordionTitle}>{capitalize(element)}s</Text>
                                    <MasterDataSectionSummary element={element}
                                        elementObject={elementObject}
                                        expanded={expanded} />
                                </View>
                            }
                            right={() => (
                                <Icon name={`md-caret-${expanded[element] ? 'up' : 'down'}`}
                                    size={15}
                                    color={'#292929'}
                                    style={{ marginRight: 20 }} />
                            )}
                            expanded={expandedElement === element}
                            onPress={() => handlePress(element)}>

                            <View style={styles.accordionContent}>
                                {Object.keys(elementObject).map((key: string) => {
                                        return (
                                            <View style={styles.accordionRow} key={`${key}-${element}`}>
                                                <View style={styles.accordionRowTitle}>
                                                    <Typography size={16} fontWeight='Medium'>
                                                        {element === 'roof' ? 'Roof Light' : `${capitalize(key)}`}
                                                    </Typography>
                                                    {['wall', 'floor', 'ceiling'].includes(element) &&
                                                        <Typography size={16} fontWeight='Medium'> {capitalize(element)}</Typography>
                                                    }
                                                </View>

                                                {typeof adjacentSpace[element] !== 'undefined' &&
                                                    <View style={styles.dropdownWrapper}>
                                                            <DropDown
                                                                multiSelect={false}
                                                                label='Adjacent Space'
                                                                mode='outlined'
                                                                visible={adjacentSpaceDropdowns[element as RoomElement][key as subElementType] as boolean}
                                                                showDropDown={() => setDropdownValue(
                                                                    'Adjacent Space',
                                                                    true,
                                                                    element as RoomElement,
                                                                    key as subElementType
                                                                )}
                                                                onDismiss={() => setDropdownValue(
                                                                    'Adjacent Space',
                                                                    false,
                                                                    element as RoomElement,
                                                                    key as subElementType
                                                                )}
                                                                value={elementObject[key as RoomElement]?.adjacentSpace}
                                                                setValue={(value) => saveAdjacentSpace(
                                                                    element,
                                                                    value as string,
                                                                    key as subElementType
                                                                )}
                                                                disable={isDisabled(key as subElementType) }
                                                                list={adjacentSpace[element][key as subElementType] || []} />
                                                    </View>
                                                }

                                                <View style={styles.dropdownWrapper}>
                                                    <DropDown
                                                        multiSelect={false}
                                                        label='Fabric Type'
                                                        mode='outlined'
                                                        visible={dropdowns[element as RoomElement][key as subElementType] as boolean}
                                                        showDropDown={() => setDropdownValue(
                                                            'Fabric Type',
                                                            true,
                                                            element as RoomElement,
                                                            key as subElementType
                                                        )}
                                                        onDismiss={() => setDropdownValue(
                                                            'Fabric Type',
                                                            false,
                                                            element as RoomElement,
                                                            key as subElementType
                                                        )}
                                                        value={elementObject[key as RoomElement]?.fabricType}
                                                        setValue={(value) => saveFabricType(
                                                            element,
                                                            value as string,
                                                            key as subElementType
                                                        )}
                                                        list={fabricTypes[element][key as subElementType] || []}
                                                        textStyle={{
                                                            // width: viewWidthRef.current === 0 ? '100%' : 0.4 * viewWidthRef.current,
                                                        }} />
                                                </View>

                                                <View style={styles.textInputWrapper}>
                                                    <TextInput
                                                        maxLength={20}
                                                        autoComplete='off'
                                                        dense={false}
                                                        mode='outlined'
                                                        label={'Uvalue'}
                                                        value={(elementObject[key].uValue as question_detail)?.answer}
                                                        error={(elementObject[key].uValue as question_detail)?.inputRuleError}
                                                        onChangeText={(newAnswer) => saveUvalue(
                                                            elementObject,
                                                            newAnswer as string,
                                                            key as subElementType,
                                                            setElementObject
                                                        )}
                                                        onFocus={
                                                            isDesktop
                                                            ? (e) => e.target.setAttribute('autocomplete', 'off')
                                                            : undefined
                                                        }
                                                        style={{
                                                            width: isMobile ? '80%' : '100%'
                                                        }} />

                                                    {isMobile &&
                                                        <View style={styles.hint}>
                                                            <Hint text={'Brackets show the U-Value in W/m2k'} />
                                                        </View>
                                                    }
                                                </View>

                                                {!isMobile &&
                                                    <View style={styles.hint}>
                                                        <Hint text={'Brackets show the U-Value in W/m2k'} />
                                                    </View>
                                                }
                                            </View>
                                        );
                                    })}
                            </View>
                        </List.Accordion>
                    </List.Section>
                );
            })}

            <View style={styles.saveBtnWrapper}>
                <Button
                    testID='save-button'
                    mode='contained'
                    disabled={false}
                    onPress={saveToFirebase}>
                    Save
                </Button>
            </View>
        </View>
    );
};

export default MasterConstructionData;
