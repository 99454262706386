import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import type { cylinderProduct, product } from '../../../util/types';
import { setUpCollectionListener } from '../../../util/firebaseConnection';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { DesktopContext } from '../../../util/contexts';
import { ActivityIndicator, List } from 'react-native-paper';
import { desktopStyle } from '../../../styles/desktop';

const Cylinder = (props: {
    product: product;
    colors: ReactNativePaper.ThemeColors;
    onPress: () => void;
}) => {
    const isDesktop = useContext(DesktopContext);
    const [initializing, setInitializing] = useState(true);

    useEffect(() => {
        if (initializing) {
            if (props.product.ean != 'standalone') {
                (setUpCollectionListener('data', true, undefined, [
                    ['type', '==', 'cylinderData'],
                    ['ean', '==', props.product.ean],
                ]) as Promise<QuerySnapshot<DocumentData>>).then((doc) => {
                    props.product.content = doc.docs[0].data() as cylinderProduct<'string'>;
                    setInitializing(false);
                });
            } else {
                setInitializing(false);
            }
        }
    }, []);

    if (!initializing) {
        if (props.product.ean != 'standalone') {
            //NOTE: Displayed in Cylinder Selection
            return (
                <View style={
                        isDesktop
                        ? desktopStyle(props.colors).headlineQuestionList
                        : { width: '100%' }
                    }>

                    <List.Item
                        titleNumberOfLines={2}
                        title={
                        props.product.content.manufacturer +
                        ' ' +
                        (props.product.content as cylinderProduct<'string'>)
                            .cylinderModelName +
                        ' ' +
                        (props.product.content as cylinderProduct<'string'>)
                            .cylinderType +
                        ' (£' +
                        props.product.price +
                        ')'
                        }
                        description={
                        'Dimensions (LxHxW): ' +
                        (props.product.content as cylinderProduct<'string'>).length +
                        'x' +
                        (props.product.content as cylinderProduct<'string'>).height +
                        'x' +
                        (props.product.content as cylinderProduct<'string'>).width +
                        ' | Size: ' +
                        (props.product.content as cylinderProduct<'string'>).size
                        }
                        onPress={props.onPress} />
                </View>
            );
        } else {
            return (
                <View style={
                    isDesktop
                    ? desktopStyle(props.colors).headlineQuestionList
                    : { width: '100%' }
                }>
                    <List.Item
                        title={'Standalone - Heating only' + ' (£' + props.product.price + ')'}
                        onPress={props.onPress} />
                </View>
            );
        }
    } else {
        return (
            <View style={
                isDesktop
                    ? desktopStyle(props.colors).headlineQuestionList
                    : { width: "100%" }
                }>
                <ActivityIndicator />
            </View>
        );
    }
};

export default Cylinder;