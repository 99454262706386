import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    heading: {
        fontSize: 16,
        fontFamily: 'RobotoMedium'
    },
    row: {
        backgroundColor: 'white',
        padding: 20,
        flex: 1,
        flexDirection: 'row',
        width: '90%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
        flexWrap: 'wrap'
    },
    sectionTitle: {
        fontSize: 16
    },
    details: {
        fontSize: 14,
        color: '#919BB7'
    }
});

export default styles;