import { itemType, question_detail, section } from '../types';

export const initialUValue: question_detail = {
    conditionalFunctionType: '',
    type: 'wall',
    input_rule: 'minMax',
    hint: '',
    values: [],
    options: [
        '0.0001',
        '100'
    ],
    question_text: 'U value',
    answer: '',
    inputRuleError: false
};

const prepopulateFieldsFromMasterData = (items: itemType[] | any, routeParams: any, fieldName: string) => {

    if (['FABRIC TYPE', 'U VALUE', 'ADJACENT SPACE'].includes(fieldName)) return;

    if (typeof routeParams.roomsMasterData === 'undefined') return;

    const adjacentSpaceIndex = items.findIndex((item: any) => item.content.question_text.toUpperCase() === 'ADJACENT SPACE');
    const fabricTypeIndex = items.findIndex((item: any) => item.content.question_text.toUpperCase() === 'FABRIC TYPE');
    const subType = items[fabricTypeIndex]?.content?.subType; // external | internal | party

    if (items.length >= 6 && fabricTypeIndex !== -1) {
        const elementType = items[0]?.content?.type;

        console.log(`Fabric Types: ${subType}-${elementType} prepopulation`);
        items[adjacentSpaceIndex].content.answer = routeParams?.roomsMasterData[elementType][subType].adjacentSpace;
        items[fabricTypeIndex].content.answer = routeParams?.roomsMasterData[elementType][subType].fabricType;
        items[fabricTypeIndex + 1].content.answer = routeParams?.roomsMasterData[elementType][subType].uValue.answer;
    }
};

export const prepopulateFabricTypesOnSubElements = (section: section, items: itemType[], routeParams: any) => {
    let elementType = '';
    let subType = '';

    if (section.text.toUpperCase() === 'ROOF LIGHTS' &&
        section.answer === 'plus-circle-outline') {
        elementType = 'roof';
        subType = 'roofLight';
    }

    if (section.text.toUpperCase() === 'WINDOWS' &&
        section.answer === 'plus-circle-outline') {
        elementType = 'window';
        subType = 'window';
    }

    if (section.text.toUpperCase() === 'DOORS' &&
        section.answer === 'plus-circle-outline') {
        elementType = 'door';
        subType = 'door';
    }

    const fabricTypeIndex = (items as any).findLastIndex((item: any) =>
        item?.content?.question_text?.toUpperCase() === 'FABRIC TYPE'
            && item?.content?.subType === subType
            && item?.content?.answer === '');

    if (items.length >= 6 && fabricTypeIndex !== -1) {
        console.log(`Fabric Types: ${subType} prepopulation`);
        (items[fabricTypeIndex].content as question_detail).answer = routeParams?.roomsMasterData[elementType][subType].fabricType;
        (items[fabricTypeIndex + 1].content as question_detail).answer = routeParams?.roomsMasterData[elementType][subType].uValue.answer;
    }
};

export default prepopulateFieldsFromMasterData;