import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WhiteLabelState = {
    company: string;
    isBaxi: boolean;
};

const initialState: WhiteLabelState = {
    company: '',
    isBaxi: false
};

export const whiteLabelSlice = createSlice({
    name: 'whiteLabel',
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<string>) => {
            state.company = action.payload;
        },
        setIsBaxi: (state, action: PayloadAction<boolean>) => {
            state.isBaxi = action.payload;
        },
        clearWhiteLabel: () => ({
            ...initialState,
        }),
    },
});

export const {
    setCompany,
    setIsBaxi,
    clearWhiteLabel
} = whiteLabelSlice.actions;

export default whiteLabelSlice.reducer;
