import { store } from '../../redux/store';
import { checkIfInputRuleViolated } from '../checkIfInputRuleViolated';
import { initialUValue } from './prepopulateFieldsFromMasterData';
import type { subElementType, input_rule_type } from '../types';

const saveUvalue = (
    elementObject: any,
    newAnswer: string,
    type: subElementType,
    setElementObject: (objectValue: any) => void,
) => {
    checkIfInputRuleViolated(
        newAnswer,
        initialUValue.input_rule as input_rule_type,
        initialUValue.values
            ? initialUValue.values
            : initialUValue.options
    ).then((inputRuleError: boolean) => {
        store.dispatch(setElementObject({
            subType: type,
            fabricType: elementObject[type as keyof typeof elementObject].fabricType,
            uValue: {
                answer: newAnswer,
                inputRuleError
            }
        }));
    });
};

export default saveUvalue;