import { Fragment, useContext, useState, useEffect } from 'react';
import { useRoute } from '@react-navigation/native';
import { Hoverable } from 'react-native-web-hover';
import { FlatList, ListRenderItem, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setHighlightedTabsPerProject } from '../../../../redux/modules/roomByRoom';
import AppEventEmitter, { AppEvent } from '../../../../util/appEvent';
import {
  Divider,
  IconButton,
  List,
  useTheme,
} from 'react-native-paper';
import {
  DesktopContext,
  ProjectDetailContext,
  RoomsContext,
} from '../../../../util/contexts';
import {
  createDocument,
  deleteDocument,
  mergeDataWithDocument,
} from '../../../../util/firebaseConnection';
import {
  projectDetailNavigationProps,
  questionListData,
} from '../../../../util/navigationProps';
import type {
    radiator,
  room_detail
} from '../../../../util/types';
import uuid from "react-native-uuid";
import {
  desktopStyle,
  desktopStylePressables,
} from '../../../../styles/desktop';
import AddElementBtn from '../AddElementBtn';
import { Confirmation } from '../../../Dialogs';
import { Magicplan, FloorPlanDialog } from '../../dialogs';
import { iconColor, titleStyle } from '../../../Flatlists';
import navigationStyles from '../../../../styles/navigation';
import RoomElementTitle from '../RoomElementTitle/RoomElementTitle';
import RoomDataTitle from '../RoomDataTitle/RoomDataTitle';
import { Typography } from '../../../base';

export const RoomByRoomFlatList = (props: {
  navigate: (
    name: keyof projectDetailNavigationProps,
    params: questionListData | undefined
  ) => void;
  newRooms?: boolean;
  highlightedStep?: number;
  setHighlightedStep?: (index: number) => void;
}) => {
    const dispatch = useAppDispatch();

    const { colors } = useTheme();
    const [expandedId, setExpandedId] = useState(-1);
    const isDesktop = useContext(DesktopContext);
    const rooms = useContext(RoomsContext);
    const thisProject = useContext(ProjectDetailContext);
    const roomPath = "projects/" + thisProject.id + "/rooms";
    const [alertVisible, setAlertVisible] = useState(false);
    const [roomIdToDelete, setRoomIdToDelete] = useState('');
    const [isMagicplanVisible, setIsMagicplanVisible] = useState(false);
    const [displayFloorPlanDialog, setDisplayFloorPlanDialog] = useState(false);

    const [isNewRadiatorSelected, setIsNewRadiatorIsSelected] = useState(false);

    const route = useRoute() as any;

    const { highlightedTabsPerProject } = useAppSelector(({ roomByRoom }) => roomByRoom);

    useEffect(() => {
        const displayFloorPlan = AppEventEmitter.addListener(AppEvent.DisplayFloorPlanDialog, () => setDisplayFloorPlanDialog(true));

        if (!props.newRooms) {
            highlightPreviouslyOpenedTab();
        }

        return () => {
            displayFloorPlan.remove();
        };
    }, []);

    const highlightPreviouslyOpenedTab = () => {
        if (!highlightedTabsPerProject) return;

        const roomByRoomCache = highlightedTabsPerProject[thisProject.id];

        if (highlightedTabsPerProject && roomByRoomCache) {
            if (roomByRoomCache.previousCollapsedRoom !== -1) {
                setExpandedId(roomByRoomCache.previousCollapsedRoom as number);
            }

            if (roomByRoomCache.previousHighlightedStep !== -1) {
                props.navigate('Questions', roomByRoomCache?.previousQuestionCollection);
                props.setHighlightedStep && props.setHighlightedStep(roomByRoomCache.previousHighlightedStep);
            }
        }
    };

    const onAccordionPress = (newExpandedId: number) => {
        let previousCollapsedRoom = -1;

        if (newExpandedId != expandedId) {
            setExpandedId(newExpandedId as number);
            previousCollapsedRoom = newExpandedId;
        } else setExpandedId(-1);

        dispatch(setHighlightedTabsPerProject({
            projectId: thisProject.id,
            valuesToSave: {
                previousCollapsedRoom,
                previousHighlightedStep: -1,
                previousQuestionCollection: -1
            }
        }));
    };

    const onViewRadiator = (item: any, index: number, subIndex:number) => {
        setIsNewRadiatorIsSelected(false);
        const navigationDetails = {
            displayedQuestionCollection: props.newRooms ? 'newRadiator' : 'existingRadiator',
            valueStoragePath: roomPath,
            valueStorageDocument: item.id,
            categoryOfAnswers: 'room',
            nestedPath: props.newRooms ? 'newRadiators[' + subIndex + ']' : 'existingRadiators[' + subIndex + ']',
            isProvidingInformationForArrayElement: props.newRooms ? 'newRadiators' : 'existingRadiators',
            variablesToKeep: [],
        };
        const highlightedStep = index + (subIndex + 1) / 10000;

        props.navigate('Questions', navigationDetails as any);
        props.setHighlightedStep && props.setHighlightedStep(highlightedStep);

        if (!props.newRooms) { //Note: trigger on room by room tab only
            dispatch(setHighlightedTabsPerProject({
                projectId: thisProject.id,
                valuesToSave: {
                    previousHighlightedStep: highlightedStep,
                    previousQuestionCollection: navigationDetails
                }
            }));
        }
    };

    const onViewNewlyAddedRadiator = (item: any, index: number, subIndex:number) => {
        setIsNewRadiatorIsSelected(true);
        const navigationDetails = {
            displayedQuestionCollection: 'newRadiator',
            valueStoragePath: roomPath,
            valueStorageDocument: item.id,
            categoryOfAnswers: 'room',
            nestedPath: 'addedRadiators[' + subIndex + ']',
            isProvidingInformationForArrayElement: 'addedRadiators',
            variablesToKeep: [],
        };
        const highlightedStep = index + (subIndex + 1) / 10000;

        props.navigate('Questions', navigationDetails as any);
        props.setHighlightedStep && props.setHighlightedStep(highlightedStep);
    };

    const onViewGeneralInformation = () => {
        const navigationDetails = {
            displayedQuestionCollection: props.newRooms ? 'new-rooms-general' : 'roombyroom-general',
            valueStoragePath: 'projects',
            valueStorageDocument: thisProject.id,
            categoryOfAnswers: 'project',
            nestedPath: !props.newRooms ? 'heatingZones' : 'heatingZones_lwt'
        };
        const highlightedStep = -0.1;

        props.navigate('Questions', navigationDetails as any);
        props.setHighlightedStep && props.setHighlightedStep(highlightedStep);

        dispatch(setHighlightedTabsPerProject({
            projectId: thisProject.id,
            valuesToSave: {
                previousHighlightedStep: highlightedStep,
                previousQuestionCollection: navigationDetails
            }
        }));
    };

    const renderRoom: ListRenderItem<room_detail<"standard">> = ({
        item,
        index,
    }) => {
        return (
            <Fragment>
                <Confirmation
                    visible={alertVisible}
                    setVisible={setAlertVisible}
                    executedFunction={() => {
                        props.setHighlightedStep && props.setHighlightedStep(-1);
                        deleteDocument(roomPath, roomIdToDelete);
                    }} />

                <Hoverable>
                    {({ hovered }) => (
                        <List.Accordion
                            testID="new-room"
                            style={
                                isDesktop &&
                                desktopStylePressables(
                                { pressed: false, hovered: hovered },
                                colors
                                ).secondMenuButtons
                            }
                            id={index + 1}
                            title={(item.general && item.general.customName) || "New Room"}
                            description={
                                props.newRooms && item.heatLoss != undefined
                                ? item.general &&
                                    item.general.roomType +
                                    "\nHeat Loss: " +
                                    Math.round(item.heatLoss) +
                                    "W" +
                                    (item.heatGeneration != undefined &&
                                    item.heatGeneration != 0
                                        ? "\nHeat Generated: " +
                                        Math.round(item.heatGeneration) +
                                        "W"
                                        : "")
                                : item.general && item.general.roomType
                            }
                            descriptionNumberOfLines={3}
                        >
                            {!props.newRooms && (
                                <RoomDataTitle
                                    key={'room-data'}
                                    highlightedStep={props?.highlightedStep as number}
                                    roomIndex={index}
                                    newRooms={props?.newRooms as any}
                                    room={item}
                                    roomPath={roomPath}
                                    roomsMasterData={route.params?.roomsMasterData}
                                    setHighlightedStep={props.setHighlightedStep as (step: number) => void}
                                    setRoomIdToDelete={setRoomIdToDelete}
                                    setAlertVisible={setAlertVisible}
                                    navigate={props.navigate} />
                            )}

                            {item.elements &&
                                item.elements.map(
                                (element, subIndex) =>
                                    !props.newRooms && ['floor', 'ceiling'].includes(element.type) && (
                                        <RoomElementTitle
                                            key={`${element.type}-${subIndex}`}
                                            subIndex={subIndex}
                                            highlightedStep={props?.highlightedStep as number}
                                            roomIndex={index}
                                            element={element}
                                            newRooms={props?.newRooms as any}
                                            room={item}
                                            roomPath={roomPath}
                                            roomsMasterData={route.params?.roomsMasterData}
                                            setHighlightedStep={props.setHighlightedStep as (step: number) => void}
                                            navigate={props.navigate} />
                                )
                            )}
                            {!props.newRooms && (
                                <AddElementBtn
                                    elementType='Ceiling'
                                    roomPath={roomPath}
                                    item={item}
                                    index={index}
                                    props={props}
                                    roomsMasterData={route.params?.roomsMasterData} />
                            )}

                            {item.elements &&
                                item.elements.map(
                                (element, subIndex) =>
                                    !props.newRooms && element.type === 'wall' && (
                                        <RoomElementTitle
                                            key={`${element.type}-${subIndex}`}
                                            subIndex={subIndex}
                                            highlightedStep={props?.highlightedStep as number}
                                            roomIndex={index}
                                            element={element}
                                            newRooms={props?.newRooms as any}
                                            room={item}
                                            roomPath={roomPath}
                                            roomsMasterData={route.params?.roomsMasterData}
                                            setHighlightedStep={props.setHighlightedStep as (step: number) => void}
                                            navigate={props.navigate} />
                                )
                            )}

                            {!props.newRooms && (
                                <AddElementBtn
                                    elementType='Wall'
                                    roomPath={roomPath}
                                    item={item}
                                    index={index}
                                    props={props}
                                    roomsMasterData={route.params?.roomsMasterData} />
                            )}

                            {item.general &&
                                item.general.zone != undefined &&
                                thisProject.heatingZones != undefined &&
                                thisProject.heatingZones.heatingZones[item.general.zone] &&
                                (thisProject.heatingZones.heatingZones[item.general.zone]
                                .emitterType == "Radiator" ||
                                thisProject.heatingZones.heatingZones[item.general.zone]
                                    .emitterType == "UFH/Radiator") && (
                                    <Fragment>
                                        {item.existingRadiators &&
                                        item.existingRadiators.map(
                                            (existingRadiator, subIndex) => {
                                            const everythingFilledOut = !props.newRooms
                                                ? existingRadiator.everythingFilledOut == true
                                                : existingRadiator.doNotReplace == true ||
                                                !item.newRadiators ||
                                                !item.newRadiators[subIndex] ||
                                                item.newRadiators[subIndex].everythingFilledOut ==
                                                    true;
                                            return (
                                                <Hoverable key={subIndex}>
                                                {({ hovered }) => (
                                                    <List.Item
                                                        titleStyle={titleStyle(
                                                            isDesktop,
                                                            index + (subIndex + 1) / 10000 ==
                                                            props.highlightedStep,
                                                            hovered,
                                                            everythingFilledOut,
                                                            colors
                                                        )}
                                                        style={[
                                                            navigationStyles.listItem,
                                                            isDesktop &&
                                                            desktopStylePressables(
                                                                {
                                                                pressed:
                                                                    index + (subIndex + 1) / 10000 ==
                                                                    props.highlightedStep && !isNewRadiatorSelected,
                                                                hovered: hovered,
                                                                },
                                                                colors
                                                            ).secondMenuButtons,
                                                        ]}
                                                        key={subIndex}
                                                        left={() => (
                                                            <List.Icon
                                                            icon="radiator"
                                                            color={iconColor(
                                                                isDesktop,
                                                                index + (subIndex + 1) / 10000 ==
                                                                props.highlightedStep,
                                                                hovered,
                                                                everythingFilledOut,
                                                                colors
                                                            )}
                                                            />
                                                        )}
                                                        right={() =>
                                                            !props.newRooms && (
                                                            <IconButton
                                                                icon="delete"
                                                                style={{ alignSelf: "center" }}
                                                                onPress={() => {
                                                                    props.setHighlightedStep &&
                                                                        props.setHighlightedStep(-1);
                                                                    item.existingRadiators &&
                                                                    item.existingRadiators.splice(
                                                                        subIndex,
                                                                        1
                                                                    );
                                                                    item.newRadiators &&
                                                                        item.newRadiators.splice(
                                                                        subIndex,
                                                                        1
                                                                    );
                                                                    mergeDataWithDocument(
                                                                        roomPath,
                                                                        item.id,
                                                                        {
                                                                            existingRadiators: item.existingRadiators,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            )
                                                        }
                                                        title={`Radiator ${subIndex.toString()}`}
                                                        description={
                                                            everythingFilledOut
                                                            ? props.newRooms &&
                                                                item.newRadiators &&
                                                                item.newRadiators[subIndex] &&
                                                                item.newRadiators[subIndex]
                                                                .heatOutput &&
                                                                "Heat Generated: " +
                                                                Math.round(
                                                                    item.newRadiators[subIndex]
                                                                    .heatOutput || 0
                                                                ) +
                                                                "W"
                                                            : "Missing Information"
                                                        }
                                                        onPress={() => onViewRadiator(item, index, subIndex)}
                                                    />
                                                )}
                                                </Hoverable>
                                            );
                                            }
                                        )}

                                        {props.newRooms && item.addedRadiators &&
                                        item.addedRadiators.map(
                                            (addedRadiator, subIndex) => {

                                            const everythingFilledOut = addedRadiator.everythingFilledOut;

                                            return (
                                                <Hoverable key={subIndex}>
                                                {({ hovered }) => (
                                                    <List.Item
                                                        titleStyle={titleStyle(
                                                            isDesktop,
                                                            index + (subIndex + 1) / 10000 ==
                                                            props.highlightedStep,
                                                            hovered,
                                                            everythingFilledOut,
                                                            colors
                                                        )}
                                                        style={[
                                                            navigationStyles.listItem,
                                                            isDesktop &&
                                                            desktopStylePressables(
                                                                {
                                                                pressed:
                                                                    index + (subIndex + 1) / 10000 ==
                                                                    props.highlightedStep && isNewRadiatorSelected,
                                                                hovered: hovered,
                                                                },
                                                                colors
                                                            ).secondMenuButtons,
                                                        ]}
                                                        key={subIndex}
                                                        left={() => (
                                                            <List.Icon
                                                            icon="radiator"
                                                            color={iconColor(
                                                                isDesktop,
                                                                index + (subIndex + 1) / 10000 ==
                                                                props.highlightedStep,
                                                                hovered,
                                                                everythingFilledOut,
                                                                colors
                                                            )}
                                                            />
                                                        )}
                                                        right={() =>
                                                            props.newRooms && (
                                                            <IconButton
                                                                icon="delete"
                                                                style={{ alignSelf: "center" }}
                                                                onPress={() => {
                                                                    props.setHighlightedStep &&
                                                                        props.setHighlightedStep(-1);
                                                                    item.addedRadiators &&
                                                                    item.addedRadiators.splice(
                                                                        subIndex,
                                                                        1
                                                                    );
                                                                    mergeDataWithDocument(
                                                                        roomPath,
                                                                        item.id,
                                                                        {
                                                                            addedRadiators: item.addedRadiators,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            )
                                                        }
                                                        title={`Radiator ${(subIndex + (item.existingRadiators || []).length).toString()}`}
                                                        description={
                                                            everythingFilledOut
                                                            ? props.newRooms &&
                                                                item.addedRadiators &&
                                                                item.addedRadiators[subIndex] &&
                                                                item.addedRadiators[subIndex]
                                                                .heatOutput &&
                                                                "Heat Generated: " +
                                                                Math.round(
                                                                    item.addedRadiators[subIndex]
                                                                    .heatOutput || 0
                                                                ) +
                                                                "W"
                                                            : "Missing Information"
                                                        }
                                                        onPress={() => onViewNewlyAddedRadiator(item, index, subIndex)}
                                                    />
                                                )}
                                                </Hoverable>
                                            );
                                            }
                                        )}


                                        {props.newRooms && (
                                            <Hoverable>
                                                {({ hovered }) => (
                                                <List.Item
                                                    title="Add New Radiator"
                                                    style={[
                                                    navigationStyles.listItem,
                                                    isDesktop &&
                                                        desktopStylePressables(
                                                        { pressed: false, hovered: hovered },
                                                        colors
                                                        ).secondMenuButtons,
                                                    ]}
                                                    left={() => <List.Icon icon="plus" />}
                                                    onPress={() => {
                                                        mergeDataWithDocument(roomPath, item.id, {
                                                            addedRadiators: item?.addedRadiators
                                                                ? [...item?.addedRadiators, {
                                                                    ean: '',
                                                                    everythingFilledOut: false,
                                                                    replace: true,
                                                                    newlyAdded: true
                                                                }]
                                                                : [{
                                                                    ean: '',
                                                                    everythingFilledOut: false,
                                                                    replace: true,
                                                                    newlyAdded: true
                                                                }]
                                                        });
                                                    }}
                                                />
                                                )}
                                            </Hoverable>
                                        )}

                                        {!props.newRooms && (
                                            <Hoverable>
                                                {({ hovered }) => (
                                                <List.Item
                                                    title="Add Radiator"
                                                    style={[
                                                    navigationStyles.listItem,
                                                    isDesktop &&
                                                        desktopStylePressables(
                                                        { pressed: false, hovered: hovered },
                                                        colors
                                                        ).secondMenuButtons,
                                                    ]}
                                                    left={() => <List.Icon icon="plus" />}
                                                    onPress={() => {
                                                    mergeDataWithDocument(roomPath, item.id, {
                                                        existingRadiators: item.existingRadiators
                                                        ? [...item.existingRadiators, {}]
                                                        : [{}],
                                                        newRadiators: item.newRadiators
                                                        ? [...item.newRadiators, {}]
                                                        : [{}],
                                                    });
                                                    }}
                                                />
                                                )}
                                            </Hoverable>
                                        )}
                                    </Fragment>
                                )}
                        </List.Accordion>
                    )}
                </Hoverable>
                <Divider />
            </Fragment>
        );
    };

    return (
        <View style={isDesktop ? desktopStyle(colors).secondMenu : { flex: 1 }}>

            <Magicplan
                visible={isMagicplanVisible}
                setVisible={setIsMagicplanVisible} />

            <FloorPlanDialog
                thisProject={thisProject}
                visible={displayFloorPlanDialog}
                setVisible={setDisplayFloorPlanDialog} />

            <List.AccordionGroup
                expandedId={expandedId}
                onAccordionPress={(newExpandedId) => onAccordionPress(newExpandedId as number)}
            >
                <FlatList
                    testID="room-by-room-flatlist"
                    data={rooms}
                    renderItem={renderRoom}
                    keyExtractor={(item) => item.id}
                    onEndReachedThreshold={2}
                    initialNumToRender={10}
                    ListHeaderComponent={
                        <Fragment>
                        <Hoverable>
                            {({ hovered }) => (
                            <List.Item
                                title="General Information"
                                description={
                                ((!props.newRooms &&
                                    thisProject.heatingZones &&
                                    thisProject.heatingZones.everythingFilledOut ==
                                    false) ||
                                    (props.newRooms &&
                                    thisProject.heatingZones_lwt &&
                                    thisProject.heatingZones_lwt.everythingFilledOut ==
                                        false)) &&
                                "Missing Information"
                                }
                                titleStyle={titleStyle(
                                    isDesktop,
                                    -0.1 == props.highlightedStep,
                                    hovered,
                                    !props.newRooms
                                        ? thisProject.heatingZones &&
                                            thisProject.heatingZones.everythingFilledOut
                                        : thisProject.heatingZones_lwt &&
                                            thisProject.heatingZones_lwt.everythingFilledOut,
                                    colors
                                )}
                                style={[
                                    { paddingLeft: 15 },
                                    navigationStyles.listItem,
                                    isDesktop &&
                                        desktopStylePressables(
                                        {
                                            pressed: -0.1 == props.highlightedStep,
                                            hovered: hovered,
                                        },
                                        colors
                                        ).secondMenuButtons,
                                ]}
                                left={() => (
                                    <List.Icon
                                        icon="account-details"
                                        color={iconColor(
                                        isDesktop,
                                        -0.1 == props.highlightedStep,
                                        hovered,
                                        !props.newRooms
                                            ? thisProject.heatingZones &&
                                                thisProject.heatingZones.everythingFilledOut
                                            : thisProject.heatingZones_lwt &&
                                                thisProject.heatingZones_lwt
                                                .everythingFilledOut,
                                        colors
                                        )}
                                    />
                                )}
                                onPress={onViewGeneralInformation}
                            />
                            )}
                        </Hoverable>
                        <Divider />
                        </Fragment>
                    }
                    ListFooterComponent={
                        !props.newRooms ? (
                            <>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <List.Item
                                            testID="add-room-button"
                                            title="Add Room"
                                            style={[
                                                navigationStyles.listItem,
                                                isDesktop &&
                                                    desktopStylePressables({ pressed: false, hovered: hovered }, colors).secondMenuButtons,
                                            ]}
                                            left={() => <List.Icon icon="plus-circle" />}
                                            onPress={() => {
                                                const valueStorageDocument = uuid.v1().toString();

                                                createDocument(roomPath, valueStorageDocument, {
                                                    created: Date.now(),
                                                    elements: [{ type: "floor" }, { type: "ceiling" }],
                                                    accessRights: {
                                                        read: thisProject.accessRights.read,
                                                        write: thisProject.accessRights.write,
                                                    },
                                                }).then(() => {
                                                    /*props.setHighlightedStep &&
                                                        props.setHighlightedStep(rooms.length + 1);
                                                    setExpandedId(rooms.length + 1);
                                                    /*props.navigate("Questions", {
                                                        displayedQuestionCollection: "wall",
                                                        valueStoragePath: roomPath,
                                                        valueStorageDocument: item.id,
                                                        categoryOfAnswers: "room",
                                                        nestedPath:
                                                        "elements[" + item.elements.length + "]",
                                                        isProvidingInformationForArrayElement: "elements",
                                                        variablesToKeep: [
                                                        "type",
                                                        "heatLoss",
                                                        "energyUsagePerYear",
                                                        ],
                                                    });*/
                                                });
                                            }}
                                        />
                                    )}
                                </Hoverable>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <List.Item
                                            title='Magicplan'
                                            style={[
                                                navigationStyles.listItem,
                                                isDesktop &&
                                                desktopStylePressables(
                                                    { pressed: false, hovered: hovered },
                                                    colors
                                                ).secondMenuButtons,
                                            ]}
                                            left={() => <List.Icon icon='file-import' />}
                                            onPress={() => setIsMagicplanVisible(true)} />
                                    )}
                                </Hoverable>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <List.Item
                                            title='Floor Plan'
                                            style={[
                                                navigationStyles.listItem,
                                                isDesktop &&
                                                desktopStylePressables(
                                                    { pressed: false, hovered: hovered },
                                                    colors
                                                ).secondMenuButtons
                                            ]}
                                            left={() => (<List.Icon icon={'floor-plan'} />
                                            )}
                                            onPress={() => setDisplayFloorPlanDialog(true)} />
                                    )}
                                </Hoverable>
                            </>
                        ) : null
                    }
                />
            </List.AccordionGroup>
            {/*props.newRooms && (
                <Button
                onPress={() => {
                    props.navigate("Questions", {});
                    optimizeRadiators(rooms, thisProject).then(() => {
                    rooms.forEach((room) => {
                        mergeDataWithDocument(roomPath, room.id, room);
                    });
                    mergeDataWithDocument("/projects/", thisProject.id, thisProject);
                    });
                }}
                mode="contained"
                style={{ margin: 20 }}
                >
                Optimize Radiators
                </Button>
                )*/}
        </View>
    );
};