import { Text } from 'react-native-paper';
import { View } from 'react-native';
import { Button } from 'react-native-paper';
import { sectionMissingInfo } from '../../../util/types';
import styles from './missingInfoRedirectLinksStyles';

type MissingInfoRedirectLinksProps = {
    index: number;
    section: sectionMissingInfo;
    onRedirect: () => void;
}

const MissingInfoRedirectLinks: React.FC<MissingInfoRedirectLinksProps> = ({ index, section, onRedirect }) => {

    return (
        <View key={`proceed-btn-${index}`}>
            {index === 0 && <Text style={styles.heading}>Please complete the missing information below</Text>}
            <View style={styles.row}>
                <View>
                    <Text style={styles.sectionTitle}>{section.sectionTitle}</Text>
                    <Text style={styles.details}>{section?.missingInfoDetails}</Text>
                </View>
                <Button mode='outlined' onPress={onRedirect}>VIEW {section.sectionTitle}</Button>
            </View>
        </View>
    );
};

export default MissingInfoRedirectLinks;
