import {
    setFloor,
    setWalls,
    setCeilings,
    setRoofs,
    setWindows,
    setDoors
} from '../../redux/modules/masterData';
import { store } from '../../redux/store';
import type { elementType, subElementType } from '../types';
import { TargetElement } from '../types';

const getTargetElement = (
    element: elementType | subElementType
): TargetElement => {
    const { floor, ceilings, walls, roofs, windows, doors } = store.getState().masterData;

    const elements = {
        floor: {
            elementObject: floor,
            setElementObject: setFloor
        },
        wall: {
            elementObject: walls,
            setElementObject: setWalls
        },
        ceiling: {
            elementObject: ceilings,
            setElementObject: setCeilings
        },
        roof: {
            elementObject: roofs,
            setElementObject: setRoofs
        },
        window: {
            elementObject: windows,
            setElementObject: setWindows
        },
        door: {
            elementObject: doors,
            setElementObject: setDoors
        }
    };

    return elements[element as keyof typeof elements];
};

export default getTargetElement;