import { getDocument } from '../firebaseConnection';
import { setFabricTypes, setIsFabricTypesFetched } from '../../redux/modules/masterData';
import { store } from '../../redux/store';
import { questionResponse } from '../types';

const getFabricTypes = async () => {

    const { isFabricTypesFetched, fabricTypes: initialFabricTypes } = store.getState().masterData;

    if (isFabricTypesFetched) return;

    const documentStoragePath = {
        floor: [ 'ground-floor3v0', 'intermediate-floor2v0', 'party-floor3v0' ],
        wall: [ 'internal-walls2v0', 'external-walls3v0', 'party-walls3v0' ],
        ceiling: [ 'roof-ceiling3v0', 'intermediate-ceiling2v0', 'party-ceiling3v0' ],
        roof: ['roof-lights3v0'],
        window: ['windows3v0'],
        door: ['external-door3v0']
    };

    for (const [key] of Object.entries(documentStoragePath)) {
        const promises = [];

        for (const path of documentStoragePath[key as keyof typeof documentStoragePath]) {
            promises.push(getDocument('questions', path));
        }

        try {
            const fabricTypes = { ...initialFabricTypes };
            const responses = await Promise.all(promises) as questionResponse[];

            for (const response of responses) {
                const options = [];

                for (let i = 0; i < response.options.length; i++) {
                    options.push({
                        label: response.options[i],
                        value: response.options[i]
                    });
                }

                fabricTypes[response.subType] = options;
            }

            store.dispatch(setFabricTypes({
                [key]: { ...fabricTypes }
            }));
        } catch (err) {
            console.log(`Fetch fabric type options: ${err}`);
        }
    }

    store.dispatch(setIsFabricTypesFetched(true));
};

export default getFabricTypes;