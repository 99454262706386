import { getDocument } from '../firebaseConnection';
import { setGeneralDropdownOptions, setIsGeneralOptionsFetched } from '../../redux/modules/masterData';
import { store } from '../../redux/store';
import { questionResponse } from '../types';
import { getOptions } from '../helper';

const getGeneralDropdownOptions = async () => {

    const { isGeneralDropdownsFetched } = store.getState().masterData;

    if (isGeneralDropdownsFetched) return;

    const documentStoragePath = {
        constructionDate: 'room-general-questions2v0',
        temperatureDrop: 'room-general-questions11v0'
    };

    const promises = [];

    for (const [key] of Object.entries(documentStoragePath)) {
        const path = documentStoragePath[key as 'constructionDate' | 'temperatureDrop'];
        promises.push(getDocument('questions', path));
    }

    try {
        const responses = await Promise.all(promises) as unknown as questionResponse[];
        const perFieldOptions = getOptions(responses, 'variableName');

        store.dispatch(setGeneralDropdownOptions(perFieldOptions));
    } catch (err) {
        console.log(`Fetch general dropdown options: ${err}`);
    }

    store.dispatch(setIsGeneralOptionsFetched(true));
};

export default getGeneralDropdownOptions;