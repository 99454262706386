import { noise as computeNoise } from './localCloudFunctions/projectDetailConsistency';
import type { project_detail, noiseCalculationFactors } from './types';

type replacedValuesType = {
    noise: {
        noise: number,
        passed: boolean,
        calculationFactors: noiseCalculationFactors,
        obstruction: string
    }
};

export const addNoiseCalculationFactors = async (replacedValues: replacedValuesType, thisProject: project_detail<'standard'>) => {
    const noiseCalculation = await computeNoise({ ...thisProject, noise: replacedValues.noise as any }) as {
        soundPower: string,
        dbDistanceReduction: string,
        noise: number,
        passed: boolean
    };

    const pressureLevelComputation = parseInt(noiseCalculation.soundPower) + parseInt(noiseCalculation.dbDistanceReduction) + parseInt(replacedValues.noise.obstruction);

    if (!pressureLevelComputation) return;

    replacedValues.noise.calculationFactors = {
        pressureLevelComputation: pressureLevelComputation,
        dbDistanceReduction: noiseCalculation.dbDistanceReduction,
        heatPumpNoiseLevel: noiseCalculation.soundPower,
        noiseLevelDifference: 40 - pressureLevelComputation,
        everythingFilledOut: true
    }

    replacedValues.noise.noise = noiseCalculation.noise;
    replacedValues.noise.passed = noiseCalculation?.passed;
}