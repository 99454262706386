import React from 'react';
import { View } from 'react-native';
import { colors } from '../../../styles/colors';
import type { bannerType } from '../../../util/types';
import Typography from '../Typography/Typography';

export interface InfoBannerProps {
    message: string;
    type?: bannerType;
    style?: Object;
    children?: any;
}

const InfoBanner: React.FC<InfoBannerProps> = ({
    message = '',
    type = 'info',
    style,
    children
}) => {
    const backgroundColor = () => {
        switch(type as bannerType) {
            case 'success':
                return colors.supportSuccessLightGreen;
            case 'error':
                return colors.supportErrorLightRed;
            default:
                return colors.supportInfoLightBlue;
        };
    };

    const borderColor = () => {
        switch(type as bannerType) {
            case 'success':
                return colors.supportSuccessGreen;
            case 'error':
                return colors.supportErrorRed;
            default:
                return colors.supportInfoBlue;
        };
    };

    const textColor = () => {
        switch(type as bannerType) {
            case 'success':
                return colors.supportSuccessGreen;
            case 'error':
                return colors.supportErrorRed;
            default:
                return colors.supportInfoBlue;
        };
    };

    return (
        <View style={{
            backgroundColor: backgroundColor(),
            borderWidth: 1,
            borderColor: borderColor(),
            paddingVertical: 15,
            paddingHorizontal: 25,
            borderRadius: 5,
            ...style
        }}>
            <Typography color={textColor()}>{message}</Typography>
            {message === '' && children}
        </View>
    );
};

export default InfoBanner;
