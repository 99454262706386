import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flex: 1,
        marginLeft: 20,
        alignItems: 'center',
        marginTop: -5,
        marginBottom: 5,
        flexWrap: 'wrap'
    },
    type: {
        flexDirection: 'row',
        alignContent: 'center'
    }
});

export default styles;