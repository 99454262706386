import { useContext } from 'react';
import { View } from 'react-native';
import {
    Headline,
    IconButton,
    Subheading,
    Text,
    useTheme
} from 'react-native-paper';
import { desktopStyle } from '../../../styles/desktop';
import { prepopulateFabricTypesOnSubElements } from '../../../util/masterData/prepopulateFieldsFromMasterData';
import type { conditionalFunction, section } from '../../../util/types';
import { DesktopContext } from '../../../util/contexts';

type PageSectionProps = {
    section: section,
    index: number,
    itemsRef: any,
    routeParams: any,
    setItems: (items: any) => void,
    conditionalFunction?: conditionalFunction
}

const PageSection: React.FC<PageSectionProps> = ({ section, index, itemsRef, routeParams, setItems, conditionalFunction }) => {
    const isDesktop = useContext(DesktopContext);
    const { colors } = useTheme();

    return (
        <View style={ isDesktop ? desktopStyle(colors).headlineQuestionList : { width: '100%' }}>
            <View style={{ width: 230 }}>
                {section.typography == 'headline' ? (
                    <Headline>{section.text}</Headline>
                ) : section.typography == 'subheading' ? (
                    <Subheading>{section.text}</Subheading>
                ) : (
                    <Text>{section.text}</Text>
                )}
            </View>

            {conditionalFunction && section.answer && section.answer != '' && (
                <IconButton
                    testID={section.answer}
                    onPress={() => {
                        conditionalFunction({
                            items: itemsRef.current,
                            index: index,
                        }).then((items) => {
                            prepopulateFabricTypesOnSubElements(section, items, routeParams);
                            setItems(items); //NOTE: gets triggered when plus button or delete button is clicked on the Heating Zones section in Room By Room (General Information)
                        });
                    }}
                    icon={section.answer}/>
            )}
        </View>
    );
};

export default PageSection;