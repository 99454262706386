import { getDocument } from '../firebaseConnection';
import { setAdjacentSpaceTypes, setIsAdjacentSpaceTypesFetched } from '../../redux/modules/masterData';
import { store } from '../../redux/store';
import { questionResponse } from '../types';

const getAdjacentSpaceTypes = async () => {

    const { isAdjacentSpaceTypesFetched, adjacentSpace: initialAdjacentSpaces } = store.getState().masterData;

    if (isAdjacentSpaceTypesFetched) return;

    const documentStoragePath = {
        floor: [ 'ground-floor1v0', 'party-floor1v0' ],
        wall: [ 'external-walls1v0', 'party-walls1v0' ],
        ceiling: [ 'roof-ceiling1v0', 'party-ceiling1v0' ]
    };

    for (const [key] of Object.entries(documentStoragePath)) {
        const promises = [];

        for (const path of documentStoragePath[key as keyof typeof documentStoragePath]) {
            promises.push(getDocument('questions', path));
        }

        try {
            const adjacentSpace = { ...initialAdjacentSpaces };
            const responses = await Promise.all(promises) as questionResponse[];

            for (const response of responses) {
                const options = [];

                for (let i = 0; i < response.options.length; i++) {
                    options.push({
                        label: response.options[i],
                        value: response.options[i]
                    });
                }

                adjacentSpace[response.subType] = options;
            }

            store.dispatch(setAdjacentSpaceTypes({
                [key]: { ...adjacentSpace }
            }));
        } catch (err) {
            console.log(`Fetch adjacent space options: ${err}`);
        }
    }

    store.dispatch(setIsAdjacentSpaceTypesFetched(true));
};

export default getAdjacentSpaceTypes;