import { useState, useCallback, useRef } from 'react';
import { getImageLink, deleteFile } from '../../../util/firebaseConnection';
import { getUser } from '../../../util/firebaseConnection';
import { mergeDataWithDocument } from '../../../util/firebaseConnection';
import {
    prepareImagesForUpload,
    uploadFloorPlan,
    extractFileName,
    removeCancelledFiles
} from '../../../util/floorPlan';
import type { floorPlanImage } from '../../../util/types';
import { useFocusEffect } from '@react-navigation/native';
import FloorPlanDialog from './FloorPlanDialog';

type FloorPlanDialogContainerProps = {
    thisProject: any;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const FloorPlanDialogContainer: React.FC<FloorPlanDialogContainerProps> = ({
    thisProject,
    visible,
    setVisible
}) => {

    const [fileList, setFileList] = useState([] as floorPlanImage[]);
    const [displayedImages, setDisplayedImages] = useState([] as { url: string, name: string }[]);
    const [duplicateFileNames, setDuplicateFileNames] = useState([]);
    const [invalidFileTypes, setInvalidFileTypes] = useState([]);
    const [displayError, setDisplayError] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const filesToCancel = useRef([] as string[]);

    const [displayFullImage, setDisplayFullImage] = useState({ display: false, imgUrl: '', fileName: '' });

    useFocusEffect(
        useCallback(() => {
            if (typeof thisProject.floorPlanFileNames !== 'undefined') getFloorPlanImageLinks();
        }, [])
    );

    const getFloorPlanImageLinks = async () => {
        const promises = [];

        for (const fileName of thisProject.floorPlanFileNames) {
            promises.push(getImageLink(`${getUser()?.uid}/${thisProject.id}/floorPlans/${fileName}`));
        }

        const imageUrls = await Promise.all(promises);
        const imageUrlsArr = imageUrls.map((url, index) => ({ url, name: extractFileName(url) }));

        setDisplayedImages(imageUrlsArr);
    };

    const uploadImages = async () => {
        resetMessageErrors();

        const {
            uniqueFileNames: fileListTemp,
            duplicateFileNames: duplicates,
            invalidFormat
        } = await prepareImagesForUpload(thisProject) as any;

        if (fileListTemp.length + thisProject?.floorPlanFileNames?.length > 5) {
            setDisplayError(true);
            return;
        }

        if (duplicates.length !== 0) {
            setDuplicateFileNames(duplicates);
            return;
        }

        if (invalidFormat.length !== 0) {
            setInvalidFileTypes(invalidFormat);
            return;
        }

        setFileList(fileListTemp);
        setIsUploading(true);

        try {
            const imageUrls = await uploadFloorPlan(fileListTemp, thisProject.id);
            const floorPlanFileNames = fileListTemp.map((file: floorPlanImage) => file.name);
            let replacedValues = {
                floorPlanFileNames: thisProject.floorPlanFileNames ?
                    [ ...new Set([...thisProject.floorPlanFileNames, ...floorPlanFileNames]) ]
                    : floorPlanFileNames
            };

            await mergeDataWithDocument(
                'projects',
                thisProject.id,
                replacedValues
            );

            const uploadedFiles = [ ...displayedImages, ...imageUrls ];
            const filteredUploadedFiles = removeCancelledFiles(uploadedFiles, filesToCancel.current);

            setDisplayedImages(filteredUploadedFiles as []);
            setFileList([]);

            deleteImageViaCancelCTA(filteredUploadedFiles as []);
        } catch (err) {
            console.log(`Upload floor plan: ${err}`);
        }

        setIsUploading(false);
    };

    const onDeleteImage = async(fileIndex: number) => {
        const updatedImages = [ ...displayedImages ];
        const updatedFloorPlanFileNames = [ ...thisProject.floorPlanFileNames ];
        const fileName = (thisProject.floorPlanFileNames[fileIndex]);

        thisProject.floorPlanFileNames.splice(fileIndex, 1);
        updatedFloorPlanFileNames.splice(fileIndex, 1);
        updatedImages.splice(fileIndex, 1);

        try {
            await deleteFile(`${getUser()?.uid}/${thisProject.id}/floorPlans/${fileName}`);

            await mergeDataWithDocument(
                'projects',
                thisProject.id,
                { floorPlanFileNames: updatedFloorPlanFileNames }
            );

            setDisplayedImages(updatedImages);
        } catch (err) {
            console.log(`Delete floor plan: ${err}`);
        }
    };

    const onCancelImgUpload = (fileIndex: number) => {
        const updatedFileList = [ ...fileList ];

        updatedFileList.splice(fileIndex, 1);
        filesToCancel.current.push(fileList[fileIndex].name);

        setFileList(updatedFileList);
    };

    const onCloseModal = () => {
        setVisible(false);
        resetMessageErrors();
    };

    const resetMessageErrors = () => {
        setDisplayError(false);
        setInvalidFileTypes([]);
        setDuplicateFileNames([]);
    };

    const deleteImageViaCancelCTA = async (displayedImages: { url: string, name: string }[]) => {
        if (!isUploading && filesToCancel.current.length !== 0) {
            try {
                const filteredUploadedFiles = removeCancelledFiles(displayedImages, filesToCancel.current); // Rollback
                const fileNames = filteredUploadedFiles.map((file: any, index) => file.name);

                await mergeDataWithDocument(
                    'projects',
                    thisProject.id,
                    { floorPlanFileNames: fileNames }
                );

                for (const fileName of filesToCancel.current) {
                    deleteFile(`${getUser()?.uid}/${thisProject.id}/floorPlans/${fileName}`);
                }

                filesToCancel.current = [];
            } catch (err) {
                console.log(`Cancel image upload: ${err}`);
            }
        }
    };

    return (
        <FloorPlanDialog
            visible={visible}
            isUploading={isUploading}
            displayError={displayError}
            duplicateFileNames={duplicateFileNames}
            invalidFileTypes={invalidFileTypes}
            noOfImagesUploaded={displayedImages.length}
            displayedImages={displayedImages}
            floorPlanFileNames={thisProject.floorPlanFileNames || []}
            fileList={fileList}
            displayFullImage={displayFullImage}
            uploadImages={uploadImages}
            onCloseModal={onCloseModal}
            onDeleteImage={onDeleteImage}
            onCancelImgUpload={onCancelImgUpload}
            setDisplayFullImage={setDisplayFullImage} />
    );
};

export default FloorPlanDialogContainer;
