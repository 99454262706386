export const reports = (
  contractBeforeSurvey: boolean,
  inOfficeContract: boolean
) => {
  let reports = [
    {
      text: "Heat Loss Summary and Radiator Schedule",
      question_collection: "standard",
      description: "Overview of Project Information",
      extraData: ["standard", "reports"],
      nestedPath: "standardContract",
    },
    //this one should eventually be renamed to journey1Proposal not contract
    {
      text: "Variation of Contract - Journey 2",
      question_collection: "NA",
      description: "journey-2-model-variation-of-contract",
      extraData: ["journey-2-model-variation-of-contract", "reports"],
      nestedPath: "contractVariation",
    },
    {
      text: "Proposal - Journey 1",
      question_collection: "projectContract",
      description:
        "Model covering letter for proposals - contract agreed after full site survey",
      extraData: [
        "journey-1-model-covering-letter-for-proposals-parameters",
        "reports",
      ],
      nestedPath: "contract",
    },
    {
      text: "Performance estimate - Journey 1",
      question_collection: "NA",
      description: "Produce a performance estimate report for the design",
      extraData: ["journey-1-model-performance-estimate", "reports"],
      nestedPath: "performanceEstimate",
    },
    {
      text: "Quotation and Order Form - Journey 1",
      question_collection: "projectQuote",
      description: "Produce a quote and order form for the customer",
      extraData: ["journey-1-model-quotation-and-order-form", "reports"],
      nestedPath: "quote",
    },
    {
      text: "MCS Compliance Certificate",
      question_collection: "projectMCSCompliance",
      description: "Document for certifying compliance to MCS heat pump rules",
      extraData: ["MCS-compliance-certificate", "reports"],
      nestedPath: "MCSCompliance",
    },
    {
      text: "MCS Performance Estimate",
      question_collection: "mcsPerformance",
      description: "Run cost overview according to MCS standards",
      extraData: ["MCS-performance-estimate", "reports"],
      nestedPath: "mcsPerformance",
    },
    {
      text: "RECC Contract",
      question_collection: "RECCContract",
      description: "Contract for installation services",
      extraData: ["model-contract", "reports"],
      nestedPath: "RECCContract",
    },
    {
      text: "Express Request Form",
      question_collection: "NA",
      description: "Request form for the customer to complete",
      extraData: [
        "model-express-request-form-for-companies-who-do-sell-in-the-home-or-by-distance",
        "reports",
      ],
      nestedPath: "expressRequestOffPremises",
    },
    {
      text: "Cancellation Form",
      question_collection: "NA",
      description: "Cancellation form for in-office contracts",
      extraData: [
        "model-cancellation-form-for-companies-who-do-not-sell-in-the-home-or-by-distance",
        "reports",
      ],
      nestedPath: "cancellationFormOnPremises",
    },
    {
      text: "Noise Calculation",
      question_collection: "NA",
      description: "Noise calculation form",
      extraData: [
        "noise-calculation-report",
        "reports",
      ],
      nestedPath: "noiseCalculationReport",
    },
    //until kevin helps fill this in
    // {
    //   text: "Installer Warranty",
    //   question_collection: "NA",
    //   description: "Customer warranty for installation services",
    //   extraData: ["model-installer-warranty", "reports"],
    //   nestedPath: "installerWarranty",
    // },
    /*{
      text: "Download all",
      question_collection: "NA",
      description: "Customer warranty for installation services",
      extraData: ["download-all", "reports"],
      nestedPath: "downloadAll",
    },*/
  ];
  if (contractBeforeSurvey) {
    reports = reports.filter(
      (report) => !report.extraData[0].includes("journey-1")
    );
  } else {
    reports = reports.filter(
      (report) => !report.extraData[0].includes("journey-2")
    );
  }
  if (inOfficeContract) {
    reports = reports.filter(
      (report) => !report.extraData.includes("who-do-sell-in-the-home")
    );
  }
  return reports;
};
