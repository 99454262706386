import { createStackNavigator } from "@react-navigation/stack";
import React, { ComponentType } from "react";
//TODO: replace with simple object based definition. Maybe unnecessary?
export const Stack = (props: {
  screens: {
    name: string;
    component: ComponentType;
    initialParams?: object;
    options?: object;
  }[];
  initialRoute: string;
}) => {
  const Stack = createStackNavigator();
  if (props.screens.length == 0) {
    throw ReferenceError("At least one screen has to be set.");
  }
  return (
    <Stack.Navigator
      initialRouteName={props.initialRoute}
      screenOptions={{
        headerShown: false,
      }}
    >
      {props.screens.map((screen, key) => (
        <Stack.Screen
          key={key}
          name={screen.name as string}
          component={screen.component}
          initialParams={screen.initialParams}
          options={screen.options}
        />
      ))}
    </Stack.Navigator>
  );
};
