import { StyleSheet } from 'react-native';
import { floorPlan, mobileScreenWidth, screenWidth } from '../../../styles/constants';
import { colors } from '../../../styles/colors';

const styles = StyleSheet.create({
    container: {
        width: floorPlan.imgBoxWidth,
        borderStyle: 'dashed',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: screenWidth <= mobileScreenWidth ? 0 : 28,
        marginBottom: 28
    },
    image: {
        width: floorPlan.imgBoxWidth,
        height: floorPlan.imgBoxHeight
    },
    fileNameBtn: {
        width: '100%',
        marginTop: 20,
        borderWidth: 1,
        borderColor: colors.neutral500,
        borderRadius: 5,
    },
    removeBtn: {
        marginTop: 5
    }
});

export default styles;