import { StyleSheet } from "react-native";

const buttonStyles = StyleSheet.create({
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
  },
});
export default buttonStyles;
