export const colors = {
    black: '#000000',
    white: '#ffffff',
    grey: '#F5F5F5',
    grey2: '#F8F9FA',
    neutral: '#202532',
    neutral300: '#919BB7',
    neutral500: '#292929',
    blueGreen: '#008a67',
    lightRed: '#FFE8ED',
    supportErrorRed: '#FF003D',
    supportErrorLightRed: '#FFF3F6',
    supportInfoBlue: '##919BB7',
    supportInfoLightBlue: '#F9FAFF',
    supportSuccessGreen: '#6eB02F',
    supportSuccessLightGreen: '#f2ffe6'
};