import React from 'react';
import { View, Image } from 'react-native';
import { Dialog, IconButton } from 'react-native-paper';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import { mobileScreenWidth, screenWidth } from '../../../styles/constants';
import { colors } from '../../../styles/colors';
import Typography from '../Typography/Typography';
import styles from './fullImageModalStyles';

export interface FloorImageModalProps {
    isVisible: boolean;
    imgFileName: string;
    imageUrl: string;
    onDismissDialog: () => void;
    closeModal: () => void;
}

const FloorImageModal: React.FC<FloorImageModalProps> = ({
    isVisible,
    imgFileName,
    imageUrl,
    onDismissDialog,
    closeModal
}) => {
    const isViewedOnMobile = screenWidth < 780;

    return (
        <Dialog
            visible={isVisible}
            onDismiss={onDismissDialog}
            style={styles.container}>
            
            <View style={styles.contentWrapper}>
                <View style={styles.header}>
                    <View style={styles.fileNameTextWrapper}>
                        <Typography size={32} fontWeight='Medium' noOfLines={1}>
                            {screenWidth <= mobileScreenWidth && `${imgFileName.slice(0, 10)}${imgFileName.length > 10 ? '...' : ''}`}
                        </Typography>
                    </View>
                    <IconButton icon='window-close'
                        size={40}
                        onPress={closeModal} />
                </View>

                <View style={styles.zoomWrapper}>
                    <ReactNativeZoomableView
                        maxZoom={3}
                        minZoom={0.5}
                        zoomStep={0.25}
                        initialZoom={1}
                        bindToBorders={true}
                        style={{ padding: 10 }} >
                        <Image
                            style={styles.image}
                            resizeMode='contain'
                            source={{ uri: imageUrl }} />
                    </ReactNativeZoomableView>
                </View>

                <View style={styles.instruction}>
                    <Typography fontWeight='Bold' color={colors.white}>INSTRUCTION</Typography>
                    <Typography color={colors.white} style={{ marginTop: 5 }}>
                        Please {isViewedOnMobile ? 'tap your screen ' : 'click your mouse '}
                        twice to zoom in. You can move the image around when it's zoomed in.
                    </Typography>
                </View>
            </View>
        </Dialog>
    );
};

export default FloorImageModal;
