import { StyleSheet } from 'react-native';
import { floorPlan, mobileScreenWidth, screenWidth } from '../../../styles/constants';
import { colors } from '../../../styles/colors';

const styles = StyleSheet.create({
    bannerWrapper: {
        marginBottom: 40,
        marginTop: -10,
        width: '100%'
    },
    icon: { marginRight: 5 },
    fileName: {
        flexDirection: 'row',
        alignItems: 'center'
    }
});

export default styles;