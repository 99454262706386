import { useContext, useState } from "react";
import { Appbar, Text } from "react-native-paper";
import { HeaderDispatch, ProjectDetailContext } from "../../util/contexts";
import { QuestionList } from "../../components/QuestionList";
import { StackScreenProps } from "@react-navigation/stack";
import {
  projectDetailNavigationProps,
  questionListData,
} from "../../util/navigationProps";
import { FirstQuoteFlatlist, ReportFlatlist } from "../../components/Flatlists";
import { useFocusEffect } from "@react-navigation/native";
import createSideNavigator from "../../components/SideNavigator";
import { reports } from "../../util/reports";
import { firstQuotes } from "../../util/firstQuotes";
export const FirstQuoteMobile = ({
  route,
  navigation,
}: StackScreenProps<projectDetailNavigationProps, "FirstQuote">) => {
  const setHeaderOptions = useContext(HeaderDispatch);
  useFocusEffect(() => {
    setHeaderOptions({
      title: "First Quote",
      left: <Appbar.BackAction onPress={() => navigation.goBack()} />,
    });
  });
  const thisProject = useContext(ProjectDetailContext);
  if (thisProject.general?.everythingFilledOut)
    return (
      <FirstQuoteFlatlist
        navigate={(
          name: keyof projectDetailNavigationProps,
          params: questionListData | undefined
        ) => navigation.navigate({ name: name, params: params })}
      />
    );
  else return <Text>Please fill out all the previous sections first</Text>;
};
export const FirstQuoteDesktop = ({
  route,
}: StackScreenProps<projectDetailNavigationProps, "FirstQuote">) => {
  const [highlightedStep, setHighlightedStep] = useState(0);
  const Side = createSideNavigator();
  const thisProject = useContext(ProjectDetailContext);
  if (thisProject.general?.everythingFilledOut) {
    return (
      <Side.Navigator
        initialRouteName="Questions"
        screenOptions={{
          left: (
            sideNavigate: (
              name: keyof projectDetailNavigationProps,
              params?: questionListData
            ) => void,
            unmountSide
          ) =>
            FirstQuoteFlatlist({
              navigate: (
                name: keyof projectDetailNavigationProps,
                params: questionListData | undefined
              ) => sideNavigate(name, params),
              highlightedStep: highlightedStep,
              setHighlightedStep: (number) => {
                setHighlightedStep(number);
                if (number == -1) unmountSide();
              },
            }),
        }}
      >
        <Side.Screen
          name="Questions"
          component={QuestionList}
          initialParams={{
            displayedQuestionCollection: firstQuotes(
              thisProject.general.contractBeforeSurvey || false,
              thisProject.general?.inOfficeContract || false
            )[0].question_collection,
            valueStoragePath: "projects",
            valueStorageDocument: thisProject.id,
            categoryOfAnswers: "project",
            nestedPath: firstQuotes(
              thisProject.general.contractBeforeSurvey || false,
              thisProject.general?.inOfficeContract || false
            )[0].nestedPath,
          }}
        />
      </Side.Navigator>
    );
  } else return <Text>Please fill out all the previous sections first</Text>;
};
