import React from 'react';
import { View } from 'react-native';
import Icon from '@expo/vector-icons/AntDesign';
import { colors } from '../../../styles/colors';
import InfoBanner from '../InfoBanner/InfoBanner';
import Typography from '../Typography/Typography';
import styles from './errorFileListStyles';

export interface ErrorFileListProps {
    title: string;
    fileNames: string[];
}

const ErrorFileList: React.FC<ErrorFileListProps> = ({
    title,
    fileNames
}) => {
    return (
        <View style={styles.bannerWrapper}>
            <InfoBanner type='error' message=''>
                <Typography color={colors.supportErrorRed}>{title}</Typography>
                {fileNames.map((fileName) => (
                    <View style={styles.fileName}>
                        <Icon
                            name={'file1'}
                            size={12}
                            color={colors.supportErrorRed}
                            style={styles.icon} />
                        <Typography color={colors.supportErrorRed} size={14}>{fileName}</Typography>
                    </View>
                ))}
            </InfoBanner>
        </View>
    );
};

export default ErrorFileList;
