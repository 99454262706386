import { Appbar } from "react-native-paper";

export const HeaderBar = (props: {
    title?: string;
    right?: React.ReactNode;
    left?: React.ReactNode;
}) => {
    return (
        <Appbar.Header style={{ height: 72 }}>
            {props.left}
            <Appbar.Content
                title={props.title}
                titleStyle={{ fontFamily: 'RobotoBold' }}
                style={{ flexShrink: 0.5, flexGrow: 4 }} />
            {props.right}
        </Appbar.Header>
    );
};
